import { enableShortcutCounters } from '@gonfalon/dogfood-flags';

import { Shortcut } from './types';
/**
 * Returns true if the shortcut supports rich mode, which is currently only
 * supported for flags shortcuts that filter on state=readyToArchive.
 */
export function shortcutSupportsRichMode(shortcut: Shortcut) {
  if (!enableShortcutCounters()) {
    return false;
  }

  if (shortcut.type === 'flags') {
    return (
      shortcut.filters?.filter?.state === 'readyToArchive' || shortcut.filters?.filter?.guardedRollout !== undefined
    );
  }

  return false;
}
