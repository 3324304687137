import React from 'react';
import { getFlagVariationIndexFromId } from '@gonfalon/flags';
import { unitsToIncludeInTimeDisplay } from '@gonfalon/measured-rollouts';
import { formatDuration, intervalToDuration } from 'date-fns';
import { getVariationName } from 'ia-poc/services/flag-creation/utils';
import { isImmutable, List } from 'immutable';

import { Cell, Grid, GridLayoutValue } from 'components/ui/grid';
import Variation from 'components/Variation';
import { colorVariation, Variation as FlagVariation } from 'utils/flagUtils';
import { MeasuredRolloutSemanticInstructionV2 } from 'utils/instructions/shared/types';

interface Props {
  semanticInstruction: MeasuredRolloutSemanticInstructionV2;
  variations: List<FlagVariation>;
}

export const MeasuredRolloutRuleInstructionDescription = ({ semanticInstruction, variations }: Props) => {
  const duration = intervalToDuration({
    start: 0,
    end: semanticInstruction.monitoringWindowMilliseconds ?? 0,
  });

  const formattedDuration =
    formatDuration(duration, {
      delimiter: ', ',
      format: unitsToIncludeInTimeDisplay(duration),
    }) || '0 seconds';

  const GridRow = ({ label, value }: { label: string; value: React.ReactNode }) => (
    <Grid layout={GridLayoutValue.ONE_OF_TWO} gap="2">
      <Cell>{label}:</Cell>
      <Cell>{value}</Cell>
    </Grid>
  );

  const metrics = isImmutable(semanticInstruction?.metrics)
    ? semanticInstruction?.metrics?.toJS()
    : semanticInstruction?.metrics;

  const controlVariationIndex = getFlagVariationIndexFromId([...variations], semanticInstruction.controlVariationId);
  const controlVariationName = getVariationName([...variations], controlVariationIndex);

  return (
    <Grid direction="column" gap="1">
      <GridRow
        label="Control"
        value={<Variation color={colorVariation(controlVariationIndex)} value={controlVariationName} />}
      />
      <GridRow label="Rollout duration" value={<strong>{formattedDuration}</strong>} />
      {metrics &&
        metrics?.length > 0 &&
        metrics.map((metric, index) => (
          <GridRow
            key={index}
            label={metric.metricKey}
            value={
              <>
                {metric.regressionThreshold ? (
                  <span>
                    <strong>{metric.regressionThreshold * 100}%</strong> threshold,&nbsp;
                  </span>
                ) : (
                  ''
                )}
                auto rollback <strong>{metric.onRegression?.rollback ? 'on' : 'off'}</strong>
              </>
            }
          />
        ))}
    </Grid>
  );
};
