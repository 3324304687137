import { constants } from 'utils/reduxUtils';

/* eslint-disable import/no-default-export */
export default constants('flags', [
  'REQUEST_FLAGS',
  'REQUEST_FLAGS_FAILED',
  'RECEIVE_FLAGS',
  'REQUEST_FLAG',
  'REQUEST_DEPENDENT_FLAGS_WITH_ENV',
  'RECEIVE_DEPENDENT_FLAGS_WITH_ENV',
  'RECEIVE_DEPENDENT_FLAGS_WITH_ENV_FAILED',
  'REQUEST_DEPENDENT_FLAGS',
  'RECEIVE_DEPENDENT_FLAGS',
  'RECEIVE_DEPENDENT_FLAGS_FAILED',
  'REQUEST_FLAG_FAILED',
  'RECEIVE_FLAG',
  'REQUEST_FLAG_STATUSES',
  'REQUEST_FLAG_STATUSES_FAILED',
  'RECEIVE_FLAG_STATUSES',
  'REQUEST_FLAG_STATUS',
  'REQUEST_FLAG_STATUS_FAILED',
  'RECEIVE_FLAG_STATUS',
  'DELETE_FLAG',
  'DELETE_FLAG_DONE',
  'DELETE_FLAG_FAILED',
  'UPDATE_FLAG',
  'UPDATE_FLAG_DONE',
  'UPDATE_FLAG_FAILED',
  'CREATE_FLAG',
  'CREATE_FLAG_DONE',
  'CREATE_FLAG_FAILED',
  'EDIT_FLAG',
  'ADD_VARIATION',
  'REMOVE_VARIATION',
  'EDIT_VARIATION',
  'CHECK_ACCESS_RESOURCE',
  'INITIALIZE_PROJECT_FLAG_MAINTAINER',
  'INITIALIZE_FLAG_CONFIG_MANAGER',
  'DESTROY_FLAG_CONFIG_MANAGER',
  'CHANGE_FLAG_CONFIGURATION',
  'RESET_FLAG_CONFIGURATION',
  'RESET_FLAG_CONFIGURATION_SETTING',

  'INITIALIZE_VARIATION_MANAGER',
  'DESTROY_VARIATION_MANAGER',
  'CHANGE_VARIATIONS',
  'RESET_VARIATIONS',
  'EDIT_PROJECT_FLAG_MAINTAINER',
  'EDIT_PROJECT_FLAG_SETTINGS',
  'UPDATE_PROJECT_FLAG_SETTINGS',
  'UPDATE_PROJECT_FLAG_SETTINGS_DONE',
  'UPDATE_PROJECT_FLAG_SETTINGS_FAILED',

  'EDIT_RULE_EXCLUSION',
  'UPDATE_RULE_EXCLUSION',
  'UPDATE_RULE_EXCLUSION_DONE',
  'UPDATE_RULE_EXCLUSION_FAILED',

  'UPDATE_FLAG_GOALS',
  'UPDATE_FLAG_GOALS_DONE',
  'UPDATE_FLAG_GOALS_FAILED',

  'REQUEST_GOAL_RESULTS',
  'REQUEST_GOAL_RESULTS_FAILED',
  'REQUEST_GOAL_RESULTS_DONE',
  'DELETE_GOAL_RESULTS',
  'DELETE_GOAL_RESULTS_FAILED',
  'DELETE_GOAL_RESULTS_DONE',

  'REQUEST_EXPERIMENT_SUMMARY_RESULTS',
  'REQUEST_EXPERIMENT_SUMMARY_RESULTS_FAILED',
  'REQUEST_EXPERIMENT_SUMMARY_RESULTS_DONE',

  'REQUEST_EXPERIMENT_SERIES_RESULTS',
  'REQUEST_EXPERIMENT_SERIES_RESULTS_FAILED',
  'REQUEST_EXPERIMENT_SERIES_RESULTS_DONE',

  'RESET_EXPERIMENT',
  'RESET_EXPERIMENT_FAILED',
  'RESET_EXPERIMENT_DONE',

  'CREATE_EXPERIMENT_INTERVAL',
  'CREATE_EXPERIMENT_INTERVAL_FAILED',
  'CREATE_EXPERIMENT_INTERVAL_FAILED_GUIDANCE',

  'UPDATE_FLAG_DEBUG',
  'UPDATE_FLAG_DEBUG_DONE',
  'UPDATE_FLAG_DEBUG_FAILED',

  'CHANGE_FLAG_PROJECT',
  'CHANGE_FLAG_SELECTION',

  'LOAD_MORE_FLAGS',
  'RESET_PAGINATION',
]);
