import { type ButtonProps, Button, Link } from '@launchpad-ui/components';

import { Account } from 'utils/accountUtils';
import { getComponentAnalytics } from 'utils/analyticsUtils';
import { trackContactSalesLinkClicked } from 'utils/contactSalesUtils';

import styles from './styles/ContactUsButton.module.css';

export type ContactUsButtonProps = {
  onOpenModal: () => void;
  account?: Account;
  featureKind?: string;
  isEnterpriseOnlyFeature: boolean;
  isAddOnFeature: boolean;
  componentForAnalyticTracking: string;
  contactSales?: boolean;
  className?: string;
  isLink?: boolean;
  isTrialBanner?: boolean;
  cta?: string;
  buttonKind?: ButtonProps['variant'];
  buttonSize?: ButtonProps['size'];
};

const ContactUsButton = ({
  onOpenModal,
  componentForAnalyticTracking,
  contactSales,
  className,
  isLink,
  isTrialBanner,
  cta = 'Contact us',
  buttonSize,
  buttonKind = 'primary',
}: ContactUsButtonProps) => {
  const componentType: 'button' | 'link' = isLink ? 'link' : 'button';

  const handleTracking = () =>
    trackContactSalesLinkClicked(
      getComponentAnalytics({
        component: componentForAnalyticTracking,
        type: componentType,
        cta,
      }),
    );

  const handleContactUs = () => {
    onOpenModal();
    handleTracking();
  };
  return (
    <>
      {isLink ? (
        <Link className={styles.link} onPress={handleContactUs}>
          {isTrialBanner ? 'get in touch.' : cta}
        </Link>
      ) : (
        <Button variant={buttonKind} size={buttonSize} className={className} onPress={handleContactUs}>
          {contactSales ? 'Contact sales' : cta}
        </Button>
      )}
    </>
  );
};

/* eslint-disable import/no-default-export */
export default ContactUsButton;
