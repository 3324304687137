import { GetMetricsQueryParams } from '@gonfalon/openapi';
import { getMetricsQuery } from '@gonfalon/rest-api';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useSuspenseMetrics = (projectKey: string, query?: GetMetricsQueryParams) =>
  useSuspenseQuery(
    getMetricsQuery({
      projectKey,
      query,
    }),
  );
