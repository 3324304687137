import { GetExperimentsQueryParams } from '@gonfalon/openapi';
import { getExperimentsQuery } from '@gonfalon/rest-api';
import { useQuery } from '@tanstack/react-query';

export const useExperiments = (
  {
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetExperimentsQueryParams;
  },
  options?: { enabled?: boolean },
) =>
  useQuery({
    ...getExperimentsQuery({ projectKey, environmentKey, query: params }),
    ...options,
  });
