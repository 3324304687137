import { GetMetricQueryParams } from '@gonfalon/openapi';
import { getMetricQuery } from '@gonfalon/rest-api';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useSuspenseMetric = (projectKey: string, metricKey: string, query?: GetMetricQueryParams) =>
  useSuspenseQuery(
    getMetricQuery({
      projectKey,
      metricKey,
      query,
    }),
  );
