import type { ReactNode } from 'react';
import { toastQueue, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import { PressResponder } from '@react-aria/interactions';

type CopyToClipboardProps = {
  children: ReactNode;
  text: string;
  tooltip?: string;
  showTooltip?: boolean;
};

export const CopyToClipboard = ({
  children,
  text,
  tooltip = 'Copy to clipboard',
  showTooltip = true,
}: CopyToClipboardProps) => {
  const handlePress = async () => {
    try {
      await navigator.clipboard.writeText(text);
      toastQueue.add({ title: 'Copied!', status: 'success' });
    } catch (error) {
      toastQueue.add({ title: 'Unable to copy', status: 'error' });
    }
  };

  return (
    <TooltipTrigger>
      <PressResponder onPress={handlePress}>{children}</PressResponder>
      {showTooltip && <Tooltip placement="bottom">{tooltip}</Tooltip>}
    </TooltipTrigger>
  );
};
