import { memoize } from '@gonfalon/es6-utils';
import dayjs from 'dayjs';

import config from '../app/config';

const { defaultTimezone } = config;

const REFERENCE_DATE = dayjs('2024-01-15 14:30:00');

// Define patterns to match different date formats
const CUSTOM_DATE_FORMATS = [
  // Hour formats
  {
    pattern: /^\d{1,2}:\d{2} [AP]M, \d{1,2}(?:st|nd|rd|th) \w+$/,
    format: 'h:mm A, Do MMM',
  },
  // Day formats
  {
    pattern: /^\w+ \d{1,2}(?:st|nd|rd|th), \d{4}$/,
    format: 'MMM Do, YYYY',
  },
  {
    pattern: /^\d{1,2}(?:st|nd|rd|th) \w+$/,
    format: 'Do MMM',
  },
  // Week formats
  {
    pattern: /^Week of (?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{1,2}(?:st|nd|rd|th), \d{4}$/,
    format: '[Week] of MMM Do, YYYY',
  },
  {
    pattern: /^\d{1,2}(?:st|nd|rd|th) \w+ \d{4}$/,
    format: 'Do MMM YYYY',
  },
  // Month formats
  {
    pattern: /^\w+, \d{4}$/,
    format: 'MMM, YYYY',
  },
  {
    pattern: /^\w+ \d{4}$/,
    format: 'MMM YYYY',
  },
  // Quarter formats
  {
    pattern: /^Q[1-4]-\d{4}$/,
    format: '[Q]Q-YYYY',
  },
  // Year formats
  {
    pattern: /^\d{4}$/,
    format: 'YYYY',
  },
] as const;

type ParseDateToTimeZoneProps = {
  dateString: string;
  timeZone?: string;
  format?: string;
};

// convert date string to any date format in preferred time zone
export function parseDateToTimeZone({
  dateString,
  timeZone = defaultTimezone,
  format = 'YYYY-MM-DD',
}: ParseDateToTimeZoneProps): string {
  const parsedDate = dayjs(dateString);
  // if time zone is locale, parse date to local timezone
  return (timeZone === 'locale' ? parsedDate.local() : parsedDate.tz(timeZone)).format(format);
}

export const detectCustomDateFormat = memoize((dateString: string): string | null => {
  const format = CUSTOM_DATE_FORMATS.find(({ pattern }) => pattern.test(dateString));
  return format ? format.format : null;
});

export const getDateFormatExamples = (dateFormat?: string) => {
  const examples: Record<string, string> = {};
  CUSTOM_DATE_FORMATS.forEach(({ format }) => {
    examples[format] = REFERENCE_DATE.format(format);
  });
  return dateFormat ? examples[dateFormat] : examples;
};

export const isDateFormatValid = ({ format, dateString }: { format?: string; dateString?: string }): boolean => {
  const supportedFormatExample = getDateFormatExamples(format);
  return !!supportedFormatExample || dayjs(dateString).isValid();
};

export const convertDateFormat = (dateString: string, options: { targetFormat?: string } = {}): string => {
  const { targetFormat = 'YYYY-MM-DD' } = options;

  const format = detectCustomDateFormat(dateString);
  if (!format) {
    return isDateFormatValid({ dateString }) ? dayjs(dateString).format(targetFormat) : '';
  }

  try {
    // Special case for quarter format
    if (dateString.match(/^Q[1-4]-\d{4}$/)) {
      const [quarter, year] = dateString.replace('Q', '').split('-');
      const date = dayjs(`${year}-${Number(quarter) * 3 - 2}-01`);
      return date.format(targetFormat);
    }

    const cleanDateString = dateString.replace(/^Week of\s+/, '');
    const date = dayjs(cleanDateString, format.replace(/^\[Week] of /, ''));

    if (!date.isValid()) {
      throw new Error(`Invalid date: ${dateString}`);
    }

    return date.format(targetFormat);
  } catch (error) {
    return '';
  }
};

// Usage examples:
/*
// Convert to different formats
(convertDateFormat('Jan 15th, 2024', { targetFormat: 'MMM Do, YYYY' }));
(convertDateFormat('2:30 PM, 15th Jan', { targetFormat: 'h:mm A' }));
(convertDateFormat('Week of Jan 15th, 2024', { targetFormat: 'Do MMM YYYY' }));
(convertDateFormat('Q1-2024', { targetFormat: 'MMMM YYYY' }));

// With custom default format
(convertDateFormat('Jan 15th, 2024', { defaultFormat: 'DD/MM/YYYY' }));

// test valid format
(isDateFormatValid('h:mm A, Do MMM')); // true
(isDateFormatValid('[Week] of MMM Do, YYYY')); // true
(isDateFormatValid('invalid-format')); // false
*/
