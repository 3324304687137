import { Link } from 'react-router';
import {
  toApplication,
  toAuthorization,
  toCustomRoles,
  toExperimentDesign,
  toFlag,
  toHref,
  toMember,
  toMetricDetails,
  toProjectSettings,
  toRelayProxies,
  toReleasePipeline,
  toSegmentTargeting,
  toTeamMembers,
} from '@gonfalon/navigator';
import { resourceSpecifierFromString } from '@gonfalon/resource-specifiers';

import { isCreation } from '../isCreation';
import { isDeletion } from '../isDeletion';
import { AuditLogEntryRep, MemberDataRep, SubjectDataRep, TokenSummaryRep } from '../types';

import { EntrySummaryClickableObject } from './EntrySummaryClickableObject';
import { kindFromIdentifier } from './EnvironmentResourceV2';
import { Resource } from './Resource';

import styles from './EntryTitle.module.css';

type EntryTitleActorProps = {
  organizationName?: string;
  member?: MemberDataRep;
  subject?: SubjectDataRep;
  token?: TokenSummaryRep;
};

const EntryTitleActor = ({ organizationName, member, subject, token }: EntryTitleActorProps) => {
  const renderMemberName = (m: MemberDataRep) => {
    const { firstName, lastName, email } = m;
    if (firstName || lastName) {
      return `${firstName} ${lastName}`;
    }
    return email;
  };
  if (subject) {
    return subject?._links?.target?.href ? (
      <Link className="u-currentColor" to={subject?._links?.target?.href}>
        {subject.name}
      </Link>
    ) : (
      <>{subject.name}</>
    );
  }
  if (member) {
    return (
      <EntrySummaryClickableObject
        title={member.email}
        to={toHref(toMember({ memberId: member._id || '' }))}
        className="u-currentColor"
      >
        {renderMemberName(member)}
      </EntrySummaryClickableObject>
    );
  }
  if (token && token.serviceToken) {
    return <>A service token</>;
  }
  if (organizationName) {
    return <>{organizationName}</>;
  }
  return <>Someone</>;
};

type EntryTitleProps = {
  organizationName?: string;
  entry: AuditLogEntryRep;
};

export const EntryTitle = ({ organizationName, entry }: EntryTitleProps) => {
  const { subject, member, parent, target, titleVerb, token, kind } = entry;
  let verbClass;

  if (isCreation(entry)) {
    verbClass = 'u-positive';
  }

  if (isDeletion(entry)) {
    verbClass = 'u-negative';
  }
  const resource = target?.resources?.[0];
  let path = target?._links?.site?.href;
  if (resource) {
    switch (kind) {
      case 'release-pipeline':
        const { val, ok } = resourceSpecifierFromString(resource);
        if (ok) {
          if (val.type === 'release-pipeline') {
            path = toHref(toReleasePipeline({ releasePipelineKey: val.name, projectKey: val.project.name }));
          }
        }
        break;
      case 'flag':
        const { val: flagVal, ok: flagOk } = resourceSpecifierFromString(resource);
        if (flagOk) {
          if (flagVal.type === 'flag') {
            path = toHref(
              toFlag({
                flagKey: flagVal.name,
                projectKey: flagVal.environment.project.name,
                environmentKey: flagVal.environment.name,
              }),
            );
          }
        }
        break;
      case 'segment':
        const { val: segmentVal, ok: segmentOk } = resourceSpecifierFromString(resource);
        if (segmentOk) {
          if (segmentVal.type === 'segment') {
            path = toHref(
              toSegmentTargeting({
                segmentKey: segmentVal.name,
                projectKey: segmentVal.environment.project.name,
                environmentKey: segmentVal.environment.name,
              }),
            );
          }
        }
        break;
      case 'experiment':
        const { val: experimentVal, ok: experimentOk } = resourceSpecifierFromString(resource);
        if (experimentOk) {
          if (experimentVal.type === 'experiment') {
            path = toHref(
              toExperimentDesign({
                projectKey: experimentVal.environment.project.name,
                environmentKey: experimentVal.environment.name,
                experimentKey: experimentVal.name,
              }),
            );
          }
        }
        break;
      case 'metric':
        const { val: metricVal, ok: metricOk } = resourceSpecifierFromString(resource);
        if (metricOk) {
          if (metricVal.type === 'metric') {
            path = toHref(
              toMetricDetails({
                projectKey: metricVal.project.name,
                metricKey: metricVal.name,
              }),
            );
          }
        }
        break;
      case 'project':
        const { val: projectVal, ok: projectOk } = resourceSpecifierFromString(resource);
        if (projectOk) {
          if (projectVal.type === 'proj') {
            path = toHref(toProjectSettings({ projectKey: projectVal.name }));
          }
        }
        break;
      case 'role':
        const { val: roleVal, ok: roleOk } = resourceSpecifierFromString(resource);
        if (roleOk) {
          if (roleVal.type === 'role' && target?.name) {
            // this one is special because we get a GUID as the resource name but the U.I expects a role name (resource name is not valid)
            const search = new URLSearchParams();
            search.append('q', target.name);
            path = toHref(
              toCustomRoles({
                search,
              }),
            );
          }
        }
        break;
      case 'member':
        const { val: memberVal, ok: memberOk } = resourceSpecifierFromString(resource);
        if (memberOk) {
          if (memberVal.type === 'member') {
            path = toHref(toMember({ memberId: memberVal.name }));
          }
        }
        break;
      case 'relay-proxy-config':
        const { val: relayProxyConfigVal, ok: relayProxyConfigOk } = resourceSpecifierFromString(resource);
        if (relayProxyConfigOk) {
          if (relayProxyConfigVal.type === 'relay-proxy-config') {
            // no ability to route to specific relay proxy config
            path = toHref(toRelayProxies());
          }
        }
        break;
      case 'application':
        const { val: applicationVal, ok: applicationOk } = resourceSpecifierFromString(resource);
        if (applicationOk) {
          if (applicationVal.type === 'application') {
            path = toHref(toApplication({ applicationKey: applicationVal.name }));
          }
        }
        break;
      case 'team':
        const { val: teamVal, ok: teamOk } = resourceSpecifierFromString(resource);
        if (teamOk) {
          if (teamVal.type === 'team') {
            path = toHref(toTeamMembers({ teamKey: teamVal.name }));
          }
        }
        break;
      case 'service-token':
        const { val: serviceTokenVal, ok: serviceTokenOk } = resourceSpecifierFromString(resource);
        if (serviceTokenOk) {
          if (serviceTokenVal.type === 'service-token') {
            path = toHref(toAuthorization());
          }
        }
        break;
      default:
    }
  }

  return (
    <span id={`${entry._id}-title`} className={styles.title} data-test-id="entry-title">
      <EntryTitleActor organizationName={organizationName} member={member} subject={subject} token={token} />{' '}
      <span className={verbClass}>{titleVerb}</span>{' '}
      {target?.resources?.length && (
        <Resource
          kind={kindFromIdentifier(target?.resources[0])}
          id={target?.resources[0]}
          name={target?.name ?? 'Resource'}
          siteLink={path}
        />
      )}
      {parent?.resource && (
        <span>
          {' '}
          in{' '}
          <Resource
            id={parent?.resource}
            kind={kindFromIdentifier(parent.resource)}
            name={parent?.name ?? 'Resource'}
            siteLink={target?._links?.site?.href}
          />
        </span>
      )}
    </span>
  );
};
