import dayjs from 'dayjs';

import { DatePeriodUnitsForDateFilter, DatePoint, HousewareDateFilter } from '../../../types/types';
import convertToTitleCase from '../../../utils/transformToTitleCase';
import { emptyDateLabel } from '../constants';

export function getFormattedGranularity(v: 'week' | 'month') {
  return v === 'week' ? 'isoWeek' : 'month';
}

export function getHumanDateStrFromDateObject(dateWindow: DatePoint['window'] | undefined): string | undefined {
  if (dateWindow?.value === undefined) {
    return undefined;
  }
  if (dateWindow.unit === 'day') {
    if (dateWindow.value === 1) {
      return 'Yesterday';
    }
    if (dateWindow.value === 0) {
      return 'Today';
    }
  }
  if (dateWindow.value === 0) {
    return `This ${dateWindow.unit}`;
  }
  if (dateWindow.value === 1) {
    return `Last ${dateWindow.unit}`;
  }
  return undefined;
}

export function getDateObjectFromHumanStr(dateStr: string): {
  from: DatePoint['window'];
  to: DatePoint['window'];
} {
  const today = {
    unit: 'day' as const,
    value: 0,
  };

  if (dateStr === 'Today') {
    return {
      from: today,
      to: today,
    };
  }
  if (dateStr === 'Yesterday') {
    return {
      from: {
        unit: 'day',
        value: 1,
      },
      to: today,
    };
  }
  const [relativePeriod, unit] = dateStr.split(' ');

  return {
    from: {
      unit: unit as DatePeriodUnitsForDateFilter,
      value: relativePeriod === 'This' ? 0 : 1,
    },
    to: today,
  };
}

export function getHumanReadableLabelForDateSelector<T extends DatePoint | undefined>(datepoint: T) {
  if (datepoint?.window) {
    const dateStr = getHumanDateStrFromDateObject(datepoint.window);
    return dateStr ?? `Last ${datepoint.window.value} ${datepoint.window.unit}`;
  }
  return datepoint?.date ? dayjs(datepoint.date).format('MMM Do YYYY') : 'Select date';
}

export function getHumanDateLabelFromDateFilter(dateFilter?: HousewareDateFilter): {
  isValid: boolean;
  label: string;
} {
  if (!dateFilter) {
    return {
      isValid: false,
      label: emptyDateLabel,
    };
  }
  if (!dateFilter.view || dateFilter.view === 'presets') {
    const label = getHumanDateStrFromDateObject(dateFilter?.from?.window);
    return {
      isValid: !!label,
      label: label || emptyDateLabel,
    };
  }
  if (['since', 'on'].includes(dateFilter.view)) {
    if (!dateFilter.from?.date) {
      return {
        isValid: false,
        label: emptyDateLabel,
      };
    }
    return {
      isValid: true,
      label: `${convertToTitleCase(dateFilter.view)} ${dateFilter.from?.date}`,
    };
  }

  if (dateFilter.view === 'last') {
    const offsetLabel = dateFilter?.offset?.value
      ? `offset by ${dateFilter?.offset?.value} ${dateFilter?.offset?.unit}s`
      : '';
    if (dateFilter.from?.window?.value === null || dateFilter.from?.window?.unit === null) {
      return {
        isValid: false,
        label: emptyDateLabel,
      };
    }
    return {
      isValid: true,
      label: `Last ${dateFilter.from?.window?.value} ${dateFilter.from?.window?.unit}s ${offsetLabel}`,
    };
  }

  if (dateFilter.view === 'between') {
    if (!dateFilter.from?.date && !dateFilter.to?.date) {
      return {
        isValid: false,
        label: emptyDateLabel,
      };
    }
    return {
      isValid: true,
      label: `${getHumanReadableLabelForDateSelector(
        dateFilter.from,
      )} to ${getHumanReadableLabelForDateSelector(dateFilter.to)}`,
    };
  }

  return {
    isValid: false,
    label: emptyDateLabel,
  };
}
