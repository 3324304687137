import cx from 'clsx';

import styles from './DataList.module.css';

export type DataListProps = {
  className?: string;
  compact?: boolean;
  testId?: string;
  children?: React.ReactNode;
};

export function DataList({ className, compact, testId, children }: DataListProps) {
  return (
    <div className={cx(styles.dataList, className, { [styles.dataListCompact]: compact })} data-test-id={testId}>
      <div className={styles.dataListWrapper}>
        <div className={styles.dataListContent}>{children}</div>
      </div>
    </div>
  );
}
