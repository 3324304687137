import { type Segment, type SegmentRule, type SegmentRuleClause } from '@gonfalon/openapi';

export const actions = {
  addBigSegmentIncludedTargets: (values: string[]) => ({ type: 'ADD_BIG_SEGMENT_INCLUDED_TARGETS', values }) as const,
  addClausesToRule: (ruleId: string, clauses: SegmentRuleClause[]) =>
    ({ type: 'ADD_CLAUSES_TO_RULE', ruleId, clauses }) as const,
  addExpiringTarget: (contextKind: string, contextKey: string, targetType: string, value: number) =>
    ({ type: 'ADD_EXPIRING_TARGET', contextKind, contextKey, targetType, value }) as const,
  addRule: (rule: SegmentRule, beforeRuleId?: string, beforeRuleKey?: string) => {
    if (beforeRuleKey && beforeRuleId) {
      return { type: 'ADD_RULE', rule, beforeRuleId, beforeRuleKey } as const;
    } else if (beforeRuleId) {
      return { type: 'ADD_RULE', rule, beforeRuleId } as const;
    }
    return { type: 'ADD_RULE', rule } as const;
  },
  addValuesToClause: (ruleId: string, clauseId: string, values: unknown[]) =>
    ({ type: 'ADD_VALUES_TO_CLAUSE', ruleId, clauseId, values }) as const,
  changeRuleDescription: (ruleId: string, description: string, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_DESCRIPTION', ruleId, description, isNewRule }) as const,
  changeRuleRollout: (ruleId: string, weight: number, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_ROLLOUT', ruleId, weight, isNewRule }) as const,
  changeRuleRolloutContextKind: (ruleId: string, contextKind: string, isNewRule: boolean) =>
    ({ type: 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND', ruleId, contextKind, isNewRule }) as const,
  editClause: (ruleId: string, clause: SegmentRuleClause, isNewRule: boolean) =>
    ({ type: 'EDIT_CLAUSE', ruleId, clause, isNewRule }) as const,
  processBigSegmentImport: (importId: string) => ({ type: 'PROCESS_BIG_SEGMENT_IMPORT', importId }) as const,
  removeBigSegmentIncludedTargets: (values: string[]) =>
    ({ type: 'REMOVE_BIG_SEGMENT_INCLUDED_TARGETS', values }) as const,
  removeClausesFromRule: (ruleId: string, clauseIds: string[]) =>
    ({ type: 'REMOVE_CLAUSES_FROM_RULE', ruleId, clauseIds }) as const,
  removeExpiringTarget: (contextKind: string, contextKey: string, targetType: string) =>
    ({ type: 'REMOVE_EXPIRING_TARGET', contextKind, contextKey, targetType }) as const,
  removeRule: (ruleId: string) => ({ type: 'REMOVE_RULE', ruleId }) as const,
  removeValuesFromClause: (ruleId: string, clauseId: string, values: unknown[]) =>
    ({ type: 'REMOVE_VALUES_FROM_CLAUSE', ruleId, clauseId, values }) as const,
  reorderRules: (ruleIds: string[]) => ({ type: 'REORDER_RULES', ruleIds }) as const,
  resetAllInstructions: () => ({ type: 'RESET_ALL_INSTRUCTIONS' }) as const,
  resetRuleInstructions: (ruleId: string) => ({ type: 'RESET_RULE_INSTRUCTIONS', ruleId }) as const,
  resetRuleRollout: (ruleId: string, isNewRule: boolean) =>
    ({ type: 'RESET_RULE_ROLLOUT', ruleId, resetRollout: true, isNewRule }) as const,
  save: (currentSegment: Segment) => ({ type: 'SAVE', currentSegment }) as const,
  undoExpiringTarget: (contextKind: string, contextKey: string, targetType: string, value: number) =>
    ({ type: 'UNDO_EXPIRING_TARGET', contextKind, contextKey, targetType, value }) as const,
  updateAddRule: (ruleKey: string, beforeRuleId?: string, beforeRuleKey?: string) =>
    ({
      type: 'UPDATE_ADD_RULE',
      ruleKey,
      beforeRuleId,
      beforeRuleKey,
    }) as const,
  updateClause: (ruleId: string, clauseId: string, clause: SegmentRuleClause) =>
    ({ type: 'UPDATE_CLAUSE', ruleId, clauseId, clause }) as const,
  updateExcludedTargets: (
    targets: string[],
    contextKind: string,
    kind: 'removeExcludedTargets' | 'addExcludedTargets',
  ) => ({ type: 'UPDATE_EXCLUDED_TARGETS', targets, contextKind, kind }) as const,
  updateExcludedUsers: (targets: string[], kind: 'removeExcludedUsers' | 'addExcludedUsers') =>
    ({ type: 'UPDATE_EXCLUDED_USERS', targets, kind }) as const,
  updateExpiringTarget: (contextKind: string, contextKey: string, targetType: string, value: number, version: number) =>
    ({ type: 'UPDATE_EXPIRING_TARGET', contextKind, contextKey, targetType, value, version }) as const,
  updateIncludedTargets: (
    targets: string[],
    contextKind: string,
    kind: 'removeIncludedTargets' | 'addIncludedTargets',
  ) => ({ type: 'UPDATE_INCLUDED_TARGETS', targets, contextKind, kind }) as const,
  updateIncludedUsers: (targets: string[], kind: 'removeIncludedUsers' | 'addIncludedUsers') =>
    ({ type: 'UPDATE_INCLUDED_USERS', targets, kind }) as const,
};

export type SegmentPendingChangesActionsType = ReturnType<(typeof actions)[keyof typeof actions]>;
