import { createTrackerForCategory } from '@gonfalon/analytics';
import { isNil } from '@gonfalon/es6-utils';
import { addDays, addHours, addMinutes } from 'date-fns';
import { List, Map, Record } from 'immutable';

import {
  ContextTargetingExpirationUpdates,
  ExpiringContextTargetsByContextKindAndKey,
} from 'reducers/expiringContextTargets';
import { WaitDurationUnit } from 'utils/scheduledChangesUtils';

import { getExpiringTargetInstructionsForKey } from './instructions/expiringTargets/helpers';
import { ExpiringTargetsInstructionKind } from './instructions/expiringTargets/types';

export type ContextTargetingExpirationProps = {
  scheduledContextTarget: ExpiringContextTarget;
  scheduledContextTargets: List<ExpiringContextTarget>;
  userKey: string;
  type: string;
  flagKey: string;
  envKey: string;
  projKey: string;
  segmentKey?: string;
  timestamp: number;
};

export enum ContextTargetingExpirationInstructionKind {
  REMOVE = 'removeExpiringTarget',
  ADD = 'addExpiringTarget',
  UPDATE = 'updateExpiringTarget',
}

export type ExpiringContextValuesInstructionProps = {
  contextKind: string;
  contextKey: string;
  flagKey?: string;
  updatedExpirationDate?: number | null;
  variationId?: string;
  version?: number;
  targetType?: string;
  segmentKey?: string;
};

export type ContextTargetingExpirationInstruction = {
  kind: ExpiringTargetsInstructionKind;
  contextKind: string;
  contextKey: string;
  flagKey?: string;
  variationId?: string;
  value?: number;
  segmentKey?: string;
  targetType?: string;
  version?: number;
};

export type ExpiringContextValueUpdateForFlag = {
  updatedExpirationDate?: number;
  variationId: string | null;
  targetType?: string | null;
  instruction: ContextTargetingExpirationInstruction;
};

export enum SegmentVariationTypes {
  INCLUDED = 'included',
  EXCLUDED = 'excluded',
}

type flagKey = string;
export type ContextTargetExpirationUpdateByFlagKey = Map<flagKey, ExpiringContextValueUpdateForFlag>;

type FeatureWorkflowType = {
  _creationDate: string;
  _resourceId: { projectKey: string; environmentKey: string; flagKey: string };
  kind: 'expiration';
  stages: Array<{
    _id: string;
    conditions: Array<{
      _id: string;
      kind: 'schedule';
      scheduleMetadata: {
        kind: string;
        waitDuration: number;
        waitDurationUnit: string;
        scheduleDate: number;
      };
    }>;
    action: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      patch: Array<{ kind: string; variationId: string; values: any[] }>;
    };
  }>;
  execution: { status: string; startDate: string };
  _links: {
    self: {
      href: string;
      type: string;
    };
  };
};

export class FeatureWorkflow extends Record<FeatureWorkflowType>({
  _creationDate: '',
  execution: {
    status: '',
    startDate: '',
  },
  _resourceId: {
    projectKey: '',
    environmentKey: '',
    flagKey: '',
  },
  kind: 'expiration',
  stages: [
    {
      _id: '5234567890abcdef12345678',
      conditions: [
        {
          _id: '6234567890abcdef12345678',
          kind: 'schedule',
          scheduleMetadata: {
            kind: 'absolute',
            waitDuration: 0,
            waitDurationUnit: '',
            scheduleDate: 0,
          },
        },
      ],
      action: {
        patch: [
          {
            kind: 'removeContextTargets',
            variationId: '',
            values: [''],
          },
        ],
      },
    },
  ],
  _links: {
    self: {
      href: '',
      type: '',
    },
  },
}) {
  getVariationId() {
    return this.toJS().stages[0].action.patch[0].variationId;
  }
  getExpirationDate() {
    const scheduleMetadata = this.toJS().stages[0].conditions[0].scheduleMetadata;
    if (scheduleMetadata.scheduleDate) {
      return scheduleMetadata.scheduleDate;
    } else if (scheduleMetadata.kind === 'relative') {
      return getRelativeWaitDuration(scheduleMetadata.waitDurationUnit, scheduleMetadata.waitDuration);
    }
    return null;
  }
  selfLink() {
    return this.getIn(['_links', 'self', 'href']);
  }
  getContextId() {
    return this.toJS().stages[0].action.patch[0].values[0];
  }
  getKey() {
    const resource = this.toJS()._resourceId;
    return `${resource.flagKey}/${resource.projectKey}/${resource.environmentKey}`;
  }
  getFlagKey() {
    return this.toJS()._resourceId.flagKey;
  }
  updateScheduleDate(newDate: number) {
    return this.setIn(['stages', [0], 'conditions', [0], 'scheduleMetadata', 'scheduleDate'], newDate);
  }
}

export class ExpiringContextTarget extends Record({
  _id: '',
  _version: 0,
  expirationDate: 0,
  variationId: '',
  targetType: '',
  contextKey: '',
  contextKind: '',
  flagKey: '',
  _resourceId: {
    environmentKey: '',
    flagKey: '',
    key: '',
    kind: '',
    projectKey: '',
  },
  _links: {
    self: {
      href: '',
      type: '',
    },
  },
}) {
  getResourceKindKey() {
    return this.getIn(['_resourceId', 'key']);
  }

  getKey() {
    const resource = this.toJS()._resourceId;
    return `${resource.flagKey}/${resource.projectKey}/${resource.environmentKey}`;
  }
  getVariationId() {
    return this.get('variationId');
  }

  getTargetType() {
    return this.get('targetType');
  }

  getVariationIdOrTargetType() {
    return this.getVariationId() || this.getTargetType();
  }

  getExpirationDate() {
    return this.toJS().expirationDate;
  }
  selfLink() {
    return this.getIn(['_links', 'self', 'href']);
  }
  getContextKey() {
    return this.get('contextKey');
  }
  getContextKind() {
    return this.get('contextKind');
  }
  getFlagKey() {
    return this.getIn(['_resourceId', 'flagKey']);
  }
}

export function createExpiringContextTarget(props?: object) {
  return props instanceof ExpiringContextTarget ? props : new ExpiringContextTarget(props);
}

const getRelativeWaitDuration = (waitDurationUnit: string, waitDuration: number) => {
  if (waitDurationUnit === WaitDurationUnit.DAY) {
    return addDays(new Date(), waitDuration).valueOf();
  } else if (waitDurationUnit === WaitDurationUnit.MINUTE) {
    return addMinutes(new Date(), waitDuration).valueOf();
  } else if (waitDurationUnit === WaitDurationUnit.HOUR) {
    return addHours(new Date(), waitDuration);
  }
};

//get list of workflows that relate to a flag in a given project/environment
export function getMapOfScheduledWorkFlowsForGivenKey(workFlowEntities: List<ExpiringContextTarget>, key: string) {
  return workFlowEntities.filter((entity) => entity.getKey() === key);
}

export const getContextTargetingExpirationInstructionKind = (
  originalExpirationDate?: number | null,
  updatedExpirationDate?: number | null,
) => {
  if (
    originalExpirationDate === updatedExpirationDate ||
    (isNil(originalExpirationDate) && isNil(updatedExpirationDate))
  ) {
    return null;
  }
  if (isNil(originalExpirationDate)) {
    return ContextTargetingExpirationInstructionKind.ADD;
  }
  if (isNil(updatedExpirationDate)) {
    return ContextTargetingExpirationInstructionKind.REMOVE;
  }
  return ContextTargetingExpirationInstructionKind.UPDATE;
};

export const getContextTargetingExpirationInstructionsForKey = (
  contextTargetingExpirationUpdates: ContextTargetingExpirationUpdates,
  key: string,
): ContextTargetingExpirationInstruction[] => {
  const expirationWorkflowForContext = contextTargetingExpirationUpdates.get(key);
  if (expirationWorkflowForContext) {
    const instructions: ContextTargetingExpirationInstruction[] = [];
    expirationWorkflowForContext
      .valueSeq()
      .forEach((updatesByContextKey: Map<string, ExpiringContextValueUpdateForFlag>) => {
        updatesByContextKey.valueSeq().forEach((update) => {
          instructions.push(update.instruction);
        });
      });
    return instructions;
  }
  return [];
};

export const getContextTargetingExpirationInstructionsForFlag = (
  contextTargetingExpirationUpdates: ContextTargetingExpirationUpdates,
  flagKey: string,
): ContextTargetingExpirationInstruction[] => {
  const expirationWorkflowForContext = contextTargetingExpirationUpdates.get(flagKey);
  if (expirationWorkflowForContext) {
    const instructions: ContextTargetingExpirationInstruction[] = [];
    expirationWorkflowForContext
      .valueSeq()
      .forEach((updatesByContextKey: Map<string, ExpiringContextValueUpdateForFlag>) => {
        updatesByContextKey.valueSeq().forEach((update) => {
          instructions.push(update.instruction);
        });
      });
    return instructions;
  }
  return [];
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const getDeletedWorkflowsContextAndFlagKeys = (
  instructions: ContextTargetingExpirationInstruction[],
): List<{ contextKey: string; flagKey: string }> =>
  List(
    instructions
      .filter(
        (instruction: ContextTargetingExpirationInstruction) =>
          instruction.kind === ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE,
      )
      .map((instruction: ContextTargetingExpirationInstruction) => ({
        contextKind: instruction.contextKind,
        contextKey: instruction.contextKey,
        flagKey: instruction.flagKey!,
      })),
  ); /* eslint-enable @typescript-eslint/no-non-null-assertion */

export const getDeletedWorkflowsContextAndSegmentKey = (
  instructions: ContextTargetingExpirationInstruction[],
  segmentKey: string,
): List<{ contextKey: string }> =>
  List(
    instructions
      .filter(
        (instruction: ContextTargetingExpirationInstruction) =>
          instruction.kind === ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE,
      )
      .map((instruction: ContextTargetingExpirationInstruction) => ({
        contextKey: instruction.contextKey,
        contextKind: instruction.contextKind,
        segmentKey,
      })),
  );

export const getExpiringContextTargetsForSegment = (
  instructions: ContextTargetingExpirationInstruction[],
  segmentKey: string,
): List<{ contextKey: string }> =>
  List(
    instructions
      .filter(
        (instruction: ContextTargetingExpirationInstruction) =>
          instruction.kind === ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE ||
          instruction.kind === ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE,
      )
      .map((instruction: ContextTargetingExpirationInstruction) =>
        createExpiringContextTarget({
          _resourceId: {
            key: segmentKey,
          },
          contextKey: instruction.contextKey,
          contextKind: instruction.contextKind,
          expirationDate: instruction.value,
          targetType: instruction.targetType,
          _version: instruction.version,
        }),
      ),
  );

const createInstructionsForSegmentOrFlagSpecificExpiringValue = ({
  variationId,
  flagKey,
  instruction,
}: {
  variationId: string;
  flagKey?: string;
  instruction: ContextTargetingExpirationInstruction;
}) => {
  const createInstruction = instruction;
  if (variationId === SegmentVariationTypes.EXCLUDED || variationId === SegmentVariationTypes.INCLUDED) {
    createInstruction.targetType = variationId;
  } else {
    if (flagKey) {
      createInstruction.flagKey = flagKey;
    }
    createInstruction.variationId = variationId;
  }
  return createInstruction;
};

export const makeAddContextTargetingExpirationInstruction = (
  contextKind: string,
  contextKey: string,
  flagKey: string,
  variationId: string,
  updatedExpirationDate: number,
): ContextTargetingExpirationInstruction => {
  const instruction: ContextTargetingExpirationInstruction = {
    kind: ExpiringTargetsInstructionKind.ADD_EXPIRE_TARGET_DATE,
    value: updatedExpirationDate,
    contextKind,
    contextKey,
  };
  return createInstructionsForSegmentOrFlagSpecificExpiringValue({ variationId, instruction, flagKey });
};

export const makeUpdateContextTargetingExpirationInstruction = (
  contextKind: string,
  contextKey: string,
  flagKey: string,
  variationId: string,
  updatedExpirationDate: number,
  version: number,
): ContextTargetingExpirationInstruction => {
  const instruction: ContextTargetingExpirationInstruction = {
    kind: ExpiringTargetsInstructionKind.UPDATE_EXPIRE_TARGET_DATE,
    value: updatedExpirationDate,
    contextKind,
    contextKey,
    version,
  };
  return createInstructionsForSegmentOrFlagSpecificExpiringValue({ variationId, instruction, flagKey });
};

export const makeRemoveContextTargetingExpirationInstruction = (
  contextKind: string,
  contextKey: string,
  flagKey: string,
  variationId: string,
): ContextTargetingExpirationInstruction => {
  const instruction: ContextTargetingExpirationInstruction = {
    kind: ExpiringTargetsInstructionKind.REMOVE_EXPIRE_TARGET_DATE,
    contextKind,
    contextKey,
    flagKey,
    variationId,
  };
  if (variationId === SegmentVariationTypes.EXCLUDED || variationId === SegmentVariationTypes.INCLUDED) {
    instruction.targetType = variationId;
  } else {
    instruction.variationId = variationId;
  }
  return instruction;
};

export const getContextTargetingExpirationInfo = (
  contextTargetScheduledForRemoval?: ExpiringContextTarget,
  contextExpirationUpdate?: ExpiringContextValueUpdateForFlag,
): {
  originalExpirationDate: number | null;
  originalExpirationVariationId: string | null;
  updatedExpirationDate: number | null;
  updatedExpirationVariationId: string | null;
} => {
  const originalExpirationDate = contextTargetScheduledForRemoval?.getExpirationDate() || null;
  const updatedExpirationDate = contextExpirationUpdate
    ? contextExpirationUpdate.updatedExpirationDate
    : originalExpirationDate;
  const originalExpirationVariationId =
    contextTargetScheduledForRemoval?.getVariationId() || contextTargetScheduledForRemoval?.getTargetType() || null;

  const updatedExpirationVariationId = contextExpirationUpdate
    ? contextExpirationUpdate.variationId || contextExpirationUpdate.targetType || null
    : originalExpirationVariationId;
  return { originalExpirationDate, originalExpirationVariationId, updatedExpirationDate, updatedExpirationVariationId };
};

export function getContextKeysWithExpirations(
  workflowsByFlagAndEnvKey: ExpiringContextTargetsByContextKindAndKey | undefined,
  contextKind: string,
  targetingExpirationUpdates: ContextTargetingExpirationUpdates,
  flagKey: string,
  variationId: string,
) {
  const workflowsByVariationId = workflowsByFlagAndEnvKey?.get(contextKind)?.filter((entity) => {
    const workflow: ExpiringContextTarget = entity.first();
    return !!workflow && workflow.getVariationId() === variationId;
  });
  if (typeof workflowsByVariationId === 'undefined') {
    return [];
  }
  const contextKeysWithExistingExpirations = workflowsByVariationId.keySeq().toArray();
  const contextKeysWithPendingExpirations: string[] = [];
  targetingExpirationUpdates.get(flagKey)?.forEach((expiringContextValueUpdates) => {
    expiringContextValueUpdates.forEach((pendingExp, contextKey) => {
      if (pendingExp.variationId === variationId) {
        contextKeysWithPendingExpirations.push(contextKey);
      }
    });
  });

  return [...contextKeysWithExistingExpirations, ...contextKeysWithPendingExpirations];
}

export function getExpiringTargetKeys(
  contextKind: string,
  variation: string | SegmentVariationTypes,
  expiringContextTargetsForResource?: ExpiringContextTargetsByContextKindAndKey,
) {
  const expiringContextKeys: string[] = [];

  expiringContextTargetsForResource?.get(contextKind)?.forEach((expiringContextTargets, contextKey) => {
    const expiringContextTarget: ExpiringContextTarget = expiringContextTargets.first();
    if (!!expiringContextTarget && expiringContextTarget.getVariationIdOrTargetType() === variation) {
      expiringContextKeys.push(contextKey);
    }
  });

  return expiringContextKeys;
}

export function getPendingExpiringTargetKeys(
  contextKind: string,
  resourceKey: string,
  variation: string | SegmentVariationTypes,
  targetingExpirationUpdates?: ContextTargetingExpirationUpdates,
) {
  const expiringContextKeys: string[] = [];
  targetingExpirationUpdates
    ?.get(resourceKey)
    ?.get(contextKind)
    ?.forEach((pendingExp, contextKey) => {
      if (pendingExp.variationId === variation) {
        expiringContextKeys.push(contextKey);
      }
    });

  return expiringContextKeys;
}

export function getContextKeysWithExpirationsForSegments(
  expiringTargetsOnSegment: ExpiringContextTargetsByContextKindAndKey | undefined,
  targetingExpirationUpdates: ContextTargetingExpirationUpdates,
  segmentKey: string,
  targetType: SegmentVariationTypes,
) {
  const contextKeysWithExistingExpirations = expiringTargetsOnSegment?.keySeq().toArray();
  const contextKeysWithPendingExpirations: string[] = [];
  targetingExpirationUpdates.get(segmentKey)?.forEach((expiringContextValueUpdatesByContextKind) => {
    expiringContextValueUpdatesByContextKind.forEach((expiringContextValueUpdate, contextKey) => {
      if (expiringContextValueUpdate.variationId === targetType) {
        contextKeysWithPendingExpirations.push(contextKey);
      }
    });
  });

  return [...(contextKeysWithExistingExpirations || []), ...contextKeysWithPendingExpirations];
}

export const trackExpiringContextTargetsEvent = createTrackerForCategory('Expiring Context Targets');

export function filterExpiringTargetChangesByTargetType({
  expirationUpdates,
  targetType,
  flagKey,
  segmentKey,
}: {
  expirationUpdates: ContextTargetingExpirationUpdates;
  targetType: string;
  flagKey?: string;
  segmentKey?: string;
}): ContextTargetingExpirationUpdates {
  const key = flagKey || segmentKey;
  if (!key) {
    return Map();
  }

  const updatesForKey = expirationUpdates.get(key);
  if (!updatesForKey) {
    return Map();
  }

  const contextKinds = updatesForKey.keySeq().toArray();

  let filtered = updatesForKey;

  for (const contextKind of contextKinds) {
    const contextKeys = updatesForKey.get(contextKind)?.keySeq().toArray();
    if (!contextKeys) {
      continue;
    }

    for (const contextKey of contextKeys) {
      const update = updatesForKey.get(contextKind)?.get(contextKey);
      if (!update) {
        continue;
      }

      const { instruction } = update;
      if (instruction.targetType !== targetType) {
        filtered = filtered.deleteIn([contextKind, contextKey]);

        if (filtered.get(contextKind)?.isEmpty()) {
          filtered = filtered.delete(contextKind);
        }
      }
    }
  }

  return expirationUpdates.set(key, filtered);
}

export function getFilteredExpiringTargetInstructionsByTargetType({
  expirationUpdates,
  targetType,
  flagKey,
  segmentKey,
}: {
  expirationUpdates: ContextTargetingExpirationUpdates;
  targetType: string;
  flagKey?: string;
  segmentKey?: string;
}) {
  const key = flagKey || segmentKey;
  if (!key) {
    return [];
  }

  const pendingUpdates = filterExpiringTargetChangesByTargetType({
    expirationUpdates,
    targetType,
    flagKey,
    segmentKey,
  });

  return getExpiringTargetInstructionsForKey(pendingUpdates, key);
}
