import { type JSX } from 'react';

import { EmbeddedConfluence } from './EmbeddedConfluence';
import { isConfluenceLink } from './utils';

export type SmartLink = {
  domain: string;
  render: (props: { href: string; text?: string; onOpen?: () => void; onClose?: () => void }) => JSX.Element | void;
};

export const atlassianSmartLink: SmartLink = {
  domain: 'atlassian.net',
  render: ({ href, text, onOpen, onClose }) => {
    if (!isConfluenceLink(href)) {
      return;
    }

    return <EmbeddedConfluence url={href} buttonLabel={text} onOpen={onOpen} onClose={onClose} />;
  },
};
