import { type JSX } from 'react';
import cx from 'clsx';
import { RequiredAsterisk } from 'launchpad';

import { FieldError, FormGroup, FormHint } from 'components/ui/forms';
import { AnyFormRecord, FormState } from 'utils/formUtils';

import './styles/FormField.css';

type FormFieldProps = {
  isRequired: boolean;
  label?: string;
  name: string;
  htmlFor: string;
  hint?: string;
  errorMessage?: string;
  formState?: FormState<AnyFormRecord>;
  children: JSX.Element;
  className?: string;
  onBlur?: (field: string) => void;
};

export function FormField({
  isRequired,
  label,
  name,
  htmlFor,
  hint,
  errorMessage,
  formState,
  children,
  className,
  onBlur,
}: FormFieldProps) {
  const handleBlur = () => {
    onBlur && onBlur(name);
  };
  return (
    <FormGroup className={cx('FormField', className)} name={name} formState={formState} onBlur={handleBlur}>
      {label && (
        <label htmlFor={htmlFor}>
          {label}
          {isRequired && <RequiredAsterisk />}
        </label>
      )}
      {hint && <FormHint className="FormField-hint u-subtle">{hint}</FormHint>}
      {children}
      <FieldError className="FormField-errorMessage" formState={formState} name={name} errorMessage={errorMessage} />
    </FormGroup>
  );
}
