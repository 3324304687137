import { Focusable, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import cx from 'clsx';

import styles from './styles.module.css';

type PolicyActionProps = {
  action: string;
  description?: string;
  effect?: string;
  readOnly?: boolean;
  className?: string;
};
/* eslint-disable import/no-default-export */
export default function PolicyAction({ action, description, effect, readOnly, className }: PolicyActionProps) {
  const el = (
    <span
      className={cx(
        styles.policyStatementAction,
        {
          [styles.allow]: effect === 'allow',
          [styles.deny]: effect === 'deny',
        },
        className,
      )}
    >
      {action === '*' ? 'all actions' : action}
    </span>
  );

  if (!description) {
    return el;
  }

  if (readOnly) {
    return (
      <div className={className}>
        {el}
        <li>{description}</li>
      </div>
    );
  }

  return (
    <TooltipTrigger>
      <Focusable>
        <span role="button">{el}</span>
      </Focusable>
      <Tooltip>{description}</Tooltip>
    </TooltipTrigger>
  );
}
