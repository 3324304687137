import { isPlainObject } from '@gonfalon/es6-utils';

import { APP_FONT_FAMILY } from '../app/theme/ProductAnalyticsAppThemeProvider';

function isEmpty<T>(value: T): boolean {
  if (value === null) {
    return true;
  }
  if (Array.isArray(value) || typeof value === 'string') {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false;
}

export function getTextWidth(el: HTMLInputElement | null, text: string, fontSize = '1rem') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }
  const textNode = (el && el.value) || text;
  context.font = `${fontSize} ${APP_FONT_FAMILY}`;
  const textMeasurement = context.measureText(textNode);

  return textMeasurement.width;
}

export function removeUndefinedKeys(obj: unknown, removeNullsAndEmpty = false): any {
  if (Array.isArray(obj)) {
    return obj
      .map((item) => removeUndefinedKeys(item, removeNullsAndEmpty))
      .filter((item) => !removeNullsAndEmpty || !isEmpty(item));
  } else if (isPlainObject(obj)) {
    return Object.entries(obj as Record<string, unknown>).reduce(
      (acc, [key, value]) => {
        const cleanedValue = removeUndefinedKeys(value, removeNullsAndEmpty);
        if (value !== undefined && (!removeNullsAndEmpty || (value !== null && !isEmpty(cleanedValue)))) {
          // eslint-disable-next-line no-param-reassign
          acc[key] = cleanedValue;
        }
        return acc;
      },
      {} as Record<string, unknown>,
    );
  }
  return obj;
}
