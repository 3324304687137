import { useSearchParams } from 'react-router';

export const useChangeHistoryDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const hasExistingChangeHistoryEntryId = searchParams.get('activity-auditId');

  const open = ({ changeHistoryEntryId }: { changeHistoryEntryId: string }) => {
    const newParams = new URLSearchParams(searchParams);
    if (hasExistingChangeHistoryEntryId && hasExistingChangeHistoryEntryId === changeHistoryEntryId) {
      return;
    }
    newParams.set('activity-auditId', changeHistoryEntryId);
    setSearchParams(newParams);
  };

  const close = () => {
    if (!hasExistingChangeHistoryEntryId) {
      return;
    }
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('activity-auditId');
    setSearchParams(newParams);
  };

  return {
    open,
    close,
    hasExistingChangeHistoryEntryId,
  };
};
