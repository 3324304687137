import { Member } from '@gonfalon/members';
import { schemas } from '@gonfalon/openapi';
import { checkAccess } from '@gonfalon/permissions';

type AiModelConfig = schemas['ModelConfig'];

export type Action = 'createAIModelConfig' | 'deleteAIModelConfig';

export function checkAIModelConfigAccess(member: Member, action: Action, aiModelConfig?: AiModelConfig) {
  const access = aiModelConfig?._access;
  return checkAccess(member, access, action);
}
