import { toFlagPendingTargetingChanges } from '@gonfalon/navigator';
import { snackbarQueue } from '@launchpad-ui/components';
import { OrderedMap } from 'immutable';

import { WorkflowNotificationLink } from 'components/PendingChanges/WorkflowNotificationLink';
import { GlobalDispatch } from 'reducers';
// eslint-disable-next-line import/no-namespace
import * as ScheduledChangesAPI from 'sources/ScheduledChanges';
import { UrlProps } from 'sources/types/utils';
import { ImmutableServerError } from 'utils/httpUtils';
import { WorkflowKind, WorkflowNotificationKind } from 'utils/pendingChangesUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import {
  ConflictsList,
  ScheduledChange,
  ScheduledFlagChangesOptions,
  ScheduledPatchType,
  ScheduleFetchType,
} from 'utils/scheduledChangesUtils';

const requestUpdateFlagScheduledChange = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'scheduledChanges/UPDATE_FLAG_SCHEDULED_CHANGE', flagKey, projKey, envKey }) as const;

const requestUpdateFlagScheduledChangeDone = (
  envKey: string,
  projKey: string,
  flagKey: string,
  workflowID: string,
  scheduledChange: ScheduledChange,
) =>
  ({
    type: 'scheduledChanges/UPDATE_FLAG_SCHEDULED_CHANGE_DONE',
    envKey,
    projKey,
    flagKey,
    workflowID,
    scheduledChange,
  }) as const;

const requestUpdateFlagScheduledChangeFailed = (error: ImmutableServerError) =>
  ({
    type: 'scheduledChanges/UPDATE_FLAG_SCHEDULED_CHANGE_FAILED',
    error,
  }) as const;

const updateScheduledChangesForFlag =
  (
    { flagKey, projKey, envKey, workflowID, comment, executionDate, instructions }: UrlProps & ScheduledPatchType,
    { shouldRefetchScheduledFlagChanges }: ScheduledFlagChangesOptions,
  ) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestUpdateFlagScheduledChange(flagKey, projKey, envKey));
    return ScheduledChangesAPI.updateScheduledChangesForFlag({
      projKey,
      envKey,
      flagKey,
      workflowID,
      comment,
      instructions,
      executionDate,
    })
      .then(async (scheduledChange) => {
        dispatch(requestUpdateFlagScheduledChangeDone(envKey, projKey, flagKey, workflowID, scheduledChange));
        shouldRefetchScheduledFlagChanges &&
          (await dispatch(fetchScheduledChangesForFlag({ flagKey, projKey, envKey })));
      })
      .catch((error) => {
        dispatch(requestUpdateFlagScheduledChangeFailed(error));
      });
  };

export const requestCreateFlagScheduledChange = () =>
  ({ type: 'scheduledChanges/CREATE_FLAG_SCHEDULED_CHANGE' }) as const;

export const requestCreateFlagScheduledChangeDone = (
  scheduledChange: ScheduledChange,
  flagKey: string,
  envKey: string,
  projKey: string,
) =>
  ({
    type: 'scheduledChanges/CREATE_FLAG_SCHEDULED_CHANGE_DONE',
    scheduledChange,
    flagKey,
    envKey,
    projKey,
  }) as const;

const requestCreateFlagScheduledChangeFailed = (error: ImmutableServerError) =>
  ({
    type: 'scheduledChanges/CREATE_FLAG_SCHEDULED_CHANGE_FAILED',
    error,
  }) as const;

const createFlagScheduledChanges =
  (
    {
      executionDate,
      instructions,
      flagKey,
      envKey,
      projKey,
      comment,
    }: ScheduleFetchType & UrlProps & { comment?: string },
    { shouldRefetchScheduledFlagChanges, shouldNotify = true }: ScheduledFlagChangesOptions,
  ) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestCreateFlagScheduledChange());
    return ScheduledChangesAPI.postScheduledChangesForFlag({
      flagKey,
      projKey,
      envKey,
      executionDate,
      instructions,
      comment,
    })
      .then(async (scheduledChange) => {
        dispatch(requestCreateFlagScheduledChangeDone(scheduledChange, flagKey, envKey, projKey));

        if (shouldNotify) {
          snackbarQueue.add({
            description: 'Changes scheduled successfully',
            action: (
              <WorkflowNotificationLink
                workflowKind={WorkflowKind.SCHEDULED_CHANGE}
                notificationKind={WorkflowNotificationKind.LINK_PENDING_CHANGES_DRAWER}
                pendingChangesLink={toFlagPendingTargetingChanges({
                  projectKey: projKey,
                  flagKey,
                  environmentKey: envKey,
                })}
              />
            ),
            status: 'info',
          });
        }

        shouldRefetchScheduledFlagChanges &&
          (await dispatch(fetchScheduledChangesForFlag({ flagKey, projKey, envKey })));
      })
      .catch((error) => dispatch(requestCreateFlagScheduledChangeFailed(error)));
  };

const requestScheduledChange = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'scheduledChanges/FETCH_FLAG_SCHEDULED_CHANGE', flagKey, projKey, envKey }) as const;

const requestScheduledChangeDone = (
  flagKey: string,
  envKey: string,
  projKey: string,
  scheduledChange: ScheduledChange,
) =>
  ({
    type: 'scheduledChanges/RECEIVE_FLAG_SCHEDULED_CHANGE',
    flagKey,
    envKey,
    projKey,
    scheduledChange,
  }) as const;

const requestScheduledChangeFailed = (flagKey: string, envKey: string, projKey: string, error: ImmutableServerError) =>
  ({
    type: 'scheduledChanges/RECEIVE_FLAG_SCHEDULED_CHANGE_FAILED',
    flagKey,
    envKey,
    projKey,
    error,
  }) as const;

const fetchScheduledChangeForFlag =
  ({ flagKey, projKey, envKey, id }: UrlProps & { id: string }) =>
  (dispatch: GlobalDispatch) => {
    dispatch(requestScheduledChange(flagKey, projKey, envKey));
    ScheduledChangesAPI.getScheduledChangeForFlag({ flagKey, projKey, envKey, id })
      .then((scheduledChange) => {
        dispatch(requestScheduledChangeDone(flagKey, envKey, projKey, scheduledChange));
      })
      .catch((error) => {
        dispatch(requestScheduledChangeFailed(flagKey, envKey, projKey, error));
      });
  };

const requestScheduledChanges = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'scheduledChanges/FETCH_FLAG_SCHEDULED_CHANGES', flagKey, projKey, envKey }) as const;

const requestScheduledChangesDone = (
  flagKey: string,
  envKey: string,
  projKey: string,
  scheduledChanges: OrderedMap<string, ScheduledChange>,
) =>
  ({
    type: 'scheduledChanges/RECEIVE_FLAG_SCHEDULED_CHANGES',
    flagKey,
    envKey,
    projKey,
    scheduledChanges,
  }) as const;

const requestScheduledChangesFailed = (flagKey: string, envKey: string, error: ImmutableServerError) =>
  ({
    type: 'scheduledChanges/RECEIVE_FLAG_SCHEDULED_CHANGES_FAILED',
    flagKey,
    envKey,
    error,
  }) as const;

const fetchScheduledChangesForFlag =
  ({ flagKey, projKey, envKey }: UrlProps) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestScheduledChanges(flagKey, projKey, envKey));
    return ScheduledChangesAPI.getScheduledChangesForFlag({ flagKey, projKey, envKey })
      .then((scheduledChanges) => {
        dispatch(requestScheduledChangesDone(flagKey, envKey, projKey, scheduledChanges));
      })
      .catch((error) => {
        dispatch(requestScheduledChangesFailed(flagKey, envKey, error));
      });
  };

const requestScheduledChangeConflicts = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'scheduledChanges/FETCH_CONFLICTS', flagKey, projKey, envKey }) as const;

const requestScheduledChangeConflictsDone = (
  flagKey: string,
  envKey: string,
  projKey: string,
  conflicts: ConflictsList | null,
) =>
  ({
    type: 'scheduledChanges/RECEIVE_CONFLICTS',
    flagKey,
    envKey,
    projKey,
    conflicts,
  }) as const;

const requestScheduleChangeConflictsFailed = (flagKey: string, envKey: string, error: ImmutableServerError) =>
  ({
    type: 'scheduledChanges/RECEIVE_CONFLICTS_FAILED',
    flagKey,
    envKey,
    error,
  }) as const;

const fetchConflictsFlag =
  ({
    flagKey,
    projKey,
    envKey,
    instructions,
    executionDate,
    existingScheduledChangeId,
  }: UrlProps & ScheduleFetchType) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestScheduledChangeConflicts(flagKey, projKey, envKey));
    return ScheduledChangesAPI.getConflictsForScheduledChangesForFlag({
      flagKey,
      projKey,
      envKey,
      instructions,
      executionDate,
      existingScheduledChangeId,
    })
      .then((conflicts) => {
        dispatch(requestScheduledChangeConflictsDone(flagKey, envKey, projKey, conflicts));
      })
      .catch((error) => {
        dispatch(requestScheduleChangeConflictsFailed(flagKey, envKey, error));
      });
  };

export const clearConflicts = ({ flagKey, projKey, envKey }: UrlProps) =>
  requestScheduledChangeConflictsDone(flagKey, envKey, projKey, null);

const requestDeleteScheduledChanges = (flagKey: string, projKey: string, envKey: string) =>
  ({ type: 'scheduledChanges/DELETE_FLAG_SCHEDULED_CHANGE', flagKey, projKey, envKey }) as const;

const requestDeleteScheduledChangesDone = (envKey: string, projKey: string, flagKey: string, workflowID: string) =>
  ({
    type: 'scheduledChanges/DELETE_FLAG_SCHEDULED_CHANGE_DONE',
    envKey,
    projKey,
    flagKey,
    workflowID,
  }) as const;

const requestDeleteScheduledChangesFailed = (error: ImmutableServerError) =>
  ({
    type: 'scheduledChanges/DELETE_FLAG_SCHEDULED_CHANGE_FAILED',
    error,
  }) as const;

const deleteScheduledChangesForFlag =
  (
    { flagKey, projKey, envKey, workflowID }: UrlProps & { workflowID: string },
    { shouldRefetchScheduledFlagChanges }: ScheduledFlagChangesOptions,
  ) =>
  async (dispatch: GlobalDispatch) => {
    dispatch(requestDeleteScheduledChanges(flagKey, projKey, envKey));
    return ScheduledChangesAPI.deleteScheduledChangesForFlag({ projKey, envKey, flagKey, workflowID })
      .then(async () => {
        dispatch(requestDeleteScheduledChangesDone(envKey, projKey, flagKey, workflowID));
        shouldRefetchScheduledFlagChanges &&
          (await dispatch(fetchScheduledChangesForFlag({ flagKey, projKey, envKey })));
      })
      .catch((error) => {
        dispatch(requestDeleteScheduledChangesFailed(error));
      });
  };

export {
  fetchScheduledChangesForFlag,
  fetchScheduledChangeForFlag,
  createFlagScheduledChanges,
  deleteScheduledChangesForFlag,
  updateScheduledChangesForFlag,
  fetchConflictsFlag,
};

const ScheduledChangesActionCreators = {
  requestUpdateFlagScheduledChange,
  requestUpdateFlagScheduledChangeDone,
  requestUpdateFlagScheduledChangeFailed,
  requestCreateFlagScheduledChange,
  requestCreateFlagScheduledChangeDone,
  requestCreateFlagScheduledChangeFailed,
  requestScheduledChange,
  requestScheduledChangeDone,
  requestScheduledChangeFailed,
  requestScheduledChanges,
  requestScheduledChangesDone,
  requestScheduledChangesFailed,
  requestScheduledChangeConflicts,
  requestScheduledChangeConflictsDone,
  requestScheduleChangeConflictsFailed,
  requestDeleteScheduledChanges,
  requestDeleteScheduledChangesDone,
  requestDeleteScheduledChangesFailed,
};

export type ScheduledChangesAction = GenerateActionType<typeof ScheduledChangesActionCreators>;
