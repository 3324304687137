import { type IconButtonProps, IconButton } from '@launchpad-ui/components';
import cx from 'clsx';

import styles from './ClearButton.module.css';

type ClearButtonProps = Omit<IconButtonProps, 'icon' | 'size' | 'kind'>;

export const ClearButton = ({ className, ...rest }: ClearButtonProps) => (
  <IconButton className={cx(styles.clearButton, className)} size="small" variant="default" {...rest} icon="cancel" />
);
