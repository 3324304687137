import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { difference, noop } from '@gonfalon/es6-utils';

export enum EditableModeContainerIds {
  PREREQUISITES = 'prerequisites',
  INDIVIDUAL_TARGETS = 'individualTargets',
  FALLTHROUGH = 'fallthrough',
  SEGMENT_INCLUDED_CONTEXTS = 'segmentIncludedContexts',
  SEGMENT_EXCLUDED_CONTEXTS = 'segmentExcludedContexts',
  FLAG_TEMPLATES = 'flagTemplates',
}

enum GlobalContainerEvents {
  COLLAPSE_ALL = 'collapseAll',
  EXPAND_ALL = 'expandAll',
  CLOSE_EDIT_MODE_FOR_ALL = 'closeEditModeForAll',
}

type State = {
  registeredContainers: Set<EditableModeContainerIdType>;
  openEditableContainers: Set<EditableModeContainerIdType>;
  collapsedEditableContainers: Set<EditableModeContainerIdType>;
  globalContainerEventPerformed: null | GlobalContainerEvents;
  scrollMargin?: number;
};

const actions = {
  registerContainer: (containerId: EditableModeContainerIdType) =>
    ({ type: 'registerContainer', containerId }) as const,
  deregisterContainer: (containerId: EditableModeContainerIdType) =>
    ({ type: 'deregisterContainer', containerId }) as const,
  openEditModeForContainer: (containerId: EditableModeContainerIdType) =>
    ({ type: 'openEditModeForContainer', containerId }) as const,
  closeEditModeForContainer: (containerId: EditableModeContainerIdType) =>
    ({ type: 'closeEditModeForContainer', containerId }) as const,
  closeEditModeForAllContainers: () => ({ type: 'closeEditModeForAllContainers' }) as const,
  collapseContainer: (containerId: EditableModeContainerIdType) =>
    ({ type: 'collapseContainer', containerId }) as const,
  expandContainer: (containerId: EditableModeContainerIdType) => ({ type: 'expandContainer', containerId }) as const,
  collapseAllContainers: () => ({ type: 'collapseAllContainers' }) as const,
  expandAllContainers: () => ({ type: 'expandAllContainers' }) as const,
  unsetGlobalContainerEvent: () => ({ type: 'unsetGlobalContainerEvent' }) as const,
};

function reducer(state: State, action: ReturnType<(typeof actions)[keyof typeof actions]>) {
  switch (action.type) {
    case 'registerContainer':
      return {
        ...state,
        registeredContainers: new Set([...state.registeredContainers, action.containerId]),
        globalContainerEventPerformed: null,
      };
    case 'deregisterContainer':
      const registeredContainers = new Set(state.registeredContainers);
      registeredContainers.delete(action.containerId);
      return { ...state, registeredContainers, globalContainerEventPerformed: null };
    case 'openEditModeForContainer':
      return {
        ...state,
        openEditableContainers: new Set([...state.openEditableContainers, action.containerId]),
        globalContainerEventPerformed: null,
      };
    case 'closeEditModeForContainer':
      const openEditableContainers = new Set(state.openEditableContainers);
      openEditableContainers.delete(action.containerId);
      return { ...state, openEditableContainers, globalContainerEventPerformed: null };
    case 'closeEditModeForAllContainers':
      return {
        ...state,
        openEditableContainers: new Set<EditableModeContainerIdType>(),
        editModes: new Map(),
        globalContainerEventPerformed: GlobalContainerEvents.CLOSE_EDIT_MODE_FOR_ALL,
      };
    case 'collapseContainer':
      return {
        ...state,
        collapsedEditableContainers: new Set([...state.collapsedEditableContainers, action.containerId]),
        globalContainerEventPerformed: null,
      };
    case 'expandContainer':
      const collapsedEditableContainers = new Set(state.collapsedEditableContainers);
      collapsedEditableContainers.delete(action.containerId);
      return { ...state, collapsedEditableContainers, globalContainerEventPerformed: null };
    case 'collapseAllContainers':
      const containersToCollapse = difference(
        Array.from(state.registeredContainers),
        Array.from(state.openEditableContainers),
      );
      return {
        ...state,
        collapsedEditableContainers: new Set(containersToCollapse),
        globalContainerEventPerformed: GlobalContainerEvents.COLLAPSE_ALL,
      };
    case 'expandAllContainers':
      return {
        ...state,
        collapsedEditableContainers: new Set<EditableModeContainerIdType>(),
        globalContainerEventPerformed: GlobalContainerEvents.EXPAND_ALL,
      };
    case 'unsetGlobalContainerEvent':
      return {
        ...state,
        globalContainerEventPerformed: null,
      };
    default:
      return state;
  }
}

// string in the case of rendering a list where index must be used
export type EditableModeContainerIdType = EditableModeContainerIds | string;

type EditableModeContainerContextType = {
  registerContainer: (containerId: EditableModeContainerIdType) => void;
  deregisterContainer: (containerId: EditableModeContainerIdType) => void;

  openEditModeForContainer: (containerId: EditableModeContainerIdType) => void;
  closeEditModeForContainer: (containerId: EditableModeContainerIdType) => void;
  closeEditModeForAllContainers: () => void;
  isContainerInEditMode: (containerId: EditableModeContainerIdType) => boolean;
  areAnyEditableContainersOpen: boolean;

  collapseContainer: (containerId: EditableModeContainerIdType) => void;
  expandContainer: (containerId: EditableModeContainerIdType) => void;
  isContainerCollapsed: (containerId: EditableModeContainerIdType) => boolean;
  collapseAllContainers: () => void;
  expandAllContainers: () => void;
  globalContainerEventPerformed: null | GlobalContainerEvents;
  unsetGlobalContainerEvent: () => void;
  scrollMargin?: number;
};

const EditableModeContainerContext = createContext<EditableModeContainerContextType>({
  registerContainer: noop,
  deregisterContainer: noop,

  openEditModeForContainer: noop,
  closeEditModeForContainer: noop,
  closeEditModeForAllContainers: noop,
  isContainerInEditMode: () => false,
  areAnyEditableContainersOpen: false,

  collapseContainer: noop,
  expandContainer: noop,
  isContainerCollapsed: () => false,
  collapseAllContainers: noop,
  expandAllContainers: noop,
  globalContainerEventPerformed: null,
  unsetGlobalContainerEvent: noop,
});

type EditableModeContainerContextProviderProps = {
  children: ReactNode;
  scrollMargin?: number;
};

export const EditableModeContainerContextProvider = ({
  children,
  scrollMargin,
}: EditableModeContainerContextProviderProps) => {
  // container state:
  // a container can be either "in edit mode" or "expanded/collapsed"
  // a container can NOT be both "in edit mode" and "expanded/collapsed"
  // a container is expanded by default

  const [state, dispatch] = useReducer(reducer, {
    registeredContainers: new Set<EditableModeContainerIdType>(),
    openEditableContainers: new Set<EditableModeContainerIdType>(),
    collapsedEditableContainers: new Set<EditableModeContainerIdType>(),
    globalContainerEventPerformed: null,
    scrollMargin,
  });

  const { globalContainerEventPerformed, openEditableContainers, collapsedEditableContainers } = state;

  const registerContainer = (containerId: EditableModeContainerIdType) => {
    dispatch({ type: 'registerContainer', containerId });
  };

  const deregisterContainer = (containerId: EditableModeContainerIdType) => {
    dispatch({ type: 'deregisterContainer', containerId });
  };

  const openEditModeForContainer = (containerId: EditableModeContainerIdType) => {
    dispatch({ type: 'openEditModeForContainer', containerId });
  };

  const closeEditModeForContainer = (containerId: EditableModeContainerIdType) => {
    dispatch({ type: 'closeEditModeForContainer', containerId });
  };

  const isContainerInEditMode = useCallback(
    (containerId: EditableModeContainerIdType) => openEditableContainers.has(containerId),
    [openEditableContainers],
  );

  const closeEditModeForAllContainers = () => {
    dispatch({ type: 'closeEditModeForAllContainers' });
  };

  const collapseContainer = (containerId: EditableModeContainerIdType) => {
    dispatch({ type: 'collapseContainer', containerId });
  };

  const expandContainer = (containerId: EditableModeContainerIdType) => {
    dispatch({ type: 'expandContainer', containerId });
  };

  const isContainerCollapsed = useCallback(
    (containerId: EditableModeContainerIdType) => collapsedEditableContainers.has(containerId),
    [collapsedEditableContainers],
  );

  const collapseAllContainers = () => {
    dispatch({ type: 'collapseAllContainers' });
  };

  const expandAllContainers = () => {
    dispatch({ type: 'expandAllContainers' });
  };

  const unsetGlobalContainerEvent = () => {
    dispatch({ type: 'unsetGlobalContainerEvent' });
  };

  const contextValue = useMemo(
    () => ({
      registerContainer,
      deregisterContainer,

      openEditModeForContainer,
      closeEditModeForContainer,
      closeEditModeForAllContainers,
      isContainerInEditMode,
      areAnyEditableContainersOpen: openEditableContainers.size > 0,

      collapseContainer,
      expandContainer,
      isContainerCollapsed,
      collapseAllContainers,
      expandAllContainers,
      globalContainerEventPerformed,
      unsetGlobalContainerEvent,
      scrollMargin,
    }),
    [
      isContainerInEditMode,
      openEditableContainers.size,
      isContainerCollapsed,
      globalContainerEventPerformed,
      scrollMargin,
    ],
  );

  return <EditableModeContainerContext.Provider value={contextValue}>{children}</EditableModeContainerContext.Provider>;
};

export const useEditableModeContainerContext = ({ onExpandAll }: { onExpandAll?: () => void } = {}) => {
  const { globalContainerEventPerformed, ...context } = useContext(EditableModeContainerContext);

  useEffect(() => {
    if (globalContainerEventPerformed === GlobalContainerEvents.EXPAND_ALL) {
      onExpandAll?.();
    }
  }, [globalContainerEventPerformed, onExpandAll]);

  return context;
};
