import { Tag, TagGroup, TagList } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import MetricKind, { getMetricKindDisplay, MetricGroupKind } from '../MetricKind';

type MetricLabelProps = {
  kind: MetricKind | MetricGroupKind;
  isNumeric?: boolean;
  unitAggregationType?: 'sum' | 'average';
};

export const MetricLabel = ({ kind, isNumeric, unitAggregationType }: MetricLabelProps) => {
  const { displayIcon, displayName } = getMetricKindDisplay(kind, unitAggregationType, isNumeric);

  return (
    <TagGroup aria-label={displayName}>
      <TagList>
        <Tag size="small" textValue={displayName}>
          <Icon name={displayIcon} />
          {displayName}
        </Tag>
      </TagList>
    </TagGroup>
  );
};
