import { guardedRolloutSchema } from './types';

export function parseGuardedRolloutState(value: unknown) {
  const result = guardedRolloutSchema.safeParse(value);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
