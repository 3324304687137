import { ReactNode } from 'react';
import { createContext } from 'use-context-selector';

import { AppState } from './types';
import useAppReducer, { AppDispatch } from './useAppReducer';
import useAppServices from './useAppServices';

export type AppStore = ReturnType<typeof useAppServices> & {
  state: AppState;
  dispatch: AppDispatch;
};

export const StoreContext = createContext<AppStore>({} as AppStore);

type Props = {
  children: ReactNode;
};

export function StoreProvider({ children }: Props) {
  const [state, dispatch] = useAppReducer();
  const appServies = useAppServices(dispatch);

  return <StoreContext.Provider value={{ state, dispatch, ...appServies }}>{children}</StoreContext.Provider>;
}
