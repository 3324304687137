import { type JSX, Fragment } from 'react';
import { ClauseValue } from '@gonfalon/clauses';
import { useProjectContext, useSelectedEnvironmentKey } from '@gonfalon/context';
import { Time } from '@gonfalon/datetime';
import { DateFormat } from '@gonfalon/format';
import { Segment, SegmentRule, SegmentSemanticInstruction, UpdateRuleRolloutInstruction } from '@gonfalon/openapi';
import { capitalize } from '@gonfalon/strings';
import { List } from 'immutable';

import { ClauseInstructionDescription } from 'components/InstructionList/ClauseInstructionDescription';
import { ClauseValueInstructionEntry } from 'components/InstructionList/ClauseValueInstructionEntry';
import { CollapsibleInstructionListItem } from 'components/InstructionList/CollapsibleInstructionListItem';
import { InstructionListConflictWrapper } from 'components/InstructionList/InstructionListConflicts';
import { InstructionListItem } from 'components/InstructionList/InstructionListItem';
import { InstructionListChangeKind, InstructionListSubCategory } from 'components/InstructionList/instructionListUtils';
import { NestedInstructionListItem } from 'components/InstructionList/NestedInstructionListItem';
import { RuleDescriptionKind } from 'components/InstructionList/RuleInstructionDescription';
import { createClause } from 'utils/clauseUtils';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { ClauseInstructionKind } from 'utils/instructions/clauses/types';
import { ConflictKind, ConflictsInfoForInstruction, SemanticInstruction } from 'utils/instructions/shared/types';

import { useOriginalSegment } from '../hooks/useOriginalSegment';

import { SegmentRuleInstructionDescription } from './SegmentRuleInstructionDescription';

import styles from './SegmentInstructionsList.module.css';

export type SegmentInstructionsListProps = {
  segment: Segment;
  instructions: SegmentSemanticInstruction[];
  approvalEnvKey?: string;
};

const INSTRUCTION_CATEGORY = {
  INCLUDED: 'Included targets',
  EXCLUDED: 'Excluded targets',
  RULES: 'Rules',
};

const generateConflict = (error: string) => ({
  conflictReason: error,
  conflictKind: ConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL,
});

export function SegmentInstructionsList({ instructions, segment, approvalEnvKey }: SegmentInstructionsListProps) {
  const { project } = useProjectContext();
  const projectKey = project.key;
  const environmentKey = useSelectedEnvironmentKey();
  const originalSegment = useOriginalSegment({ segmentKey: segment.key, envKey: approvalEnvKey });

  const segmentToUse = originalSegment ?? segment;
  const { instructionElementsByCategory, instructionIndexToConflictsInfo, conflictInstructionElementsByCategory } =
    generateInstructionElements(instructions, segmentToUse, projectKey, environmentKey, originalSegment);

  const categoriesWithInstructions = (instructionElemsByCategory: { [category: string]: JSX.Element[] }) =>
    Object.keys(instructionElemsByCategory).filter((category) => instructionElemsByCategory[category].length);

  return (
    <>
      {Object.keys(instructionIndexToConflictsInfo).length > 0 && (
        <InstructionListConflictWrapper
          instructions={instructions as SemanticInstruction[]}
          instructionIndexToConflictsInfo={instructionIndexToConflictsInfo}
        >
          <ul className={styles.container}>
            {categoriesWithInstructions(conflictInstructionElementsByCategory).map((category, idx) => (
              <CollapsibleInstructionListItem
                key={idx}
                initialOpen={idx === 0}
                categoryHeader={category}
                categoryInsElems={conflictInstructionElementsByCategory[category]}
              />
            ))}
          </ul>
        </InstructionListConflictWrapper>
      )}
      <ul className={styles.container}>
        {categoriesWithInstructions(instructionElementsByCategory).map((category, idx) => (
          <CollapsibleInstructionListItem
            key={idx}
            initialOpen={idx === 0}
            categoryHeader={category}
            categoryInsElems={instructionElementsByCategory[category]}
          />
        ))}
      </ul>
    </>
  );
}

const makeListItemForTargetingContext = ({
  instructionKind,
  changeKind,
  contextKind,
  instructionValues,
  index,
}: {
  instructionKind: SegmentSemanticInstruction['kind'];
  changeKind: InstructionListChangeKind;
  contextKind: string;
  instructionValues: string[];
  index: number;
}) => (
  <InstructionListItem key={`${instructionKind}-${index}`} changeKind={changeKind}>
    <span key={`${instructionValues.toString()}-${index}`}>
      {capitalize(changeKind)}{' '}
      {instructionValues.map((value, valueIdx) => (
        <Fragment key={valueIdx}>
          <code>{value}</code>
          {valueIdx !== instructionValues.length - 1 ? ', ' : ''}
        </Fragment>
      ))}{' '}
      for context kind <code>{contextKind}</code>
    </span>
  </InstructionListItem>
);

function getExpiringTargetCategory(targetType: string) {
  if (targetType === 'included') {
    return INSTRUCTION_CATEGORY.INCLUDED;
  } else if (targetType === 'excluded') {
    return INSTRUCTION_CATEGORY.EXCLUDED;
  }

  return INSTRUCTION_CATEGORY.INCLUDED;
}

function getNewRuleOrder(ruleIds: string[], rules: SegmentRule[]) {
  const originalOrder = rules;

  const newOrder: typeof originalOrder = [];

  for (const id of ruleIds) {
    for (const rule of originalOrder) {
      if (rule._id === id) {
        newOrder.push(rule);
        break;
      }
    }
  }

  return newOrder;
}

function getRuleRolloutDescription(instruction: UpdateRuleRolloutInstruction, segment: Segment) {
  if (instruction.resetRollout) {
    return 'Update rule rollout to include all targets';
  }

  const rule = segment.rules.find((r) => r._id === instruction.ruleId);
  const contextKind = instruction.contextKind ?? rule?.rolloutContextKind ?? USER_CONTEXT_KIND;
  const weight = instruction.weight ?? rule?.weight ?? 0;

  return `Update rule rollout to include ${weight / 1000}% of ${contextKind} targets`;
}

function generateInstructionElements(
  instructions: SegmentSemanticInstruction[],
  segment: Segment,
  projectKey: string,
  environmentKey: string,
  originalSegment?: Segment,
) {
  const instructionElementsByCategory: { [category: string]: JSX.Element[] } = {
    [INSTRUCTION_CATEGORY.INCLUDED]: [],
    [INSTRUCTION_CATEGORY.EXCLUDED]: [],
    [INSTRUCTION_CATEGORY.RULES]: [],
  };

  const conflictInstructionElementsByCategory: { [category: string]: JSX.Element[] } = {
    //we only have conflicts for rules currently
    [INSTRUCTION_CATEGORY.RULES]: [],
  };

  //This is a required to reuse the InstructionListConflictWrapper
  const instructionIndexToConflictsInfo: { [instructionIndex: number]: ConflictsInfoForInstruction } = {};
  const instructionsByRuleId: { [ruleId: string]: { index: number; elements: JSX.Element[] } } = {};

  const addConflict = (instructionIndex: number, errorMessage: string) => {
    if (!instructionIndexToConflictsInfo[instructionIndex]) {
      instructionIndexToConflictsInfo[instructionIndex] = {
        conflicts: [],
        summaryConflictKind: ConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL,
      };
    }
    instructionIndexToConflictsInfo[instructionIndex].conflicts.push(generateConflict(errorMessage));
  };
  const updateInstructionsForRuleId = (ruleId: string, instructionListItem: JSX.Element) => {
    instructionsByRuleId[ruleId]
      ? instructionsByRuleId[ruleId].elements.push(instructionListItem)
      : (instructionsByRuleId[ruleId] = {
          index: instructions.findIndex((i) => 'ruleId' in i && i.ruleId === ruleId),
          elements: [instructionListItem],
        });
  };

  const addInstructionToCategory = (category: string, instructionListItem: JSX.Element) => {
    instructionElementsByCategory[category].push(instructionListItem);
  };

  function getClauseForRule(ruleId: string, clauseId: string, index: number) {
    const seg = originalSegment ?? segment;
    const existingRule = seg.rules.find((r) => r._id === ruleId);
    if (!existingRule) {
      addConflict(index, `Rule ${ruleId} not found in segment ${seg.name}`);
      return createClause();
    } else {
      const existingClause = existingRule.clauses.find((c) => c._id === clauseId);
      if (!existingClause) {
        addConflict(index, `Clause ${clauseId} not found in rule ${existingRule._id}`);
        return createClause();
      } else {
        return createClause({ ...existingClause, values: existingClause.values as ClauseValue[] });
      }
    }
  }

  instructions.forEach((instruction, idx) => {
    switch (instruction.kind) {
      case 'addBigSegmentIncludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addClauses':
        instruction.clauses.forEach((clause, clauseIdx) => {
          updateInstructionsForRuleId(
            instruction.ruleId,
            <InstructionListItem
              key={`${instruction.kind}-${idx}-clause-${clauseIdx}`}
              changeKind={InstructionListChangeKind.ADD}
            >
              Add clause{' '}
              <ClauseInstructionDescription
                projKey={projectKey}
                envKey={environmentKey}
                clause={createClause({ ...clause, values: clause.values as ClauseValue[] })}
                kind={RuleDescriptionKind.CLAUSE_SUMMARY}
              />
            </InstructionListItem>,
          );
        });
        return;
      case 'addExcludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExcludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addExpiringTarget':
        addInstructionToCategory(
          getExpiringTargetCategory(instruction.targetType),
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.ADD}>
            Schedule removal of context kind <code>{instruction.contextKind}</code>{' '}
            <code>{instruction.contextKey}</code> for{' '}
            <Time
              className={styles.time}
              datetime={instruction.value}
              dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z}
              notooltip
            />
          </InstructionListItem>,
        );
        return;
      case 'addIncludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addIncludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.ADD,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'addRule':
        const { kind, ...rule } = instruction;
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.ADD}>
            Add rule <SegmentRuleInstructionDescription rule={rule} />
          </InstructionListItem>,
        );
        return;
      case 'addValuesToClause':
        updateInstructionsForRuleId(
          instruction.ruleId,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.ADD}>
            <ClauseValueInstructionEntry
              projKey={projectKey}
              envKey={environmentKey}
              clause={getClauseForRule(instruction.ruleId, instruction.clauseId, idx)}
              values={List(instruction.values as ClauseValue[])}
              kind={ClauseInstructionKind.ADD_VALUES_TO_CLAUSE}
            />
          </InstructionListItem>,
        );
        return;
      case 'processBigSegmentImport':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            Upload targets via CSV
          </InstructionListItem>,
        );
        return;
      case 'removeBigSegmentIncludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeClauses':
        instruction.clauseIds.forEach((clauseId, clauseIdx) => {
          updateInstructionsForRuleId(
            instruction.ruleId,
            <InstructionListItem
              key={`${instruction.kind}-${idx}-clause-${clauseIdx}`}
              changeKind={InstructionListChangeKind.REMOVE}
            >
              Remove clause{' '}
              <ClauseInstructionDescription
                projKey={projectKey}
                envKey={environmentKey}
                clause={getClauseForRule(instruction.ruleId, clauseId, idx)}
                kind={RuleDescriptionKind.CLAUSE_SUMMARY}
              />
            </InstructionListItem>,
          );
        });
        return;
      case 'removeExcludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeExcludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.EXCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeExpiringTarget':
        addInstructionToCategory(
          getExpiringTargetCategory(instruction.targetType),
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.REMOVE}>
            Delete scheduled removal of context kind <code>{instruction.contextKind}</code>{' '}
            <code>{instruction.contextKey}</code>
          </InstructionListItem>,
        );
        return;
      case 'removeIncludedTargets':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: instruction.contextKind,
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeIncludedUsers':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.INCLUDED,
          makeListItemForTargetingContext({
            instructionKind: instruction.kind,
            changeKind: InstructionListChangeKind.REMOVE,
            contextKind: 'user',
            instructionValues: instruction.values,
            index: idx,
          }),
        );
        return;
      case 'removeRule':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.REMOVE}>
            Remove rule{' '}
            <SegmentRuleInstructionDescription
              rule={originalSegment ? originalSegment.rules.find((r) => r._id === instruction.ruleId) : undefined}
            />
          </InstructionListItem>,
        );
        return;
      case 'removeValuesFromClause':
        updateInstructionsForRuleId(
          instruction.ruleId,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.REMOVE}>
            <ClauseValueInstructionEntry
              projKey={projectKey}
              envKey={environmentKey}
              clause={getClauseForRule(instruction.ruleId, instruction.clauseId, idx)}
              values={List(instruction.values as ClauseValue[])}
              kind={ClauseInstructionKind.REMOVE_VALUES_FROM_CLAUSE}
            />
          </InstructionListItem>,
        );
        return;
      case 'reorderRules':
        const rules = originalSegment?.rules ?? segment.rules;
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <NestedInstructionListItem
            subCategoryHeader={InstructionListSubCategory.REORDER_RULES}
            isOrderedList
            changeKind={InstructionListChangeKind.REORDER}
            key={`${instruction.kind}-${idx}`}
          >
            {getNewRuleOrder(instruction.ruleIds, rules).map((r, index) => (
              <InstructionListItem
                key={`${instruction.kind}-${idx}-${index}`}
                changeKind={InstructionListChangeKind.DECIMAL}
              >
                <SegmentRuleInstructionDescription rule={r} />
              </InstructionListItem>
            ))}
          </NestedInstructionListItem>,
        );
        return;
      case 'updateClause':
        addInstructionToCategory(
          INSTRUCTION_CATEGORY.RULES,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            Update clause{' '}
            <ClauseInstructionDescription
              projKey={projectKey}
              envKey={environmentKey}
              clause={createClause({ ...instruction.clause, values: instruction.clause.values as ClauseValue[] })}
              kind={RuleDescriptionKind.CLAUSE_SUMMARY}
            />
          </InstructionListItem>,
        );
        return;
      case 'updateExpiringTarget':
        addInstructionToCategory(
          getExpiringTargetCategory(instruction.targetType),
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            Update scheduled removal of context kind <code>{instruction.contextKind}</code>{' '}
            <code>{instruction.contextKey}</code> to{' '}
            <Time
              className={styles.time}
              datetime={instruction.value}
              dateFormat={DateFormat.MM_DD_YYYY_H_MM_A_Z}
              notooltip
            />
          </InstructionListItem>,
        );
        return;
      case 'updateRuleDescription':
        updateInstructionsForRuleId(
          instruction.ruleId,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            Update rule description to {instruction.description}
          </InstructionListItem>,
        );
        return;
      case 'updateRuleRolloutAndContextKind':
        updateInstructionsForRuleId(
          instruction.ruleId,
          <InstructionListItem key={`${instruction.kind}-${idx}`} changeKind={InstructionListChangeKind.CHANGE}>
            {getRuleRolloutDescription(instruction, segment)}
          </InstructionListItem>,
        );
        return;
      default:
    }
  });

  Object.keys(instructionsByRuleId).forEach((ruleId) => {
    if (instructionsByRuleId[ruleId].elements.length === 0) {
      return;
    }
    const ruleIdx = originalSegment ? originalSegment.rules.findIndex((rule) => rule._id === ruleId) : -1;
    const ruleNumber = ruleIdx + 1;
    const rule = originalSegment ? originalSegment.rules[ruleIdx] : undefined;
    const ruleDescription = rule ? rule.description || `Rule ${ruleNumber}` : 'rule not found';

    const insElem = (
      <NestedInstructionListItem
        subCategoryHeader="Edit rule"
        subCategoryDetails={ruleDescription}
        changeKind={InstructionListChangeKind.CHANGE}
        key={ruleId}
      >
        {instructionsByRuleId[ruleId].elements.map((ins) => ins)}
      </NestedInstructionListItem>
    );
    if (instructionIndexToConflictsInfo[instructionsByRuleId[ruleId].index]) {
      conflictInstructionElementsByCategory[INSTRUCTION_CATEGORY.RULES].push(insElem);
    }
    addInstructionToCategory(INSTRUCTION_CATEGORY.RULES, insElem);
  });

  return { instructionElementsByCategory, instructionIndexToConflictsInfo, conflictInstructionElementsByCategory };
}
