import { CSSProperties, ReactNode, useEffect } from 'react';
import { LayoutHeader, LayoutHeaderActions } from '@gonfalon/chrome';
import { Layout } from 'antd';

const { Content } = Layout;

type PageContentProps = {
  children: ReactNode;
  pageTitle?: string;
  noLayoutHeader?: boolean;
  className?: string;
  id?: string;
  style?: CSSProperties;
  layoutHeaderActions?: ReactNode[];
};

export default function PageContent(props: PageContentProps) {
  const { className, id, pageTitle, children, style, noLayoutHeader, layoutHeaderActions } = props;

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
    }
  }, [pageTitle]);

  return (
    <Layout
      className={className}
      style={{
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {!noLayoutHeader && (
        <LayoutHeader>
          <LayoutHeaderActions>{layoutHeaderActions}</LayoutHeaderActions>
        </LayoutHeader>
      )}
      <Content
        style={{
          margin: 0,
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <div
          id={id}
          style={{
            margin: '0 auto',
            width: '100%',
            height: '100%',
            ...style,
          }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
}
