import { minimumRolloutThresholdsV1 } from '@gonfalon/dogfood-flags';

import { hasMeasuredRolloutReachedFinalStage, MeasuredRollout } from '.';

export function isSafeRollForward(measuredRollout: MeasuredRollout) {
  const hasReachedFinalStage = hasMeasuredRolloutReachedFinalStage(measuredRollout);

  return minimumRolloutThresholdsV1() && hasReachedFinalStage
    ? measuredRollout.stageStates[measuredRollout.state.latestStageIndex].safeRollForward
    : true; // when safeRollForward is true, that preserves the current behavior
}
