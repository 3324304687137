import { useParams } from 'react-router';
import { useProjectContext } from '@gonfalon/context';
import { toInstallSDK } from '@gonfalon/navigator';

export function useShouldShowInstallSDK() {
  const projectContext = useProjectContext({ optional: true });
  // TODO: Remove params logic when new IA is fully released and we no longer support the old routes
  const { projKey, projectKey, envKey, environmentKey } = useParams();
  let shouldShowInstallSDK = true;
  let installSDKPath = null;

  if (projectContext && projectContext.project && projectContext.selectedEnvironment) {
    installSDKPath = toInstallSDK({
      projectKey: projectContext.project.key,
      environmentKey: projectContext.selectedEnvironment.key,
    });
  } else if (projKey && envKey) {
    installSDKPath = toInstallSDK({
      projectKey: projKey,
      environmentKey: envKey,
    });
  } else if (projectKey && environmentKey) {
    installSDKPath = toInstallSDK({
      projectKey,
      environmentKey,
    });
  } else {
    shouldShowInstallSDK = false;
  }

  return { shouldShowInstallSDK, installSDKPath };
}
