import { guardedRolloutUIState } from '@gonfalon/dogfood-flags';
import { MeasuredRolloutTimeSeriesResultsWithMetric } from '@gonfalon/measured-rollouts';

export const hasInsufficientResultsForMetric = (
  timeSeriesResults: MeasuredRolloutTimeSeriesResultsWithMetric | undefined,
  minimumDataPoints = 1,
): boolean => {
  // stub out the behavior of metrics temporarily
  const stubbedUIState = guardedRolloutUIState();
  if (stubbedUIState === 'insufficient-sample-size-rolled-back') {
    return true;
  }

  if (!timeSeriesResults?.results?.results?.length) {
    return true;
  }

  return timeSeriesResults.results.results.length < minimumDataPoints;
};
