import { type JSX } from 'react';

import { Details } from 'components/ui/details';

export type CollapsibleInstructionListItemProps = {
  initialOpen: boolean;
  categoryHeader: string;
  categoryInsElems: JSX.Element[];
  hideCategoryHeaders?: boolean;
};

export function CollapsibleInstructionListItem({
  categoryHeader,
  initialOpen,
  categoryInsElems,
  hideCategoryHeaders,
}: CollapsibleInstructionListItemProps) {
  return (
    <li className="CollapsibleInstructionListItem-category">
      {hideCategoryHeaders ? (
        <ul>{categoryInsElems}</ul>
      ) : (
        <Details initialOpen={initialOpen} summary={categoryHeader}>
          <ul>{categoryInsElems}</ul>
        </Details>
      )}
    </li>
  );
}
