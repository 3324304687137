import { type JSX } from 'react';
import { Placement, VisuallyHidden } from 'react-aria';
import { Focusable, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import type { IconProps } from '@launchpad-ui/icons';
import { Icon } from '@launchpad-ui/icons';
import classNames from 'clsx';

import styles from 'stylesheets/components/HelpTooltip.module.css';

export type HelpTooltipProps = {
  placement?: Placement;
  className?: string;
  size?: IconProps['size'];
  children?: string | JSX.Element;
  labelText?: string;
  iconClassName?: string;
  tooltipClassName?: string;
};

function HelpTooltipComponent({
  children,
  placement = 'bottom',
  className,
  size = 'small',
  labelText,
  iconClassName,
  tooltipClassName,
  ...props
}: HelpTooltipProps) {
  return (
    <TooltipTrigger>
      <Focusable>
        <span
          {...props}
          role="button"
          className={classNames(styles.helpTooltip, className)}
          data-test-id="help-tooltip-trigger"
        >
          <VisuallyHidden elementType="span">{labelText ?? 'More information'}</VisuallyHidden>
          <Icon name="info-circle" className={classNames(styles.helpIcon, iconClassName)} size={size} />
        </span>
      </Focusable>
      <Tooltip className={tooltipClassName} placement={placement}>
        {children}
      </Tooltip>
    </TooltipTrigger>
  );
}

/* eslint-disable import/no-default-export */
export default function HelpTooltip(props: HelpTooltipProps) {
  return <HelpTooltipComponent {...props} />;
}
