import { useCallback } from 'react';
import { useProjectContext } from '@gonfalon/context';
import { toProductAnalyticsRoute } from '@gonfalon/navigator';

export default function useProductAnalyticsPathGenerator() {
  const currentContext = useProjectContext();
  const { project, selectedEnvironment } = currentContext;

  const getProductAnalyticsPath = useCallback(
    (relativePath: string, params?: Record<string, string>) =>
      toProductAnalyticsRoute(relativePath, {
        projectKey: project.key,
        environmentKey: selectedEnvironment.key,
        ...params,
      }),
    [project.key, selectedEnvironment.key],
  );

  return getProductAnalyticsPath;
}
