import { type JSX, lazy, Suspense, useEffect } from 'react';
import { LinkButton } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import { StartEnterpriseCampaignContainer } from 'components/billing/StartEnterpriseCampaignContainer';
import DocumentationLink from 'components/DocumentationLink';
import { Module, ModuleContent } from 'components/ui/module';
import {
  getComponentAnalytics,
  trackUpsellCtaClicked,
  trackUpsellFeatureViewed,
  trackUpsellLearnMore,
} from 'utils/analyticsUtils';

import styles from './UpsellFeature.module.css';

const ContactUs = lazy(async () => import(/* webpackChunkName: "ContactUs" */ 'components/contactUs/ContactUs'));

export type UpsellFeatureProps = {
  featureKind: string;
  featureImage?: JSX.Element;
  featureIcon?: JSX.Element;
  title: string;
  titleClassName?: string;
  canManagePlans: boolean;
  isEnterpriseOnlyFeature: boolean;
  isAddOnFeature?: boolean;
  customCta?: JSX.Element;
  learnMoreUrl?: string;
  description?: string | JSX.Element;
  descriptionClassName?: string;
  featureBullets?: string[];
  isModal?: boolean;
  isList?: boolean;
  omitPrimaryCtaButton?: boolean;
  componentForAnalyticTracking?: string;
  featureContent?: React.ReactNode;
  className?: string;
  enterpriseCampaignActive?: boolean;
};

export const UpsellFeature = ({
  canManagePlans,
  featureIcon,
  featureImage,
  featureKind,
  title,
  learnMoreUrl,
  customCta,
  description,
  titleClassName,
  descriptionClassName,
  featureBullets,
  isEnterpriseOnlyFeature,
  isAddOnFeature,
  isModal,
  isList,
  omitPrimaryCtaButton,
  componentForAnalyticTracking = 'Upsell Feature',
  featureContent,
  className,
  enterpriseCampaignActive,
}: UpsellFeatureProps) => {
  useEffect(() => {
    trackUpsellFeatureViewed(
      featureKind,
      getComponentAnalytics({
        component: 'UpsellFeature',
        type: 'view',
      }),
    );
  }, []);

  const renderCtaButton = () => {
    if (!!enterpriseCampaignActive && isEnterpriseOnlyFeature) {
      return null;
    }

    if (isEnterpriseOnlyFeature || isAddOnFeature) {
      return (
        <Suspense fallback={null}>
          <ContactUs
            featureKind={featureKind}
            isEnterpriseOnlyFeature={isEnterpriseOnlyFeature}
            isAddOnFeature={isAddOnFeature}
            componentForAnalyticTracking={componentForAnalyticTracking}
          />
          <StartEnterpriseCampaignContainer upsellFeaturePageKind={featureKind} />
        </Suspense>
      );
    } else if (canManagePlans) {
      return (
        <>
          <LinkButton
            variant="primary"
            href="/settings/billing/plans"
            onPress={() =>
              trackUpsellCtaClicked(
                featureKind,
                'Upgrade',
                getComponentAnalytics({
                  cta: 'Upgrade',
                  component: 'UpsellFeature',
                  type: 'button',
                }),
              )
            }
          >
            Upgrade
          </LinkButton>
          <StartEnterpriseCampaignContainer upsellFeaturePageKind={featureKind} />
        </>
      );
    }
    return (
      <LinkButton variant="primary" href="https://launchdarkly.com/pricing/" target="_blank" rel="noreferrer">
        View plans
      </LinkButton>
    );
  };

  return (
    <div className={cx(styles.upsellFeature, className)} data-test-id="UpsellFeature">
      <Module className={styles.module}>
        <ModuleContent
          className={styles.moduleContent}
          isSeamless={isModal || isList}
          isSnug={isModal}
          data-test-id="UpsellFeature-moduleContent"
        >
          <section className={styles.section}>
            <h2 id="Modal-title" className={cx(styles.title, titleClassName)}>
              {title}
            </h2>
            {featureImage && (
              <div className={styles.image} data-test-id="UpsellFeature-image">
                {featureImage}
              </div>
            )}
            {featureIcon && (
              <div className={styles.icon} data-test-id="UpsellFeature-icon">
                {featureIcon}
              </div>
            )}
            {featureBullets && (
              <ul className={styles.list} data-test-id="UpsellFeature-list">
                {featureBullets.map((bullet, index) => (
                  <li className={styles.listItem} key={`UpsellFeature-listItem--${index}`}>
                    <Icon name="check-circle" />
                    <span className={styles.listText}>{bullet}</span>
                  </li>
                ))}
              </ul>
            )}
            {featureContent && <div className="UpsellFeature-content">{featureContent}</div>}
            {description && (
              <p className={cx(descriptionClassName, styles.paragraphText)} data-test-id="UpsellFeature-paragraphText">
                {description}
              </p>
            )}
            {!!learnMoreUrl && (
              <div className="UpsellFeature-learnMoreLink">
                To learn more,{' '}
                <DocumentationLink
                  href={learnMoreUrl}
                  component="UpsellFeature"
                  onTrackEvent={() =>
                    trackUpsellLearnMore(
                      featureKind,
                      getComponentAnalytics({
                        component: 'UpsellFeature',
                        type: 'button',
                        cta: 'Learn more',
                      }),
                    )
                  }
                />
              </div>
            )}
            {!!customCta ? (
              <div className="UpsellFeature-customCta" data-test-id="UpsellFeature-customCta">
                {customCta}
              </div>
            ) : (
              <div className={styles.cta} data-test-id="UpsellFeature-cta">
                {!omitPrimaryCtaButton && renderCtaButton()}
              </div>
            )}
          </section>
        </ModuleContent>
      </Module>
    </div>
  );
};

/* eslint-disable import/no-default-export */
export default UpsellFeature;
