import { forwardRef } from 'react';
import cx from 'clsx';

import styles from './DataListItem.module.css';

export type DataListItemProps = {
  className?: string;
  isActive?: boolean;
  heading?: boolean;
  children?: React.ReactNode;
  role?: string;
  tabIndex?: number;
  onKeyPress?(): void;
  onClick?(): void;
  testid?: string;
  useSpaceBetween?: boolean;
};

export type Ref = HTMLDivElement;

export const DataListItem = forwardRef<Ref, DataListItemProps>((props, ref) => {
  const { heading, isActive, className, children, useSpaceBetween, ...other } = props;

  return (
    <div
      ref={ref}
      {...other}
      className={cx(
        styles.dataListItem,
        {
          [styles.dataListItemHeading]: heading,
          [styles.dataListItemActive]: isActive,
          [styles.dataListItemSpaceBetween]: useSpaceBetween,
        },
        className,
      )}
    >
      {children}
    </div>
  );
});
