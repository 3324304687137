import { useEffect, useState } from 'react';
import { useDebounce } from '@gonfalon/async';
import { SearchFieldProps as LPSearchFieldProps } from '@launchpad-ui/components';

import { SearchField } from './SearchField';

interface DebouncedSearchFieldProps extends LPSearchFieldProps {
  placeholder?: string | undefined;
}

export function DebouncedSearchField({ value, onChange, onClear, ...props }: DebouncedSearchFieldProps) {
  const [search, setSearch] = useState(value ?? undefined);
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const setSearchDebounced = useDebounce((nextValue: string) => {
    setDebouncedSearch(nextValue);
  }, 250);

  useEffect(() => {
    if (onChange && debouncedSearch !== undefined) {
      onChange(debouncedSearch);
    }
  }, [debouncedSearch]);

  return (
    <SearchField
      {...props}
      value={search}
      onChange={(nextValue) => {
        setSearch(nextValue);
        setSearchDebounced(nextValue);
      }}
      onClear={() => {
        setSearch('');
        onClear?.();
      }}
    />
  );
}
