import { type JSX } from 'react';
import cx from 'clsx';

import './styles.css';

export type ModuleProps = {
  className?: string;
  tagName?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  testId?: string;
};

export function Module({ className, children, tagName, testId, ...props }: ModuleProps) {
  const Tag = tagName || 'div';
  return (
    <Tag {...props} className={cx('Module', className)} data-test-id={testId}>
      {children}
    </Tag>
  );
}
