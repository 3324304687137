import { ReactNode, useMemo } from 'react';
import { css, Global } from '@emotion/react';
import { Theme, useThemeValue } from '@gonfalon/theme';
import type { ThemeConfig } from 'antd';
import { ConfigProvider, theme } from 'antd';

export const APP_FONT_FAMILY = getComputedStyle(document.documentElement).getPropertyValue('--lp-font-family-base');

type ColorsHex = `#${string}`;

type ThemeColors =
  | '--primary-100'
  | '--primary-90'
  | '--primary-60'
  | '--primary-30'
  | '--primary-15'
  | '--primary-10'
  | '--primary-5'
  | '--primary-0'
  | '--secondary-100'
  | '--secondary-90'
  | '--secondary-60'
  | '--secondary-30'
  | '--secondary-15'
  | '--secondary-10'
  | '--secondary-5'
  | '--tertiary-100'
  | '--warning-100';

const themeColors: Record<Theme, Record<ThemeColors, ColorsHex>> = {
  default: {
    '--primary-100': '#425eff',
    '--primary-90': '#536eff',
    '--primary-60': '#879cff',
    '--primary-30': '#bbc8ff',
    '--primary-15': '#d5ddff',
    '--primary-10': '#dde4ff',
    '--primary-5': '#e4eaff',
    '--primary-0': '#e4eaff40',

    '--secondary-100': '#a736ec',
    '--secondary-90': '#ae4aee',
    '--secondary-60': '#c586f4',
    '--secondary-30': '#e0c3fa',
    '--secondary-15': '#efe1fd',
    '--secondary-10': '#f5ebfe',
    '--secondary-5': '#faf5ff',

    '--tertiary-100': '#00a455',
    '--warning-100': '#FFC107',
  },
  dark: {
    '--primary-100': '#425eff',
    '--primary-90': '#536eff',
    '--primary-60': '#879cff',
    '--primary-30': '#bbc8ff',
    '--primary-15': '#d5ddff',
    '--primary-10': '#dde4ff',
    '--primary-5': '#e4eaff',
    '--primary-0': '#e4eaff40',

    '--secondary-100': '#a736ec',
    '--secondary-90': '#ae4aee',
    '--secondary-60': '#c586f4',
    '--secondary-30': '#e0c3fa',
    '--secondary-15': '#efe1fd',
    '--secondary-10': '#f5ebfe',
    '--secondary-5': '#faf5ff',

    '--tertiary-100': '#00a455',
    '--warning-100': '#FFC107',
  },
};

const housewareColors = {
  '--border': 'var(--lp-color-bg-ui-tertiary)',
  '--dark-border': 'var(--lp-color-bg-ui-tertiary)',
  '--background': 'var(--lp-color-bg-ui-primary)',
  '--dark-foreground': 'var(--lp-color-bg-ui-tertiary)',
  '--light-foreground': 'var(--lp-color-bg-ui-secondary)',
  '--disabled-bg': 'var(--lp-color-bg-ui-tertiary)',

  '--danger': 'var(--lp-color-fill-feedback-error)',
  '--success': 'var(--lp-color-fill-feedback-success)',

  '--heading-text': 'var(--lp-color-text-ui-primary-base)',
  '--primary-text': 'var(--lp-color-text-ui-primary-base)',
  '--secondary-text': 'var(--lp-color-text-ui-secondary)',
  '--tertiary-text': 'var(--lp-color-fill-ui-secondary)',
  '--link-text': 'var(--primary-100)',
  '--disabled-text': 'var(--lp-color-fill-ui-secondary)',
};

function getThemeColors(themeMode: Theme) {
  return {
    ...themeColors[themeMode],
    ...housewareColors,
  };
}

function getFontSizeInPxFromRemCssVar(cssVar: string): number {
  const fontSize = getComputedStyle(document.documentElement).getPropertyValue(cssVar);
  const fontSizeInRem = parseFloat(fontSize);
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const fontSizeInPx = fontSizeInRem * rootFontSize;
  return fontSizeInPx;
}

export function getTheme(themeMode: Theme): ThemeConfig {
  const themeAppColors = getThemeColors(themeMode);
  const themeConfig: ThemeConfig = {
    algorithm: themeMode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      // Seed Token
      colorPrimary: themeAppColors['--primary-100'],
      borderRadius: 8,
      fontSize: getFontSizeInPxFromRemCssVar('--lp-font-size-200'),
      colorBorderSecondary: themeAppColors['--border'],
      colorSplit: themeAppColors['--border'],
      colorText: themeAppColors['--primary-text'],
      colorTextHeading: themeAppColors['--heading-text'],
      colorTextDescription: themeAppColors['--secondary-text'],
      fontFamily: APP_FONT_FAMILY,
      colorLink: themeAppColors['--link-text'],
      colorTextDisabled: themeAppColors['--disabled-text'],
      colorError: themeAppColors['--danger'],
      colorBgContainerDisabled: themeAppColors['--disabled-bg'],
      colorInfo: themeAppColors['--primary-100'],
      wireframe: false,
      colorTextBase: themeAppColors['--primary-text'],
    },
    components: {
      Typography: {
        titleMarginBottom: 0,
        colorTextDescription: themeAppColors['--secondary-text'],
        colorLink: themeAppColors['--link-text'],
        colorLinkHover: themeAppColors['--link-text'],
      },
      Menu: {
        activeBarBorderWidth: 0,
        horizontalItemHoverBg: themeAppColors['--background'],
        itemBg: themeAppColors['--background'],
        itemSelectedBg: themeAppColors['--link-text'],
        itemSelectedColor: themeAppColors['--primary-10'],
        itemHeight: 30,
        itemBorderRadius: 6,
        groupTitleColor: themeAppColors['--tertiary-text'],
        groupTitleFontSize: 12,
        itemPaddingInline: 2,
        itemMarginInline: 2,
        iconMarginInlineEnd: 8,
      },
      Avatar: {
        colorTextPlaceholder: themeAppColors['--secondary-60'],
      },
      Button: {
        borderColorDisabled: themeAppColors['--border'],
        dangerColor: themeAppColors['--background'],
        primaryShadow: 'none',
        primaryColor: themeAppColors['--primary-5'],
        colorPrimary: themeAppColors['--link-text'],
        borderRadius: 6,
        paddingInline: 12,
        fontWeight: 500,
        colorError: themeAppColors['--danger'],
        defaultBg: themeAppColors['--background'],
        defaultColor: themeAppColors['--primary-text'],
        defaultBorderColor: themeAppColors['--border'],
        colorLink: themeAppColors['--link-text'],
      },
      Segmented: {
        itemActiveBg: themeAppColors['--background'],
        itemSelectedBg: themeAppColors['--background'],
        itemColor: themeAppColors['--primary-text'],
      },
      Card: {
        colorBgContainer: themeAppColors['--background'],
        actionsLiMargin: '0px',
      },
      Select: {
        clearBg: themeAppColors['--background'],
        selectorBg: themeAppColors['--background'],
        colorBgElevated: themeAppColors['--background'],
        colorPrimaryHover: themeAppColors['--border'],
        colorPrimary: themeAppColors['--border'],
        optionSelectedBg: themeAppColors['--link-text'],
        optionSelectedColor: themeAppColors['--primary-10'],
        optionActiveBg: themeAppColors['--dark-foreground'],
        colorBorder: themeAppColors['--border'],
        borderRadiusSM: 4,
        colorTextPlaceholder: themeAppColors['--tertiary-text'],
      },
      Radio: {
        buttonBg: themeAppColors['--background'],
        buttonColor: themeAppColors['--primary-text'],
        buttonCheckedBg: themeAppColors['--primary-10'],
        buttonSolidCheckedBg: themeAppColors['--primary-10'],
        buttonSolidCheckedActiveBg: themeAppColors['--dark-foreground'],
        buttonSolidCheckedColor: themeAppColors['--primary-90'],
        buttonSolidCheckedHoverBg: themeAppColors['--primary-10'],

        // On Hover over selected
        colorBorder: themeAppColors['--border'],
        borderRadius: 5,
      },
      Popover: {
        colorBgElevated: themeAppColors['--background'],
      },
      Input: {
        activeBorderColor: themeAppColors['--border'],
        hoverBorderColor: themeAppColors['--border'],
        colorBorder: themeAppColors['--border'],
        activeShadow: 'none',
        colorPrimaryHover: themeAppColors['--tertiary-text'],
        colorPrimary: themeAppColors['--secondary-text'],
        colorBgContainer: themeAppColors['--background'],
        colorTextPlaceholder: themeAppColors['--tertiary-text'],
      },
      Tooltip: {
        colorBgSpotlight: 'var(--lp-color-gray-900)',
        fontSize: 12,
        borderRadius: 8,
        boxShadow: 'none',
        colorTextLightSolid: 'var(--lp-color-gray-0)',
      },
      Dropdown: {
        colorBgElevated: themeAppColors['--background'],
        paddingContentHorizontal: 10,
      },
      Divider: {
        colorText: themeAppColors['--tertiary-text'],
        colorTextHeading: themeAppColors['--tertiary-text'],
        fontSize: 4,
        fontSizeLG: 10,
        margin: 0,
        marginLG: 0,
      },
      Drawer: {
        colorBgElevated: themeAppColors['--background'],
      },
      Modal: {
        contentBg: themeAppColors['--background'],
        headerBg: themeAppColors['--background'],
      },
      Table: {
        borderColor: themeAppColors['--border'],
        headerBg: themeAppColors['--background'],
        colorBgContainer: themeAppColors['--background'],
        rowHoverBg: themeAppColors['--dark-foreground'],
        headerSplitColor: themeAppColors['--background'],
        lineWidth: 0.1,
        headerColor: themeAppColors['--secondary-text'],
      },
      Tag: {
        defaultBg: themeAppColors['--dark-foreground'],
        defaultColor: themeAppColors['--primary-text'],
        colorBorder: themeAppColors['--border'],
      },
      Layout: {
        siderBg: themeAppColors['--background'],
        bodyBg: themeAppColors['--background'],
        footerBg: themeAppColors['--background'],
        headerBg: themeAppColors['--background'],
      },
      Tabs: {
        itemColor: themeAppColors['--tertiary-text'],
        itemHoverColor: themeAppColors['--primary-text'],
      },
    },
  };

  return themeConfig;
}

export function ProductAnalyticsAppThemeProvider({ children }: { children: ReactNode }) {
  const themeMode = useThemeValue();
  const { antdTheme, globalTheme } = useMemo(() => {
    const themeAppColors = getThemeColors(themeMode);

    return {
      antdTheme: getTheme(themeMode),
      globalTheme: Object.entries(themeAppColors)
        .map(
          ([key, value]) => `
          ${key}: ${value};
        `,
        )
        ?.join(''),
    };
  }, [themeMode]);

  return (
    <ConfigProvider theme={antdTheme}>
      <Global
        styles={css`
          :root {
            ${globalTheme}
          }
        `}
      />
      {children}
    </ConfigProvider>
  );
}
