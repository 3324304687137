import { createSearchParams } from 'react-router';
import { createStorageSlot } from '@gonfalon/browser-storage';
import { cacheFlagFiltersAndSortOrdering } from '@gonfalon/dogfood-flags';

import { clearEnvironmentFilters } from './clearEnvironmentFilters';
import { parseUIFlagFilterFromBrowser } from './parseUIFlagFilterFromBrowser';
import { cacheKey } from './useCachedFlagListFilters';

const slot = createStorageSlot(cacheKey, {
  storageType: 'sessionStorage',
  isEnabled: cacheFlagFiltersAndSortOrdering(),
});

export function readCachedFlagListFilters(selectedEnvironmentKey?: string) {
  const raw = slot.get();

  if (!raw) {
    return {};
  }

  const params = createSearchParams(raw);
  const returnedFromCache = parseUIFlagFilterFromBrowser(params);
  if (params.has('filterEnv') && params.get('filterEnv') !== selectedEnvironmentKey) {
    return clearEnvironmentFilters(returnedFromCache);
  }

  return returnedFromCache;
}
