import { clearDefaultFlagFilterValues } from './internal/clearDefaultFlagFilterValues';
import { type UIFlagFilter } from './types';

export function serializeUIFlagFilterForBrowser(input: UIFlagFilter) {
  const searchParams = new URLSearchParams();

  const filter = clearDefaultFlagFilterValues(input);

  if (filter !== undefined) {
    if (filter.query) {
      searchParams.set('q', filter.query);
    }

    if (filter.hasDataExport !== undefined) {
      searchParams.set('hasDataExport', String(filter.hasDataExport));
    }

    if (filter.hasExperiment !== undefined) {
      searchParams.set('hasExperiment', String(filter.hasExperiment));
    }

    if (filter.maintainerId !== undefined) {
      searchParams.set('maintainerId', filter.maintainerId);
    }

    if (filter.maintainerTeamKey !== undefined) {
      searchParams.set('maintainerTeamKey', filter.maintainerTeamKey);
    }

    if (filter.tags !== undefined) {
      for (const tag of filter.tags) {
        searchParams.append('tag', tag);
      }
    }

    if (filter.type !== undefined) {
      searchParams.set('type', filter.type);
    }

    if (filter.sdkAvailability !== undefined) {
      searchParams.set('sdkAvailability', filter.sdkAvailability);
    }

    if (filter.guardedRollout !== undefined) {
      searchParams.set('guardedRollout', filter.guardedRollout);
    }

    if (filter.filterEnv !== undefined) {
      searchParams.set('filterEnv', filter.filterEnv);
    }

    if (filter.status !== undefined) {
      searchParams.set('status', filter.status);
    }

    if (filter.evaluated !== undefined) {
      searchParams.set('evaluated', filter.evaluated);
    }

    if (filter.state !== undefined) {
      searchParams.set('state', String(filter.state));
    }

    if (filter.targeting !== undefined) {
      searchParams.set('targeting', filter.targeting);
    }

    if (filter.created !== undefined) {
      searchParams.set('created', filter.created);
    }

    if (filter.contextKindTargeted !== undefined) {
      searchParams.set('contextKindTargeted', filter.contextKindTargeted);
    }

    if (filter.contextKindsEvaluated !== undefined) {
      for (const contextKind of filter.contextKindsEvaluated) {
        searchParams.append('contextKindsEvaluated', contextKind);
      }
    }

    if (filter.followerId !== undefined) {
      searchParams.set('followerId', filter.followerId);
    }

    if (filter.releasePipeline !== undefined) {
      searchParams.set('releasePipeline', filter.releasePipeline);
    }

    if (filter.codeReferences !== undefined) {
      searchParams.set('codeReferences', String(filter.codeReferences));
    }

    if (filter.applicationEvaluated !== undefined) {
      searchParams.set('applicationEvaluated', filter.applicationEvaluated);
    }

    if (filter.segmentTargeted !== undefined) {
      searchParams.set('segmentTargeted', filter.segmentTargeted);
    }
  }

  return searchParams;
}
