import { Component } from 'react';
import { formatJSON } from '@gonfalon/strings';
import { Link, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import invariant from 'tiny-invariant';

import { CodeEditor, CodeEditorComponent } from 'components/CodeEditor';
import ResourceFinderPrompt from 'components/ResourceFinderPrompt';
import { FieldError, FormHint } from 'components/ui/forms';
import { isMac } from 'utils/detectionUtils';
import { FormState } from 'utils/formUtils';
import { getFullResourceString, parseResourceType } from 'utils/policyUtils';
import { trackResourceFinderResourceClicked, trackResourceFinderViewed } from 'utils/roleUtils';

import styles from './styles.module.css';

export type JsonPolicyEditorProps = {
  value: string;
  name: string;
  onChange(value: string): void;
  onBlur?(): void;
  formState?: FormState;
  screenReaderLabel?: string;
};
/* eslint-disable import/no-default-export */
export default class JsonPolicyEditor extends Component<JsonPolicyEditorProps> {
  _editor: CodeEditorComponent | null = null;

  state = {
    resourceTypeAtCursor: '',
    showResourceFinder: false,
    formatted: formatJSON(this.props.value),
  };

  handleChange = (v: string) => {
    const { onChange } = this.props;
    this.setState({ formatted: v });
    onChange(v);
  };

  render() {
    const { name, onBlur, formState } = this.props;
    const { resourceTypeAtCursor, showResourceFinder, formatted } = this.state;
    const metaKey = isMac ? 'Cmd' : 'Ctrl';

    const keyMap: CodeMirror.KeyMap = {
      Tab: false,
      'Shift-Tab': false,
    };
    keyMap[`${metaKey}-.`] = () => this.showResourceFinder();

    return (
      <div className={styles.codeEditorContainer}>
        <CodeEditor
          id={name}
          ref={(editor) => {
            this._editor = editor as unknown as CodeEditorComponent;
          }}
          name={name}
          value={formatted}
          mode="json"
          keyMap={keyMap}
          onBlur={onBlur}
          onChange={this.handleChange}
          screenReaderLabel="Policy editor"
        />

        <FieldError name={name} formState={formState} />

        <FormHint>
          Hint: Type <kbd>{metaKey === 'Cmd' ? '⌘' : 'ctrl'}</kbd> + <kbd>.</kbd> when editing to launch the{' '}
          <TooltipTrigger>
            <Link onPress={this.handleResourceFinderClick}>resource finder</Link>
            <Tooltip placement="bottom">
              The resource finder makes it easy to find the IDs of your account resources
            </Tooltip>
          </TooltipTrigger>
          .
        </FormHint>

        {showResourceFinder && (
          <ResourceFinderPrompt
            resourceType={resourceTypeAtCursor}
            onValue={this.handleResourceFinderValue}
            onCancel={this.handleResourceFinderCancel}
            onClearResourceType={this.handleClearResourceType}
          />
        )}
      </div>
    );
  }

  handleClearResourceType = () => {
    this.setState({ resourceTypeAtCursor: '' });
  };

  handleResourceFinderClick = () => {
    trackResourceFinderViewed();
    this.showResourceFinder();
  };

  handleResourceFinderValue = (id: string, type: string, projKey?: string) => {
    trackResourceFinderResourceClicked();
    this._editor?.withDocument((doc) => {
      invariant(!!doc, 'doc is expected to be truthy.');
      const cursor = doc.getCursor();
      const word = doc.findWordAt(cursor); // XXX: This doesn't include the following chars: .-*
      const str = doc.getRange(word.anchor, word.head);
      const line = doc.getLine(cursor.line).replace(/"/g, '').trim();
      const selection = doc.getSelection();
      const atCursor = doc.getRange({ line: cursor.line, ch: cursor.ch }, { line: cursor.line, ch: cursor.ch + 1 });
      const idString = `"${id}"`;

      if (selection) {
        // if something is selected, just replace it
        doc.replaceSelection(selection.startsWith('"') && selection.endsWith('"') ? idString : id);
      } else if (!line) {
        // if there's no line content, insert a full valid resource id
        const valueToInsert = `"${getFullResourceString(id, type, projKey)}"`;
        if (str.match(/[\w"]+/)) {
          doc.replaceRange(valueToInsert, word.anchor, word.head);
        } else {
          doc.replaceSelection(valueToInsert);
        }
      } else if (str.match(/\w+/)) {
        //
        // if the cursor is at a word but it's not selected, try to replace the whole word
        // XXX: this is known to fail for "words" that contain characters like .-*
        doc.replaceRange(id, word.anchor, word.head);
      } else if (atCursor === '*') {
        // specific check for wildcard, since CodeMirror won't return that as a word
        doc.setSelection({ line: cursor.line, ch: cursor.ch }, { line: cursor.line, ch: cursor.ch + 1 });
        doc.replaceSelection(id);
      } else {
        doc.replaceSelection(`"${getFullResourceString(id, type, projKey)}"`);
      }
    });

    this.closeResourceFinder();
  };

  handleResourceFinderCancel = () => {
    this.closeResourceFinder();
  };

  showResourceFinder() {
    this._editor?.withDocument((doc) => {
      invariant(!!doc, 'doc is expected to be truthy.');
      const cursor = doc.getCursor();
      const upToCursor = doc
        .getRange({ line: cursor.line, ch: 0 }, { line: cursor.line, ch: cursor.ch })
        .replace(/"/g, '')
        .trim();
      this.setState(
        {
          resourceTypeAtCursor: parseResourceType([upToCursor]),
          showResourceFinder: true,
        },
        () => this.lock(),
      );
    });
  }

  closeResourceFinder() {
    this.setState(
      {
        showResourceFinder: false,
      },
      () => this.unlockAndFocus(),
    );
  }

  lock() {
    setTimeout(() => this._editor?.lock());
  }

  unlockAndFocus() {
    setTimeout(() => this._editor?.unlock().focus());
  }
}
