import { getFeatureFlagQuery } from '@gonfalon/rest-api';
import { useFlagKey } from '@gonfalon/router';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useProjectContext } from './useProjectContext';

export function useFlagContext() {
  const { context } = useProjectContext();
  const flagKey = useFlagKey();

  const flag = useSuspenseQuery(
    getFeatureFlagQuery({
      projectKey: context.projectKey,
      featureFlagKey: flagKey,
      query: { env: Array.from(context.environmentKeys) },
    }),
  );

  return { flag: flag.data };
}
