import { EyeOutlined } from '@ant-design/icons';
import { Button, Popover, Space, Tooltip, Typography } from 'antd';

import useAppStoreSelector from '../../../../app/store/useAppStoreSelector';

export default function EventDetailsQuickViewModal({ eventItem, eventMetadata, shouldTruncateEventName }) {
  const updatePreviewAssetState = useAppStoreSelector((s) => s.updatePreviewAssetState);
  const eventObject = typeof eventItem === 'object' && eventItem !== null ? eventItem : { name: eventItem };

  return (
    <div
      style={{
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span>
        {shouldTruncateEventName && eventObject.name.length > 50
          ? `${eventObject.name.slice(0, 50)}...`
          : eventObject.name}
      </span>
      <Popover
        content={
          <Space direction="vertical">
            {eventMetadata?.event_count_l30d !== undefined && eventItem?.custom_event_uuid === null ? (
              <Tooltip title="Times the definition event appeared in the last 30 days">
                <div>Event volume: {eventMetadata?.event_count_l30d.toLocaleString()}</div>
              </Tooltip>
            ) : null}
            <div>Description: {eventItem?.description}</div>
            <Typography.Text type="secondary">Click on the eye button to view more details.</Typography.Text>
          </Space>
        }
        title={<Typography.Text style={{ fontSize: ' 1.5rem' }}>{eventObject.name}</Typography.Text>}
        zIndex={9999}
        placement="right"
      >
        <Button
          size="small"
          type="text"
          icon={<EyeOutlined />}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updatePreviewAssetState({
              eventName: eventObject.name,
            });
          }}
        />
      </Popover>
    </div>
  );
}
