import { ReactNode, useEffect } from 'react';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { useFlagOrAiConfigKey, useProjectKey } from '@gonfalon/router';
import nullthrows from 'nullthrows';

import { ExperimentsProvider } from 'components/experimentation/common/providers/ExperimentsProvider';
import FlagNotFound from 'components/FlagNotFound';
import AppSkeletonBody from 'components/SkeletonLoader/AppSkeletonBody';
import { useManageFlagState } from 'components/useManageFlagState';
import { Member as TMember } from 'utils/accountUtils';
import { Flag } from 'utils/flagUtils';

interface ManageFlagProps {
  envId: string;
  envKey: string;
  flag: Flag;
  flagKey: string;
  isABTestingEnabled: boolean;
  isCodeReferencesEnabled: boolean;
  isAuditLogEnabled: boolean;
  isFlagLinksEnabled: boolean;
  isInAppDemoEnabled: boolean;
  isPresenceEnabled: boolean;
  isWorkflowsTabEnabled: boolean;
  profile: TMember;
  projKey: string;
}

export function LegacyFlagAdapter({
  children,
  isMonitoringTab,
}: {
  children: ((props: ManageFlagProps) => ReactNode) | ReactNode;
  isMonitoringTab?: boolean;
}) {
  const projKey = useProjectKey();
  const envKey = useSelectedEnvironmentKey();
  const flagKey = useFlagOrAiConfigKey();
  const {
    isReady,
    error,
    flag: maybeFlag,
    fetchFlag,
    ...finalProps
  } = useManageFlagState({ projKey, envKey, flagKey });

  useEffect(() => {
    if (!isReady) {
      fetchFlag();
    }
  }, [projKey, envKey, flagKey, isReady]);

  if (!isReady) {
    // We don't want to show the skeleton loader on the monitoring tab,
    return isMonitoringTab ? null : <AppSkeletonBody />;
  }

  if (error) {
    return <FlagNotFound projKey={projKey} envKey={envKey} />;
  }

  const flag = nullthrows(maybeFlag, 'Expected flag');

  return (
    <ExperimentsProvider initialFlagKey={flag.key}>
      {typeof children === 'function' ? children({ ...finalProps, flag }) : children}
    </ExperimentsProvider>
  );
}
