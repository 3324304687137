import { CSSProperties, ReactNode, useState } from 'react';
import { CheckCircleFilled, LinkOutlined } from '@ant-design/icons';
import { Button, ButtonProps, message, Tooltip } from 'antd';

interface Props {
  children?: ReactNode;
  copyText?: string;
  notifyText?: string;
  blocktype?: string;
  representation?: string;
  tooltipTitle?: string;
  size?: 'large' | 'middle' | 'small';
  style?: CSSProperties;
}

export async function copyLink(copyText: string, notifyText?: string) {
  await navigator.clipboard.writeText(copyText);
  if (notifyText) {
    message.success(notifyText);
  }
}

function CopyLinkButton(props: Props & ButtonProps) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    copyLink(props.copyText || window.location.href);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Tooltip placement="top" title={props.tooltipTitle}>
      <Button
        {...props}
        onClick={() => {
          handleCopy();
        }}
      >
        {isCopied ? <CheckCircleFilled style={{ color: 'var(--success)' }} /> : <LinkOutlined />}
        {props.children}
      </Button>
    </Tooltip>
  );
}

export default CopyLinkButton;
