import { useState } from 'react';
import { Alert, Heading, Text } from '@launchpad-ui/components';

export const EXPERIMENTATION_ALERT_HEADER = 'Experiment data may be impacted by the changes you are making.';
export const EXPERIMENTATION_ALERT_TEXT =
  'Iteration results and confidence intervals may update because of changes you’ve made to this flag’s targeting rules.';

/* eslint-disable import/no-default-export */
export default function ExperimentationAlert() {
  const [hide, setHide] = useState(false);
  return !hide ? (
    <section className="u-mt-l">
      <Alert status="error" isDismissable onDismiss={() => setHide(true)}>
        <Heading>{EXPERIMENTATION_ALERT_HEADER}</Heading>
        <Text>{EXPERIMENTATION_ALERT_TEXT}</Text>
      </Alert>
    </section>
  ) : null;
}
