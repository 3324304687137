import { getMetricEventsByEventKeyQuery } from '@gonfalon/rest-api';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useSuspenseEventActivityByEventKey = (projectKey: string, eventKey: string, eventKind: string) =>
  useSuspenseQuery(
    getMetricEventsByEventKeyQuery({
      projectKey,
      eventKey,
      eventKind,
    }),
  );
