import { z } from 'zod';

const statement = z.object({
  actions: z.array(z.string()),
  effect: z.enum(['allow']),
  resources: z.array(z.string()),
});

export const activityURLSchema = z.array(statement);

export const activityHandleSchema = z.object({
  activity: z.union([z.array(statement), z.function().args(z.any()).returns(z.array(statement))]),
});
