import { createTrackerForCategory } from '@gonfalon/analytics';

import { ReleasePipelinePhaseAudienceTrackEventInfo } from './types';

// Release Pipelines
const trackReleasePipelineEvent = createTrackerForCategory('Release Pipelines');
const trackReleasePipelineAttemptedDeleteEnvironment = () =>
  trackReleasePipelineEvent('Delete Environment Included In Release Pipeline Attempted');
const trackReleasePipelineCreateButtonClick = () => trackReleasePipelineEvent('Create Release Pipeline Button Clicked');
const trackReleasePipelineCreated = ({
  audienceCount,
  phaseCount,
  environmentCounts,
  audiences,
}: {
  audienceCount: number;
  phaseCount: number;
  environmentCounts: {
    [key: string]: number;
  };
  audiences?: ReleasePipelinePhaseAudienceTrackEventInfo[];
}) =>
  trackReleasePipelineEvent('Release Pipeline Created', {
    audienceCount,
    phaseCount,
    environmentCounts,
    audiences,
  });
const trackReleasePipelineDeleteButtonClicked = () =>
  trackReleasePipelineEvent('Delete Release Pipeline Button Clicked');
const trackReleasePipelineDeleted = () => trackReleasePipelineEvent('Release Pipeline Deleted');
const trackReleasePipelineEnviromentInMultiplePhases = ({
  projectKey,
  releasePipelineKey,
  environmentKey,
}: {
  projectKey: string;
  releasePipelineKey: string;
  environmentKey: string;
}) =>
  trackReleasePipelineEvent('Release Pipeline Env In Multiple Phases', {
    projectKey,
    releasePipelineKey,
    environmentKey,
  });
const trackReleasePipelineSaveButtonClicked = () => trackReleasePipelineEvent('Save Release Pipeline Button Clicked');

const trackDuplicateButtonClicked = () => trackReleasePipelineEvent('Duplicate Release Pipeline Button Clicked');
const trackMakeDefaultButtonClicked = (isDefault?: boolean) =>
  trackReleasePipelineEvent('Make Default Button Clicked', {
    isDefault,
  });

// Releases View
const trackReleasesViewEvent = createTrackerForCategory('Releases View');
const trackAddFlagMenuItemClicked = (releasePipelineKey: string, flagKey: string) => {
  trackReleasesViewEvent('Flag Item Clicked In Add Flags Menu', {
    releasePipelineKey,
    flagKey,
  });
};

const trackReleasePipelineSwitcherClicked = () => trackReleasesViewEvent('Switched Release Pipeline');

// Releases
const trackReleaseEvent = createTrackerForCategory('Releases');

const trackStartReleaseButtonClicked = () => trackReleaseEvent('Start Release Button Clicked');
const trackCreateReleasePipelineButtonClickedFromOverview = () =>
  trackReleaseEvent('Create Release Pipeline Button Clicked From Flag Overview');
const trackAddReleasePipelineButtonClicked = () => trackReleaseEvent('Add Release Pipeline Button Clicked');
const trackReleaseStarted = () => trackReleaseEvent('Release Started');
const trackMoveToPipelineClicked = () => trackReleaseEvent('Move To Pipeline Button Clicked');
const trackReleasePipelineReplaced = () => trackReleaseEvent('Release Pipeline Replaced');

const trackRemoveReleasePipelineButtonClicked = () => trackReleaseEvent('Remove Release Pipeline Button Clicked');
const trackCancelReleaseButtonClicked = () => trackReleaseEvent('Cancel Release Button Clicked');
const trackReleaseCanceled = () => trackReleaseEvent('Release Canceled');

const trackPhaseStartButtonClicked = () => trackReleaseEvent('Phase Start Button Clicked');
const trackPhaseStarted = () => trackReleaseEvent('Phase Started');
const trackPhaseRestarted = () => trackReleaseEvent('Phase Restarted');

const trackPhaseButtonClicked = () => trackReleaseEvent('Phase Button Clicked');
const trackReleasedButtonClicked = () => trackReleaseEvent('Released Button Clicked');

const trackMarkPhaseCompleteAttempted = () => trackReleaseEvent('Mark Phase Complete Attempted');
const trackMarkPhaseComplete = () => trackReleaseEvent('Phase Marked Complete');
const trackMarkReleaseComplete = () => trackReleaseEvent('Release Marked Complete');

const trackPhaseReactivatedAttempted = () => trackReleaseEvent('Phase Reactivated Attempted');
const trackPhaseReactivated = () => trackReleaseEvent('Phase Reactivated');

const trackReleasesViewReleasePipelineButtonClicked = () =>
  trackReleaseEvent('Releases View Release Pipeline Button Clicked');
const trackManageReleasePipelineLinkClicked = () => trackReleaseEvent('Manage Release Pipeline Link Clicked');

export {
  trackReleasePipelineAttemptedDeleteEnvironment,
  trackReleasePipelineCreateButtonClick,
  trackReleasePipelineCreated,
  trackReleasePipelineDeleted,
  trackReleasePipelineDeleteButtonClicked,
  trackReleasePipelineEnviromentInMultiplePhases,
  trackReleasePipelineSaveButtonClicked,
  trackStartReleaseButtonClicked,
  trackCreateReleasePipelineButtonClickedFromOverview,
  trackAddReleasePipelineButtonClicked,
  trackReleaseStarted,
  trackRemoveReleasePipelineButtonClicked,
  trackCancelReleaseButtonClicked,
  trackReleaseCanceled,
  trackPhaseStartButtonClicked,
  trackPhaseStarted,
  trackPhaseRestarted,
  trackPhaseButtonClicked,
  trackReleasedButtonClicked,
  trackMarkPhaseCompleteAttempted,
  trackMarkPhaseComplete,
  trackMarkReleaseComplete,
  trackPhaseReactivatedAttempted,
  trackPhaseReactivated,
  trackReleasesViewReleasePipelineButtonClicked,
  trackManageReleasePipelineLinkClicked,
  trackAddFlagMenuItemClicked,
  trackDuplicateButtonClicked,
  trackMakeDefaultButtonClicked,
  trackReleasePipelineReplaced,
  trackMoveToPipelineClicked,
  trackReleasePipelineSwitcherClicked,
};
