import { useId } from 'react';
import { Collection, Text } from 'react-aria-components';
import { EnvironmentMarker } from '@gonfalon/environments';
import { SearchField, TextTruncator } from '@gonfalon/launchpad-experimental';
import { Autocomplete, Dialog, Header, ListBox, ListBoxItem, ListBoxSection } from '@launchpad-ui/components';

import { SelectedEnvironment } from '../types';

import { useSelectEnvironment } from './useSelectEnvironment';

import styles from './SelectEnvironmentDialog.module.css';

export function SelectEnvironmentDialog({
  projectKey,
  excludedIds,
  onSelect,
}: {
  projectKey: string;
  excludedIds: Set<string>;
  onSelect: (environment: SelectedEnvironment) => void;
}) {
  const { isPending, updateTextFilter, items, asSections, unsectionedItems, handleAction } = useSelectEnvironment({
    projectKey,
    excludedIds,
    onSelect,
  });

  const listBoxId = useId();

  const listBoxProps = {
    'aria-label': 'Environments',
    renderEmptyState: () => 'No items found',
    id: listBoxId,
    className: styles.list,
    onAction: handleAction,
  } as const;

  return (
    <Dialog className={styles.dialog} aria-label="Select environment">
      <Autocomplete onInputChange={updateTextFilter}>
        <SearchField
          autoFocus
          className={styles.search}
          aria-controls={listBoxId}
          aria-label="Search environments"
          placeholder="Search environments"
          isBusy={isPending}
          onChange={updateTextFilter}
        />
        {asSections ? (
          <ListBox {...listBoxProps} items={items}>
            {(section) => (
              <ListBoxSection id={section.id}>
                <Header>{section.name}</Header>
                <Collection items={section.children}>
                  {(item) => (
                    <ListBoxItem id={item.id} textValue={item.id}>
                      <Text slot="label">
                        <EnvironmentMarker color={item.color} />
                        <TextTruncator>{item.name}</TextTruncator>
                      </Text>
                    </ListBoxItem>
                  )}
                </Collection>
              </ListBoxSection>
            )}
          </ListBox>
        ) : (
          <ListBox {...listBoxProps} items={unsectionedItems}>
            {(item) => (
              <ListBoxItem id={item.id} textValue={item.id}>
                <Text slot="label">
                  <EnvironmentMarker color={item.color} />
                  <TextTruncator>{item.name}</TextTruncator>
                </Text>
              </ListBoxItem>
            )}
          </ListBox>
        )}
      </Autocomplete>
    </Dialog>
  );
}
