import { userId } from '@gonfalon/constants';
import { enablePauseForSNEIntegration, enablePauseForSnowflakeExportIntegration } from '@gonfalon/dogfood-flags';
import { has, isEmpty } from '@gonfalon/es6-utils';
import { BigSegmentStoreIntegrationItem, FlagImportIntegration } from '@gonfalon/rest-api';
// eslint-disable-next-line no-restricted-imports
import { fromJS, is, List, Map, OrderedMap, Record } from 'immutable';

import {
  Approval,
  AuditLogEventsHook,
  FeatureStore,
  // TODO: Vendor Goaltender
  // FlagImport,
  FormVariable,
  LaunchDarklyIntegrationsManifest,
  ReservedCustomProperties,
  Trigger,
} from 'types/generated/integrationSchema';
import { AccessChecks, allowDecision, createAccessDecision } from 'utils/accessUtils';
import { createMemberSummary, Member } from 'utils/accountUtils';
import { Repository } from 'utils/codeRefs/codeRefsUtils';
import { Destination, SlackIncomingHook } from 'utils/integrationUtils';
import { isPolicyString } from 'utils/policyUtils';
import { isChecked, isJSON, isLength, isNotEmpty, isURL, optional, validateRecord } from 'utils/validationUtils';
import { Webhook } from 'utils/webhookUtils';

import { FeatureStoreIntegrationCollectionItem } from './featureStoreUtils';
import { CreateFunctionInput, ImmutableMap } from './immutableUtils';
import { Links } from './linkUtils';

export type SubscriptionIntegration =
  | Webhook
  | Repository
  | Destination
  | SlackIncomingHook
  | GoaltenderSubscription
  | FeatureStoreIntegrationCollectionItem
  | FlagImportIntegration;

export type ManifestRecord = ImmutableMap<IntegrationsManifestServed>;

export type SubscriptionError = { statusCode: number; responseBody: string; timestamp: number };

export type SubscriptionStatus = {
  successCount: number;
  lastSuccess: number;
  lastError: number;
  errorCount: number;
  errors: SubscriptionError[];
};
type ImmutableSubscriptionStatus = ImmutableMap<
  Omit<SubscriptionStatus, 'errors'> & { errors: List<ImmutableMap<SubscriptionError>> }
>;

export type GoaltenderSubscriptionType = {
  _access?: AccessChecks;
  _manifest: ManifestRecord;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _links: Map<any, any>;
  _id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: Map<any, any>;
  statements: string;
  on: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: List<any>;
  isValidating?: boolean;
  _status: ImmutableSubscriptionStatus;
};

export class GoaltenderSubscription extends Record<GoaltenderSubscriptionType>({
  _access: Map(),
  _manifest: Map(),
  _links: Map(),
  _id: '',
  name: '',
  config: Map(),
  statements: '',
  on: true,
  tags: List(),
  isValidating: false,
  _status: Map(),
}) {
  checkAccess({ profile }: { profile: Member }) {
    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (
      profile.isAdmin() ||
      profile.isWriter() ||
      profile.isOwner() ||
      !this.get('_access') ||
      !this.getIn(['_access', 'denied'])
    ) {
      return allowDecision;
    }

    return (action?: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const deniedAction = this.getIn(['_access', 'denied']).find((v: Map<string, any>) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  parentLink() {
    return this._manifest.getIn(['_links', 'subscriptions', 'href']);
  }

  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  toForm() {
    return createGoaltenderSubscriptionFormRecord({
      name: this.name,
      config: fromJS(this.config),
      statements: this.statements ? JSON.stringify(this.statements, null, 2) : '',
      on: this.on,
      tags: this.tags,
      _manifest: this._manifest,
      _links: this._links,
      // We only want to get privacy policy approval for new subscriptions. If we already have a subscription
      // then we can assume the privacy policy has been approved
      privacyPolicyApproved: true,
    });
  }

  mergeForm(form: GoaltenderSubscriptionFormRecord) {
    return this.withMutations((subscription) =>
      subscription.merge({
        name: form.name,
        config: form.config,
        statements: isEmpty(form.statements) ? null : JSON.parse(form.statements),
        on: form.on,
        tags: form.tags,
        _manifest: this._manifest,
        _links: this._links,
      }),
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDefaultValue = (formVar: Map<string, any>) => {
  if (formVar.get('defaultValue')) {
    return formVar.get('defaultValue');
  }
  const type = formVar.get('type');
  if (['string', 'uri'].includes(type)) {
    return '';
  }
  if (type === 'enum') {
    return formVar.get('allowedValues').first();
  }
  return false;
};

export type GoaltenderSubscriptionFormRecordProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _manifest: Map<any, any>;
  _links: Links;
  name: string;
  config: Map<string, string>;
  statements: string;
  on: boolean;
  tags: List<string>;
  privacyPolicyApproved: boolean;
};

export class GoaltenderSubscriptionFormRecord extends Record({
  _manifest: Map(),
  _links: Map(),
  name: '',
  config: Map(),
  statements: '',
  on: false,
  tags: List(),
  privacyPolicyApproved: false,
  _status: Map(),
}) {
  constructor(props: GoaltenderSubscriptionFormRecordProps) {
    let finalProps = props;
    if (props?._manifest) {
      const formVariables = props._manifest.get('formVariables');
      if (formVariables?.size > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const configWithDefaults: { [key: string]: any } = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formVariables.forEach((formVar: Map<string, any>) => {
          configWithDefaults[formVar.get('key')] = props.config.get(formVar.get('key')) || getDefaultValue(formVar);
        });
        finalProps = Object.assign(props, { config: fromJS(configWithDefaults) });
      }
    }
    super(finalProps);
  }

  validate() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formVariables = this._manifest.get('formVariables') as Array<Map<string, any>>;
    const optionalValidators = formVariables.map((formVar) =>
      formVar.get('isOptional')
        ? optional(isNotEmpty)(`config.${formVar.get('key')}`)
        : isNotEmpty(`config.${formVar.get('key')}`),
    );
    const uriValidators = formVariables
      .filter((formVar) => formVar.get('type') === 'uri')
      .map((formVar) => optional(isURL)(`config.${formVar.get('key')}`));
    const lengthValidators = formVariables
      .filter((formVar) => ['string', 'uri'].includes(formVar.get('type')))
      .map((formVar) => isLength(0, 500)(`config.${formVar.get('key')}`));
    return validateRecord(
      this,
      isChecked('privacyPolicyApproved'),
      optional(isNotEmpty)('name'),
      isLength(0, 100)('name'),
      optional(isJSON)('statements'),
      optional(isPolicyString)('statements'),
      ...optionalValidators,
      ...uriValidators,
      ...lengthValidators,
    );
  }

  is(other: GoaltenderSubscriptionFormRecordProps) {
    try {
      return (
        this.on === other.on &&
        this.name === other.name &&
        this.config === other.config &&
        ((isEmpty(this.statements) && isEmpty(other.statements)) ||
          is(fromJS(JSON.parse(this.statements)), fromJS(JSON.parse(other.statements)))) &&
        is(this.tags, other.tags)
      );
    } catch (e) {
      return false;
    }
  }

  toGoaltenderSubscription() {
    const statements = this.statements !== '' ? JSON.parse(this.statements) : null;
    return createGoaltenderSubscription({
      _manifest: this._manifest,
      _links: this._links,
      name: this.name,
      config: this.config,
      statements: statements && statements.length ? statements : null,
      on: this.on,
      tags: this.tags,
      _status: this._status,
    });
  }
}

export type OAuthConnectionSummary = {
  connected: boolean;
  member: string;
  _member?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    role: string;
    email: string;
  };
};

export const getOAuthDisplayName = (oauthSummary: OAuthConnectionSummary) => {
  if (!oauthSummary._member) {
    return 'a deleted member';
  }
  if (oauthSummary.member === userId()) {
    return 'you';
  }
  return createMemberSummary(oauthSummary._member).getDisplayName();
};

export const getTypesWithoutOnOff = () => {
  const types: string[] = [];
  if (!enablePauseForSNEIntegration()) {
    types.push('snowflake-experimentation');
  }
  if (!enablePauseForSnowflakeExportIntegration()) {
    types.push('snowflake-v2');
  }
  return types;
};

export const TypesToGenerateUniqueEnvironmentKeys = ['snowflake-v2', 'snowflake-experimentation'];

export enum UpsellKindType {
  DATA_EXPORT = 'data-export',
  ENTERPRISE = 'enterprise',
  NONE = 'none',
}

export type IntegrationsManifestServed = LaunchDarklyIntegrationsManifest & {
  additionalFields: {
    status: string;
    upsellKind: `${UpsellKindType}`;
    shouldUpsell: boolean;
  };
  oauth: OAuthConnectionSummary;
  key: string;
};

export function createIntegrationManifest(props?: Partial<IntegrationsManifestServed>): IntegrationsManifestServed {
  const defaultManifest = {
    _links: {
      parent: '',
      self: '',
      subscriptions: '',
    },
    key: 'sample-key',
    additionalFields: {
      status: '',
      upsellKind: '',
    },
    oauth: {
      connected: false,
      member: '',
      _member: null,
    },
    author: 'sample-human',
    categories: [''],
    description: 'this is a sample integration',
    icons: {
      horizontal: 'https://static.launchdarkly.com/integrations/507a2bfc/sample-integration/assets/images/square.svg',
      square: 'https://static.launchdarkly.com/integrations/507a2bfc/sample-integration/assets/images/square.svg',
    },
    links: { launchdarklyDocs: '', privacyPolicy: '', supportWebsite: '' },
    name: 'Sample Name',
    overview: '',
    supportEmail: 'toggle@launchdarkly.com',
    version: '1.0',
  };

  return { ...defaultManifest, ...props } as unknown as IntegrationsManifestServed;
}

export type IntegrationManifests = {
  [key: string]: IntegrationsManifestServed;
};

export const manifestHasEventsHookCapability = (manifest?: IntegrationsManifestServed) =>
  !!manifest?.capabilities?.auditLogEventsHook;

export const manifestAllowsAdditionalFormVariable = (manifest?: IntegrationsManifestServed) =>
  manifest &&
  !!manifestHasApprovalCapability(manifest) &&
  !!manifest.capabilities?.approval?.allowAdditionalApprovalFormVariables;

export const manifestIsTriggerOnly = (manifest: IntegrationsManifestServed) =>
  manifest.capabilities?.trigger && Object.keys(manifest.capabilities).length === 1;

export const manifestHasApprovalCapability = (manifest?: IntegrationsManifestServed) =>
  manifest?.capabilities?.approval;

export const manifestUsesIntegrationConfiguration = (manifest?: IntegrationsManifestServed) =>
  manifest?.allowIntegrationConfigurations === true;

export const manifestHasHideConfigurationCapability = (manifest?: IntegrationsManifestServed) =>
  manifest?.capabilities?.hideConfiguration;

export const manifestHasExternalConfigurationURLCapability = (manifest?: IntegrationsManifestServed) =>
  manifest?.capabilities?.externalConfigurationURL;

export const manifestMapHasEventsHookCapability = (manifest?: ManifestRecord) =>
  manifest?.hasIn(['capabilities', 'auditLogEventsHook']);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manifestMapIsTriggerOnly = (manifest?: Map<any, any>) =>
  manifest?.hasIn(['capabilities', 'trigger']) && manifest?.get('capabilities').size === 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const manifestMapHasApprovalCapability = (manifest?: Map<any, any>) =>
  manifest?.hasIn(['capabilities', 'approval']);

export function createGoaltenderSubscription(props: CreateFunctionInput<GoaltenderSubscriptionType>) {
  return props instanceof GoaltenderSubscription ? props : new GoaltenderSubscription(fromJS(props));
}

export function createGoaltenderSubscriptionFormRecord(props: GoaltenderSubscriptionFormRecordProps) {
  return props instanceof GoaltenderSubscriptionFormRecord ? props : new GoaltenderSubscriptionFormRecord(props);
}

export const filterManifestsForCapability = (capability: string, manifests: OrderedMap<string, $TSFixMe>) =>
  manifests.filter((m) => m.hasIn(['capabilities', capability]));

export type LegacyIntegrationContainer = {
  type:
    | LegacyIntegrationKind.SLACK
    | LegacyIntegrationKind.DESTINATION
    | LegacyIntegrationKind.REPOSITORY
    | LegacyIntegrationKind.WEBHOOK;
  name: string;
  kind: string;
  integrations: List<SlackIncomingHook> | List<Destination> | List<Repository> | List<Webhook>;
};

export interface DestinationIntegrationContainer extends LegacyIntegrationContainer {
  type: LegacyIntegrationKind.DESTINATION;
  integrations: List<Destination>;
}

export interface RepositoryIntegrationContainer extends LegacyIntegrationContainer {
  type: LegacyIntegrationKind.REPOSITORY;
  integrations: List<Repository>;
}

export interface WebhookIntegrationContainer extends LegacyIntegrationContainer {
  type: LegacyIntegrationKind.WEBHOOK;
  integrations: List<Webhook>;
}

export interface SlackIncomingHookIntegrationContainer extends LegacyIntegrationContainer {
  type: LegacyIntegrationKind.SLACK;
  integrations: List<SlackIncomingHook>;
}

// All non goaltender kind integrations are legacy
export enum LegacyIntegrationKind {
  DESTINATION = 'Destination',
  REPOSITORY = 'Repository',
  WEBHOOK = 'Webhook',
  SLACK = 'SlackIncomingHook',
}

export const manifestHasSupportedCapability = (manifest: IntegrationsManifestServed) =>
  Boolean(
    manifestHasEventsHookCapability(manifest) ||
      manifestHasFeatureStoreCapability(manifest) ||
      manifestHasBigSegmentCapability(manifest) ||
      manifestHasFlagImportCapability(manifest) ||
      manifestUsesIntegrationConfiguration(manifest),
  );

type ManifestCapabilityCheck = (manifest: IntegrationsManifestServed) => boolean;

export const manifestHasFeatureStoreCapability: ManifestCapabilityCheck = (manifest) =>
  has(manifest, 'capabilities.featureStore');

export const manifestHasBigSegmentCapability: ManifestCapabilityCheck = (manifest) =>
  has(manifest, 'capabilities.bigSegmentStore');

export const manifestHasFlagImportCapability: ManifestCapabilityCheck = (manifest) =>
  has(manifest, 'capabilities.flagImport');

type KnownCapability = AuditLogEventsHook | ReservedCustomProperties | Trigger | Approval | FeatureStore;
// TODO: Vendor Goaltender
// type KnownCapability = AuditLogEventsHook | ReservedCustomProperties | Trigger | Approval | FeatureStore | FlagImport;

const hasFormVariables = (capability: KnownCapability): capability is FeatureStore => has(capability, 'formVariables');

// returns all formVariables from the manifest and its capabilities
export const getAllFormVariables = (manifest: LaunchDarklyIntegrationsManifest): FormVariable[] =>
  (manifest.capabilities &&
    (Object.values(manifest.capabilities) as KnownCapability[]).reduce(
      (acc: FormVariable[], capability: KnownCapability) => {
        if (!hasFormVariables(capability)) {
          return acc;
        }
        return acc.concat(capability.formVariables);
      },
      manifest.formVariables ?? [],
    )) ??
  manifest.formVariables ??
  [];

const getDefaultFormVariableValue = (formVar: FormVariable): string | boolean => {
  if (formVar.defaultValue) {
    return formVar.defaultValue;
  }
  switch (formVar.type) {
    case 'string':
    case 'uri':
      return '';
    case 'enum':
      return formVar.allowedValues?.[0] ?? '';
    case 'boolean':
      return false;
    default:
      return '';
  }
};

type DefaultValues = { [key: string]: string | boolean | unknown };

export const getDefaultFormVariableValues = (manifest: LaunchDarklyIntegrationsManifest): DefaultValues =>
  getAllFormVariables(manifest).reduce<DefaultValues>((acc, formVar) => {
    // eslint-disable-next-line no-param-reassign
    acc[formVar.key] = getDefaultFormVariableValue(formVar);
    return acc;
  }, {});

export const getSortedReservedCustomPropertiesFromManifests = (
  configuredIntegrationManifests: OrderedMap<string, ImmutableMap<IntegrationsManifestServed>>,
  startingProperties: ReservedCustomProperties = [],
): ReservedCustomProperties => {
  const path = ['capabilities', 'reservedCustomProperties'];
  const goaltenderReservedCustomPropertyTypes = configuredIntegrationManifests
    .filter((manifest) => manifest.hasIn(path))
    .map((manifest) => manifest.getIn(path))
    .toList()
    .flatten(1)
    .toJS() as ReservedCustomProperties;

  const reservedCustomProperties = [...startingProperties, ...goaltenderReservedCustomPropertyTypes];
  reservedCustomProperties.sort((a, b) => (a.name < b.name ? -1 : 1));

  return reservedCustomProperties;
};

export const getApprovalSystemName = (manifest: IntegrationsManifestServed | LaunchDarklyIntegrationsManifest) => {
  const name = manifest.capabilities?.approval?.name;
  return name || manifest.name;
};

export const getBigSegmentInitialFormVariableValues = (
  manifest: LaunchDarklyIntegrationsManifest,
  integration: BigSegmentStoreIntegrationItem,
): DefaultValues =>
  getAllFormVariables(manifest).reduce<DefaultValues>((acc, formVar) => {
    // eslint-disable-next-line no-param-reassign
    acc[formVar.key] = integration.config[formVar.key];
    return acc;
  }, {});
