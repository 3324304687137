import { createContext, useState } from 'react';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import { noop } from '@gonfalon/es6-utils';
import { useProjectKey } from '@gonfalon/router';
import { FetchStatus, useQuery } from '@tanstack/react-query';

// eslint-disable-next-line import/no-namespace
import * as expAPI from '../api/experiment';
import { ExperimentV2 } from '../types';

export type Context = {
  experiments: ExperimentV2[];
  error?: unknown;
  isLoading: boolean;
  refetch: () => void;
  setFlagKey: (key: string) => void;
  fetchStatus: FetchStatus;
};

const defaultContext: Context = {
  experiments: [],
  isLoading: false,
  refetch: noop,
  setFlagKey: noop,
  fetchStatus: 'idle',
};

export const ExperimentsContext = createContext<Context>(defaultContext);

export type ProviderProps = {
  children: React.ReactNode;
  initialFlagKey?: string;
  getExperiments?: typeof expAPI.getExperiments;
};

export const ExperimentsProvider = ({
  initialFlagKey,
  children,
  getExperiments = expAPI.getExperiments,
}: ProviderProps) => {
  const envKey = useSelectedEnvironmentKey();
  const projKey = useProjectKey();
  const [flagKey, setFlagKey] = useState(initialFlagKey);

  const { isPending, error, data, refetch, fetchStatus } = useQuery({
    queryKey: [projKey, envKey, flagKey, 'experiments'],
    queryFn: async ({ signal }) =>
      getExperiments(
        projKey,
        envKey,
        {
          filter: {
            flagKey,
            kind: 'experiment',
          },
          limit: 30,
          lifecycleState: 'archived,active',
        },
        { signal },
      ),
    enabled: !!flagKey,
  });

  return (
    <ExperimentsContext.Provider
      value={{
        experiments: data?.items ?? [],
        error,
        isLoading: isPending,
        refetch,
        setFlagKey,
        fetchStatus,
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  );
};

/* eslint-disable import/no-default-export */
export default ExperimentsProvider;
