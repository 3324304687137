import { productAnalyticsBlockServiceUrl, productAnalyticsDataServiceUrl } from '@gonfalon/constants';

const DATA_SERVICE_URL = !__DEV__ ? `${productAnalyticsDataServiceUrl()}/api/v1` : '/data-service/api/v1';
const BLOCK_SERVICE_URL = !__DEV__ ? `${productAnalyticsBlockServiceUrl()}/api/v1` : '/block-service/api/v1';
const DEFAULT_DATE_FILTER_RANGE_IN_DAYS = 3;

const config = {
  dataServiceURL: DATA_SERVICE_URL,
  blockServiceURL: BLOCK_SERVICE_URL,
  defaultDateFilterWindow: {
    unit: 'day',
    value: DEFAULT_DATE_FILTER_RANGE_IN_DAYS,
  },
  defaultTimezone: 'UTC',
};

export default config;
