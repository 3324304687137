import { useInView } from 'react-intersection-observer';
import { EntrySummary, groupEntriesByDay, projectFromIdentifier, Statement } from '@gonfalon/audit-log';
import { useAccountContext, useProjectContext } from '@gonfalon/context';
import { Time } from '@gonfalon/datetime';
import { isEmpty } from '@gonfalon/es6-utils';
import { DateFormat } from '@gonfalon/format';
import { Card, CardGroup, CopyToClipboard } from '@gonfalon/launchpad-experimental';
import { toGeneralSettings, toHref } from '@gonfalon/navigator';
import { useInfiniteAuditLog } from '@gonfalon/rest-api';
import { Button, IconButton, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import { Box } from '@launchpad-ui/core';

import { useChangeHistoryDetails } from '../../ChangeHistoryModal/useChangeHistoryDetails';
import { Expansion } from '../types';

import styles from './AuditLog.module.css';

export function AuditLog({
  spec,
  after,
  before,
  q,
  sort = 'desc',
  expansion,
  expandItem,
}: {
  spec: Statement[];
  after?: Date;
  before?: Date;
  q?: string;
  sort?: 'asc' | 'desc';
  expansion: Expansion;
  expandItem: (id: string) => void;
}) {
  const activity = useInfiniteAuditLog({
    spec,
    params: { after: after?.valueOf(), before: before?.valueOf(), q },
  });
  const { account } = useAccountContext();
  const projectContext = useProjectContext({ optional: true });
  const { open } = useChangeHistoryDetails();

  const { ref } = useInView({
    onChange(inView) {
      if (inView) {
        activity.fetchNextPage().catch(() => {});
      }
    },
  });

  const groupedByDay = groupEntriesByDay(activity.data?.pages?.map((page) => page.items).flat() ?? [], sort);

  if (activity.isSuccess && isEmpty(groupedByDay)) {
    return (
      <div className={styles.emptyStateContainer}>
        <div className={styles.emptyStateHeading}>No change history entries found</div>
        <div className={styles.emptyStateDescription}>Try adjusting your filters</div>
      </div>
    );
  }

  return (
    <div className={styles.auditlog}>
      {Object.entries(groupedByDay).map(([day, items]) => {
        const dateTime = new Date(Number(day));
        return (
          <div className={styles.day} key={day}>
            <Time className={styles.timestamp} dateFormat={DateFormat.MMM_D_YYY} datetime={dateTime} />
            <CardGroup>
              {items.map((entry) => {
                const projectList = entry?.target?.resources?.map((r) => projectFromIdentifier(r));
                const projectName = projectContext?.project?.name ?? 'Project';
                const selectedProjectName = projectList && projectList.length > 1 ? projectName : undefined;
                return (
                  <Card key={entry._id} edgeless>
                    <Box display="flex" justifyContent="space-between">
                      <EntrySummary
                        className={styles.entry}
                        entry={entry}
                        organizationName={account?.organization || 'org'}
                        projectName={selectedProjectName}
                        fromNow={false}
                        dateFormat={DateFormat.H_MM_SS_A}
                        expandItem={expandItem}
                        expansion={expansion}
                      />
                      <Box display="flex" justifyContent="space-between">
                        <CopyToClipboard
                          text={toHref(
                            toGeneralSettings({
                              changeHistory: entry._id,
                            }),
                            { absolute: true },
                          )}
                          tooltip="Copy entry details link"
                        >
                          <IconButton aria-label="Copy history link" variant="minimal" icon="link" />
                        </CopyToClipboard>
                        <TooltipTrigger>
                          <IconButton
                            onPress={() => open({ changeHistoryEntryId: entry._id })}
                            aria-label="expand entry"
                            variant="minimal"
                            icon="arrows-maximize"
                          />
                          <Tooltip>View entry details</Tooltip>
                        </TooltipTrigger>
                      </Box>
                    </Box>
                  </Card>
                );
              })}
            </CardGroup>
          </div>
        );
      })}
      {activity.hasNextPage && (
        <Box marginTop="$400" marginBottom="$400">
          <Button ref={ref} isDisabled={activity.isFetchingNextPage} onPress={async () => activity.fetchNextPage()}>
            {activity.isFetchingNextPage ? 'Loading more...' : 'Load Newer'}
          </Button>
        </Box>
      )}
    </div>
  );
}
