import config from '../config';
import $fetch from '../services/$fetch';

export async function getAllSnowflakeIntegrationAPI() {
  // integration works at account level, hence url doesn't contain projectKey and environmentKey
  const res = await $fetch(`${config.dataServiceURL}/integrations`);
  if (res.ok) {
    const data = await res.json();
    return data;
  }
  throw Error(res.statusText || res.status || 'Something went wrong');
}
