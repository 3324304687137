import type { Clause as ClauseType } from '@gonfalon/clauses';
import {
  ClauseValue,
  formatClauseContextKind,
  getAttributeFromClause,
  getClauseComponents,
  getClauseKind,
  getOperationLabel,
  hasAppSupportedOp,
  hasSegmentOp,
  isMobileClause,
  translateToText,
  updateClauseAttribute,
  updateClauseContextKind,
  updateClauseOperator,
} from '@gonfalon/clauses';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Record } from 'immutable';
import nullthrows from 'nullthrows';
import { v4 } from 'uuid';

import AttributeReference from 'components/Contexts/utils/AttributeUtils';
import {
  APP_VERSION_SUPPORT_STATUS_ATTRIBUTE,
  APPLICATION_CONTEXT_KIND,
  DEVICE_CONTEXT_KIND,
  USER_CONTEXT_KIND,
} from 'utils/constants';
import { CreateFunctionInput } from 'utils/immutableUtils';
import { Segment } from 'utils/segmentUtils';

import { mobileAttributesMapping } from './userAttributeUtils';

export const shouldAutoCompleteAttributeValue = (c: ClauseType) => c.op === 'in';

export type ClauseProps = {
  _key: string;
  _id: string;
  attribute: string;
  op: string;
  contextKind: string;
  values: ClauseValue[];
  negate: boolean;
};

export class Clause extends Record<Omit<ClauseProps, 'values'> & { values: List<ClauseValue> }>({
  _key: '',
  _id: '',
  attribute: '',
  op: '',
  contextKind: '',
  values: List(),
  negate: false,
}) {
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Clause) => {
      map.remove('_key');
    });
  }

  updateAttribute(attr: string) {
    const updatedClause = updateClauseAttribute(this.toJS(), attr);
    return this.withMutations((c) => {
      c.set('attribute', updatedClause.attribute)
        .set('op', updatedClause.op)
        .set('negate', updatedClause.negate)
        .set('values', List(updatedClause.values as ClauseValue[]));
    });
  }

  updateContextKind(contextKind: string = '') {
    const updatedClause = updateClauseContextKind(this.toJS(), contextKind);
    return this.set('contextKind', nullthrows(updatedClause.contextKind));
  }

  updateOperator(op: string, negate: boolean) {
    const updatedClause = updateClauseOperator(this.toJS(), op, negate);
    return this.merge({
      op: updatedClause.op,
      negate: updatedClause.negate,
      values: List(updatedClause.values as ClauseValue[]),
    });
  }

  convertSegmentValueKeysToNames(segments: List<Segment> = List()) {
    return this.values.map((value) => {
      const segment = segments.find((s: Segment) => s.key === value);
      return segment?.name || value;
    });
  }

  getOperationLabel() {
    return getOperationLabel(this.toJS());
  }

  translateToText(segments?: List<Segment>) {
    const plainClause = this.toJS();
    const attribute = new AttributeReference(getAttributeFromClause(plainClause)).toString();
    return translateToText(plainClause, attribute, mobileAttributesMapping, segments ? segments.toJS() : undefined);
  }

  hasSegmentOp() {
    return hasSegmentOp(this.toJS());
  }

  hasAppSupportedOp() {
    return hasAppSupportedOp(this.toJS());
  }

  hasSegment(segmentKey: string) {
    return this.hasSegmentOp() && this.values.includes(segmentKey);
  }

  getClauseComponents(segments: List<Segment> = List()) {
    const plainClause = this.toJS();
    const attr = new AttributeReference(getAttributeFromClause(plainClause)).toString();
    const { values, ...rest } = getClauseComponents(plainClause, segments.toJS(), attr, mobileAttributesMapping);
    return {
      ...rest,
      values: List(values as ClauseValue[]),
    };
  }

  getContextKind() {
    return this.contextKind || USER_CONTEXT_KIND;
  }

  getFormarmatedContextKind() {
    return formatClauseContextKind(this.toJS());
  }

  isMobileClause() {
    return isMobileClause(this.toJS());
  }

  getClauseKind() {
    return getClauseKind(this.toJS());
  }
}

export function createClause(props: CreateFunctionInput<Clause> = {}) {
  const clause = props instanceof Clause ? props : new Clause(fromJS(props));
  return clause.update('_key', (key) => key || v4()).update('values', (values) => values || List());
}

export const createSegmentRuleClause = (props: CreateFunctionInput<Omit<Clause, 'attribute' | 'op'>> = {}) =>
  createClause({ attribute: 'segmentMatch', op: 'segmentMatch', negate: false, ...props });

export const createApplicationContextClause = (props: CreateFunctionInput<Omit<Clause, 'contextKind'>> = {}) =>
  createClause({ contextKind: APPLICATION_CONTEXT_KIND, ...props });

export const createDeviceContextClause = (props: CreateFunctionInput<Omit<Clause, 'contextKind'>> = {}) =>
  createClause({ contextKind: DEVICE_CONTEXT_KIND, ...props });

export const createDefaultMobileClauseList = (
  props: CreateFunctionInput<Omit<Clause, 'contextKind' | 'attribute' | 'op'>> = {},
) => [
  createApplicationContextClause({ attribute: APP_VERSION_SUPPORT_STATUS_ATTRIBUTE, ...props }),
  createDeviceContextClause({ ...props }),
];
