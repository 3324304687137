import { BlockFilter } from '../../../types/types';

//operators that require a field but no value in the filter property
type OperatorsWithNoValues = 'is_set' | 'is_not_set';

export function isFilterPropertyValid(filter: BlockFilter): boolean {
  const { field, operator, value } = filter;

  // Ensure 'field' and 'operator' are present and are strings
  if (typeof field !== 'string' || typeof operator !== 'string') {
    return false;
  }

  const operatorsWithNoValues: Set<OperatorsWithNoValues> = new Set(['is_set', 'is_not_set'] as const);

  if (operatorsWithNoValues.has(operator as OperatorsWithNoValues)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  // Check for non-falsy values excluding 0
  return value !== null && value !== undefined && value !== '' && !Number.isNaN(value);
}
