import { getQueryClient } from '@gonfalon/react-query-client';
import { ResourceSpecifier } from '@gonfalon/resource-specifiers';
import {
  fetchApplication,
  fetchEnvironment,
  fetchMember,
  fetchTeam,
  getFeatureFlagQuery,
  projectsDetail,
} from '@gonfalon/rest-api';

export const validateName = async (resource: ResourceSpecifier) => {
  if (!resource.hasOwnProperty('name')) {
    return true;
  }

  if (
    ('project' in resource && resource.project.name.includes('*')) ||
    ('environment' in resource && resource.environment.project.name.includes('*'))
  ) {
    return false;
  }

  if ('name' in resource) {
    if (resource.name.includes('*')) {
      return true;
    }

    switch (resource.type) {
      case 'integration':
        break;
      case 'application':
        try {
          await fetchApplication({ applicationKey: resource.name });
          return true;
        } catch (error) {
          return false;
        }
      case 'domain-verification':
        break;
      case 'member':
        try {
          await fetchMember({ memberId: resource.name });
          return true;
        } catch (error) {
          return false;
        }
      case 'proj':
        try {
          await getQueryClient().fetchQuery(projectsDetail({ projectKey: resource.name }));
          return true;
        } catch (error) {
          return false;
        }
      case 'env':
        try {
          await fetchEnvironment({ projectKey: resource.project.name, environmentKey: resource.name });
          return true;
        } catch (error) {
          return false;
        }
      case 'flag':
        try {
          await getQueryClient().fetchQuery(
            getFeatureFlagQuery({ projectKey: resource.environment.project.name, featureFlagKey: resource.name }),
          );
          return true;
        } catch (error) {
          return false;
        }
      case 'relay-proxy-config':
        break;
      case 'role':
        break;
      case 'service-token':
        break;
      case 'team':
        try {
          await fetchTeam({ teamKey: resource.name });
          return true;
        } catch (error) {
          return false;
        }
      case 'template':
        break;
      case 'webhook':
        break;
      case 'code-reference-repository':
        break;
      default:
        break;
    }
  }
};
