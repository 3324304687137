import { List } from 'immutable';
import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { from, merge, Observable, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap } from 'rxjs/operators';

import { fetchTagsDone, fetchTagsFailed, TagsAction } from 'actions/tags';
import registry from 'epics/registry';
import { fetchTags } from 'sources/TagAPI';
import { ImmutableServerError } from 'utils/httpUtils';
import { DEBOUNCE_MS } from 'utils/inputUtils';

export function resolveTags(action$: Observable<AnyAction>) {
  return action$.pipe(
    ofType<TagsAction, 'tags/FETCH_TAGS'>('tags/FETCH_TAGS'),
    debounceTime(DEBOUNCE_MS),
    switchMap((action) => from(fetchTags(action.prefix || null, action.kind, action.archived))),
    mergeMap((response) => of(fetchTagsDone(response as List<string>))),
    catchError((error: ImmutableServerError, source) => merge(of(fetchTagsFailed(error)), source)),
  );
}

registry.addEpics([resolveTags]);
