import { createContext, ReactNode, useContext } from 'react';
import { Alert } from '@launchpad-ui/components';

import { CollapsibleInstructionList } from 'components/InstructionList/CollapsibleInstructionList';
import { Flag } from 'utils/flagUtils';
import { ConflictKind, InstructionIndexToConflictsInfo, SemanticInstruction } from 'utils/instructions/shared/types';

import { filterInstructionsForConflict } from './instructionListUtils';

export type InstructionListConflictsProps = {
  instructions: SemanticInstruction[];
  flag: Flag;
  projKey: string;
  envKey: string;
  instructionIndexToConflictsInfo: InstructionIndexToConflictsInfo;
  expandTargetList?: boolean;
  onExit?: () => void;
};

export const InstructionListConflicts = ({
  instructions,
  flag,
  projKey,
  envKey,
  instructionIndexToConflictsInfo,
  expandTargetList,
  onExit,
}: InstructionListConflictsProps) => (
  <InstructionListConflictWrapper
    instructions={instructions}
    instructionIndexToConflictsInfo={instructionIndexToConflictsInfo}
  >
    <InstructionListConflictContent
      flag={flag}
      projKey={projKey}
      envKey={envKey}
      expandTargetList={expandTargetList}
      onExit={onExit}
    />
  </InstructionListConflictWrapper>
);

export const InstructionListConflictContent = ({
  flag,
  projKey,
  envKey,
  expandTargetList,
  onExit,
}: Omit<InstructionListConflictsProps, 'instructions' | 'instructionIndexToConflictsInfo'>) => {
  const { conflictKind, filteredInstructions } = useInstructionListConflictsContext();
  return (
    <CollapsibleInstructionList
      instructions={filteredInstructions}
      flag={flag}
      projKey={projKey}
      envKey={envKey}
      expandTargetList={expandTargetList}
      shouldShowConflictLinks={conflictKind !== ConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL}
      onExit={onExit}
    />
  );
};

export const InstructionListConflictWrapper = ({
  children,
  instructions,
  instructionIndexToConflictsInfo,
}: {
  children: ReactNode;
  instructions: SemanticInstruction[];
  instructionIndexToConflictsInfo: InstructionIndexToConflictsInfo;
}) => {
  const conflictKindToHeaderText = {
    [ConflictKind.PENDING_CHANGES_WILL_FAIL]: 'These instructions will cause a pending change to fail.',
    [ConflictKind.PROPOSED_SCHEDULED_CHANGES_WILL_FAIL]:
      'These instructions will fail due to a conflict with a pending change.',
    [ConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL]:
      'These instructions will fail and prevent the change request from being applied',
  };

  return (
    <Alert status="error" className="Alert">
      {Object.keys(conflictKindToHeaderText).map((conflictKind, i) => {
        const filteredInstructions = filterInstructionsForConflict({
          instructions,
          instructionIndexToConflictsInfo,
          conflictKind: conflictKind as ConflictKind,
        });
        const hasConflictsForKind = filteredInstructions.some((ins) => ins !== undefined);
        return hasConflictsForKind ? (
          <div key={i}>
            <h4 className="u-mb-s u-fw-regular">{conflictKindToHeaderText[conflictKind as ConflictKind]}</h4>
            <InstructionListConflictsContext.Provider value={{ conflictKind, filteredInstructions }}>
              {children}
            </InstructionListConflictsContext.Provider>
          </div>
        ) : null;
      })}
    </Alert>
  );
};

type InstructionListConflictsContextType = {
  conflictKind: string;
  filteredInstructions: Array<SemanticInstruction | undefined>;
};

export const InstructionListConflictsContext = createContext<InstructionListConflictsContextType | undefined>(
  undefined,
);

export function useInstructionListConflictsContext() {
  const context = useContext(InstructionListConflictsContext);
  if (!context) {
    throw new Error('useInstructionListContext must be used within an InstructionListContextProvider');
  }
  return context;
}
