import { useMemo, useState } from 'react';

import { BlockData, BlockFilter, EventDimensionProperties } from '../../../types/types';
import { isFilterPropertyValid } from '../../visualization/utils/isFilterPropertyValid';
import { filterOperators, housewareDataTypes } from '../constants';

type DimensionValueProps = {
  blockData: BlockData<'visualization'>;
  dimensions?: EventDimensionProperties[];
};

export type BlockFilterMap = { [field: string]: BlockFilter };

type BlockFiltersResult = {
  filters: BlockFilterMap;
  updateFilters: (newFilters: any) => void;
  areFiltersValid: () => boolean;
  sortedEventsDimensionData: EventDimensionProperties[];
};

type FilterField = {
  name: string;
  values: any[];
  data_type?: string;
  is_high_cardinality?: boolean;
};

export function useSupportedFilterOperators(field?: FilterField | string) {
  return useMemo(
    () =>
      filterOperators.filter(
        (op) =>
          op.type !== 'eq' &&
          op.type !== 'neq' &&
          op.supported_data_types.includes(
            typeof field === 'string' ? field : field?.data_type || housewareDataTypes.string,
          ),
      ),
    [field],
  );
}

export function convertToSupportedFilter(filter: BlockFilter) {
  let { operator, value } = filter;

  // backwards compatibility for equals/not equals
  if (operator === 'eq' || operator === 'neq') {
    value = value && !Array.isArray(value) ? [value] : value;
  }
  if (operator === 'eq') {
    operator = 'in';
  } else if (operator === 'neq') {
    operator = 'not_in';
  }

  return {
    ...filter,
    operator,
    value,
  };
}

export default function useBlockFilters({ blockData, dimensions }: DimensionValueProps): BlockFiltersResult {
  const [filters, updateFilters] = useState<BlockFilterMap>(() => {
    const initialBlockFilters = blockData?.configuration?.filters;
    if (!initialBlockFilters) {
      return {};
    }

    let tempFiltersMap = {};
    initialBlockFilters?.and.forEach((filter) => {
      tempFiltersMap = {
        ...tempFiltersMap,
        [filter.field]: filter ? convertToSupportedFilter(filter) : null,
      };
    });
    return tempFiltersMap;
  });

  const areFiltersValid = () => {
    const isAnyFilterInvalid = Object.values(filters).some((fl) => !isFilterPropertyValid(fl));
    return !isAnyFilterInvalid;
  };

  const sortedEventsDimensionData =
    dimensions?.sort((dimA, dimB) => {
      const nameA = dimA && dimA.name ? dimA.name : '';
      const nameB = dimB && dimB.name ? dimB.name : '';
      return nameA.localeCompare(nameB);
    }) || [];

  return { filters, updateFilters, areFiltersValid, sortedEventsDimensionData };
}
