import { APP_FONT_FAMILY } from '../../app/theme/ProductAnalyticsAppThemeProvider';

export const chartColors = [
  '#425eff',
  '#a736ec',
  '#5a014f',
  '#d682c8',
  '#ffabf3',
  '#fad0f4',
  '#eac288',
  '#da84b0',
  '#cb47d8',
  '#dc5cc6',
  '#ee72b4',
  '#ff87a2',
  '#842d76',
  '#ad599d',
  '#1DB6AE',
  '#F68512',
  '#DE3D81',
  '#7E84FA',
  '#9a60b4',
  '#188F5D',
  '#147AF3',
  '#7330D3',
  '#F95D6A',
  '#E8C600',
  '#2F4B7C',
  '#BCE931',
  '#003f5c',
  '#665191',
  '#D45087',
];

const theme = {
  color: chartColors,
  backgroundColor: 'var(--lp-color-bg-ui-primary)',
  textStyle: {
    fontFamily: APP_FONT_FAMILY,
  },
  categoryAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: '#aaaaaa',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#e6e6e6'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: '#aaaaaa',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#e6e6e6'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#aaaaaa',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#e6e6e6'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#aaaaaa',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      color: '#999999',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#e6e6e6'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: '#999999',
    },
    emphasis: {
      iconStyle: {
        borderColor: '#666666',
      },
    },
  },
  legend: {
    textStyle: {
      color: '#999999',
    },
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: '#cccccc',
        width: 1,
      },
      crossStyle: {
        color: '#cccccc',
        width: 1,
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#893448',
      width: 1,
    },
    itemStyle: {
      color: '#893448',
      borderWidth: 1,
    },
    controlStyle: {
      color: '#893448',
      borderColor: '#893448',
      borderWidth: 0.5,
    },
    checkpointStyle: {
      color: '#eb8146',
      borderColor: '#ffb248',
    },
    label: {
      color: '#893448',
    },
    emphasis: {
      itemStyle: {
        color: '#ffb248',
      },
      controlStyle: {
        color: '#893448',
        borderColor: '#893448',
        borderWidth: 0.5,
      },
      label: {
        color: '#893448',
      },
    },
  },
  dataZoom: {
    dataBackgroundColor: 'var(--primary-100)',
    fillerColor: 'var(--primary-0)',
    handleColor: 'var(--primary-100)',
    handleSize: '100%',
    textStyle: {
      color: '#333',
    },
  },
  markPoint: {
    label: {
      color: '#ffffff',
    },
    emphasis: {
      label: {
        color: '#ffffff',
      },
    },
  },

  // chart specific styles
  line: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: '2',
    },
    symbolSize: 3,
    showSymbol: true,
    symbol: 'circle',
    smooth: true,
  },
  radar: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: '2',
    },
    symbolSize: '6',
    symbol: 'circle',
    smooth: true,
  },
  bar: {
    itemStyle: {
      barBorderWidth: '0',
      barBorderColor: '#dedede',
      borderRadius: 10,
    },
    label: {
      show: true,
      position: 'top',
      color: '#7f7f7f',
    },
  },
  pie: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
      borderRadius: 2,
    },
    nodeAlign: 'left',
    lineStyle: {
      color: 'gradient',
      opacity: 0.1,
      curveness: 0.6,
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
    },
  },
  candlestick: {
    itemStyle: {
      color: '#eb8146',
      color0: 'transparent',
      borderColor: '#d95850',
      borderColor0: '#58c470',
      borderWidth: '2',
    },
  },
  graph: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#dedede',
    },
    lineStyle: {
      width: 1,
      color: '#aaa',
    },
    symbolSize: '6',
    symbol: 'circle',
    smooth: true,
    color: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', '#ebdba4'],
    label: {
      color: '#ffffff',
    },
  },
  map: {
    itemStyle: {
      areaColor: '#f3f3f3',
      borderColor: '#999999',
      borderWidth: 0.5,
    },
    label: {
      color: '#893448',
    },
    emphasis: {
      itemStyle: {
        areaColor: '#ffb248',
        borderColor: '#eb8146',
        borderWidth: 1,
      },
      label: {
        color: '#893448',
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: '#f3f3f3',
      borderColor: '#999999',
      borderWidth: 0.5,
    },
    label: {
      color: '#893448',
    },
    emphasis: {
      itemStyle: {
        areaColor: '#ffb248',
        borderColor: '#eb8146',
        borderWidth: 1,
      },
      label: {
        color: '#893448',
      },
    },
  },
};

export default theme;
