import { JSONPatch } from '@gonfalon/rest-api';
import { isImmutable, Set } from 'immutable';

import { createVariation, Flag } from 'utils/flagUtils';
import { createJsonPatch } from 'utils/patchUtils';

export const createFlagJsonPatch = (
  oldFlag: Flag,
  newFlag: Flag,
  comment?: string,
  options?: { removeEmptyFields?: boolean },
) => {
  const oldRep = oldFlag.toGlobalRep();
  const newRep = newFlag.toGlobalRep();
  const patchOptions = {
    comment,
    removeEmptyFields: options?.removeEmptyFields,
    propFilter: () => false,
    transformPatch: (patch: JSONPatch) => {
      let updatedPatch = [...patch];

      const configOpRegex = /^\/environments\/([.A-Za-z_\-0-9]+)\//;

      let numEnvironmentChanges = 0;
      const patchedConfigs = patch.reduce((acc, op) => {
        const match = op.path.match(configOpRegex);
        if (!match) {
          return acc;
        }
        numEnvironmentChanges++;

        const [, envKey] = match;

        return newFlag.environments.has(envKey) ? acc.add(envKey) : acc;
      }, Set());

      const numOfAllChanges = updatedPatch.length;
      if (numEnvironmentChanges < numOfAllChanges) {
        const nextVersion = isImmutable(newRep) ? newRep.get('_version') : newRep._version;
        updatedPatch = [{ op: 'test', path: '/_version', value: nextVersion }, ...updatedPatch];
      }

      if (patchedConfigs.size === 0) {
        return updatedPatch;
      }

      for (const envKey of patchedConfigs) {
        updatedPatch = [
          {
            op: 'test',
            path: `/environments/${envKey}/version`,
            value: newFlag.getIn(['environments', envKey, 'version']),
          },
          ...updatedPatch,
        ];
      }
      return updatedPatch;
    },
  };

  const jsonPatch = createJsonPatch(oldRep, newRep, patchOptions);

  return jsonPatch;
};

// Matches a uuid in any subset of a string. Copied (and slightly modified to handle substrings) from api2/init_test.go.
const uuidRegexp = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/;

const uuidShouldBeSubstitutedRegexp = new RegExp(`/variations/${uuidRegexp.source}$`);

// createVariationJsonPatch uses a custom algorithm for correctly generating variation updates based on variation ID.
// assumes invariant: you cannot manually reorder flag variations
export function createVariationJsonPatch(oldFlag: Flag, newFlag: Flag, comment?: string) {
  const oldVariations = oldFlag.variations;
  const newVariations = newFlag.variations;

  const oldDefaults = oldFlag.defaults;
  const newDefaults = newFlag.defaults;

  // We'll create patches using variation ids as keys (instead of array indices), and replace the IDs later
  // This is safe because we don't support reordering variations
  const oldVariationMap: { [s: string]: number } = {};
  const newVariationMap: { [s: string]: number } = {};

  // Keep track of the original index location of each ID
  const oldVariationIndices: { [s: string]: number } = {};

  oldVariations.forEach((v, i) => {
    oldVariationMap[v._id] = createVariation(v).toRep();
    oldVariationIndices[v._id] = i;
  });
  newVariations.forEach((v) => (newVariationMap[v._id] = createVariation(v).toRep()));

  // Keep track of the current index location of each ID as we iterate through the created patch operations
  const currentVariationIndices = { ...oldVariationIndices };

  const variationPatchOptions = {
    comment,
    propFilter: () => false,
    transformPatch: (patch: JSONPatch) => {
      let updatedPatch = [...patch];
      const tests: JSONPatch = [];
      const seenIds: { [s: string]: boolean } = {};
      updatedPatch = updatedPatch.map((p) => {
        const match = p.path.match(uuidRegexp);
        if (!match || !match.length) {
          return p;
        }

        const id = match[0];
        const currentIdx = currentVariationIndices[id];
        let path = p.path;

        if (uuidShouldBeSubstitutedRegexp.test(path)) {
          switch (p.op) {
            case 'add':
              // Adding a variation
              return { op: p.op, path: '/variations/-', value: p.value };
            case 'remove':
              // Removing a variation
              // Update bookkeeping variables
              for (const [k, v] of Object.entries(currentVariationIndices)) {
                v > currentIdx && currentVariationIndices[k]--;
              }
              break;
            default:
          }
        }

        // Replace the id in the path with the correct index of the item to replace
        // Since tests are prepended to the patch, use the original index
        const originalIdx = oldVariationIndices[id];
        path = p.path.replace(id, currentIdx.toString());
        if (!seenIds[id]) {
          tests.push({ op: 'test', path: `/variations/${originalIdx}/_id`, value: id });
        }
        seenIds[id] = true;

        switch (p.op) {
          case 'add':
          case 'replace':
            return { op: p.op, path, value: p.value };
          case 'remove':
            return { op: p.op, path };
          default:
            return p;
        }
      });
      updatedPatch = [...tests, ...updatedPatch];
      return updatedPatch;
    },
  };

  const variationsPatch = createJsonPatch({ defaults: oldDefaults }, { defaults: newDefaults });
  const patch = createJsonPatch(
    { variations: oldVariationMap },
    { variations: newVariationMap },
    variationPatchOptions,
  );

  if ('patch' in patch) {
    if (Array.isArray(variationsPatch)) {
      patch.patch.push(...variationsPatch);
    }
  } else {
    if (Array.isArray(variationsPatch)) {
      patch.push(...variationsPatch);
    }
  }
  return patch;
}
