import { Component, createRef } from 'react';
import { find } from '@gonfalon/es6-utils';
import { Focusable, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';

import {
  parseResourceType,
  PolicyActionAndDescription,
  PolicyActions,
  PolicyStatement as PolicyStatementRecord,
  PolicyStatementRecordPlainType,
} from 'utils/policyUtils';

import PolicyAction from './PolicyAction';
import PolicyResource from './PolicyResource';
import PolicyStatementEditorContainer from './PolicyStatementEditorContainer';

import styles from './styles.module.css';

export type PolicyStatementProps = {
  canEdit?: boolean;
  canRemove?: boolean;
  isEditing?: boolean;
  isPolicyActionsReady?: boolean;
  onCancel: () => void;
  onSubmit: (statement: PolicyStatementRecord) => void;
  onSubmitSuccess: () => void;
  onEdit: () => void;
  onRemove: () => void;
  onUpdateEditState?: () => void;
  policyActions: PolicyActions;
  shouldFocusOnMount?: boolean;
  shouldFocusOnAdded?: boolean;
  statement: PolicyStatementRecord;
  customEffects?: {
    allow: string;
    deny: string;
  };
  hideActions?: boolean;
};

/* eslint-disable import/no-default-export */
export default class PolicyStatement extends Component<PolicyStatementProps> {
  statementRef = createRef<HTMLDivElement>();

  state = {
    isFocused: false,
  };

  componentDidUpdate() {
    if (this.props.shouldFocusOnAdded && !this.state.isFocused) {
      this.statementRef.current?.focus();
      this.setState({ isFocused: true });
    }
  }

  render() {
    const {
      policyActions,
      canEdit,
      canRemove,
      isEditing,
      isPolicyActionsReady,
      onRemove,
      onSubmit,
      onSubmitSuccess,
      shouldFocusOnMount,
      statement,
      customEffects,
      hideActions,
    } = this.props;
    const { actions, effect, negateActions, negateResources, resources }: PolicyStatementRecordPlainType =
      statement.toJS();

    if (isEditing) {
      return (
        <PolicyStatementEditorContainer
          customEffects={customEffects}
          hideActions={hideActions}
          canRemove={canRemove}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
          onCancel={this.handleCancel}
          onRemove={onRemove}
          policyActions={isPolicyActionsReady ? policyActions : undefined}
          shouldFocusOnMount={shouldFocusOnMount}
          statement={statement}
        />
      );
    }

    const resourceType = parseResourceType(resources);
    const actionsForResourceType =
      resourceType && isPolicyActionsReady ? policyActions.getIn([resourceType, 'items']).toJS() : [];
    const actionsWithDescription: PolicyActionAndDescription[] = actions.map((action: string) => {
      if (action === '*') {
        // No extra description for "All actions"
        return { action };
      } else if (action.indexOf('*') !== -1) {
        return {
          action,
          description: `Actions matching "${action}"`,
        };
      }
      return find(actionsForResourceType, { action }) || { action };
    });

    const editBtnProps = () => {
      if (canEdit) {
        return {
          role: 'button',
          tabIndex: 0,
          onKeyDown: (event: React.KeyboardEvent) => {
            if ([' ', 'Enter'].includes(event.key)) {
              this.handleClick();
            }
          },
          'aria-label': 'Edit',
        };
      }

      return;
    };

    const component = (
      <div
        ref={this.statementRef}
        role="presentation"
        {...editBtnProps()}
        className={cx(styles.statement, {
          [styles.statementEditable]: canEdit,
        })}
        onClick={this.handleClick}
      >
        {resources.length ? (
          resources.map((r, index) => <PolicyResource resourceId={r} key={index} negate={negateResources} />)
        ) : (
          <span className={styles.unspecified}>No resources specified</span>
        )}
        <div className={styles.statementActions}>
          {!!effect && (
            <span
              className={cx(styles.statementEffect, {
                [styles.allow]: effect === 'allow',
                [styles.deny]: effect === 'deny',
              })}
            >
              {effect}
            </span>
          )}
          {negateActions && <span className={styles.statementNegation}>all actions except for</span>}
          {actionsWithDescription.length ? (
            actionsWithDescription.map((actionObj) => (
              <PolicyAction key={actionObj.action} effect={effect} {...actionObj} />
            ))
          ) : (
            <span className={styles.unspecified}>No actions specified</span>
          )}
        </div>
        {canEdit && <Icon name="edit" data-test-id="EditIcon" className={styles.statementEditIcon} size="small" />}
      </div>
    );

    if (canEdit) {
      return (
        <TooltipTrigger>
          <Focusable>{component}</Focusable>
          <Tooltip>Edit statement</Tooltip>
        </TooltipTrigger>
      );
    } else {
      return component;
    }
  }

  handleCancel = (shouldRemove: boolean) => {
    this.props.onCancel();
    if (shouldRemove) {
      this.props.onRemove();
    }
  };

  handleClick = () => {
    if (this.props.canEdit) {
      this.props.onEdit();
    }
  };
}
