import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch } from 'react-redux';
import {
  enableAppsAndAppVersions as isAppsAndAppVersionsEnabled,
  enableTeamsOfTeams as isTeamsOfTeamsEnabled,
  isABTestingEnabled,
  isCustomRolesEnabled,
  isFlagStatusEnabled,
  isPayloadFilteringEnabled,
  isTeamsEnabled,
} from '@gonfalon/dogfood-flags';
import { useApplications, useReleasePipelines } from '@gonfalon/rest-api';
import { ProgressBar } from '@launchpad-ui/components';
import { keepPreviousData } from '@tanstack/react-query';
import { List } from 'immutable';
import { useWorkflowTemplateList } from 'queries/workflowTemplateQueries';

import { fetchFlagsForProject as fetchFlagsAction } from 'actions/flags';
import { fetchGoaltenderManifestsAndSubscriptions as fetchManifestsAction } from 'actions/integrations';
import { fetchMembersIfNeeded as fetchMembersAction } from 'actions/members';
import { fetchPaginatedMetrics as fetchMetricsAction } from 'actions/metrics';
import { fetchPayloadFilters as fetchPayloadFiltersAction } from 'actions/payloadFilters';
import { fetchProjects as fetchProjectsAction } from 'actions/projects';
import { fetchRoles as fetchRolesAction } from 'actions/roles';
import { fetchSegments as fetchSegmentsAction } from 'actions/segments';
import { fetchTeams as fetchTeamsAction } from 'actions/teams';
import ResourceFinder from 'components/ResourceFinder';
import { useSelector } from 'hooks/useSelector';
import { flagListRequestSelector, flagListSelector, flagStatusesSelector } from 'reducers/flags';
import { goaltenderSubscriptionsSelector, integrationsListSelector } from 'reducers/integrations';
import { allMembersSelector } from 'reducers/members';
import { metricListSelector, metricPaginationSelector } from 'reducers/metrics';
import { payloadFiltersRequestSelector, payloadFiltersSelector } from 'reducers/payloadFilters';
import {
  environmentsSelector,
  projectsSelector,
  useCurrentEnvironmentKey,
  useCurrentProjectKey,
} from 'reducers/projects';
import { rolesSelector } from 'reducers/roles';
import { segmentListRequestSelector, segmentListSelector } from 'reducers/segments';
import { teamsListSelector, teamsRequestSelector } from 'reducers/teams';
import { GoaltenderSubscription } from 'utils/goaltenderUtils';
import { createPayloadFilterFilters } from 'utils/payloadFilterUtils';
import { ready, RequestState } from 'utils/reduxUtils';
import { createTeamsFilters } from 'utils/teamsUtils';

const useReduxActions = () => {
  const projectKey = useCurrentProjectKey();
  const environmentKey = useCurrentEnvironmentKey();
  const dispatch = useDispatch();
  const fetchFlags = () => dispatch(fetchFlagsAction(projectKey, [environmentKey]));
  const fetchGoaltenderSubscriptions = () => dispatch(fetchManifestsAction());
  const fetchMembers = () => dispatch(fetchMembersAction());
  const fetchMetrics = () => dispatch(fetchMetricsAction());
  const fetchPayloadFilters = () =>
    dispatch(fetchPayloadFiltersAction(projectKey, createPayloadFilterFilters({ limit: -1 })));
  const fetchProjects = () => dispatch(fetchProjectsAction());
  const fetchRoles = () => dispatch(fetchRolesAction());
  const fetchSegments = () => dispatch(fetchSegmentsAction(projectKey, environmentKey));
  const fetchTeams = () => dispatch(fetchTeamsAction(createTeamsFilters({ limit: 1000 })));

  return {
    fetchFlags,
    fetchGoaltenderSubscriptions,
    fetchMembers,
    fetchMetrics,
    fetchPayloadFilters,
    fetchProjects,
    fetchRoles,
    fetchSegments,
    fetchTeams,
  };
};

const useRedux = () => ({
  enableABTesting: isABTestingEnabled(),
  enableCustomRoles: isCustomRolesEnabled(),
  enableFlagStatus: isFlagStatusEnabled(),
  enableTeams: isTeamsEnabled(),
  enableAppsAndAppVersions: isAppsAndAppVersionsEnabled(),
  enableTeamsOfTeams: isTeamsOfTeamsEnabled(),
  enablePayloadFiltering: isPayloadFilteringEnabled(),
  ...useReduxActions(),
});

type ResourceFinderContainerProps = {
  onClearResourceType: () => void;
  onValue: (id: string, type: string, projKey?: string) => void;
  resourceType: string;
};

export const ResourceFinderContainer = ({
  onClearResourceType,
  onValue,
  resourceType,
}: ResourceFinderContainerProps) => {
  const {
    enableABTesting,
    enableCustomRoles,
    enableFlagStatus,
    enablePayloadFiltering,
    enableTeams,
    enableAppsAndAppVersions,
    enableTeamsOfTeams,
    fetchFlags,
    fetchGoaltenderSubscriptions,
    fetchMembers,
    fetchMetrics,
    fetchPayloadFilters,
    fetchProjects,
    fetchRoles,
    fetchSegments,
    fetchTeams,
  } = useRedux();

  const environments = useSelector(environmentsSelector);
  const flagRequest = useSelector(flagListRequestSelector);
  const flags = useSelector(flagListSelector);
  const integrations = useSelector(integrationsListSelector) as List<GoaltenderSubscription>;
  const integrationsRequest = useSelector(goaltenderSubscriptionsSelector);
  const members = useSelector(allMembersSelector);
  const metrics = useSelector(metricListSelector);
  const metricsRequest = useSelector(metricPaginationSelector);
  const payloadFilters = useSelector(payloadFiltersSelector);
  const payloadFiltersRequest = useSelector(payloadFiltersRequestSelector);
  const projects = useSelector(projectsSelector);
  const roles = useSelector(rolesSelector);
  const segmentListRequest = useSelector(segmentListRequestSelector);
  const segments = useSelector(segmentListSelector);
  const statuses = useSelector(flagStatusesSelector);
  const teams = useSelector(teamsListSelector);
  const teamsRequest = useSelector(teamsRequestSelector);
  const { data: applications, isPending: isLoadingApplications } = useApplications(undefined, {
    enabled: enableAppsAndAppVersions,
  });
  const projectKey = useCurrentProjectKey();
  const releasePipelinesQuery = useReleasePipelines({ projectKey });

  let resources: Array<RequestState | undefined> = [projects, flagRequest, integrationsRequest];

  if (enableTeams) {
    resources = [...resources, members];

    if (isCustomRolesEnabled()) {
      resources = [...resources, roles];
    }
  }

  if (enableABTesting) {
    resources = [...resources, metricsRequest];
  }

  resources = [...resources, segmentListRequest];

  if (isTeamsOfTeamsEnabled()) {
    resources = [...resources, teamsRequest];
  }

  if (enablePayloadFiltering) {
    resources = [...resources, payloadFiltersRequest];
  }

  const { data: templates, isFetching: isFetchingTemplates } = useWorkflowTemplateList(
    { search: '', summary: true },
    { placeholderData: keepPreviousData, refetchOnWindowFocus: false },
    'Resource finder',
  );

  const areResourcesReady = ready(...resources);
  let isReady = areResourcesReady;
  if (enableAppsAndAppVersions) {
    isReady = areResourcesReady && !isLoadingApplications;
  }
  isReady = isReady && !releasePipelinesQuery.isPending;

  useEffect(() => {
    if (!isReady) {
      fetchProjects();
      fetchFlags();
      fetchGoaltenderSubscriptions();
      fetchSegments();

      if (enableTeams) {
        fetchMembers();

        if (enableCustomRoles) {
          fetchRoles();
        }
      }

      if (enableABTesting) {
        fetchMetrics();
      }

      if (enableTeamsOfTeams) {
        fetchTeams();
      }

      if (enablePayloadFiltering) {
        fetchPayloadFilters();
      }
    }
  }, []);

  if (!isReady || isFetchingTemplates) {
    return (
      <div className="ResourceFinder">
        <ProgressBar aria-label="Loading…" isIndeterminate />
      </div>
    );
  }

  return (
    <ResourceFinder
      enableABTesting={enableABTesting}
      enableCustomRoles={enableCustomRoles}
      enableFlagStatus={enableFlagStatus}
      applications={enableAppsAndAppVersions ? applications : undefined}
      enableTeams={enableTeams}
      enableAppsAndAppVersions={enableAppsAndAppVersions}
      enableTeamsOfTeams={enableTeamsOfTeams}
      enablePayloadFiltering={enablePayloadFiltering}
      environments={environments.get('entities')}
      flags={flags}
      integrations={integrations}
      members={members.get('entities')}
      metrics={metrics}
      onClearResourceType={onClearResourceType}
      onValue={onValue}
      payloadFilters={payloadFilters}
      projects={projects.get('entities')}
      releasePipelines={releasePipelinesQuery.data?.items || []}
      resourceType={resourceType}
      roles={roles.get('entities')}
      segments={segments}
      statuses={statuses}
      teams={teams}
      templates={templates || []}
    />
  );
};
