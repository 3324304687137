import { useCallback, useRef, useState } from 'react';
import { isEmpty } from '@gonfalon/es6-utils';

import { HousewareDateFilter } from '../../types/types';

import { useDatePickerUI } from './components/useDatePickerUI';
import { isDateFilterValid } from './utils/common';
import { allPeriodTypes } from './constants';
import { UseDateFilterProps } from './types';

export const useDateFilter = ({
  currentDateFilter,
  allowClearDateFilter = true,
  allowedDateRange,
  segmentedStyle = {},
  disabledViews = [],
  disableOffset = false,
  possiblePeriodTypes = allPeriodTypes,
  vizTypeAndTimeGranularity,
  onChange,
}: UseDateFilterProps) => {
  const [dateFilter, setDateFilter] = useState<HousewareDateFilter>(currentDateFilter ?? {});
  const [activeDateFilterType, setActiveDateFilterType] = useState<HousewareDateFilter['view']>(
    dateFilter?.view ?? 'between',
  );
  const [showOffset, setShowOffset] = useState(!!dateFilter?.offset);

  const lastDateFilterRef = useRef(currentDateFilter);

  if (currentDateFilter && lastDateFilterRef.current !== currentDateFilter) {
    lastDateFilterRef.current = currentDateFilter;
    setDateFilter(currentDateFilter);
    setActiveDateFilterType(currentDateFilter.view ?? 'between');
    setShowOffset(!!currentDateFilter.offset);
  }

  const handleDateFilterUpdate = useCallback(
    (value: Partial<HousewareDateFilter> | ((prev: HousewareDateFilter) => HousewareDateFilter)) => {
      if (isEmpty(value)) {
        return;
      }
      setDateFilter((prev) => {
        const updated = typeof value === 'function' ? value(prev) : { ...prev, ...value };
        onChange?.(updated);
        return updated;
      });
    },
    [onChange],
  );

  const clearDateFilter = useCallback(() => {
    setDateFilter({});
    onChange?.(undefined);
  }, [onChange]);

  const { datePickerContent } = useDatePickerUI({
    activeDateFilterType,
    setActiveDateFilterType,
    dateFilter,
    handleDateFilterUpdate,
    allowClearDateFilter,
    allowedDateRange,
    vizTypeAndTimeGranularity,
    showOffset,
    setShowOffset,
    disableOffset,
    possiblePeriodTypes,
    clearDateFilter,
    disabledViews,
    segmentedStyle,
  });

  const isValidDateFilter = isDateFilterValid(dateFilter);

  return {
    dateFilterUI: datePickerContent,
    dateFilter,
    clearDateFilter,
    handleDateFilterUpdate,
    isValidDateFilter,
  };
};
