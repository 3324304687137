import { BlockData, FilterOperatorKeys, TimeGranularityUnit } from '../../types/types';

type VizBlock = BlockData<'visualization'>;

type TabItem = {
  tab: string;
  key: VizBlock['properties']['representation'];
  disabled?: boolean;
  hide?: boolean;
  tooltip?: string;
  helpLink?: string;
  category: 'pa';
  icon?: React.ReactNode;
};

export const PAVizs: TabItem[] = [
  {
    tab: 'Trends',
    key: 'insights',
    tooltip: 'Analyse event metrics and compositions within your event data.',
    helpLink: 'https://docs.houseware.io/docs/trends',
    category: 'pa',
  },
  {
    tab: 'Funnels',
    key: 'funnels',
    tooltip: 'Investigate conversion through a series of events.',
    helpLink: 'https://docs.houseware.io/docs/funnels',
    category: 'pa',
  },

  {
    tab: 'Retention',
    key: 'retention_viz',
    tooltip: 'Assess user engagement over a period of time',
    helpLink: 'https://docs.houseware.io/docs/retention',
    category: 'pa',
  },
  {
    tab: 'Stickiness',
    key: 'stickiness_viz',
    tooltip: `See how often a user performs an event on a weekly or monthly basis to learn which events bring consumers back to your product.`,
    helpLink: 'https://docs.houseware.io/docs/stickiness',
    category: 'pa',
  },
  {
    tab: 'Flows',
    key: 'user_flow',
    tooltip: 'View the most common sequences of events that are performed by your users.',
    helpLink: 'https://docs.houseware.io/docs/flows',
    category: 'pa',
  },
];
export const PAVizKeys = PAVizs.map((viz) => viz.key);

export const periodFormatByTimegrain: Record<TimeGranularityUnit, string> = {
  hour: 'h:mm A, Do MMM',
  day: 'MMM Do, YYYY',
  week: '[Week] of MMM Do, YYYY',
  month: 'MMM, YYYY',
  quarter: '[Q]Q-Y',
  year: 'YYYY',
  all_time: '',
};

export const periodFormatByTimegrainLabel: Record<TimeGranularityUnit, string> = {
  hour: 'h:mm A, Do MMM',
  day: 'Do MMM',
  week: 'Do MMM YYYY',
  month: 'MMM YYYY',
  quarter: '[Q]Q-Y',
  year: 'YYYY',
  all_time: '',
};

export const housewareDataTypes = {
  string: 'string',
  number: 'number',
  float: 'float',
  date: 'date',
  boolean: 'boolean',
};

export const humanReadableMappingForAllFilterOperators: Record<FilterOperatorKeys, string> = {
  eq: 'equals',
  neq: 'not equals',
  gt: 'greater than',
  lt: 'less than',
  gte: 'greater than equals',
  lte: 'less than equals',
  in: 'is',
  not_in: 'is not',
  icontains: 'contains',
  like: 'like',
  is_not_set: 'is not set',
  is_set: 'is set',
  between: 'is between',
  not_between: 'is not between',
} as const;

export const humanReadableMappingForDateFilterOperators = {
  in: 'is',
  not_in: 'is not',
  gte: 'on or after',
  lte: 'on or before',
  between: 'is between',
  not_between: 'is not between',
} as const;

export const filterOperators: Array<{
  type: string;
  tooltip?: string;
  label: string;
  disabled: boolean;
  blockFilterDisabled?: boolean;
  appFilterDisabled: boolean;
  supported_data_types: string[];
}> = [
  {
    type: 'eq',
    label: humanReadableMappingForAllFilterOperators.eq,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [
      housewareDataTypes.string,
      housewareDataTypes.number,
      housewareDataTypes.float,
      housewareDataTypes.date,
      housewareDataTypes.boolean,
    ],
  },
  {
    type: 'neq',
    label: humanReadableMappingForAllFilterOperators.neq,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [
      housewareDataTypes.string,
      housewareDataTypes.number,
      housewareDataTypes.float,
      housewareDataTypes.boolean,
    ],
  },
  {
    type: 'in',
    label: humanReadableMappingForAllFilterOperators.in,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [
      housewareDataTypes.string,
      housewareDataTypes.number,
      housewareDataTypes.float,
      housewareDataTypes.date,
      housewareDataTypes.boolean,
    ],
  },
  {
    type: 'not_in',
    label: humanReadableMappingForAllFilterOperators.not_in,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [
      housewareDataTypes.string,
      housewareDataTypes.number,
      housewareDataTypes.float,
      housewareDataTypes.date,
      housewareDataTypes.boolean,
    ],
  },
  {
    type: 'icontains',
    label: humanReadableMappingForAllFilterOperators.icontains,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.string],
  },
  {
    type: 'gt',
    label: humanReadableMappingForAllFilterOperators.gt,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.number, housewareDataTypes.float],
  },
  {
    type: 'gte',
    label: humanReadableMappingForAllFilterOperators.gte,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.number, housewareDataTypes.float, housewareDataTypes.date],
  },
  {
    type: 'lt',
    label: humanReadableMappingForAllFilterOperators.lt,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.number, housewareDataTypes.float],
  },
  {
    type: 'lte',
    label: humanReadableMappingForAllFilterOperators.lte,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.number, housewareDataTypes.float, housewareDataTypes.date],
  },
  {
    type: 'between',
    label: humanReadableMappingForAllFilterOperators.between,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.number, housewareDataTypes.float, housewareDataTypes.date],
  },
  {
    type: 'not_between',
    label: humanReadableMappingForAllFilterOperators.not_between,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.number, housewareDataTypes.float, housewareDataTypes.date],
  },
  {
    type: 'is_not_set',
    tooltip: 'Select all events where property does not have any value',
    label: humanReadableMappingForAllFilterOperators.is_not_set,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.string],
  },
  {
    type: 'is_set',
    tooltip: 'Select all events where property has any value',
    label: humanReadableMappingForAllFilterOperators.is_set,
    disabled: false,
    blockFilterDisabled: false,
    appFilterDisabled: false,
    supported_data_types: [housewareDataTypes.string],
  },
];

export const blockEvents = {
  add: 'blockAdded',
};
