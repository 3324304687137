import { useEffect, useRef } from 'react';
import type { Matcher } from 'react-day-picker';
import { DateFormat } from '@gonfalon/format';
import { Button, ButtonGroup, Dialog, DialogTrigger, Popover } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { differenceInHours, isSameDay } from 'date-fns';

import { DateRangePicker } from '../DateRangePicker';
import { Time } from '../Time';
import { TimeRange } from '../TimeRange';

import './styles.css';

export type DateRangeFilterWithTriggerProps = {
  dates: Array<Date | number | undefined>;
  isOpen?: boolean;
  onChange(dates: Date[]): void;
  onConfirm(): void;
  enableCustomRange?: boolean;
  createShortcuts?(): Array<{
    label: string;
    dateRange: Array<Date | undefined>;
  }>;
  renderSelectionFn?(options: {
    isHour?: boolean;
    isSingleDay: boolean;
    startDate?: Date | number;
    endDate?: Date | number;
  }): React.ReactNode;
  disabledDays?: Matcher | Matcher[];
  footerBanner?: React.ReactNode;
  optionalDateSelection?: boolean;
};

export const DateRangeFilterWithTrigger = (props: DateRangeFilterWithTriggerProps) => {
  const {
    dates,
    isOpen,
    onConfirm,
    enableCustomRange = true,
    createShortcuts,
    renderSelectionFn,
    disabledDays,
    footerBanner,
    optionalDateSelection,
  } = props;

  const [startDate, endDate] = dates;
  const isClear = !startDate && !endDate;
  let noDatesText = optionalDateSelection ? 'Most recent' : 'Select';
  if (optionalDateSelection && createShortcuts) {
    const shortcut = createShortcuts().find((s) => !s.dateRange[0] && !s.dateRange[1]);
    if (shortcut) {
      noDatesText = shortcut.label;
    }
  }
  const isSingleDay = !!startDate && !!endDate && isSameDay(startDate, endDate);
  const canConfirm = optionalDateSelection
    ? !!((!startDate && !endDate) || (startDate && endDate))
    : !!(startDate && endDate);
  const hourDiff = startDate && endDate && differenceInHours(endDate, startDate);
  const isHour = hourDiff === 1;
  const triggerElement = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    triggerElement.current?.setAttribute('aria-expanded', isOpen?.toString() || '');
  }, [isOpen]);

  const renderSelection = () => {
    if (renderSelectionFn) {
      const selection = renderSelectionFn({ isHour, isSingleDay, startDate, endDate });
      if (selection !== null) {
        return selection;
      }
    }

    if (isHour) {
      return (
        <span>
          {startDate && <Time dateFormat={DateFormat.H_MM_A} datetime={startDate} notooltip />} &ndash;{' '}
          {startDate && endDate ? <Time dateFormat={DateFormat.H_MM_A} datetime={endDate} notooltip /> : 'To'}
        </span>
      );
    } else if (isSingleDay) {
      return <Time dateFormat={DateFormat.MMM_D_YYY} datetime={startDate} notooltip />;
    } else if (startDate && endDate) {
      return <TimeRange startDate={startDate} endDate={endDate} />;
    }
    return (
      <span>{startDate && <Time dateFormat={DateFormat.MMM_D_YYY} datetime={startDate} notooltip />} &ndash; To</span>
    );
  };

  const handleDateRangeChange = (dateRange: Date[]) => {
    props.onChange(dateRange);
  };

  const handleConfirm = (onClose: () => void) => {
    onConfirm();
    onClose();
  };

  return (
    <DialogTrigger>
      <Button>
        <Icon name="calendar" size="small" />
        {isClear ? noDatesText : <span>{renderSelection()}</span>}{' '}
        <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} size="small" />
      </Button>
      <Popover placement="bottom end">
        <Dialog>
          {({ close }) => (
            <div>
              <DateRangePicker
                createShortcuts={createShortcuts}
                dates={dates.map((d) => (d ? new Date(d) : undefined))}
                onChange={handleDateRangeChange}
                enableCustomRange={enableCustomRange}
                disabledDays={disabledDays}
              />

              {enableCustomRange && (
                <div className="DateRangeFilter-footer">
                  {footerBanner || null}
                  <ButtonGroup className="DateRangeFilter-footer-buttonGroup">
                    <Button size="small" onPress={close}>
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="primary"
                      isDisabled={!canConfirm}
                      onPress={() => handleConfirm(close)}
                    >
                      Apply
                    </Button>
                  </ButtonGroup>
                </div>
              )}
            </div>
          )}
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
};
