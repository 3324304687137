import { useRef, useState } from 'react';
import { useFocus, useFocusVisible, useHover } from 'react-aria';
import { useMatches } from 'react-router';
import { useTextOverflow } from '@gonfalon/dom';
import { QuietErrorBoundary } from '@gonfalon/error-boundaries';
import {
  Breadcrumb as Breadcrumb_,
  Breadcrumbs as Breadcrumbs_,
  Link,
  Tooltip,
  TooltipTrigger,
} from '@launchpad-ui/components';
import clsx from 'clsx';
import { z } from 'zod';

import styles from './Breadcrumbs.module.css';

const breadcrumbSchema = z.object({
  breadcrumb: z.union([z.string(), z.function().args(z.any()).returns(z.string())]),
});

type Item = {
  // id is used by react-aria-components under the hood
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
  text: string;
  href: string;
};

export function Breadcrumbs() {
  const matches = useMatches();

  const crumbs = matches.reduce((acc, match) => {
    const parse = breadcrumbSchema.safeParse(match.handle);
    if (!parse.success) {
      return acc;
    }

    const {
      data: { breadcrumb },
    } = parse;
    let text;
    if (typeof breadcrumb === 'function') {
      if (!match.data) {
        return acc;
      }
      text = breadcrumb({ data: match.data });
    } else {
      text = breadcrumb;
    }

    acc.push({
      id: match.id,
      text,
      href: match.pathname,
    });
    return acc;
  }, new Array<Item>());

  return (
    <QuietErrorBoundary severity="medium">
      <Breadcrumbs_ key={window.location.pathname} items={crumbs} className={styles.breadcrumbs}>
        {(item) => <Breadcrumb {...item} />}
      </Breadcrumbs_>
    </QuietErrorBoundary>
  );
}

const MAX_CHARS = 64;

function Breadcrumb(item: Item) {
  const { href, text } = item;
  const innerRef = useRef<HTMLSpanElement>(null);
  const { ref, hasOverflow, props: overflowProps } = useTextOverflow<HTMLSpanElement>();
  const { hoverProps, isHovered } = useHover({});
  const [isFocused, setFocused] = useState(false);
  const { focusProps } = useFocus({
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
  });
  const { isFocusVisible } = useFocusVisible();

  return (
    <Breadcrumb_
      className={clsx(styles.crumb, {
        [styles.shrink]: text.length < MAX_CHARS,
      })}
    >
      <TooltipTrigger isDisabled={!hasOverflow} isOpen={hasOverflow && (isHovered || (isFocusVisible && isFocused))}>
        {/* @ts-expect-error react-aria-components and react-aria are in disagreement about the event type here, but it's legit. */}
        <Link href={href} {...overflowProps} {...focusProps} ref={ref}>
          <span ref={innerRef} {...hoverProps}>
            {text}
          </span>
        </Link>
        <Tooltip className={styles.itemText} triggerRef={ref} placement="bottom start">
          {text}
        </Tooltip>
      </TooltipTrigger>
    </Breadcrumb_>
  );
}
