type CustomFetchConfig = Partial<RequestInit>;

export default async function $fetch(
  url: string,
  customConfig: CustomFetchConfig = {},
  headers: CustomFetchConfig['headers'] = {},
): Promise<Response> {
  // Check if body is FormData and adjust headers accordingly
  const isFormData = customConfig.body instanceof FormData;

  const config: RequestInit = {
    method: 'GET',
    ...customConfig,
    credentials: 'include',
    headers: {
      ...(!isFormData
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        : {}),
      ...headers,
    },
  };

  const res = await fetch(url, config);
  return res;
}
