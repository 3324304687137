import { formatValueAsCurrency, isCurrency } from './formatValueAsCurrency';

function hasAllTrailingZerosInDecimal(num: number | string) {
  const decimalPart = String(num).split('.')[1];
  return decimalPart ? /^0+$/.test(decimalPart) : false;
}

export function formatTreatmentResultValue(value: number | string, unit?: string) {
  if (Number(value) === 0) {
    return '0.00';
  }

  if (unit && isCurrency(unit)) {
    return formatValueAsCurrency(value, unit);
  }

  if (hasAllTrailingZerosInDecimal(value)) {
    // Any call to Number() with a string containing a comma will return NaN.
    // If value has BOTH a comma and all trailing zeros, we need to remove
    // the commas before parsing into a number, then add them back in at the end.

    const valueWithoutCommas = String(value).replace(',', '');
    return Number(valueWithoutCommas).toLocaleString('en-US', {
      // Hardcode two decimals
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,

      // Insert commas every 3 digits
      useGrouping: true,
    });
  }

  return `${value}${unit ? `${unit}` : ''}`;
}
