import { Experiment } from './types';

export const getIterationNumberForExperiment = ({
  experiment,
  iterationId,
}: {
  experiment: Experiment;
  iterationId: string;
}) => {
  let allIterations = experiment.previousIterations ?? [];
  if (experiment.currentIteration?.status === 'stopped') {
    allIterations = [experiment.currentIteration, ...allIterations];
  }
  const iterationIndex = allIterations.findIndex((iteration) => iteration?._id === iterationId);
  // not found in the list of iterations
  if (iterationIndex === -1) {
    return;
  }

  return allIterations.length - iterationIndex;
};
