import { clearEnvironmentFilters } from './clearEnvironmentFilters';
import { parseUIFlagListViewFromBrowser } from './parseUIFlagListViewFromBrowser';
import { serializeUIFlagListViewForBrowser } from './serializeUIFlagListViewForBrowser';

export const filterOutEnvironmentFiltersParams = ({ params }: { params: URLSearchParams }) => {
  const flagListViewFilter = parseUIFlagListViewFromBrowser(params);

  if (flagListViewFilter?.filter) {
    const filtersWithoutEnvFilters = clearEnvironmentFilters(flagListViewFilter.filter);
    flagListViewFilter.filter = filtersWithoutEnvFilters;
    return serializeUIFlagListViewForBrowser(flagListViewFilter);
  }
  return params;
};
