import { GetMetricGroupsQueryParams } from '@gonfalon/openapi';
import { getMetricGroupsQuery } from '@gonfalon/rest-api';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useSuspenseMetricGroups = (projectKey: string, query?: GetMetricGroupsQueryParams) =>
  useSuspenseQuery(
    getMetricGroupsQuery({
      projectKey,
      query,
    }),
  );
