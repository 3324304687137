import config from '../../app/config';
import {
  DatePeriodUnitsForDateFilter,
  DateWindow,
  HousewareDateFilter,
  HumanDateString,
  NonDayUnits,
} from '../../types/types';

export const allPeriodTypes: DatePeriodUnitsForDateFilter[] = ['day', 'week', 'month', 'quarter', 'year'];

export const dateFilterType = ['presets', 'since', 'last', 'between', 'on'] as const;

export const humanDateStrings: HumanDateString[] = [
  'Today',
  'Yesterday',
  ...allPeriodTypes
    .filter((period): period is NonDayUnits => period !== 'day')
    .map((period) => `This ${period}` as const),
  ...allPeriodTypes
    .filter((period): period is NonDayUnits => period !== 'day')
    .map((period) => `Last ${period}` as const),
];

export const VIZ_DATE_FILTER_FORMAT = 'YYYY-MM-DD';

export const periodDays = {
  day: 1,
  week: 7,
  month: 30,
  quarter: 90,
  year: 365,
};

export const defaultDateFilter: HousewareDateFilter = {
  view: 'last',
  from: {
    window: config.defaultDateFilterWindow as DateWindow,
  },
  to: {
    window: {
      unit: 'day',
      value: 0,
    } as DateWindow,
  },
};

export const emptyDateLabel = 'Select a date range';
