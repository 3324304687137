import { getAllSnowflakeIntegrationAPI } from '../app/apis/integration';

import { useProjectEnvAwareQuery } from './useProjectEnvAwareQueryHooks';

export type SnowflakeIntegration = {
  name: string;
  project_key: string;
  environment_key: string;
};

type UseGetAllSnowflakeIntegrationsOptions = {
  enabled?: boolean;
};

export function useGetAllSnowflakeIntegrations(options: UseGetAllSnowflakeIntegrationsOptions = {}) {
  const { enabled = true } = options;

  return useProjectEnvAwareQuery<SnowflakeIntegration[]>({
    queryKey: ['snowflake-integrations'],
    queryFn: getAllSnowflakeIntegrationAPI,
    enabled,
  });
}
