import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space, Tooltip } from 'antd';

import { HandleOffsetUIProps } from '../types';
export default function DatePickerOffsetUI({
  dateFilter,
  showOffset,
  setShowOffset,
  handleOffsetChange,
  handleDateFilterUpdate,
  disableOffset,
}: HandleOffsetUIProps) {
  const handleRemoveOffset = () => {
    handleDateFilterUpdate({ offset: undefined });
    setShowOffset(false);
  };

  if (showOffset) {
    return (
      <Space direction="horizontal" size="small">
        <InputNumber
          value={dateFilter?.offset?.value ?? 0}
          onChange={handleOffsetChange}
          style={{ width: '20rem' }}
          size="small"
          min={0}
          addonBefore={<span style={{ padding: '0 8px' }}>Offset by</span>}
          addonAfter={<span style={{ padding: '0 8px' }}>{dateFilter?.from?.window?.unit}s</span>}
        />
        <Tooltip title="Remove Offset" placement="top">
          <CloseCircleOutlined onClick={handleRemoveOffset} style={{ cursor: 'pointer' }} />
        </Tooltip>
      </Space>
    );
  }

  return (
    <Tooltip title={disableOffset ? 'Offset is not supported on this date filter' : 'Add offset'} placement="top">
      <Button
        size="small"
        disabled={disableOffset || !dateFilter?.from?.window?.unit}
        onClick={() => setShowOffset(true)}
        icon={<PlusOutlined />}
      >
        Add Offset
      </Button>
    </Tooltip>
  );
}
