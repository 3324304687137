import { useContext, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useBlocker, useInRouterContext, useLocation } from 'react-router';
import { isSandbox } from '@gonfalon/constants';
import { RoutePatternContext } from '@gonfalon/router';
import invariant from 'tiny-invariant';

import NavigationPromptModal from 'components/NavigationPromptModal';
import { trackPageLeaveEvent } from 'utils/navigationUtils';

import { trackCanceledNavigationWithUnsavedChanges, trackConfirmedNavigationWithUnsavedChanges } from './analytics';

export type NavigationPromptProps = {
  shouldBlock: boolean;
  allowList?: Set<string | RegExp>;
  onConfirmNavigationCallBack?(): void;
  onCancelNavigationCallBack?(): void;
  title?: string;
  body?: string;
};

/* eslint-disable import/no-default-export */
export default function NavigationPrompt({
  shouldBlock,
  allowList,
  onConfirmNavigationCallBack,
  onCancelNavigationCallBack,
  title,
  body,
}: NavigationPromptProps) {
  const isInRouterContext = useInRouterContext();
  invariant(isInRouterContext, 'You should not use <NavigationPrompt> outside of a <Router>');

  const { pathname } = useLocation();
  const routePattern = useContext(RoutePatternContext);
  const [isVisible, setVisible] = useState(false);
  const [action, setAction] = useState<('POP' | 'PUSH' | 'REPLACE') | undefined>(undefined);

  const blocker = useBlocker(({ historyAction, nextLocation }) => {
    // Never block on the sandbox. It is readonly and we want customers to roam without restrictions.
    if (isSandbox()) {
      blocker.proceed?.();
      setVisible(false);
      return false;
    }

    if (blocker.state === 'blocked' && !shouldBlock) {
      blocker.reset();
      setVisible(false);
      return false;
    }

    const nextPathname = nextLocation.pathname;

    // Check if the UI that initiated the navigation permits certain non-blocking transitions.
    // Example: the user proceeds to checkout after tweaking their plan.
    if (allowList && allowList.size > 0) {
      for (const v of allowList) {
        if (v === nextPathname || (v instanceof RegExp && nextPathname.match(v))) {
          blocker.proceed?.();
          return false;
        }
      }
    }

    // Show the confirmation dialog, remember the action type so that we can
    // forward it along with analytics
    if (shouldBlock) {
      setVisible(true);
      setAction(historyAction);
    }

    return shouldBlock;
  });

  // Wire up a `beforeunload` handler, because `useBlocker` doesn't do this for us and we want to preserve the original
  // behavior that was implemented via `history.block`:
  // - The native prompt should appear if the form is dirty and the user attempts a hard refresh (e.g. Cmd+R on a Mac)
  // - The native prompt can be opened on top of the custom prompt in NavigationPromptModal, if the form is in a dirty
  //   state, then the user attempts to navigate away, and then the user attempts to hard refresh before interacting
  //   with the custom prompt
  // - If the native prompt is shown on top of the custom prompt and then cancelled, the custom prompt still requires
  //   user interaction
  useEffect(() => {
    const handler = (e: Event) => {
      // Again like in useBlocker, never block on the sandbox.
      if (isSandbox()) {
        return;
      }

      if (shouldBlock) {
        e.preventDefault();
        // @ts-expect-error this is the intended use https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        e.returnValue = ''; // eslint-disable-line no-param-reassign

        return e.returnValue;
      }
    };

    window.addEventListener('beforeunload', handler, { capture: true });

    return () => {
      window.removeEventListener('beforeunload', handler, { capture: true });
    };
  }, [shouldBlock]);

  useEffect(() => {
    if (isVisible) {
      trackPageLeaveEvent('Leave Page Confirmation Dialogue Rendered', { url: pathname });
    }
  }, [isVisible]);

  return isVisible ? (
    <NavigationPromptModal
      onCancelNavigation={() => {
        trackCanceledNavigationWithUnsavedChanges(routePattern, action);
        if (onCancelNavigationCallBack) {
          onCancelNavigationCallBack();
        }
        setVisible(false);
        blocker.reset?.();
      }}
      onConfirmNavigation={() => {
        trackConfirmedNavigationWithUnsavedChanges(routePattern, action);
        if (onConfirmNavigationCallBack) {
          onConfirmNavigationCallBack();
        }
        flushSync(() => {
          setVisible(false);
        });
        blocker.proceed?.();
      }}
      title={title}
      body={body}
    />
  ) : null;
}
