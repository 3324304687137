import { useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { CloseCircleOutlined, DownOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';

import MutedText from '../../../components/MutedText';
import fuzzySearch from '../../../utils/fuzzySearch';
import convertToTitleCase from '../../../utils/transformToTitleCase';
import { compareStringsWithAlphabetPrecedenceIgnoringCase } from '../../../utils/visualizationDataHelpers';
import { filterOperators, housewareDataTypes, humanReadableMappingForDateFilterOperators } from '../../block/constants';

const { RangePicker } = DatePicker;

export const EventFilterSelector = ({
  filterObj,
  index,
  disabled,
  updateEvent,
  eventIndex,
  event,
  eventsData,
  showAddFilterBtn,
  eventMetadata,
  addNewEventFilter,
  shouldShowEntities,
  isEventDataLoading,
}) => {
  const eventPropertyTypes = [
    {
      label: 'All',
      value: 'all',
      key: 'all',
    },
    {
      label: 'Event properties',
      value: 'event',
      key: 'event',
    },
    {
      label: 'User attributes',
      value: 'user',
      key: 'user',
    },
    ...(shouldShowEntities && eventMetadata && eventMetadata.entities
      ? eventMetadata.entities.map((entity) => ({
          label: `${convertToTitleCase(entity.name)} properties`,
          value: entity.name,
          key: entity.name,
        }))
      : []),
  ];

  const [selectedPropertyTypeEventFilter, setSelectedPropertyTypeEventFilter] = useState('all');
  const [isPropertySelectorOpen, setIsPropertySelectorOpen] = useState(false);
  const [highCardinalityDimensionValueOptions, updateHighCardinalityDimensionValueOptions] = useState([]);

  const [highCardinalityEventFilterDimensions, updateHighCardinalityEventFilterDimensions] = useState({});
  const handleSelectedEvent = (selected, eventFilterIdx) => {
    updateEvent(
      {
        ...event,
        filters: {
          and: event?.filters?.and
            ? event.filters.and.map((filter, localIdx) => {
                if (filter.field === filterObj.field && eventFilterIdx === localIdx) {
                  return {
                    ...filterObj,
                    value: selected,
                  };
                }
                return filter;
              })
            : [
                {
                  ...filterObj,
                  value: selected,
                },
              ],
        },
      },
      eventIndex,
    );
  };

  const eventFilterDimensions = useMemo(() => {
    const eventMetadataDimensionNames = eventMetadata?.event_properties?.map((ep) => ep.name) || [];

    // ordering: event specific properties followed by common dimensions
    const tempEventFilterDimensionsMap = {};
    eventMetadata?.event_properties?.forEach((ep) => {
      tempEventFilterDimensionsMap[ep.name] = { ...ep, dimension_type: 'event' };
    });

    const sortedDimensions = Object.keys(tempEventFilterDimensionsMap)
      .sort(compareStringsWithAlphabetPrecedenceIgnoringCase)
      .reduce((obj, key) => {
        obj[key] = tempEventFilterDimensionsMap[key];
        return obj;
      }, {});

    if (shouldShowEntities) {
      eventMetadata?.entities?.forEach((entity) => {
        entity?.properties?.forEach((entityProperty) => {
          sortedDimensions[entityProperty?.name] = {
            ...entityProperty,
            dimension_type: entity?.name,
          };
        });
      });
    }

    eventsData?.dimensions
      ?.filter((dim) => dim.dimension_type !== 'unassigned' && !eventMetadataDimensionNames.includes(dim.name))
      ?.forEach((dim) => {
        sortedDimensions[dim.name] = dim;
      });

    return sortedDimensions;
  }, [eventsData?.dimensions, eventMetadata, shouldShowEntities]);

  const eventFilterDimensionsSegregatedByDimensionType = useMemo(() => {
    const objectSegreagtedByDimensionType = Object.values(eventFilterDimensions).reduce((result, obj) => {
      const { dimension_type: dimensionType, ...rest } = obj;

      if (selectedPropertyTypeEventFilter === 'all' || selectedPropertyTypeEventFilter === dimensionType) {
        if (!result[dimensionType]) {
          result[dimensionType] = { label: dimensionType, options: [] };
        }

        result[dimensionType].options.push(rest);
      }
      return result;
    }, {});

    return Object.values(objectSegreagtedByDimensionType);
  }, [eventFilterDimensions, selectedPropertyTypeEventFilter]);

  const [autoCompleteObj, setAutoCompleteObj] = useState({
    options: [],
    value: '',
    open: false,
  });

  const objectsWithBetweenOperators = event?.filters?.and?.reduce((acc, { field, operator, value }) => {
    if (operator === 'between' || operator === 'not_between') {
      if (!acc[field]) {
        acc[field] = {};
      }
      acc[field][operator] = value;
    }
    return acc;
  }, {});
  const getEventFilterDimValue = (f) => objectsWithBetweenOperators?.[f?.field]?.[f?.operator] || [];

  const getInfoForEventFilter = (dataType) => {
    if (!dataType) {
      return 'Select a dimension to add filters';
    }
    if (dataType === 'boolean') {
      return (
        <>
          Tip: Select the appropriate option for this event property: <br />
          <ul style={{ marginLeft: 0, paddingLeft: '14px' }}>
            <li>True: If the property applies or is present.</li>
            <li>False: If the property does not apply or is not present.</li>
          </ul>
        </>
      );
    }
    if (dataType === 'date') {
      return (
        <>
          Tip: To filter by date, select the appropriate option: <br />
          <ul style={{ marginLeft: 0, paddingLeft: '14px' }}>
            <li>'After': Includes the selected date and all dates afterwards.</li>
            <li>'Before': Includes the selected date and all dates before.</li>
            <li>Select is between or is not between operator to create a date range</li>
          </ul>
        </>
      );
    }
    if (dataType === 'number') {
      return 'This event property accepts only numbers as value.';
    }
    return 'This event property accepts text as value..';
  };
  const getSearchResultFromFilterValues = (query) => {
    const filteredValues = eventFilterDimensions[filterObj?.field]?.values.filter((value) =>
      value.toLowerCase().includes(query.toLowerCase()),
    );
    return filteredValues?.map((value) => ({
      value,
    }));
  };

  const handleSelectedDate = ({ selectedDate, eventFilterIndex }) => {
    updateEvent(
      {
        ...event,
        filters: {
          and: event?.filters?.and
            ? event.filters.and.map((filter, localIdx) => {
                if (filter.field === filterObj.field && eventFilterIndex === localIdx) {
                  return {
                    ...filterObj,
                    value: selectedDate,
                    operator: filterObj.operator,
                  };
                }
                return filter;
              })
            : [],
        },
      },
      eventIndex,
    );
  };

  const getHighCardinalityInputField = (eventFilterIdx) => {
    const { field, operator, value } = filterObj || {};
    const dataType = eventFilterDimensions[field]?.data_type;

    switch (dataType) {
      case 'number':
        return ['gte', 'gt', 'lt', 'lte'].includes(operator) ? (
          <Tooltip title="Tip: Too many values to show, please enter a number">
            <InputNumber
              controls={false}
              style={{ width: '100%' }}
              placeholder={!operator ? '' : 'Too many values to show, please enter a number'}
              size="small"
              disabled={!operator || disabled}
              value={value}
              onChange={(selected) => {
                if (dataType === 'number') {
                  if (Array.isArray(selected)) {
                    selected = selected.map(Number);
                  } else {
                    selected = Number(selected);
                  }
                }
                handleSelectedEvent(selected, eventFilterIdx);
                updateHighCardinalityEventFilterDimensions(selected);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Tip: Too many values to show, start typing a number and select the number to add">
            <Select
              allowClear
              showSearch
              disabled={!operator || disabled}
              size="small"
              placeholder={!operator ? '' : 'Type number to add and select'}
              style={{ width: '100%' }}
              value={value}
              onChange={(selected) => {
                if (Array.isArray(selected)) {
                  selected = selected.map(Number);
                } else {
                  selected = Number(selected);
                }
                handleSelectedEvent(selected, eventFilterIdx);
                updateHighCardinalityEventFilterDimensions(selected);
              }}
              mode={operator === 'in' || operator === 'not_in' ? 'multiple' : undefined}
              onSearch={(searchValue) => {
                if (dataType === housewareDataTypes.float) {
                  const floatValue = parseFloat(searchValue);
                  if (!Number.isNaN(floatValue)) {
                    updateHighCardinalityDimensionValueOptions([floatValue]);
                  }
                } else if (dataType === housewareDataTypes.number) {
                  const intValue = parseInt(searchValue, 10);
                  if (!Number.isNaN(intValue)) {
                    updateHighCardinalityDimensionValueOptions([intValue]);
                  }
                } else {
                  updateHighCardinalityDimensionValueOptions([searchValue]);
                }
              }}
              options={highCardinalityDimensionValueOptions.map((v) => ({
                value: v,
              }))}
              notFoundContent={
                <Space style={{ padding: '0 8px 4px' }}>Tip: Start typing and select the value to add</Space>
              }
            />
          </Tooltip>
        );
      case 'boolean':
        return null;
      default:
        return (
          <Tooltip title="Tip: Too many values to show, please enter a value">
            <Input
              size="small"
              placeholder={!operator ? '' : 'Too many values to show, please enter a value'}
              disabled={!operator || disabled}
              value={value}
              onChange={(e) => {
                handleSelectedEvent(e.target.value, eventFilterIdx);
              }}
            />
          </Tooltip>
        );
    }
  };

  return (
    <>
      <div
        key={`${filterObj.field}_${filterObj.type}_${index}`}
        style={{
          border: '1px solid var(--border)',
          padding: '12px 8px 8px 8px',
          background: 'var(--background)',
          borderRadius: 4,
          marginTop: 14,
          position: 'relative',
        }}
      >
        <Tag
          style={{
            position: 'absolute',
            left: 'calc(50% - 20px)',
            top: 'calc(-.5rem - 4px)',

            zIndex: 1,

            fontSize: '12px',
          }}
        >
          {index === 0 ? 'Where' : 'And'}
        </Tag>
        <Row gutter={8} style={{ justifyContent: 'space-between', marginBottom: 8 }}>
          <ClickAwayListener
            onClickAway={() => {
              setIsPropertySelectorOpen(false);
            }}
          >
            <Col span={disabled ? 24 : 21}>
              <Select
                disabled={disabled}
                open={isPropertySelectorOpen}
                onFocus={() => {
                  setIsPropertySelectorOpen(true);
                }}
                size="small"
                showSearch
                placeholder="Select property"
                style={{ width: '100%' }}
                value={filterObj.field}
                onChange={(selected) => {
                  setIsPropertySelectorOpen(false);
                  updateEvent(
                    {
                      ...event,
                      filters: {
                        and: [
                          ...(event?.filters?.and
                            ? event.filters.and.filter((filter) => filter.field !== filterObj.field)
                            : []),
                          {
                            field: selected,
                            operator: eventFilterDimensions[selected].data_type === 'boolean' ? 'eq' : 'in',
                            value: eventFilterDimensions[selected].data_type === 'boolean' ? true : undefined,
                            source: eventFilterDimensions[selected].dimension_type,
                          },
                        ],
                      },
                    },
                    eventIndex,
                  );
                }}
                filterOption={(inputValue, option) => fuzzySearch([option.value], inputValue)}
                loading={isEventDataLoading}
                dropdownRender={(menu) => (
                  <>
                    <Flex
                      style={{
                        padding: '0 1rem',
                      }}
                      align="center"
                      justify="space-between"
                    >
                      <Typography.Text>
                        Show{' '}
                        <Dropdown
                          value={selectedPropertyTypeEventFilter}
                          trigger={['click']}
                          menu={{
                            items: eventPropertyTypes,

                            selectable: true,
                            onClick: (val) => {
                              setSelectedPropertyTypeEventFilter(val?.key);
                            },
                          }}
                        >
                          <Button
                            type="link"
                            style={{
                              padding: '0 0.1rem',
                            }}
                            onClick={() => {
                              setIsPropertySelectorOpen(true);
                            }}
                          >
                            {
                              eventPropertyTypes?.find((property) => property?.key === selectedPropertyTypeEventFilter)
                                ?.label
                            }{' '}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Typography.Text>
                    </Flex>
                    <Divider style={{ margin: '6px 0' }} />
                    {menu}
                  </>
                )}
                options={eventFilterDimensionsSegregatedByDimensionType?.map((dimensionTypeObject) => ({
                  label: eventPropertyTypes.find((property) => property?.key === dimensionTypeObject.label)?.label,
                  options: dimensionTypeObject.options
                    .filter((dim) => {
                      // allowing multiple filters for number,date & float properties
                      if (dim.data_type === 'date' || dim.data_type === 'number' || dim.data_type === 'float') {
                        return true;
                      }
                      return event.filters.and.every((filter) => filter.field !== dim.name);
                    })
                    .sort((dim1, dim2) => dim1.name - dim2.name)
                    .map((dimension) => {
                      const isDisabled =
                        Array.isArray(dimension.values) &&
                        typeof dimension.values[0] === 'object' &&
                        dimension.values[0] !== null;
                      return {
                        key: dimension.name,
                        label: (
                          <Tooltip
                            title={
                              isDisabled
                                ? "Can't apply filter on this dimension as values for the dimension aren't supported on LaunchDarkly."
                                : dimension.name
                            }
                          >
                            {dimension.name}
                          </Tooltip>
                        ),
                        value: dimension.name,
                        disabled: isDisabled,
                      };
                    }),
                }))}
              ></Select>
            </Col>
          </ClickAwayListener>

          {!disabled && (
            <Col span={3}>
              <Flex style={{ cursor: 'pointer' }} align="center" gap={4} justify="center">
                <Tooltip
                  title={getInfoForEventFilter(
                    filterObj.field ? eventFilterDimensions[filterObj.field]?.data_type : undefined,
                  )}
                >
                  <Button type="text" size="small" icon={<InfoCircleOutlined />} />
                </Tooltip>
                <Tooltip title="Click to remove filter">
                  <Button
                    type="text"
                    size="small"
                    icon={<CloseCircleOutlined />}
                    onClick={() => {
                      updateEvent(
                        {
                          ...event,
                          filters: {
                            and: event?.filters?.and
                              ? event.filters.and.filter(
                                  (filter, localIdx) => !(filter.field === filterObj.field && index === localIdx),
                                )
                              : [],
                          },
                        },
                        eventIndex,
                      );
                    }}
                  />
                </Tooltip>
              </Flex>
            </Col>
          )}
        </Row>
        <Row gutter={6} style={{ alignItems: 'flex-end' }}>
          {filterObj?.field && eventFilterDimensions && eventFilterDimensions[filterObj?.field] && (
            <Col span={eventFilterDimensions[filterObj?.field]?.data_type === 'boolean' ? 24 : 10}>
              {eventFilterDimensions[filterObj?.field]?.data_type === 'boolean' ? (
                <Radio.Group
                  style={{ width: '100%' }}
                  value={Array.isArray(filterObj.value) ? filterObj.value[0] : filterObj.value}
                  size="small"
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => {
                    const selected = e.target.value;
                    handleSelectedEvent(selected, index);
                    updateHighCardinalityEventFilterDimensions(selected);
                  }}
                  disabled={disabled}
                >
                  <Radio.Button style={{ width: '50%' }} value>
                    True
                  </Radio.Button>
                  <Radio.Button style={{ width: '50%' }} value={false}>
                    False
                  </Radio.Button>
                </Radio.Group>
              ) : (
                <Tooltip title={filterObj.field && 'Tip: select an operator from the dropdown options'}>
                  <Select
                    size="small"
                    showSearch
                    placeholder={!filterObj.field ? '' : 'Please select an operator'}
                    style={{ width: '100%' }}
                    value={filterObj?.operator}
                    disabled={!filterObj.field || disabled}
                    onChange={(selected) => {
                      updateEvent(
                        {
                          ...event,
                          filters: {
                            and: event?.filters?.and
                              ? event.filters.and.map((filter, localIdx) => {
                                  if (filter.field === filterObj.field && index === localIdx) {
                                    return {
                                      field: filterObj.field,
                                      operator: selected,
                                      value: selected === 'is_not_set' || selected === 'is_set' ? ' ' : undefined,
                                      source: filterObj.source,
                                    };
                                  }
                                  return filter;
                                })
                              : [],
                          },
                        },
                        eventIndex,
                      );
                    }}
                  >
                    {filterOperators
                      .filter((option) => {
                        const isEqOrNeq = option.type === 'eq' || option.type === 'neq';
                        const hasSupportedDataType =
                          filterObj?.field &&
                          eventFilterDimensions[filterObj?.field] &&
                          eventFilterDimensions[filterObj?.field].data_type;
                        if (isEqOrNeq) {
                          return false;
                        }
                        if (!hasSupportedDataType) {
                          return option.supported_data_types.includes(housewareDataTypes.string);
                        }
                        return option.supported_data_types.includes(eventFilterDimensions[filterObj?.field].data_type);
                      })
                      .map((option) => {
                        const label =
                          eventFilterDimensions[filterObj?.field].data_type === 'date'
                            ? humanReadableMappingForDateFilterOperators[option.type]
                            : option.label;
                        return (
                          <Select.Option
                            key={option.type}
                            label={label}
                            value={option.type}
                            disabled={option.blockFilterDisabled}
                          >
                            <Tooltip title={option?.tooltip || label}>{label}</Tooltip>
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Tooltip>
              )}
            </Col>
          )}
          <Col span={14}>
            {eventFilterDimensions?.[filterObj?.field]?.data_type === 'boolean' ? null : eventFilterDimensions?.[
                filterObj?.field
              ]?.data_type === 'date' ? (
              ['in', 'not_in'].includes(filterObj?.operator) ? (
                <DatePicker
                  multiple
                  value={Array.isArray(filterObj?.value) || !!filterObj?.value ? filterObj.value : undefined}
                  onChange={(selectedDate) => {
                    handleSelectedDate({
                      selectedDate,
                      eventFilterIndex: index,
                    });
                  }}
                  size="small"
                  disabled={disabled}
                  maxTagCount={2}
                  style={{
                    borderRadius: '5px',
                  }}
                />
              ) : ['gte', 'lte'].includes(filterObj?.operator) ? (
                <DatePicker
                  size="small"
                  disabled={disabled}
                  style={{ width: '100%' }}
                  value={
                    Array.isArray(filterObj?.value)
                      ? dayjs(filterObj.value[0])
                      : filterObj?.value
                        ? dayjs(filterObj.value)
                        : undefined
                  }
                  placeholder={disabled ? '' : 'Select a date'}
                  onChange={(date) => {
                    const selectedDate = dayjs(date).format('YYYY-MM-DD');
                    handleSelectedDate({
                      selectedDate,
                      eventFilterIndex: index,
                    });
                  }}
                />
              ) : ['between', 'not_between'].includes(filterObj?.operator) ? (
                <RangePicker
                  size="small"
                  disabled={disabled}
                  style={{ width: '100%' }}
                  value={
                    Array.isArray(filterObj?.value) && !!filterObj?.value
                      ? [dayjs(filterObj.value[0]), dayjs(filterObj.value[1])]
                      : undefined
                  }
                  placeholder={disabled ? undefined : ['Start date', 'End date']}
                  onChange={(dates) => {
                    const selectedDate = dates?.map((date) => dayjs(date).format('YYYY-MM-DD'));
                    handleSelectedDate({
                      selectedDate,
                      eventFilterIndex: index,
                    });
                  }}
                />
              ) : null
            ) : filterObj?.operator === 'between' || filterObj?.operator === 'not_between' ? (
              <div style={{ width: '100%', display: 'flex' }}>
                <Tooltip title={disabled ? '' : 'Tip: Enter a numeric value '}>
                  <InputNumber
                    size="small"
                    style={{ width: '100%', height: '2.2rem' }}
                    controls={false}
                    disabled={disabled}
                    value={getEventFilterDimValue(filterObj)[0] ?? null}
                    placeholder={disabled ? '' : 'Please enter a value'}
                    onChange={(selected) => {
                      const newValues = [...getEventFilterDimValue(filterObj)];
                      newValues[0] = selected;
                      handleSelectedEvent(newValues, index);
                    }}
                  />
                </Tooltip>
                <MutedText style={{ padding: 5, display: 'inline-flex' }}>→</MutedText>
                <Tooltip title={disabled ? '' : 'Tip: Enter a numeric value'}>
                  <InputNumber
                    size="small"
                    style={{ width: '100%', height: '2.2rem' }}
                    controls={false}
                    disabled={disabled}
                    value={getEventFilterDimValue(filterObj)[1] ?? null}
                    placeholder={disabled ? '' : 'Please enter a value'}
                    onChange={(selected) => {
                      const newValues = [...getEventFilterDimValue(filterObj)];
                      newValues[1] = selected;
                      handleSelectedEvent(newValues, index);
                    }}
                  />
                </Tooltip>
              </div>
            ) : eventFilterDimensions?.[filterObj?.field]?.is_high_cardinality ? (
              getHighCardinalityInputField(index)
            ) : eventFilterDimensions?.[filterObj?.field]?.data_type === 'number' ? (
              <Tooltip
                title={
                  filterObj?.operator &&
                  `Tip: ${
                    filterObj?.operator === 'in' || filterObj?.operator === 'not_in'
                      ? 'You can type or select multiple values from the dropdown options'
                      : 'Select a value from the dropdown options or type in a value and select'
                  } `
                }
              >
                <Select
                  size="small"
                  showSearch
                  allowClear={filterObj?.operator === 'in' || filterObj?.operator === 'not_in'}
                  style={{ width: '100%' }}
                  placeholder={
                    !filterObj?.operator
                      ? ''
                      : `Please select ${
                          filterObj?.operator === 'in' || filterObj?.operator === 'not_in' ? ' one or more' : 'a'
                        } value`
                  }
                  disabled={!filterObj?.operator || disabled}
                  value={filterObj?.value}
                  onChange={(selected) => {
                    if (Array.isArray(selected)) {
                      selected = selected.map(Number);
                    } else {
                      selected = Number(selected);
                    }
                    handleSelectedEvent(selected, index);
                    updateHighCardinalityEventFilterDimensions(selected);
                  }}
                  mode={filterObj?.operator === 'in' || filterObj?.operator === 'not_in' ? 'multiple' : undefined}
                  notFoundContent={
                    <Space style={{ padding: '0 8px 4px' }}>Tip: Start typing and select the value to add</Space>
                  }
                  onSearch={(searchValue) => {
                    updateHighCardinalityEventFilterDimensions((prev) => ({
                      ...prev,
                      [filterObj?.field]: {
                        ...prev[filterObj?.field],
                        values: [searchValue],
                      },
                    }));
                  }}
                >
                  {Array.from(
                    new Set(
                      [
                        ...(eventFilterDimensions?.[filterObj?.field]?.values ?? []),
                        ...(highCardinalityEventFilterDimensions?.[filterObj?.field]?.values ?? []),
                      ].map(Number),
                    ),
                  )?.map((value) => (
                    <Select.Option key={value} label={value}>
                      <Tooltip title={String(value)}>{String(value)}</Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            ) : filterObj?.operator === 'icontains' ? (
              <Tooltip title="Tip: Enter value and see the results that contain the value">
                <AutoComplete
                  size="small"
                  allowClear
                  disabled={!filterObj?.field || disabled}
                  style={{ width: '100%' }}
                  placeholder="Please enter value"
                  value={filterObj.value}
                  open={autoCompleteObj.open}
                  onBlur={() =>
                    setAutoCompleteObj((prev) => ({
                      ...prev,
                      open: false,
                    }))
                  }
                  onFocus={() => {
                    if (autoCompleteObj.value) {
                      setAutoCompleteObj((prev) => ({
                        ...prev,
                        open: true,
                      }));
                    }
                  }}
                  options={[
                    {
                      label: `Select value: ${autoCompleteObj.value}`,
                      value: autoCompleteObj.value,
                    },
                    {
                      label: `value found in ${autoCompleteObj.options.length} results`,
                      options: autoCompleteObj.options,
                    },
                  ]}
                  onSelect={(value) => {
                    handleSelectedEvent(value, index);
                    setAutoCompleteObj((prev) => ({
                      ...prev,
                      value: '',
                      open: false,
                    }));
                  }}
                  onChange={(value) => {
                    setAutoCompleteObj({
                      options: value ? getSearchResultFromFilterValues(value) : [],
                      value,
                      open: !!value,
                    });
                    handleSelectedEvent(value, index);
                  }}
                />
              </Tooltip>
            ) : filterObj?.operator === 'is_not_set' || filterObj?.operator === 'is_set' ? null : (
              <Tooltip
                title={
                  filterObj?.operator &&
                  `Tip: ${
                    filterObj?.operator === 'in' || filterObj?.operator === 'not_in'
                      ? 'You can select multiple values from the dropdown options'
                      : 'Select a value from the dropdown options'
                  } `
                }
                placement="right"
              >
                <Select
                  size="small"
                  showSearch
                  allowClear={filterObj?.operator === 'in' || filterObj?.operator === 'not_in'}
                  style={{ width: '100%' }}
                  placeholder={
                    !filterObj?.operator
                      ? ''
                      : `Please select ${
                          filterObj?.operator === 'in' || filterObj?.operator === 'not_in' ? ' one or more' : 'a'
                        } value`
                  }
                  disabled={!filterObj?.operator || disabled}
                  value={filterObj?.value}
                  onChange={(selected) => {
                    const dataType = eventFilterDimensions?.[filterObj?.field]?.data_type;
                    if (dataType === 'number') {
                      if (Array.isArray(selected)) {
                        selected = selected.map(Number);
                      } else {
                        selected = Number(selected);
                      }
                    }
                    handleSelectedEvent(selected, index);
                    updateHighCardinalityEventFilterDimensions(selected);
                  }}
                  mode={filterObj?.operator === 'in' || filterObj?.operator === 'not_in' ? 'multiple' : undefined}
                  notFoundContent={
                    eventFilterDimensions?.[filterObj?.field]?.is_high_cardinality && (
                      <>
                        <Space style={{ padding: '0 8px 4px' }}>Tip: Start typing and select the value to add</Space>
                      </>
                    )
                  }
                  onSearch={(searchValue) => {
                    if (eventFilterDimensions?.[filterObj?.field]?.is_high_cardinality) {
                      updateHighCardinalityEventFilterDimensions((prev) => ({
                        ...prev,
                        [filterObj?.field]: {
                          ...prev[filterObj?.field],
                          values: [searchValue],
                        },
                      }));
                    }
                  }}
                >
                  {(eventFilterDimensions?.[filterObj?.field]?.is_high_cardinality
                    ? highCardinalityEventFilterDimensions?.[filterObj?.field]?.values
                    : eventFilterDimensions?.[filterObj?.field]?.values
                  )?.map((value) => (
                    <Select.Option key={value} label={value}>
                      <Tooltip title={String(value)}>{String(value)}</Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            )}
          </Col>
          {!filterObj?.field && (
            <Col span={10}>
              <Input style={{ width: '100%' }} value={undefined} disabled size="small" />
            </Col>
          )}
        </Row>
      </div>
      {event?.filters?.and?.length - 1 === index && !disabled && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Tooltip title="Add filter" placement="top">
            <PlusOutlined
              onClick={addNewEventFilter}
              style={{
                border: '1px solid var(--primary-100)',
                cursor: 'pointer',
                marginTop: 0,

                opacity: 1,
                fontSize: '1.1rem',
                borderRadius: '20%',
                fontWeight: 500,
                padding: '2px',
                color: 'rgba(25, 23, 17, 0.6)',
                visibility: !showAddFilterBtn ? 'hidden' : 'visible',
              }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};
