import { getProjectEnvAwareURL } from '../../hooks/useProjectEnvAwareQueryHooks';
import config from '../config';
import $fetch from '../services/$fetch';

export async function getEventsAPI({ getFullUrl }) {
  const res = await $fetch(getFullUrl(config.dataServiceURL, '/product-analytics/metadata'));
  if (res.ok) {
    const data = await res.json();
    return data;
  }
  throw Error(res.statusText || res.status || 'Something went wrong');
}

export async function createCustomEventAPI({ payload, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/custom-events`,
    }),
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function updateCustomEventAPI({ eventUuid, payload, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/custom-events/${eventUuid}`,
    }),
    {
      method: 'PATCH',
      body: JSON.stringify(payload),
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function getCustomEventsAPI({ getFullUrl }) {
  const res = await $fetch(getFullUrl(config.dataServiceURL, '/product-analytics/custom-events'));
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function getHiddenEventsAPI({ getFullUrl }) {
  const res = await $fetch(getFullUrl(config.dataServiceURL, '/product-analytics/events/blacklist'));
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function hideEventForOrgAPI({ payload, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/events/org/blacklist`,
    }),
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function unhideEventForOrgAPI({ payload, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/events/org/blacklist`,
    }),
    {
      method: 'DELETE',
      body: JSON.stringify(payload),
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function getUserEventsAPI({ payload, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/user-activity`,
    }),
    {
      method: 'POST',
      body: JSON.stringify({
        user_id: payload.userId,
        start_date: payload.dateFilter.startDate,
        end_date: payload.dateFilter.endDate,
        hide_events: payload.eventsToHide,
        only_show_events: payload.onlyShowEvents,
      }),
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results.events;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function getEventMetadataAPI({ eventName, getFullUrl }) {
  const res = await $fetch(getFullUrl(config.dataServiceURL, `/product-analytics/events/${eventName}`));
  if (res.ok) {
    return res.json();
  } else {
    const errorResponse = await res.json();
    throw new Error(errorResponse.detail);
  }
}

export async function updateDimensionTypeAPI({ property_name, dimension, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/property/${property_name}`,
    }),
    {
      method: 'PUT',
      body: JSON.stringify(dimension),
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}
export async function getUserEvents() {
  const res = await $fetch(`${config.dataServiceURL}/event/segment/`);
  return res.json();
}
