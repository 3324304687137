import { Button, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import clsx from 'clsx';
import { FormGroup, FormHint, Label, TextField } from 'launchpad';

import PolicyResource from 'components/policyEditor/PolicyResource';

import styles from '../styles.module.css';

type PolicyStatementResourcesProps = {
  handleResourceFinderClick: () => void;
  negateResources: boolean;
  onChange: (key: 'resources', value: string[]) => void;
  resources: string[];
  resourcesInput?: HTMLInputElement | null;
  setResourcesInput: (input: HTMLInputElement) => void;
  setTextSelection: (selectionStart: number, selectionEnd: number) => void;
  showResourceFinder: () => void;
};

const PolicyStatementResources = ({
  handleResourceFinderClick,
  negateResources,
  onChange,
  resources,
  resourcesInput,
  setResourcesInput,
  setTextSelection,
  showResourceFinder,
}: PolicyStatementResourcesProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // show resource finder on '⌘' + '.' or 'ctrl' + '.'
    if ((event.metaKey || event.ctrlKey) && event.key === '.') {
      if (resourcesInput) {
        const input = resourcesInput;
        if (input?.selectionStart && input.selectionEnd) {
          const { selectionStart, selectionEnd } = input;
          setTextSelection(selectionStart, selectionEnd);
        }
      }
      showResourceFinder();
    }
  };

  return (
    <FormGroup className="fs-exclude">
      <div className="u-flex u-flex-column">
        <TooltipTrigger>
          <Button
            autoFocus
            className={clsx(styles.resourceFinderBtn, styles.resourceFinderTooltip)}
            variant="default"
            size="small"
            onPress={handleResourceFinderClick}
          >
            Resource finder
          </Button>
          <Tooltip placement="top">The resource finder makes it easy to find the IDs of your account resources</Tooltip>
        </TooltipTrigger>
        <Label htmlFor="PolicyStatement-Resources">
          {negateResources ? 'Resources to exclude' : 'Enter resource identifiers for this policy statement'}
        </Label>
      </div>
      <TextField
        id="PolicyStatement-Resources"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('resources', e.target.value.split(' '))}
        onKeyDown={handleKeyDown}
        ref={(input) => {
          if (input) {
            setResourcesInput(input);
          }
        }}
        value={resources.join(' ')}
        data-test-id="resource-specifier"
      />
      <FormHint>
        {resources.filter((r: string) => r !== '').length > 1 ? (
          <span className={styles.statementResources}>{`${resources.length} resources specified`}</span>
        ) : (
          <PolicyResource resourceId={resources[0]} negate={negateResources} />
        )}
      </FormHint>
    </FormGroup>
  );
};

/* eslint-disable import/no-default-export */
export default PolicyStatementResources;
