import { useMemo } from 'react';
import { enablePaginationOnGetSegments } from '@gonfalon/dogfood-flags';
import { type GetSegmentsQueryParams } from '@gonfalon/openapi';
import { List } from 'immutable';
import { useGetSegmentsQuery } from 'queries/segmentQueries';

import { filteredSegmentListSelector, segmentsPaginationSelector } from 'reducers/segments';
import { ImmutableServerError } from 'utils/httpUtils';
import { PaginationType } from 'utils/paginationUtils';
import { Segment } from 'utils/segmentUtils';
import { makeFilter } from 'utils/stringUtils';

import { useSelector } from './useSelector';

export type UseSegmentsProps = {
  projKey: string;
  envKey: string;
  appliedParams?: GetSegmentsQueryParams;
  enabled?: boolean;
  apiVersion?: string;
};

type UseSegmentsReturnType = {
  segments: List<Segment>;
  error: ImmutableServerError | null;
  isReady: boolean;
  isSegmentsListEmpty: boolean;
  pagination: PaginationType;
};

export const useSegments = ({
  projKey,
  envKey,
  appliedParams,
  // defaults to true because we always want to fetch unless otherwise specified
  enabled = true,
  apiVersion,
}: UseSegmentsProps): UseSegmentsReturnType => {
  const isPaginationEnabledOnGetSegments = enablePaginationOnGetSegments();

  const segmentsPagination = useSelector(segmentsPaginationSelector);
  const filteredSegmentList = useSelector(filteredSegmentListSelector);

  const canUseUpdatedSegmentsApi = isPaginationEnabledOnGetSegments;

  const {
    error,
    isFetched: isReady,
    normalizedResponse: segmentsResponse,
  } = useGetSegmentsQuery({
    projectKey: projKey,
    environmentKey: envKey,
    params: { ...appliedParams, expand: 'flags' },
    enabled,
    apiVersion,
  });

  const segmentsList = useMemo<List<Segment>>(() => {
    if (canUseUpdatedSegmentsApi) {
      if (!isReady || !segmentsResponse) {
        return List();
      }
      // no need to sort or filter here because results come sorted and filtered from API
      return segmentsResponse.getIn(['entities', 'segments'])?.toList() ?? List();
    }

    // sort descending order
    const sorted = filteredSegmentList.sort((a: Segment, b: Segment) => {
      const aTime = a.lastModifiedDate ? a.lastModifiedDate : a.creationDate;
      const bTime = b.lastModifiedDate ? b.lastModifiedDate : b.creationDate;
      return bTime - aTime;
    });

    // filter by keys if present
    const filterKeys = appliedParams?.filter?.keys || [];
    const filteredByKeys = appliedParams?.filter?.keys
      ? sorted.filter((segment: Segment) => filterKeys.includes(segment.key))
      : sorted;

    // filter by query text
    const filterQuery = appliedParams?.filter?.query;
    const filteredByQuery = filterQuery
      ? filteredByKeys.filter(makeFilter(filterQuery, 'name', 'key'))
      : filteredByKeys;

    return filteredByQuery;
  }, [canUseUpdatedSegmentsApi, segmentsResponse, filteredSegmentList, appliedParams, isReady]);

  const isSegmentsListEmpty = isPaginationEnabledOnGetSegments
    ? segmentsPagination?.get('totalCount') === 0
    : segmentsList.isEmpty();

  return {
    segments: segmentsList,
    error: error as ImmutableServerError,
    isReady,
    pagination: segmentsPagination,
    isSegmentsListEmpty,
  };
};
