import { type InternalEnvironment } from '@gonfalon/environments';
import { Project } from '@gonfalon/projects/src/types';
import { getQueryClient } from '@gonfalon/react-query-client';
import {
  fetchEnvironments,
  fetchSelectedEnvironments,
  internalEnvironmentList,
  isRESTAPIError,
  projectsDetail,
} from '@gonfalon/rest-api';

import { ProjectContext } from '../../projectContext';

export interface ProjectContextForRequest {
  context: ProjectContext;
  project: Project;
  environments: InternalEnvironment[];
  shouldRedirect?: boolean;
}

const fetchDefaultEnvironmentKeys = async (projectKey: string) =>
  new Set(
    (
      await fetchEnvironments({
        projectKey,
        params: { sort: 'name', limit: 2 },
      })
    ).items
      .slice(0, 2)
      .map((env) => env.key),
  );

// This should only be used in `requireProjectContext`
/**
 * Fetches a valid project context for a given request or undefined if none defined in the request.
 *
 * A valid project context includes a project, a set of environments, and a the project context.
 * The projectContext.environmentKeys is the strict set of keys from the array of environments
 * The projectContext.selectedEnvironmentKey is one of projectContext.environmentKeys
 *
 * If no environmentKeys are requested, try pulling it from the last selected environments
 * If there are no API-saved environments, try pulling the first two environments from the project
 * If the selected/requested environment is not in the project, redirect to the first environment
 *
 * @param request - The request object containing the project context.
 * @returns A promise that resolves to an object containing the project context, project details, sorted environments, and a flag indicating if a url redirect is needed.
 * @throws Will throw an error if the project does not have at least two environments.
 */
const getProjectFallback = async (projectKey: string) => {
  const query = projectsDetail({ projectKey });
  const queryClient = getQueryClient();

  try {
    const project = await queryClient.fetchQuery(query);

    return project;
  } catch (e) {
    // If it's a REST API error, throw it, otherwise, it could be a network error, in which case we should try to fallback to the cache
    if (isRESTAPIError(e)) {
      throw e;
    }

    return queryClient.ensureQueryData(query);
  }
};
export async function fetchProjectContextForRequest(
  requestedProjectContext: ProjectContext,
): Promise<ProjectContextForRequest> {
  const projectContext = { ...requestedProjectContext };
  const queryClient = getQueryClient();

  // We don't have any environments in the URL
  if (projectContext.environmentKeys.size === 0) {
    // Ask the API for the last set of environments
    const fromAPI = await fetchSelectedEnvironments({ projectKey: projectContext.projectKey });
    projectContext.environmentKeys = new Set(fromAPI.environments.map((env) => env.key));
    projectContext.selectedEnvironmentKey = fromAPI.selectedEnvironmentKey;

    // The API didn't have any saved environments either
    if (projectContext.environmentKeys.size === 0) {
      // Pick the first two environments from the project by ascending alphabetical order
      projectContext.environmentKeys = await fetchDefaultEnvironmentKeys(projectContext.projectKey);

      // If we still don't have any, we're in trouble
      if (projectContext.environmentKeys.size === 0) {
        throw new Error('Expected project to have at least two environment');
      }
    }
  }

  if (
    !projectContext.selectedEnvironmentKey ||
    !projectContext.environmentKeys.has(projectContext.selectedEnvironmentKey)
  ) {
    projectContext.selectedEnvironmentKey = Array.from(projectContext.environmentKeys)[0];
  }

  const [project, environments] = await Promise.all([
    getProjectFallback(projectContext.projectKey),
    queryClient.fetchQuery(
      internalEnvironmentList({
        projectKey: projectContext.projectKey,
        query: {
          key: Array.from(projectContext.environmentKeys),
        },
      }),
    ),
  ]);

  let sortedEnvironments: InternalEnvironment[] = [];
  for (const key of projectContext.environmentKeys) {
    const environment = environments.items.find((it) => it.key === key);
    if (environment) {
      sortedEnvironments.push(environment);
    } else {
      projectContext.environmentKeys.delete(key);
    }
  }

  // If no environments were fetched, we need to fetch the default environments
  if (sortedEnvironments.length === 0) {
    const defaultEnvironmentKeys = await fetchDefaultEnvironmentKeys(projectContext.projectKey);
    const defaultEnvironments = await queryClient.fetchQuery(
      internalEnvironmentList({
        projectKey: projectContext.projectKey,
        query: {
          key: Array.from(defaultEnvironmentKeys),
        },
      }),
    );
    sortedEnvironments = defaultEnvironments.items;
    projectContext.environmentKeys = defaultEnvironmentKeys;
    projectContext.selectedEnvironmentKey = sortedEnvironments[0].key;
  }

  return {
    context: projectContext,
    project,
    environments: sortedEnvironments,
  };
}
