import { type TagProps, Tag, TagGroup, TagList } from '@launchpad-ui/components';

type OnOffChipProps = Omit<TagProps, 'variant'> & {
  isOn: boolean;
};
const OnOffChip = ({ isOn, ...props }: OnOffChipProps) => (
  <TagGroup aria-label="status">
    <TagList>
      <Tag data-test-id="on-off-chip" variant={isOn ? 'success' : undefined} isDisabled={!isOn} {...props}>
        {isOn ? 'On' : 'Off'}
      </Tag>
    </TagList>
  </TagGroup>
);

/* eslint-disable import/no-default-export */
export default OnOffChip;
