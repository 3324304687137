import { memo, useEffect, useMemo, useState } from 'react';
import {
  CloseCircleOutlined,
  CloseOutlined,
  EditOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Flex,
  Input,
  InputNumber,
  List,
  message,
  Popover,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';

import { getEventMetadataAPI } from '../../../../app/apis/events';
import { eventMetadataQueryKeys } from '../../../../app/store/queryKeyFactory';
import { useProjectEnvAwareQueries, useProjectEnvAwareQuery } from '../../../../hooks/useProjectEnvAwareQueryHooks';
import fuzzySearch from '../../../../utils/fuzzySearch';
import convertToTitleCase from '../../../../utils/transformToTitleCase';
import { EVENT_NAME_AND_CUSTOM_LABEL_SEPARATOR } from '../../../visualization/constant';
import { EventFilterSelector } from '../EventFiltersSelector';

import EventDetailsQuickViewModal from './EventDetailsQuickViewModal';
import getEventLabels from './getEventLabels';

const { TabPane } = Tabs;

const aggOptionsProperty = [
  {
    key: 'average_fn',
    value: 'average',
    label: 'average',
    titleForTotals: 'Average | Average across all users of the events count per user',
    titleForAgg: 'Average | Average of a numeric property value across all instances of an event',
  },
  {
    key: 'median_fn',
    value: 'median',
    label: 'median',
    titleForTotals: 'Median | Median of the events count per user',
    titleForAgg: 'Median | Median of a numeric property value across all instances of an event',
  },
  {
    key: 'minimum_fn',
    value: 'minimum',
    label: 'minimum',
    titleForTotals: 'Minimum | Minimum of the number of time a user performed the event',
    titleForAgg: 'Minimum | Minimum of a numeric property value across all instances of an event',
  },
  {
    key: 'maximum_fn',
    value: 'maximum',
    label: 'maximum',
    titleForTotals: 'Maximum | Maximum of the number of time a user performed the event',
    titleForAgg: 'Maximum | Maximum of a numeric property value across all instances of an event',
  },
  {
    key: 'percentile_fn',
    value: 'percentile',
    label: 'percentile',
    titleForTotals: 'Percentile | Percentile of the events count per user',
    titleForAgg: 'Percentile | Percentile of a numeric property value across all instances of an event',
  },
];

const ConversionCriteriaPopoverContent = ({ selectedEvents, handleItemClick, dimensions, handleClosePopover }) => {
  const eventMetadataQueries = useProjectEnvAwareQueries({
    queries: selectedEvents.map((e) => ({
      queryKey: eventMetadataQueryKeys.byEventName({ eventName: e.name }),
      queryFn: ({ getFullUrl }) => getEventMetadataAPI({ eventName: e.name, getFullUrl }),
      enabled: !!e?.name,
    })),
  });

  const eventBreakdownProperties = useMemo(() => {
    const tempEventBreakdownProperties = {};

    eventMetadataQueries.forEach((query) => {
      (query?.data?.event_properties || []).forEach((ep) => {
        tempEventBreakdownProperties[ep.name] = ep;
      });
    });

    return Object.values(tempEventBreakdownProperties);
  }, [eventMetadataQueries]);

  const [search, setSearch] = useState('');
  const handleSearch = (e) => setSearch(e.target.value);

  const sortedEventsDimensionData = dimensions?.sort((dimA, dimB) => dimA.name.localeCompare(dimB.name)) || [];

  const sortedEventPropertyData = eventBreakdownProperties
    .map((item) => ({
      ...item,
      dimension_type: 'event',
    }))
    .sort((dimA, dimB) => dimA.name.localeCompare(dimB.name));

  const allPropData = [...sortedEventPropertyData, ...sortedEventsDimensionData].filter((item) =>
    fuzzySearch([item.name], search),
  );
  const userPropFilteredData = allPropData
    .filter((item) => item.dimension_type === 'user')
    .filter((item) => fuzzySearch([item.name], search));
  const eventPropFilteredData = allPropData
    .filter((item) => item.dimension_type === 'event')
    .filter((item) => fuzzySearch([item.name], search));

  return (
    <div style={{ maxHeight: '40vh', width: '30rem', overflow: 'auto' }}>
      <Flex justify="space-between" align="center" gap={12}>
        <Input.Search placeholder="Search.." onChange={handleSearch} />{' '}
        <Button type="text" size="small" onClick={handleClosePopover}>
          <CloseOutlined />
        </Button>
      </Flex>
      <Tabs defaultActiveKey="1">
        <TabPane tab="All" key="1">
          <List
            dataSource={allPropData}
            renderItem={(item) => (
              <List.Item key={item} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
                {item.name}
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="User Attributes" key="2">
          <List
            dataSource={userPropFilteredData}
            renderItem={(item) => (
              <List.Item key={item} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
                {item.name}
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="Event Properties" key="3">
          <List
            dataSource={eventPropFilteredData}
            renderItem={(item) => (
              <List.Item key={item} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
                {item.name}
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

const EventSelectorItem = memo(
  ({
    event,
    showEventOperator,
    eventIndex,
    eventsData,
    updateEvent,
    disableSorting,
    onDelete,
    selectedEvents,
    selectableEvents,
    showLabel,
    showSteps,
    enableConversionCriteria,
    allowLabelRename,
    disabled = false,
    enableFirstTimeFilter = true,
    enableFirstTimeFilterCustomEvent = false,
    renderExtraOptions = null,
    customEventData = [],
    shouldTruncateEventName,
    shouldShowEntities,
  }) => {
    const firstTimeFilter = true;
    const showFirstTimeFilter = firstTimeFilter && !event?.custom_event_uuid;
    const showExperimentalFirstTimeFilter =
      firstTimeFilter && enableFirstTimeFilterCustomEvent && event?.custom_event_uuid;
    const disableFirstTimeFilterDropdown = !showExperimentalFirstTimeFilter && !showFirstTimeFilter;

    const { isLoading: isEventDataLoading, data: eventMetadata } = useProjectEnvAwareQuery({
      queryKey: eventMetadataQueryKeys.byEventName({ eventName: event.name }),
      queryFn: async ({ getFullUrl }) => getEventMetadataAPI({ eventName: event.name, getFullUrl }),
      enabled: !!event?.name,
    });

    const [newCustomEventLabel, setNewCustomEventLabel] = useState(null);
    const { eventLabel, hasCustomLabel, alphabetLabel, customLabel } = getEventLabels(event);
    const displayEventLabel = hasCustomLabel ? eventLabel : alphabetLabel;

    const [selectedCriteria, setSelectedCriteria] = useState(event?.measure || 'uniques');
    const [selectedEventAggregation, setSelectedEventAggregation] = useState(
      event?.measure_config?.aggregation || null,
    );
    const [selectedAggProperty, setSelectedAggProperty] = useState({
      name: event?.measure_config?.aggregation_property || null,
      data_type: null,
    });

    useEffect(() => {
      if (selectedAggProperty) {
        const selectedEventProp = eventMetadata?.event_properties?.find((e) => e.name === selectedAggProperty?.name);
        if (selectedEventProp && selectedAggProperty?.data_type === null) {
          setSelectedAggProperty((current) => ({ current, ...selectedEventProp }));
        }
      }
    }, [eventMetadata, selectedAggProperty]);

    const hasEventAggFunction = selectedCriteria === 'totals_per_user' || !!selectedAggProperty?.name;
    const [showAggPopover, setShowAggPopover] = useState(false);

    const EventMenu = ({ onFilterHandler, onDeleteHandler, onFirstTimeFilterHandler, isFirstTimeFilterApplied }) => {
      const menu = {
        items: [
          {
            label: 'Add Filter',
            key: '1',
            icon: <FilterOutlined />,
            onClick: onFilterHandler,
          },
          {
            label: isFirstTimeFilterApplied ? 'Remove First Time Filter' : 'Add First Time Filter',
            danger: isFirstTimeFilterApplied,
            key: '2',
            icon: <FieldTimeOutlined />,
            onClick: onFirstTimeFilterHandler,
          },
          {
            type: 'divider',
          },
          {
            label: 'Delete',
            danger: true,
            key: '3',
            icon: <CloseCircleOutlined />,
            onClick: onDeleteHandler,
          },
        ],
      };
      return (
        <Tooltip
          title={
            showExperimentalFirstTimeFilter
              ? 'First time filter on custom event is an  EXPERIMENTAL FEATURE. users may experience high load times on charts while using this feature. '
              : showFirstTimeFilter
                ? 'Filter, First Time Filter, and more...'
                : 'First time filter is not supported on custom event '
          }
        >
          <Dropdown disabled={disableFirstTimeFilterDropdown} menu={menu} trigger={['click']}>
            <MoreOutlined />
          </Dropdown>
        </Tooltip>
      );
    };

    const addNewEventFilter = () => {
      updateEvent(
        {
          ...event,
          filters: {
            and: [
              ...(event?.filters?.and ? event.filters.and : []),
              {
                field: undefined,
                operator: undefined,
                value: undefined,
              },
            ],
          },
        },
        eventIndex,
      );
    };

    const [showAddFilterBtn, setShowAddFilterBtn] = useState(false);

    return (
      <div
        className="funnel-event-item-root"
        key={event.name}
        style={{
          display: 'block',
          borderRadius: 5,
          border: '1px solid var(--border)',
          position: 'relative',
          width: '100%',
          background: 'var(--background)',
          padding: showEventOperator ? 0 : '0.4rem 0.4rem 0.3rem',
        }}
      >
        {showLabel && (
          <Tooltip title={!disableSorting && !disabled ? 'Drag to rearrange steps' : undefined} placement="topRight">
            <div
              style={{
                marginLeft: 4,
                cursor: !disableSorting && !disabled ? 'grab' : 'default',
              }}
              className="funnel-event-drag-handle"
            >
              <Tooltip title={`Event Label: ${displayEventLabel}`}>
                {showSteps ? 'Step' : 'Event'}: {displayEventLabel}
              </Tooltip>
            </div>
          </Tooltip>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              width: 'auto',
            }}
          >
            <Space.Compact style={{ width: '100%' }}>
              <Select
                disabled={disabled}
                placeholder="Change event"
                style={{
                  flexGrow: 1,
                  width: '100%',
                }}
                optionFilterProp="label"
                showSearch
                value={event.name}
                onChange={(value) => {
                  const newlySelectedEvent = allowLabelRename
                    ? {
                        name: value,
                        label: `${String.fromCharCode(65 + eventIndex)}: ${value}`,
                        filters: null,
                      }
                    : {
                        name: value,
                        filters: null,
                      };
                  const selectedEventObject = selectableEvents.find((e) => (e?.name ?? e) === value);

                  if (selectedEventObject?.custom_event_uuid) {
                    newlySelectedEvent.custom_event_uuid = selectedEventObject.custom_event_uuid;
                  }

                  updateEvent(newlySelectedEvent, eventIndex);
                }}
                filterOption={(inputValue, option) => {
                  const selectedEventObject = selectableEvents.find((e) => (e?.name ?? e) === option.value);
                  const searchPayload = [selectedEventObject?.name, selectedEventObject?.description ?? ''];
                  if (selectedEventObject.custom_event_uuid) {
                    searchPayload.push(
                      customEventData.find((e) => e?.event_uuid === selectedEventObject.custom_event_uuid)
                        ?.description ?? '',
                    );
                  }
                  return fuzzySearch(searchPayload, inputValue);
                }}
              >
                {selectableEvents?.map((eventItem) => {
                  const eventName = typeof eventItem === 'string' ? eventItem : eventItem.name;
                  return (
                    <Select.Option key={eventName} label={eventName} value={eventName}>
                      <EventDetailsQuickViewModal
                        eventItem={eventItem}
                        eventMetadata={eventMetadata}
                        shouldTruncateEventName={shouldTruncateEventName}
                      />
                    </Select.Option>
                  );
                })}
              </Select>
            </Space.Compact>
          </div>
          {!disabled && (
            <div style={{ display: 'flex', width: 'fit-content' }}>
              {allowLabelRename && (
                <div
                  style={{
                    marginLeft: 8,
                    fontSize: '1.5rem',
                  }}
                >
                  <Tooltip title="Rename label">
                    <Popover
                      placement="bottom"
                      content={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 10,
                          }}
                        >
                          <Input
                            placeholder="Custom event label"
                            defaultValue={hasCustomLabel ? `${customLabel}` : ''}
                            onChange={(e) => {
                              setNewCustomEventLabel(e.target.value);
                            }}
                          />
                          <Button
                            style={{
                              height: 30,
                            }}
                            type="primary"
                            onClick={() => {
                              if (!/^[\w\-\s]+$/.test(newCustomEventLabel)) {
                                message.error('Custom labels can only contain letters, digits, _ or whitespace');
                                return;
                              }
                              if (newCustomEventLabel) {
                                updateEvent(
                                  {
                                    ...event,
                                    label: `${String.fromCharCode(
                                      65 + eventIndex,
                                    )}: ${newCustomEventLabel?.trim()}${EVENT_NAME_AND_CUSTOM_LABEL_SEPARATOR}${
                                      event.name
                                    }`,
                                  },
                                  eventIndex,
                                );
                              }
                            }}
                          >
                            Save label
                          </Button>
                          <Button
                            style={{
                              height: 30,
                            }}
                            onClick={() => {
                              updateEvent(
                                {
                                  ...event,
                                  label: `${String.fromCharCode(65 + eventIndex)}: ${event.name}`,
                                },
                                eventIndex,
                              );
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      }
                      trigger="click"
                      title="Custom event label"
                    >
                      <EditOutlined />
                    </Popover>
                  </Tooltip>
                </div>
              )}
              <div style={{ marginRight: 8, marginLeft: 8, fontSize: '1.5rem' }}>
                <Tooltip title="Add Filter">
                  <FilterOutlined onClick={addNewEventFilter} style={{ cursor: 'pointer' }} />
                </Tooltip>
              </div>
              <div style={{ fontSize: '1.5rem' }}>
                <Tooltip title="Remove event">
                  <CloseCircleOutlined onClick={() => onDelete(eventIndex)} />
                </Tooltip>
              </div>
              {firstTimeFilter && enableFirstTimeFilter && (
                <div
                  style={{
                    marginLeft: 8,
                    fontSize: '1.5rem',
                    cursor: disableFirstTimeFilterDropdown ? 'not-allowed' : 'pointer',
                  }}
                >
                  <EventMenu
                    onFilterHandler={addNewEventFilter}
                    onDeleteHandler={() => onDelete(eventIndex)}
                    onFirstTimeFilterHandler={() =>
                      updateEvent(
                        {
                          ...event,
                          first_time_ever_filter: !(event?.first_time_ever_filter || false),
                        },
                        eventIndex,
                      )
                    }
                    isFirstTimeFilterApplied={event?.first_time_ever_filter || false}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {typeof renderExtraOptions === 'function' && renderExtraOptions(event, eventIndex)}
        {enableConversionCriteria && (
          <div
            style={{
              marginLeft: 5,
              marginTop: 4,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ fontSize: '0.8rem', opacity: 0.8 }}>Calculation</span>
            <Popover
              content={
                <ConversionCriteriaPopoverContent
                  selectedEvents={selectedEvents}
                  handleItemClick={(selected) => {
                    setSelectedAggProperty(selected);
                    setSelectedEventAggregation(null);
                    updateEvent(
                      {
                        ...event,
                        measure_config: {
                          ...event?.measure_config,
                          aggregation: null,
                          aggregation_property: selectedCriteria === 'aggregate_properties' ? selected.name : null,
                          is_nested_event_property:
                            selectedCriteria === 'aggregate_properties' ? selected.dimension_type === 'event' : null,
                          ...(selectedEventAggregation !== 'percentile' ? { percentile: null } : {}),
                        },
                      },
                      eventIndex,
                    );
                    setShowAggPopover(false);
                  }}
                  handleClosePopover={() => {
                    if (showAggPopover) {
                      if (!event?.measure_config?.aggregation_property) {
                        setSelectedCriteria(null);
                      }
                      setSelectedAggProperty({
                        name: event?.measure_config?.aggregation_property,
                        data_type:
                          event?.measure_config?.aggregation_property === 'distinct_count' ? 'string' : 'number',
                      });
                      setSelectedEventAggregation(event?.measure_config?.aggregation);
                    }
                    setShowAggPopover(false);
                  }}
                  dimensions={eventsData?.dimensions?.filter((dim) => dim.dimension_type !== 'unassigned')}
                />
              }
              open={showAggPopover}
              placement="leftTop"
              arrow={false}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  width: '11rem',
                  gap: '2px',
                  marginLeft: 10,
                }}
              >
                <Select
                  disabled={disabled}
                  showSearch
                  size="small"
                  placeholder="Select calculation"
                  value={selectedCriteria}
                  style={{
                    width: '21ch',
                    fontSize: '0.8rem',
                    opacity: 0.8,
                  }}
                  showArrow={false}
                  onChange={(measure) => {
                    setSelectedEventAggregation(null);
                    if (selectedEventAggregation === 'distinct_count' || selectedEventAggregation === 'sum') {
                      setSelectedEventAggregation(null);
                    }
                    if (measure === 'aggregate_properties') {
                      setShowAggPopover(true);
                    } else {
                      setSelectedAggProperty(null);
                      setShowAggPopover(false);
                    }
                    setSelectedCriteria(measure);
                    updateEvent(
                      {
                        ...event,
                        measure,
                        measure_config: hasEventAggFunction
                          ? {
                              ...event?.measure_config,
                              ...(measure !== 'aggregate_properties'
                                ? {
                                    aggregation_property: null,
                                    is_nested_event_property: null,
                                  }
                                : { aggregation: null }),
                              ...(selectedEventAggregation !== 'percentile' ? { percentile: null } : {}),
                            }
                          : null,
                        ...((measure === 'totals_per_user' &&
                          event?.measure_config?.aggregation === 'distinct_count') ||
                        event?.measure_config?.aggregation === 'sum'
                          ? { measure_config: null }
                          : {}),
                      },
                      eventIndex,
                    );
                  }}
                >
                  <Select.Option key="unique" value="uniques" style={{ fontSize: '0.8rem' }}>
                    <Tooltip title="Uniques - Number of users who performed an event">Uniques</Tooltip>
                  </Select.Option>
                  <Select.Option key="total" value="totals" style={{ fontSize: '0.8rem' }}>
                    <Tooltip title="Totals - Total number of events performed">Totals</Tooltip>
                  </Select.Option>
                  <Select.Option key="total_per_user" value="totals_per_user" style={{ fontSize: '0.8rem' }}>
                    <Tooltip title="Frequency Per User - Total number of events performed per user">
                      Frequency per User
                    </Tooltip>
                  </Select.Option>
                  <Select.Option key="aggregate_properties" value="aggregate_properties" style={{ fontSize: '0.8rem' }}>
                    <Tooltip title="Aggregate Properties - Aggregate Properties on events per User">
                      Aggregate Properties
                    </Tooltip>
                  </Select.Option>
                </Select>
                {!!selectedAggProperty?.name && (
                  <Tooltip title={selectedAggProperty?.name}>
                    <Button
                      onClick={() => {
                        setSelectedAggProperty(null);
                        setSelectedEventAggregation(null);
                        if (!showAggPopover) {
                          setShowAggPopover(true);
                        }
                      }}
                      style={{
                        fontSize: '0.7rem',
                        opacity: '0.8',
                        width: 'fit-content',
                        maxWidth: '10rem',
                      }}
                      size="small"
                    >
                      {selectedAggProperty?.name.length > 20
                        ? `${selectedAggProperty?.name.toLowerCase().slice(0, 16)}...`
                        : selectedAggProperty?.name.toLowerCase()}
                    </Button>
                  </Tooltip>
                )}
              </div>
            </Popover>
            {hasEventAggFunction ? (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: selectedCriteria === 'totals_per_user' ? '1.5fr 0.5fr' : '1fr',
                  gap: '1px',
                }}
              >
                <div>
                  <span style={{ fontSize: '0.8rem', opacity: 0.8, paddingLeft: '1rem' }}>by</span>
                  <Select
                    disabled={disabled}
                    defaultOpen={hasEventAggFunction && !selectedEventAggregation}
                    showSearch
                    size="small"
                    placeholder="Select"
                    value={selectedEventAggregation}
                    style={{
                      width: '15ch',
                      fontSize: '0.8rem',
                      opacity: 0.8,
                      marginLeft: 4,
                    }}
                    onChange={(selected) => {
                      setSelectedEventAggregation(selected);
                      updateEvent(
                        {
                          ...event,
                          measure_config: {
                            ...event?.measure_config,
                            aggregation: hasEventAggFunction ? selected : null,
                            ...(selectedEventAggregation !== 'percentile' ? { percentile: null } : {}),
                            ...(selectedCriteria !== 'aggregate_properties'
                              ? {
                                  aggregation_property: null,
                                  is_nested_event_property: null,
                                }
                              : {}),
                          },
                        },
                        eventIndex,
                      );
                    }}
                  >
                    {selectedCriteria === 'aggregate_properties' ? (
                      <>
                        {selectedAggProperty.data_type === 'number' && (
                          <Select.Option key="sum_fn" value="sum" style={{ fontSize: '0.8rem' }}>
                            <Tooltip title="Sum of a numeric property value across all instances of an event">
                              <div>sum</div>
                            </Tooltip>
                          </Select.Option>
                        )}
                        <Select.Option key="distinct_count" value="distinct_count" style={{ fontSize: '0.8rem' }}>
                          <Tooltip title="Distinct Count | Number of distinct property values across all instances of an event">
                            <div>distinct count</div>
                          </Tooltip>
                        </Select.Option>
                      </>
                    ) : null}
                    {aggOptionsProperty.map((prop) => {
                      if (selectedCriteria === 'aggregate_properties' && selectedAggProperty.data_type !== 'number') {
                        return null;
                      }
                      return (
                        <Select.Option key={prop.key} value={prop.value} style={{ fontSize: '0.8rem' }}>
                          <Tooltip
                            title={selectedCriteria === 'aggregate_properties' ? prop.titleForAgg : prop.titleForTotals}
                          >
                            <div>{prop.label}</div>
                          </Tooltip>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                {selectedEventAggregation && selectedEventAggregation === 'percentile' && (
                  <InputNumber
                    disabled={disabled}
                    size="small"
                    placeholder="25"
                    min={1}
                    max={100}
                    style={{
                      width: '5rem',
                      marginLeft: selectedCriteria === 'totals_per_user' ? '0rem' : '2rem',
                      transform: 'scale(0.9)',
                    }}
                    value={event?.measure_config?.percentile}
                    onChange={(selected) => {
                      updateEvent(
                        {
                          ...event,
                          measure_config: {
                            ...event?.measure_config,
                            percentile: selectedEventAggregation === 'percentile' ? selected : null,
                            ...(selectedCriteria !== 'aggregate_properties'
                              ? {
                                  aggregation_property: null,
                                  is_nested_event_property: null,
                                }
                              : {}),
                          },
                        },
                        eventIndex,
                      );
                    }}
                  />
                )}
              </div>
            ) : null}
          </div>
        )}
        {event?.first_time_ever_filter && !disableFirstTimeFilterDropdown && (
          <div
            style={{
              marginLeft: showLabel ? 32 : 8,
              paddingBottom: 8,
              alignItems: 'center',
            }}
          >
            <span style={{ fontSize: '0.8rem', opacity: 0.8 }}>
              <FieldTimeOutlined /> First Time Ever
            </span>
          </div>
        )}
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={() => {
            setShowAddFilterBtn(true);
          }}
          onMouseLeave={() => {
            setShowAddFilterBtn(false);
          }}
          style={{ margin: 4 }}
        >
          {event?.filters?.and?.map((filterObj, index) => (
            <EventFilterSelector
              key={index}
              filterObj={filterObj}
              index={index}
              disabled={disabled}
              updateEvent={updateEvent}
              eventIndex={eventIndex}
              event={event}
              eventsData={eventsData}
              showAddFilterBtn={showAddFilterBtn}
              eventMetadata={eventMetadata}
              addNewEventFilter={addNewEventFilter}
              shouldShowEntities={shouldShowEntities}
              isEventDataLoading={isEventDataLoading}
            />
          ))}
        </div>
        {!!showEventOperator && (
          <Tooltip title="Only or operator is supported on custom event creation. Created custom event will be counted if any of the selected event matches.">
            <Button style={{ width: '100%' }} size="small" disabled type="ghost">
              {convertToTitleCase(showEventOperator)}
            </Button>
          </Tooltip>
        )}
      </div>
    );
  },
);

export default EventSelectorItem;
