import { Member } from '@gonfalon/members';
import { schemas } from '@gonfalon/openapi';
import { checkAccess } from '@gonfalon/permissions';

type AiConfig = Omit<schemas['AIConfig'], 'variations'> & {
  variations: Array<Omit<schemas['AIConfigVariation'], 'model'> & { model: { parameters?: Record<string, unknown> } }>;
};

export type Action =
  | 'createAIConfig'
  | 'deleteAIConfig'
  | 'deleteAIConfigVariation'
  | 'updateAIConfig'
  | 'updateAIConfigVariation';

export function checkAIConfigAccess(member: Member, action: Action, aiConfig?: AiConfig) {
  const access = aiConfig?._access;
  return checkAccess(member, access, action);
}
