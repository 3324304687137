import { type JSX, cloneElement } from 'react';
import { ButtonContext, LinkContext, Provider, SelectContext } from 'react-aria-components';
import cx from 'clsx';
import type { PopoverPlacement } from 'launchpad';
import { Tooltip } from 'launchpad';

const defaultTooltip = 'You are not allowed to perform this action. Contact your administrator for more information.';

export type RestrictProps = {
  name?: string;
  tooltip?: string | JSX.Element;
  tooltipOptions?: {
    placement?: PopoverPlacement;
    rootElementStyle?: object;
    targetClassName?: string;
  };
  isRestricted: boolean;
  willHide?: boolean;
  willDisable?: boolean;
  disableTooltip?: boolean;
  children: JSX.Element | ((disabled?: boolean) => JSX.Element);
};

const Restrict = ({
  isRestricted,
  willHide,
  willDisable,
  tooltip,
  tooltipOptions = {},
  disableTooltip,
  children,
}: RestrictProps) => {
  if (isRestricted) {
    const element =
      typeof children === 'function'
        ? children(willDisable)
        : cloneElement(children, { disabled: willDisable, isDisabled: willDisable });
    const finalTooltip = disableTooltip ? '' : tooltip || defaultTooltip;

    if (willHide) {
      return null;
    }

    const finalTooltipOptions = {
      ...tooltipOptions,
      targetClassName: cx('.Popover-target', tooltipOptions.targetClassName),
    };

    return (
      <Provider
        values={[
          [LinkContext, { isDisabled: willDisable }],
          [ButtonContext, { isDisabled: willDisable }],
          [SelectContext, { isDisabled: willDisable }],
        ]}
      >
        <Tooltip targetTestId="restrict-tooltip" {...finalTooltipOptions} content={finalTooltip}>
          {element}
        </Tooltip>
      </Provider>
    );
  }

  return typeof children === 'function' ? children(undefined) : children;
};

/* eslint-disable import/no-default-export */
export default Restrict;
