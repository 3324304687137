import { enableReleaseAutomation } from '@gonfalon/dogfood-flags';
import { schemas } from '@gonfalon/openapi';

type AuditLogEntryListingRepCollection = schemas['AuditLogEntryListingRepCollection'];

type AuditLogEntryListingRep = schemas['AuditLogEntryListingRep'];

type AuditLogEntryRep = schemas['AuditLogEntryRep'];

type AuthorizedAppDataRep = schemas['AuthorizedAppDataRep'];

type MemberDataRep = schemas['MemberDataRep'];

type SubjectDataRep = schemas['SubjectDataRep'];

type TokenSummaryRep = schemas['TokenSummary'];

type ParentResourceRep = schemas['ParentResourceRep'];

type TargetResourceRep = schemas['TargetResourceRep'];

type Statement = schemas['Statement'];

const shortChangeDescriptions: { [k: string]: string } = {
  createFlag: 'Created the flag',
  updateGlobalArchived: 'Archived or restored the flag',
  deleteFlag: 'Deleted the flag',
  updateName: 'Changed the flag name',
  updateOn: 'Toggled the flag on or off',
  updateDescription: 'Changed the flag description',
  updateIncludeInSnippet: 'Changed client-side SDK availability',
  updateClientSideFlagAvailability: 'Changed the client-side SDK availability',
  updateTemporary: 'Changed whether the flag is marked as temporary',
  updateTags: 'Changed the tags',
  updatePrerequisites: 'Changed the prerequisites',
  updateTargets: 'Changed the individual user targets',
  updateRules: 'Changed the targeting rules',
  updateFallthrough: 'Changed the default variation',
  updateFallthroughWithMeasuredRollout: 'Started a guarded rollout on the default rule',
  updateOffVariation: 'Changed the off variation',
  updateMaintainer: 'Changed the flag maintainer',
  updateAttachedGoals: 'Changed the goals',
  updateFlagVariations: 'Changed the variations',
  updateTrackEvents: 'Changed whether the flag sends detailed events',
  deleteFlagAttachedGoalResults: 'Reset the experiment results',
  updateFlagCustomProperties: 'Changed a custom property',
  createExperiment: 'Created an experiment',
  deleteExperiment: 'Deleted an experiment',
  deleteExperimentResults: 'Reset data for an experiment',
  updateExperimentActive: 'Changed whether an experiment was recording',
  updateExperimentBaseline: 'Changed the experimentation baseline',
  createFlagLink: 'Created a flag link',
  updateFlagLink: 'Updated a flag link',
  deleteFlagLink: 'Deleted a flag link',
  createApprovalRequest: 'Requested approval for changes',
  reviewApprovalRequest: 'Reviewed an approval request',
  applyApprovalRequest: 'Applied approved changes',
  completeFlagFeatureWorkflowCondition: 'A workflow step completed',
  updateFlagFeatureWorkflowCondition: 'A workflow step completed',
  updateScheduledChanges: 'Scheduled changes',
  updateFlagSalt: 'Updated the flag salt',
  updateFlagConfigMigrationSettings: 'Updated the consistency check ratio value',
  default: 'Made a change',
};

const titleVerbActions = ['updateOn', 'updateFlagFeatureWorkflowCondition', 'updateGlobalArchived'];

const actionsThatCanImpactEvaluation: string[] = [
  'createFeature',
  'deleteFeature',
  'updateOn',
  'updateFlagVariations',
  'updateGlobalArchived',
  'updateFallthrough',
  'updateOffVariation',
  'updatePrerequisites',
  'updateRules',
  'updateFlagSalt',
  'updateTargets',
  'applyApprovalRequest',
  'updateFlagConfigMigrationSettings',
];

const ALLOWED_TAGS = [
  'a',
  '#text',
  'p',
  'li',
  'ol',
  'ul',
  'b',
  'strong',
  'i',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'del',
  'code',
  'blockquote',
];

const actionsRelatedToTargetingChanges = [
  ...(enableReleaseAutomation() ? ['updateReleasePhaseStatus'] : []),
  'stopMeasuredRolloutOnFlagFallthrough',
  'stopMeasuredRolloutOnFlagRule',
  'updateExpiringTargets',
  'updateFallthrough',
  'updateFallthroughWithMeasuredRollout',
  'updateFeatureWorkflows',
  'updateMeasuredRolloutConfiguration',
  'updateOffVariation',
  'updateOn',
  'updatePrerequisites',
  'updateRules',
  'updateRulesWithMeasuredRollout',
  'updateScheduledChanges',
  'updateTargets',
];

const actionsRelatedToFlagSettings = [
  ...(enableReleaseAutomation()
    ? ['addReleasePipeline', 'removeReleasePipeline', 'replaceReleasePipeline', 'updateReleasePhaseCompleted']
    : []),
  'createFlag',
  'deleteFlag',
  'updateClientSideFlagAvailability',
  'updateDescription',
  'updateDeprecated',
  'updateFlagConfigMigrationSettings',
  'updateFlagCustomProperties',
  'updateFlagDefaultVariations',
  'updateFlagVariations',
  'updateGlobalArchived',
  'updateIncludeInSnippet',
  'updateName',
  'updateTags',
  'updateTemporary',
];

const actionsRelatedToEnvironmentSettings = [
  'createTriggers',
  'deleteTriggers',
  'updateFlagSalt',
  'updateTrackEvents',
  'updateTriggers',
];

const actionsRelatedToCollaboration = [
  'applyApprovalRequest',
  'bypassRequiredApproval',
  'createApprovalRequest',
  'createFlagLink',
  'deleteApprovalRequest',
  'deleteFlagLink',
  'manageFlagFollowers',
  'reviewApprovalRequest',
  'updateMaintainer',
  'updateFlagLink',
];

const actionsRelatedToOtherFlagChanges = [
  ...(enableReleaseAutomation() ? ['terminateReleaseAudience'] : []),
  'cloneFlag',
  'copyFlagConfigFrom',
  'copyFlagConfigTo',
  'updateFlagRuleDescription',
  'createExperiment',
  'deleteExperiment',
  'deleteExperimentResults',
  'deleteFlagAttachedGoalResults',
  'updateApprovalRequest',
  'updateAttachedGoals',
  'updateExperimentActive',
  'updateExperimentBaseline',
  'updateFlagCodeReferences',
  'updateFlagFallthroughTrackEvents',
];

// flag action groups as detailed in our docs https://docs.launchdarkly.com/home/account/role-actions#feature-flag-actions
const allFlagActions = [
  ...actionsRelatedToTargetingChanges,
  ...actionsRelatedToFlagSettings,
  ...actionsRelatedToEnvironmentSettings,
  ...actionsRelatedToCollaboration,
  ...actionsRelatedToOtherFlagChanges,
];

export {
  shortChangeDescriptions,
  titleVerbActions,
  actionsThatCanImpactEvaluation,
  ALLOWED_TAGS,
  actionsRelatedToTargetingChanges,
  actionsRelatedToFlagSettings,
  actionsRelatedToEnvironmentSettings,
  actionsRelatedToCollaboration,
  actionsRelatedToOtherFlagChanges,
  allFlagActions,
};

export type {
  AuditLogEntryListingRepCollection,
  AuditLogEntryListingRep,
  AuditLogEntryRep,
  AuthorizedAppDataRep,
  MemberDataRep,
  SubjectDataRep,
  TokenSummaryRep,
  ParentResourceRep,
  TargetResourceRep,
  Statement,
};
