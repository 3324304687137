import {
  type CancelFormFields,
  Campaigns,
  trackSubscriptionCanceled,
  trackSubscriptionCancelError,
} from '@gonfalon/billing';
import { toBilling, toHref } from '@gonfalon/navigator';
import { List, OrderedMap } from 'immutable';

import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import {
  invoiceListSelector,
  limitPlansSelector,
  paymentCardSelector,
  plansSelector,
  subscriptionEntitySelector,
  subscriptionFetchRequestSelector,
  subscriptionUsageEntitySelector,
  subscriptionUsageFetchRequestSelector,
} from 'reducers/billing';
// eslint-disable-next-line import/no-namespace
import * as BillingAPI from 'sources/BillingAPI';
import { BulkMemberInvite } from 'utils/accountUtils';
import {
  billingIntervals,
  Invoice,
  LegacyPlan,
  LegacySubscription,
  limitNames,
  LimitPlans,
  PaymentCard,
  PlanList,
  planTypes,
  Subscription,
  SubscriptionChange,
  SubscriptionPreview,
  SubscriptionUsage,
  trackBillingIntervalChange,
  trackLimitAdjustment,
} from 'utils/billingUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { createOverages, saveOveragesToCache } from 'utils/overagesUtils';
import { GenerateActionType } from 'utils/reduxUtils';

import { saveEnterpriseCampaignStartedModalDismissed } from './enterpriseCampaignModals';
import { sendBulkInvite } from './members';

const requestLegacyPlans = () =>
  ({
    type: 'billing/REQUEST_LEGACY_PLANS',
  }) as const;

const requestLegacyPlansDone = (legacyPlans: List<LegacyPlan>) =>
  ({
    type: 'billing/REQUEST_LEGACY_PLANS_DONE',
    legacyPlans,
  }) as const;

const requestLegacyPlansFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_LEGACY_PLANS_FAILED',
    error,
  }) as const;

function fetchLegacyPlans() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestLegacyPlans());
    return BillingAPI.getLegacyPlans()
      .then((legacyPlans) => dispatch(requestLegacyPlansDone(legacyPlans)))
      .catch((error) => dispatch(requestLegacyPlansFailed(error)));
  };
}

const requestPlans = () =>
  ({
    type: 'billing/REQUEST_PLANS',
  }) as const;

const requestPlansDone = (plans: PlanList) =>
  ({
    type: 'billing/REQUEST_PLANS_DONE',
    plans,
  }) as const;

const requestPlansFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_PLANS_FAILED',
    error,
  }) as const;

function fetchPlans() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestPlans());
    return BillingAPI.getPlans()
      .then((plans) => dispatch(requestPlansDone(plans)))
      .catch((error) => dispatch(requestPlansFailed(error)));
  };
}

function shouldFetchLegacyPlans(state: GlobalState) {
  return !state.legacyPlans.get('lastFetched') && !state.legacyPlans.get('isFetching');
}

function shouldFetchPlans(state: GlobalState) {
  return plansSelector(state).request.shouldFetch();
}

function fetchLegacyPlansIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchLegacyPlans(getState())) {
      return dispatch(fetchLegacyPlans());
    }
  };
}

export { fetchLegacyPlansIfNeeded as fetchLegacyPlans };

function fetchPlansIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchPlans(getState())) {
      return dispatch(fetchPlans());
    }
  };
}

export { fetchPlansIfNeeded as fetchPlans };

const requestPaymentCard = () =>
  ({
    type: 'billing/REQUEST_PAYMENT_CARD',
  }) as const;

const requestPaymentIntent = () =>
  ({
    type: 'billing/REQUEST_PAYMENT_INTENT',
  }) as const;

const requestPaymentIntentDone = (secret: string) =>
  ({
    type: 'billing/REQUEST_PAYMENT_INTENT_DONE',
    secret,
  }) as const;

const requestPaymentIntentFailed = (error?: ImmutableServerError | string) =>
  ({
    type: 'billing/REQUEST_PAYMENT_INTENT_FAILED',
    error,
  }) as const;

const requestPaymentCardDone = (card: PaymentCard | null) =>
  ({
    type: 'billing/REQUEST_PAYMENT_CARD_DONE',
    card,
  }) as const;

const requestPaymentCardFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_PAYMENT_CARD_FAILED',
    error,
  }) as const;

const postAddedCardFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/POST_ADDED_CARD_FAILED',
    error,
  }) as const;

const postAddedCardDone = (card: PaymentCard | null) =>
  ({
    type: 'billing/POST_ADDED_CARD_DONE',
    card,
  }) as const;

const postAddedCardAction = () =>
  ({
    type: 'billing/POST_ADDED_CARD',
  }) as const;

export function postAddedCard(paymentIntent: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(postAddedCardAction());
    return BillingAPI.postAddedCard(paymentIntent)
      .then((card) => dispatch(postAddedCardDone(card)))
      .catch((error) => dispatch(postAddedCardFailed(error)));
  };
}

function fetchPaymentCard() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestPaymentCard());
    return BillingAPI.getPaymentCard()
      .then((card) => dispatch(requestPaymentCardDone(card)))
      .catch((error) => dispatch(requestPaymentCardFailed(error)));
  };
}

export function postPaymentIntent() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestPaymentIntent());
    return BillingAPI.postSetupIntent()
      .then((secret) => dispatch(requestPaymentIntentDone(secret)))
      .catch((error) => dispatch(requestPaymentIntentFailed(error)));
  };
}

function shouldFetchPaymentCard(state: GlobalState) {
  return paymentCardSelector(state).fetchRequest.shouldFetch();
}

function fetchPaymentCardIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchPaymentCard(getState())) {
      return dispatch(fetchPaymentCard());
    } else {
      return Promise.resolve(paymentCardSelector(getState()));
    }
  };
}

export { fetchPaymentCardIfNeeded as fetchPaymentCard };

const savePaymentCard = (stripeToken: string) =>
  ({
    type: 'billing/SAVE_PAYMENT_CARD',
    stripeToken,
  }) as const;

const savePaymentCardDone = (card: PaymentCard) =>
  ({
    type: 'billing/SAVE_PAYMENT_CARD_DONE',
    card,
  }) as const;

const savePaymentCardFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/SAVE_PAYMENT_CARD_FAILED',
    error,
  }) as const;

export function setPaymentCard(stripeToken: string) {
  return (dispatch: GlobalDispatch) => {
    dispatch(savePaymentCard(stripeToken));
    BillingAPI.setPaymentCard(stripeToken)
      .then((card) => dispatch(savePaymentCardDone(card)))
      .catch((error) => dispatch(savePaymentCardFailed(error)));
  };
}

const requestSubscription = () =>
  ({
    type: 'billing/REQUEST_SUBSCRIPTION',
  }) as const;

const requestSubscriptionDone = (subscription: Subscription) =>
  ({
    type: 'billing/REQUEST_SUBSCRIPTION_DONE',
    subscription,
  }) as const;

const requestSubscriptionFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_SUBSCRIPTION_FAILED',
    error,
  }) as const;

function fetchSubscription() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestSubscription());
    return new Promise((resolve) => {
      BillingAPI.getSubscription()
        .then((subscription) => {
          dispatch(requestSubscriptionDone(subscription));
          resolve(subscription);
        })
        .catch((error) => dispatch(requestSubscriptionFailed(error)));
    });
  };
}

function shouldFetchSubscriptionUsage(state: GlobalState) {
  return subscriptionUsageFetchRequestSelector(state).shouldFetch();
}

const requestSubscriptionUsage = () =>
  ({
    type: 'billing/REQUEST_SUBSCRIPTION_USAGE',
  }) as const;

const requestSubscriptionUsageDone = (subscriptionUsage: SubscriptionUsage) =>
  ({
    type: 'billing/REQUEST_SUBSCRIPTION_USAGE_DONE',
    subscriptionUsage,
  }) as const;

const requestSubscriptionUsageFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_SUBSCRIPTION_USAGE_FAILED',
    error,
  }) as const;

function fetchSubscriptionUsage() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestSubscriptionUsage());
    return new Promise((resolve) => {
      BillingAPI.getSubscriptionUsage()
        .then((subscriptionUsage) => {
          dispatch(requestSubscriptionUsageDone(subscriptionUsage));
          resolve(subscriptionUsage);
        })
        .catch((error) => dispatch(requestSubscriptionUsageFailed(error)));
    });
  };
}

function fetchSubscriptionUsageIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchSubscriptionUsage(getState())) {
      return dispatch(fetchSubscriptionUsage());
    } else {
      return Promise.resolve(subscriptionUsageEntitySelector(getState()));
    }
  };
}

export { fetchSubscriptionUsageIfNeeded as fetchSubscriptionUsage };

function shouldFetchSubscription(state: GlobalState) {
  return subscriptionFetchRequestSelector(state).shouldFetch();
}

function fetchSubscriptionIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchSubscription(getState())) {
      return dispatch(fetchSubscription());
    } else {
      return Promise.resolve(subscriptionEntitySelector(getState()));
    }
  };
}

export { fetchSubscriptionIfNeeded as fetchSubscription };

const requestLegacySubscription = () => ({ type: 'billing/REQUEST_LEGACY_SUBSCRIPTION' }) as const;

const requestLegacySubscriptionDone = (legacySubscription: LegacySubscription) =>
  ({
    type: 'billing/REQUEST_LEGACY_SUBSCRIPTION_DONE',
    legacySubscription,
  }) as const;

const requestLegacySubscriptionFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_LEGACY_SUBSCRIPTION_FAILED',
    error,
  }) as const;

function fetchLegacySubscription() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestLegacySubscription());
    return BillingAPI.getLegacySubscription()
      .then((legacySubscription) => {
        dispatch(requestLegacySubscriptionDone(legacySubscription));
      })
      .catch((error) => dispatch(requestLegacySubscriptionFailed(error)));
  };
}

function shouldFetchLegacySubscription(state: GlobalState) {
  return !state.legacySubscription.get('lastFetched') && !state.legacySubscription.get('isFetching');
}

function fetchLegacySubscriptionIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchLegacySubscription(getState())) {
      return dispatch(fetchLegacySubscription());
    }
  };
}

export { fetchLegacySubscriptionIfNeeded as fetchLegacySubscription };

const requestLimitPlans = () =>
  ({
    type: 'billing/REQUEST_LIMIT_PLANS',
  }) as const;

const requestLimitPlansDone = (limitPlans: LimitPlans) =>
  ({
    type: 'billing/REQUEST_LIMIT_PLANS_DONE',
    limitPlans,
  }) as const;

const requestLimitPlansFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_LIMIT_PLANS_FAILED',
    error,
  }) as const;

function fetchLimitPlans(planType?: planTypes) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestLimitPlans());
    return BillingAPI.getLimitPlans(planType)
      .then((limitPlans) => dispatch(requestLimitPlansDone(limitPlans)))
      .catch((error) => dispatch(requestLimitPlansFailed(error)));
  };
}

function shouldFetchLimitPlans(state: GlobalState) {
  return limitPlansSelector(state).request.shouldFetch();
}

export const setActivePlan = (planType: planTypes) =>
  ({
    type: 'billing/CHANGE_ACTIVE_PLAN',
    activePlan: planType,
  }) as const;

function fetchLimitPlansIfNeeded(planType?: planTypes, options?: { shouldFetch: boolean }) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchLimitPlans(getState()) || options?.shouldFetch) {
      return dispatch(fetchLimitPlans(planType));
    }
  };
}

export { fetchLimitPlansIfNeeded as fetchLimitPlans };

export function setSelectedLimitForPlan(value: number | null, key: limitNames, shouldNotTrack?: boolean) {
  if (!shouldNotTrack && (value || value === 0)) {
    trackLimitAdjustment(key, value);
  }
  return {
    type: 'billing/CHANGE_PLAN_LIMIT',
    value,
    key,
  } as const;
}

export function setSelectedLimit(value: number | null, key: limitNames) {
  return {
    type: 'billing/SELECT_LIMIT',
    value,
    key,
  } as const;
}

export const resetSelection = () =>
  ({
    type: 'billing/RESET_SELECTION',
  }) as const;

export const resetLimits = () =>
  ({
    type: 'billing/RESET_LIMITS',
  }) as const;

const requestInvoices = () => ({ type: 'billing/REQUEST_INVOICES' }) as const;

const requestInvoicesDone = (invoices: OrderedMap<string, Invoice>) =>
  ({
    type: 'billing/REQUEST_INVOICES_DONE',
    response: invoices,
  }) as const;

const requestInvoicesFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/REQUEST_INVOICES_FAILED',
    error,
  }) as const;

function fetchInvoices() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestInvoices());
    return BillingAPI.getInvoices()
      .then((response) => dispatch(requestInvoicesDone(response)))
      .catch((error) => dispatch(requestInvoicesFailed(error)));
  };
}

function shouldFetchInvoices(state: GlobalState) {
  const invoices = invoiceListSelector(state);
  return !invoices.get('lastFetched') && !invoices.get('isFetching');
}

function fetchInvoicesIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchInvoices(getState())) {
      return dispatch(fetchInvoices());
    }
  };
}

export { fetchInvoicesIfNeeded as fetchInvoices };

export const setBillingPeriod = (billingPeriod: billingIntervals) => {
  trackBillingIntervalChange(billingPeriod);
  return {
    type: 'billing/SET_BILLING_PERIOD',
    billingPeriod,
  } as const;
};

const subscribeStart = (subscription: SubscriptionChange, stripeToken?: string) =>
  ({
    type: 'billing/SUBSCRIBE',
    subscription,
    stripeToken,
  }) as const;

const subscribeDone = (subscription: Subscription) =>
  ({
    type: 'billing/SUBSCRIBE_DONE',
    subscription,
  }) as const;

const subscribeFailed = (subscription: SubscriptionChange, error: ImmutableServerError) =>
  ({
    type: 'billing/SUBSCRIBE_FAILED',
    subscription,
    error,
  }) as const;

export const subscribeAndInviteDone = (
  invites: BulkMemberInvite,
  options: {
    pathOnSuccess?: string;
    onCloseModal?: () => void;
    inviteAndSubscribe?: boolean;
  },
  subscription: Subscription,
) => ({ type: 'billing/SUBSCRIBE_AND_INVITE_DONE', invites, options, subscription }) as const;

const subscribeAndInviteSubscribeDone = (subscription: Subscription) =>
  ({ type: 'billing/SUBSCRIBE_AND_INVITE_SUBSCRIBE_DONE', subscription }) as const;

export function subscribe({
  subscription,
  stripeToken,
  prorationTime,
  promoCode,
  enableSelfServeUBBWithAnnualCommits,
  useSelfServeFoundation2023PricingV1,
  metadata,
}: {
  subscription: SubscriptionChange;
  stripeToken?: string;
  prorationTime: number;
  promoCode?: string;
  enableSelfServeUBBWithAnnualCommits?: boolean;
  useSelfServeFoundation2023PricingV1?: boolean;
  metadata?: { isDeveloperSignup?: boolean };
}) {
  return (dispatch: GlobalDispatch) => {
    dispatch(subscribeStart(subscription, stripeToken));
    BillingAPI.subscribe({
      subscription,
      stripeToken,
      prorationTime,
      promoCode,
      enableSelfServeUBBWithAnnualCommits,
      useSelfServeFoundation2023PricingV1,
      metadata,
    })
      .then((newSubscription) => {
        saveOveragesToCache(createOverages());
        dispatch(subscribeDone(newSubscription));
      })
      .catch((error) => dispatch(subscribeFailed(subscription, error)));
  };
}

const purchaseNewSeatsStart = () => ({ type: 'billing/PURCHASE_NEW_SEATS_START' }) as const;
const purchaseNewSeatsDone = (subscription: Subscription, numberOfNewSeatsPurchased: number) =>
  ({ type: 'billing/PURCHASE_NEW_SEATS_DONE', subscription, numberOfNewSeatsPurchased }) as const;
const purchaseNewSeatsFailed = (
  subscription: SubscriptionChange,
  numberOfNewSeatsPurchased: number,
  error: ImmutableServerError,
) => ({ type: 'billing/PURCHASE_NEW_SEATS_FAILED', subscription, numberOfNewSeatsPurchased, error }) as const;

export function purchaseNewSeats({
  currentSubscription,
  prorationTime,
  numberOfNewSeatsToPurchase,
}: {
  currentSubscription: SubscriptionChange;
  prorationTime: number;
  numberOfNewSeatsToPurchase: number;
}) {
  return async (dispatch: GlobalDispatch) => {
    const currentSeatCountLimit = currentSubscription.getSeatCount() || 0;
    const totalNewSeats = currentSeatCountLimit + numberOfNewSeatsToPurchase;
    const updatedSubscription = currentSubscription.setIn(['limits', limitNames.SEATS], totalNewSeats);

    dispatch(subscribeStart(updatedSubscription));
    dispatch(purchaseNewSeatsStart());

    return BillingAPI.subscribe({ subscription: updatedSubscription, prorationTime })
      .then((newSubscription) => {
        saveOveragesToCache(createOverages());
        dispatch(subscribeDone(newSubscription));
        dispatch(purchaseNewSeatsDone(newSubscription, numberOfNewSeatsToPurchase));
      })
      .catch((error) => {
        dispatch(subscribeFailed(updatedSubscription, error));
        dispatch(purchaseNewSeatsFailed(updatedSubscription, numberOfNewSeatsToPurchase, error));
      });
  };
}

const extendTrialEndDateStart = () =>
  ({
    type: 'billing/EXTEND_TRIAL_END_DATE',
  }) as const;

const extendTrialEndDateDone = (subscription: Subscription) =>
  ({
    type: 'billing/EXTEND_TRIAL_END_DATE_DONE',
    subscription,
  }) as const;

const extendTrialEndDateFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/EXTEND_TRIAL_END_DATE_FAILED',
    error,
  }) as const;

export function extendTrialEndDate() {
  return (dispatch: GlobalDispatch) => {
    dispatch(extendTrialEndDateStart());
    BillingAPI.extendTrialEndDate()
      .then((res) => {
        dispatch(extendTrialEndDateDone(res));
        // reload page after 100 milliseconds
        setTimeout(() => {
          location.reload();
        }, 100);
      })
      .catch((error) => dispatch(extendTrialEndDateFailed(error)));
  };
}

const createSubscriptionCampaignStart = (campaign: Campaigns) =>
  ({
    type: 'billing/CREATE_SUBSCRIPTION_CAMPAIGN',
    campaign,
  }) as const;

const createSubscriptionCampaignDone = (subscription: Subscription, campaign: Campaigns) =>
  ({
    type: 'billing/CREATE_SUBSCRIPTION_CAMPAIGN_DONE',
    subscription,
    campaign,
  }) as const;

const createSubscriptionCampaignFailed = (error: ImmutableServerError, campaign: Campaigns) =>
  ({
    type: 'billing/CREATE_SUBSCRIPTION_CAMPAIGN_FAILED',
    campaign,
    error,
  }) as const;

export function createSubscriptionCampaign(campaign: Campaigns) {
  return (dispatch: GlobalDispatch) => {
    dispatch(createSubscriptionCampaignStart(campaign));
    BillingAPI.createSubscriptionCampaign(campaign)
      .then((res) => {
        // We don't want to show the EnterpriseCampaignStartedModal
        // to the user that initiated the campaign
        dispatch(saveEnterpriseCampaignStartedModalDismissed());

        dispatch(createSubscriptionCampaignDone(res, campaign));

        // give the user a second to see the success toast, then reload and show drawer via query param
        setTimeout(() => {
          window.location.href = toHref(toBilling({ search: { showEnterpriseFeatures: 'true' } }));
        }, 1000);
      })
      .catch((error) => dispatch(createSubscriptionCampaignFailed(error, campaign)));
  };
}

// used for a one-click subscribe and invite from the member invite modal
export function subscribeAndInvite({
  subscription,
  stripeToken,
  prorationTime,
  invites,
  pathOnSuccess,
  onCloseModal,
}: {
  subscription: SubscriptionChange;
  stripeToken?: string;
  prorationTime: number;
  invites: BulkMemberInvite;
  pathOnSuccess: string | undefined;
  onCloseModal?(): void;
}) {
  return (dispatch: GlobalDispatch) => {
    dispatch(subscribeStart(subscription, stripeToken));
    BillingAPI.subscribe({ subscription, stripeToken, prorationTime })
      .then((newSubscription) => {
        saveOveragesToCache(createOverages());
        dispatch(subscribeAndInviteSubscribeDone(newSubscription));
        // once the subscription has been updated and we have the required seats, it's safe to dispatch the bulk invite action
        dispatch(sendBulkInvite(invites, { pathOnSuccess, onCloseModal, inviteAndSubscribe: true }, newSubscription));
      })
      .catch((error) => dispatch(subscribeFailed(subscription, error)));
  };
}

const deleteSubscriptionStart = () =>
  ({
    type: 'billing/DELETE_SUBSCRIPTION',
  }) as const;

const deleteSubscriptionDone = () =>
  ({
    type: 'billing/DELETE_SUBSCRIPTION_DONE',
  }) as const;

const deleteSubscriptionFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/DELETE_SUBSCRIPTION_FAILED',
    error,
  }) as const;

export function deleteSubscription(
  // TODO: yus: Remove default because reasons are mandatory once enableCancelSubscription is permanent.
  reasons: CancelFormFields = { mainCancelReason: undefined, customCancelReason: undefined },
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(deleteSubscriptionStart());
    return BillingAPI.deleteSubscription()
      .then(async () => {
        const subscriptionPromise = BillingAPI.getSubscription();
        const legacyPromise = BillingAPI.getLegacySubscription();

        return Promise.all([subscriptionPromise, legacyPromise]).then(([sub, legacy]) => {
          dispatch(requestSubscriptionDone(sub));
          dispatch(requestLegacySubscriptionDone(legacy));
          dispatch(deleteSubscriptionDone());

          trackSubscriptionCanceled(reasons);
        });
      })
      .catch((error) => {
        dispatch(deleteSubscriptionFailed(error));
        trackSubscriptionCancelError(error, reasons);
      });
  };
}

export const fetchSubscriptionPrice = (subscription: SubscriptionChange, promoCode?: string) =>
  ({ type: 'billing/PREVIEW_SUBSCRIPTION_PRICE', subscription, promoCode }) as const;

export const receiveSubscriptionPrice = (response: SubscriptionPreview, promoCode?: string) =>
  ({
    type: 'billing/PREVIEW_SUBSCRIPTION_PRICE_DONE',
    response,
    promoCode,
  }) as const;

export const fetchSubscriptionPriceFailed = (error: ImmutableServerError) =>
  ({
    type: 'billing/PREVIEW_SUBSCRIPTION_PRICE_FAILED',
    error,
  }) as const;

const BillingActionCreators = {
  requestLegacyPlans,
  requestLegacyPlansDone,
  requestLegacyPlansFailed,
  requestPlans,
  requestPlansDone,
  requestPlansFailed,
  requestPaymentCard,
  requestPaymentCardDone,
  requestPaymentCardFailed,
  savePaymentCard,
  savePaymentCardDone,
  savePaymentCardFailed,
  requestSubscription,
  requestSubscriptionDone,
  requestSubscriptionFailed,
  requestSubscriptionUsage,
  requestSubscriptionUsageDone,
  requestSubscriptionUsageFailed,
  requestLegacySubscription,
  requestLegacySubscriptionDone,
  requestLegacySubscriptionFailed,
  requestLimitPlans,
  requestLimitPlansDone,
  requestLimitPlansFailed,
  setActivePlan,
  setSelectedLimitForPlan,
  requestInvoices,
  requestInvoicesDone,
  requestInvoicesFailed,
  setBillingPeriod,
  subscribeStart,
  subscribeDone,
  subscribeAndInviteDone,
  subscribeFailed,
  deleteSubscriptionStart,
  deleteSubscriptionDone,
  deleteSubscriptionFailed,
  fetchSubscriptionPrice,
  receiveSubscriptionPrice,
  fetchSubscriptionPriceFailed,
  resetSelection,
  resetLimits,
  requestPaymentIntent,
  requestPaymentIntentDone,
  requestPaymentIntentFailed,
  postAddedCardDone,
  postAddedCardAction,
  extendTrialEndDateStart,
  extendTrialEndDateDone,
  extendTrialEndDateFailed,
  createSubscriptionCampaignAction: createSubscriptionCampaignStart,
  createSubscriptionCampaignActionDone: createSubscriptionCampaignDone,
  createSubscriptionCampaignActionFailed: createSubscriptionCampaignFailed,
  setSelectedLimit,
  purchaseNewSeatsStart,
  purchaseNewSeatsDone,
  purchaseNewSeatsFailed,
};

export type BillingAction = GenerateActionType<typeof BillingActionCreators>;
