import { type UIFlagFilter } from './types';

export const flagEnvironmentFilters = [
  // We ignore 'filterEnv' here because it's managed else where and we don't
  // want to count it as an active filter.
  // 'filterEnv',
  'evaluated',
  'excludeSettings',
  'followerId',
  'hasDataExport',
  'hasExperiment',
  'segmentTargeted',
  'status',
  'targeting',
  'guardedRollout',
] as const;

export type UIFlagEnvironmentFilter = Pick<UIFlagFilter, (typeof flagEnvironmentFilters)[number]>;
