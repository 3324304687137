import { ReactNode } from 'react';
import { Clause as OpenApiClause, getAttributeFromClause, isSegmentOp, translateToText } from '@gonfalon/clauses';
import { ErrorBoundary } from '@gonfalon/error-boundaries';
import cx from 'clsx';
import { List } from 'immutable';

import AttributeReference from 'components/Contexts/utils/AttributeUtils';
import { useSegments } from 'hooks/useSegments';
import { Clause as ImmutableClause } from 'utils/clauseUtils';
import { Segment } from 'utils/segmentUtils';
import { mobileAttributesMapping } from 'utils/userAttributeUtils';

import { RuleDescriptionKind } from './RuleInstructionDescription';

const ClauseInstruction = ({
  className,
  extraText,
  children,
}: {
  extraText?: string;
  className?: string;
  children: ReactNode;
}) => (
  <span>
    <span className={cx('ClauseInstructionDescription', className)}>{children}</span> <span>{extraText}</span>
  </span>
);

type Clause = ImmutableClause | OpenApiClause;

const isImmutableClause = (clause: Clause): clause is ImmutableClause => 'translateToText' in clause;

const getSegmentOp = (clause: Clause) => (isImmutableClause(clause) ? clause.hasSegmentOp() : isSegmentOp(clause.op));

const translateClauseToText = (clause: Clause, segments: List<Segment>) => {
  if (isImmutableClause(clause)) {
    return clause.translateToText(segments);
  }
  const attribute = new AttributeReference(getAttributeFromClause(clause)).toString();

  return translateToText(clause, attribute, mobileAttributesMapping, segments.toJS());
};

const SegmentClauseInstructionDescription = ({
  projKey,
  envKey,
  clause,
  extraText,
  className,
}: {
  projKey: string;
  envKey: string;
  clause: Clause | OpenApiClause;
  extraText?: string;
  className?: string;
}) => {
  const { segments } = useSegments({ projKey, envKey, enabled: getSegmentOp(clause) });

  const clauseText = translateClauseToText(clause, segments);

  return (
    <ClauseInstruction extraText={extraText} className={className}>
      {clauseText}
    </ClauseInstruction>
  );
};

export const ClauseInstructionDescription = ({
  projKey,
  envKey,
  clause,
  extraText,
  className,
  kind,
}: {
  projKey: string;
  envKey: string;
  clause: Clause;
  extraText?: string;
  className?: string;
  kind?: RuleDescriptionKind;
}) => {
  const extraClasses = cx({
    'ClauseInstructionDescription--reorderedRule': kind !== undefined && kind === RuleDescriptionKind.REORDERED_RULE,
  });

  const clauseText = translateClauseToText(clause, List());

  // special-case segment clauses because fetching and processing the list of segments is expensive and we don't want to
  // do it unless we really need to
  if (getSegmentOp(clause)) {
    return (
      <ErrorBoundary severity="medium" ignoredStatuses={[404]} fallbackRender={() => <>{clauseText}</>}>
        <SegmentClauseInstructionDescription
          className={cx(className, extraClasses)}
          clause={clause}
          extraText={extraText}
          projKey={projKey}
          envKey={envKey}
        />
      </ErrorBoundary>
    );
  }

  return (
    <ClauseInstruction className={extraClasses} extraText={extraText}>
      {clauseText}
    </ClauseInstruction>
  );
};
