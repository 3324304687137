import { useProjectContext } from '@gonfalon/context';
import { QueryKey, useQueries, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

type GetFullUrl = (serviceURL: string, path: string) => string;

/**
 * @deprecated use utils from gonfalon query client instead
 * This function is used to inject the project and environment to the query key
 * It checks if the queryKey already includes the projectKey and environmentKey
 * If it does, it returns the queryKey as is
 * Otherwise, it returns a new queryKey with the projectKey and environmentKey prepended
 * This should be used for porting queries from the old product analytics to the ld project + env context.
 */
export function getQueryKeyWithProjectEnv(queryKey: QueryKey, projectKey: string, environmentKey: string) {
  if (queryKey.includes(projectKey) && queryKey.includes(environmentKey)) {
    return queryKey;
  }
  return [projectKey, environmentKey, ...queryKey];
}

/**
 * @deprecated use utils from gonfalon query client instead
 * This function is used to get the full URL that includes the project and environment
 * It builds the URL based on the serviceURL, projectKey, environmentKey and path
 * It should be used for porting fetch functions from the old product analytics to the ld project + env context.
 */
export function getProjectEnvAwareURL({
  serviceURL,
  projectKey,
  environmentKey,
  path,
}: {
  serviceURL: string;
  projectKey: string;
  environmentKey: string;
  path: string;
}) {
  const formattedServiceURL = serviceURL.endsWith('/') ? serviceURL.slice(0, -1) : serviceURL;
  const formattedPath = path.startsWith('/') ? path.slice(1) : path;
  return `${formattedServiceURL}/projects/${projectKey}/environments/${environmentKey}/${formattedPath}`;
}

function getFullUrlConstructor({
  projectKey,
  environmentKey,
}: {
  projectKey: string;
  environmentKey: string;
}): GetFullUrl {
  return function getFullUrl(serviceURL: string, path: string) {
    return getProjectEnvAwareURL({ serviceURL, projectKey, environmentKey, path });
  };
}

type FetchFunction<TData, TVariables> = (params: TVariables & { getFullUrl: GetFullUrl }) => Promise<TData>;

/**
 * @deprecated use useQuery & utils from gonfalon query client instead
 * This hook is a wrapper around the useQuery hook
 * It injects the project and environment aware URL into the query function
 * QueryFun can use the getFullUrl function to get the full URL that includes the project and environment
 * It also injects the project and environment to the query key
 */
export function useProjectEnvAwareQuery<TData, TError = unknown>({
  queryKey,
  queryFn,
  ...options
}: {
  queryKey: QueryKey;
  queryFn: FetchFunction<TData, {}>;
} & Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>): UseQueryResult<TData, TError> {
  const { selectedEnvironment, project } = useProjectContext();
  const projectKey = project.key;
  const environmentKey = selectedEnvironment.key;
  const getFullUrl = getFullUrlConstructor({ projectKey, environmentKey });

  return useQuery<TData, TError>({
    queryKey: [projectKey, environmentKey, ...queryKey],
    queryFn: () => queryFn({ getFullUrl }),
    ...options,
  });
}

/**
 * @deprecated use useQuery & utils from gonfalon query client instead
 * This hook is a wrapper around the useQueries hook
 * It injects the project and environment aware URL into the query function
 * QueryFun can use the getFullUrl function to get the full URL that includes the project and environment
 * It also injects the project and environment to the query key
 */
export function useProjectEnvAwareQueries<TData, TError = unknown>({
  queries,
}: {
  queries: Array<
    {
      queryKey: QueryKey;
      queryFn: FetchFunction<TData, {}>;
    } & Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
  >;
}) {
  const { selectedEnvironment, project } = useProjectContext();
  const projectKey = project.key;
  const environmentKey = selectedEnvironment.key;
  const getFullUrl = getFullUrlConstructor({ projectKey, environmentKey });

  return useQueries({
    queries: queries.map((query) => ({
      ...query,
      queryKey: getQueryKeyWithProjectEnv(query.queryKey, projectKey, environmentKey),
      queryFn: () => query.queryFn({ getFullUrl }),
    })),
  });
}
