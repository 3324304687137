import { BlockVizRepresentation } from '../../types/types';

export const EVENT_NAME_AND_CUSTOM_LABEL_SEPARATOR = '////';

export const breakdownConfig: Record<
  BlockVizRepresentation,
  {
    max_breakdown: 0 | 1 | typeof Infinity;
    allow_multiple_dimensions_at_once: boolean;
    allow_multiple_cohorts: boolean;
  }
> = {
  insights: {
    max_breakdown: 1,
    allow_multiple_dimensions_at_once: false,
    allow_multiple_cohorts: true,
  },
  retention_viz: {
    max_breakdown: 1,
    allow_multiple_dimensions_at_once: false,
    allow_multiple_cohorts: false,
  },
  funnels: {
    max_breakdown: Infinity,
    allow_multiple_dimensions_at_once: false,
    allow_multiple_cohorts: true,
  },
  stickiness_viz: {
    max_breakdown: 1,
    allow_multiple_dimensions_at_once: true,
    allow_multiple_cohorts: false,
  },
  user_flow: {
    max_breakdown: 0,
    allow_multiple_dimensions_at_once: false,
    allow_multiple_cohorts: false,
  },
};
