import { type JSX } from 'react';

import { ReleaseStrategy } from 'components/LegacyReleaseStrategyModal';

import { ScheduledFlagChangesOptions } from './scheduledChangesUtils';

export enum SaveOptions {
  SAVE = 'Save changes',
  REVIEW_AND_SAVE = 'Review and save',
  SCHEDULE = 'Schedule changes',
  UPDATE_SCHEDULE = 'Update scheduled change',
  REQUEST_APPROVAL = 'Request approval',
  REVIEW_AND_REQUEST_APPROVAL = 'Review and request approval',
  REQUEST_APPROVAL_AND_SCHEDULE = 'Request approval to schedule changes',
  DELETE = 'Delete',
  SAVE_CHANGES_BYPASSING_APPROVAL = 'Save without approval',
  COPY_FLAG_BYPASSING_APPROVAL = 'Copy without approval',
  MONITOR_AND_SAVE = 'Monitor and save',
  MONITOR_AND_REQUEST_APPROVAL = 'Monitor and request approval',
}

export enum SaveOptionsForTracking {
  SAVE = 'save-changes',
  SCHEDULE = 'scheduled-changes',
  UPDATE_SCHEDULE = 'updated-scheduled-changes',
  REQUEST_APPROVAL = 'approval-request',
  DELETE_PENDING_CHANGE = 'delete-pending-change',
  REQUEST_APPROVAL_AND_SCHEDULE = 'approval-request-for-scheduled-change',
  EXPIRING_USER_TARGETS = 'expiring-user-targets',
  EXPIRING_TARGETS = 'expiring-targets',
  SAVE_CHANGES_BYPASSING_APPROVAL = 'save-changes-bypassing-approval',
  COPY_FLAG_BYPASSING_APPROVAL = 'copy-flag-bypassing-approval',
  MONITOR_AND_SAVE = 'monitor-and-save',
  MONITOR_AND_REQUEST_APPROVAL = 'monitor-and-request-approval',
}

export const saveOptionForTrackingFromSaveOptions = {
  [SaveOptions.SAVE]: SaveOptionsForTracking.SAVE,
  [SaveOptions.REVIEW_AND_SAVE]: SaveOptionsForTracking.SAVE,
  [SaveOptions.SCHEDULE]: SaveOptionsForTracking.SCHEDULE,
  [SaveOptions.UPDATE_SCHEDULE]: SaveOptionsForTracking.UPDATE_SCHEDULE,
  [SaveOptions.REQUEST_APPROVAL]: SaveOptionsForTracking.REQUEST_APPROVAL,
  [SaveOptions.REVIEW_AND_REQUEST_APPROVAL]: SaveOptionsForTracking.REQUEST_APPROVAL,
  [SaveOptions.REQUEST_APPROVAL_AND_SCHEDULE]: SaveOptionsForTracking.REQUEST_APPROVAL_AND_SCHEDULE,
  [SaveOptions.DELETE]: SaveOptionsForTracking.DELETE_PENDING_CHANGE,
  [SaveOptions.SAVE_CHANGES_BYPASSING_APPROVAL]: SaveOptionsForTracking.SAVE_CHANGES_BYPASSING_APPROVAL,
  [SaveOptions.COPY_FLAG_BYPASSING_APPROVAL]: SaveOptionsForTracking.COPY_FLAG_BYPASSING_APPROVAL,
  [SaveOptions.MONITOR_AND_SAVE]: SaveOptionsForTracking.MONITOR_AND_SAVE,
  [SaveOptions.MONITOR_AND_REQUEST_APPROVAL]: SaveOptionsForTracking.MONITOR_AND_REQUEST_APPROVAL,
};

export enum ResourceKind {
  FLAG = 'Flag',
  AI_CONFIG = 'AI Config',
  SEGMENT = 'Segment',
  USER = 'User',
  ROLE = 'Role',
  CONTEXT = 'Context',
}

export const getTrackingSaveOption = (saveOption: SaveOptions) => {
  let workflowKind: SaveOptionsForTracking;
  switch (saveOption) {
    case SaveOptions.SCHEDULE:
      workflowKind = SaveOptionsForTracking.SCHEDULE;
    // eslint-disable-next-line no-fallthrough
    case SaveOptions.REQUEST_APPROVAL:
      workflowKind = SaveOptionsForTracking.REQUEST_APPROVAL;
    // eslint-disable-next-line no-fallthrough
    default:
      workflowKind = SaveOptionsForTracking.SAVE;
  }
  return workflowKind;
};

export const getSaveOption = ({
  isScheduled,
  isApproval,
  originalSaveOption,
}: {
  isScheduled: boolean;
  isApproval: boolean;
  originalSaveOption?: SaveOptions;
}) => {
  if (isScheduled && isApproval) {
    return SaveOptions.REQUEST_APPROVAL_AND_SCHEDULE;
  } else if (isScheduled) {
    return originalSaveOption === SaveOptions.UPDATE_SCHEDULE ? SaveOptions.UPDATE_SCHEDULE : SaveOptions.SCHEDULE;
  } else if (isApproval) {
    return SaveOptions.REQUEST_APPROVAL;
  } else {
    return SaveOptions.SAVE;
  }
};

export type SaveConfirmationRenderFunction = (props: {
  savePreference: SaveOptions;
  onBack?(): void;
  onClose(): void;
}) => JSX.Element | undefined;

// this typing of the flag update "options" is only partial and won't be complete until 'actions/flags.js' is converted to TypeScript
export type OnSaveCallback = (
  comment: string,
  options?: ScheduledFlagChangesOptions,
  releaseStrategy?: ReleaseStrategy,
) => void | Promise<void>;
