// transformed by jscodeshift

import { Snippet } from './Snippet';

type JsonSnippetProps = {
  object: object;
  withCopyButton?: boolean;
  useCopyButtonIcon?: boolean;
  className?: string;
};

export function JsonSnippet(props: JsonSnippetProps) {
  const { object, ...other } = props;
  return (
    <Snippet {...other} lang="json">
      {JSON.stringify(object, null, 2)}
    </Snippet>
  );
}
