const INFINITY = 'Infinity';

export function formatRange(
  lower: number | undefined,
  upper: number | undefined,
  formatValue: (num: number) => string,
) {
  if ((lower === undefined || lower === 0) && (upper === undefined || upper === 0)) {
    return '-';
  }
  const lowerStr = formatResult(lower, formatValue);
  const upperStr = formatResult(upper, formatValue);
  return `${lowerStr.includes(INFINITY) ? '(' : '['}${lowerStr}, ${upperStr}${upperStr.includes(INFINITY) ? ')' : ']'}`;
}

function formatResult(num: number | undefined, formatValue: (num: number) => string) {
  if (num === undefined) {
    return '-';
  }
  return maybeInfinity(num) ?? formatValue(num);
}

export function maybeInfinity(num: number) {
  if (num > Number.MAX_VALUE * 0.9) {
    return INFINITY;
  }
  if (num < -Number.MAX_VALUE * 0.9) {
    return `-${INFINITY}`;
  }
}
