import type { ManipulateType } from 'dayjs';
import dayjs, { Dayjs } from 'dayjs';

import { DatePeriodUnitsForDateFilter, DateWindow, HousewareDateFilter } from '../../../types/types';
import { isDateFormatValid } from '../../../utils/convertDateFormat';
import { VIZ_DATE_FILTER_FORMAT } from '../constants';
import { HousewareStaticDateFilter } from '../types';

export function getMaxValueAllowedForTimeGranularity(unit: DatePeriodUnitsForDateFilter): number {
  switch (unit) {
    case 'day':
      return 90;
    case 'week':
      return 12;
    case 'month':
      return 12;
    case 'quarter':
      return 4;
    case 'year':
      return 3;
    default:
      return Infinity;
  }
}

export function getFormattedDateWithTimeGranularity({
  vizTypeAndTimeGranularity,
  dateFilter,
}: {
  vizTypeAndTimeGranularity: {
    vizType: 'stickiness_viz' | 'user_flow';
    timeGranularity: 'week' | 'month';
  };
  dateFilter?: [string, string];
}) {
  if (!dateFilter?.[0] || !dateFilter?.[1]) {
    return { view: 'between' as const };
  }

  const { vizType, timeGranularity } = vizTypeAndTimeGranularity;
  let startDate = dayjs(dateFilter[0]);
  let endDate = dayjs(dateFilter[1]);

  if (vizType === 'stickiness_viz') {
    const granularity = timeGranularity === 'week' ? 'isoWeek' : timeGranularity;
    startDate = startDate.startOf(granularity);
    endDate = endDate.endOf(granularity);
  } else if (vizType === 'user_flow') {
    const differenceInDays = endDate.diff(startDate, 'day');
    if (differenceInDays > 14) {
      endDate = startDate.add(14, 'day');
    }
  }

  return {
    view: 'between' as const,
    from: { date: startDate.format(VIZ_DATE_FILTER_FORMAT) },
    to: { date: endDate.format(VIZ_DATE_FILTER_FORMAT) },
  };
}

export function formatBetweenDateFilter<T extends boolean = false>({
  dateFilter,
  vizTypeAndTimeGranularity,
  shouldReturnObj = false as T,
}: {
  dateFilter?: HousewareDateFilter;
  vizTypeAndTimeGranularity?: {
    vizType: 'stickiness_viz' | 'user_flow';
    timeGranularity: 'week' | 'month';
  };
  shouldReturnObj?: T;
}): T extends true ? HousewareDateFilter | undefined : [Dayjs, Dayjs] | undefined {
  const formatReturn = (fromDate: Dayjs, toDate: Dayjs) => {
    if (shouldReturnObj) {
      return {
        from: { date: fromDate.format(VIZ_DATE_FILTER_FORMAT) },
        to: { date: toDate.format(VIZ_DATE_FILTER_FORMAT) },
        view: 'between' as const,
      };
    }
    return [fromDate, toDate] as [Dayjs, Dayjs];
  };

  if (vizTypeAndTimeGranularity?.vizType === 'stickiness_viz') {
    const granularity = vizTypeAndTimeGranularity.timeGranularity === 'week' ? 'isoWeek' : 'month';
    if (dateFilter?.from?.date && dateFilter?.to?.date) {
      return formatReturn(
        dayjs(dateFilter.from.date).startOf(granularity),
        dayjs(dateFilter.to.date).endOf(granularity),
      ) as any;
    }
  }

  return dateFilter?.from?.date && dateFilter?.to?.date
    ? (formatReturn(dayjs(dateFilter.from.date), dayjs(dateFilter.to.date)) as any)
    : undefined;
}

export function getPossiblePeriodTypesFromMaxAllowedDays(maxDays: number): DatePeriodUnitsForDateFilter[] {
  const periodTypes: DatePeriodUnitsForDateFilter[] = ['day'];

  if (maxDays >= 7) {
    periodTypes.push('week');
  }
  if (maxDays >= 30) {
    periodTypes.push('month');
  }
  if (maxDays >= 90) {
    periodTypes.push('quarter');
  }
  if (maxDays >= 365) {
    periodTypes.push('year');
  }

  return periodTypes;
}

export function getAbsoluteStartEndDateFromDateFilter(
  dateFilter: HousewareDateFilter,
  endOfDay = false,
): HousewareStaticDateFilter {
  const format = 'YYYY-MM-DD';
  let startDate = dayjs();
  let endDate = dayjs();

  switch (dateFilter.view) {
    case 'presets':
      if (dateFilter.from?.window) {
        const { unit, value } = dateFilter.from.window;
        startDate = dayjs()
          .subtract(value, unit as ManipulateType)
          .startOf(unit as ManipulateType);
        const endDateCalculated = dayjs()
          .subtract(value, unit as ManipulateType)
          .endOf(unit as ManipulateType);
        endDate = endDateCalculated.isAfter(endDate) ? endDate : endDateCalculated;
      }
      break;
    case 'last':
      if (dateFilter.from?.window) {
        const { unit, value } = dateFilter.from.window;
        startDate = dayjs().subtract(value, unit as ManipulateType);
        endDate = dayjs();
      }
      break;

    case 'between':
      if (dateFilter.from?.date) {
        startDate = dayjs(dateFilter.from.date);
      }
      if (dateFilter.to?.date) {
        endDate = dayjs(dateFilter.to.date);
      }
      break;
    case 'since':
      if (dateFilter.from?.date) {
        startDate = dayjs(dateFilter.from.date);
        endDate = dayjs();
      }
      break;
    case 'on':
      if (dateFilter.from?.date) {
        startDate = dayjs(dateFilter.from.date);
        endDate = dayjs(dateFilter.from.date);
      }
      break;
    default:
      if (dateFilter.from?.date && dateFilter.to?.date) {
        startDate = dayjs(dateFilter.from.date);
        endDate = dayjs(dateFilter.to.date);
      }
      break;
  }

  return {
    startDate: startDate.format(format),
    endDate: endOfDay ? endDate.endOf('day').format(format) : endDate.format(format),
    actualEndDate: endDate.format(format),
  };
}

const isDateFilterWindowValid = (window?: DateWindow | null): boolean => {
  if (!window) {
    return false;
  }
  return typeof window.value === 'number' && Boolean(window.unit);
};

export const isDateFilterValid = (dateFilter?: HousewareDateFilter): boolean => {
  if (!dateFilter) {
    return false;
  }

  const { from, to } = dateFilter;

  // Case 1: Validate dates if they exist
  if (from?.date || to?.date) {
    const isFromDateValid = !from?.date || Boolean(isDateFormatValid({ dateString: from.date }));
    const isToDateValid = !to?.date || Boolean(isDateFormatValid({ dateString: to.date }));
    return isFromDateValid && isToDateValid;
  }

  // Case 2: Validate date filter windows if they exist
  if (from?.window || to?.window) {
    const isFromWindowValid = !from?.window || isDateFilterWindowValid(from.window);
    const isToWindowValid = !to?.window || isDateFilterWindowValid(to.window);
    return isFromWindowValid && isToWindowValid;
  }

  return false;
};
