import { lazy, useState } from 'react';
import type { LegacyIconProps } from '@gonfalon/icons';
import { LegacyIcon } from '@gonfalon/icons';
import { NewTabLink } from '@gonfalon/launchpad-experimental';
import { useThemeValue } from '@gonfalon/theme';
import { Button } from '@launchpad-ui/components';
import { Modal, ModalBody, ModalHeader } from '@launchpad-ui/core';

import { trackIntegrationEvent } from './analytics';
import ConfluenceSVG from './confluence.svg';
import { parseConfluenceLink } from './utils';

import styles from './styles.module.css';

const ViewPage = lazy(async () =>
  import(
    /* webpackChunkName: "AtlaskitEmbeddedConfluence", webpackPrefetch: true */ '@atlaskit/embedded-confluence'
  ).then((module) => ({
    default: module.ViewPage,
  })),
);

export const ConfluenceLogo = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="confluence" className={className} size={size} {...props}>
    <ConfluenceSVG />
  </LegacyIcon>
);

type EmbeddedConfluenceProps = {
  url: string;
  buttonLabel?: string;
  onOpen?: () => void;
  onClose?: () => void;
};

export function EmbeddedConfluence({ url, buttonLabel, onOpen, onClose }: EmbeddedConfluenceProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const themeValue = useThemeValue();
  const { hostname, spaceKey, contentID, pageTitle } = parseConfluenceLink(url);

  const title = buttonLabel || pageTitle;
  return (
    <>
      <Button
        onPress={() => {
          trackIntegrationEvent('Embedded Confluence Button Clicked', { url, buttonLabel });
          setModalVisible(true);
          onOpen?.();
        }}
        aria-label="view Confluence page"
        isDisabled={modalVisible}
        size="small"
        className={styles.confluenceButton}
      >
        <ConfluenceLogo size="small" className={styles.confluenceIcon} />
        {title}
      </Button>
      {modalVisible && (
        <Modal
          size="large"
          onCancel={() => {
            setModalVisible(false);
            onClose?.();
          }}
        >
          <ModalHeader title={title} description={<NewTabLink href={url} text="Open in Confluence" />} />
          <ModalBody className={styles.confluenceModalBody}>
            <ViewPage
              hostname={hostname}
              contentId={contentID}
              locale="en-US"
              parentProduct="launchdarkly"
              spaceKey={spaceKey}
              allowedFeatures="all"
              themeState={{ colorMode: themeValue === 'dark' ? 'dark' : 'light' }}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
