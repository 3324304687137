import { getProjectEnvAwareURL } from '../../hooks/useProjectEnvAwareQueryHooks';
import config from '../config';
import $fetch from '../services/$fetch';

export async function getCohortsAPI({ getFullUrl }) {
  const res = await $fetch(getFullUrl(config.dataServiceURL, `/product-analytics/cohorts`));
  if (res.ok) {
    const results = await res.json();
    results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function getCohortAPI({ cohortId, getFullUrl }) {
  const res = await $fetch(getFullUrl(config.dataServiceURL, `/product-analytics/cohorts/${cohortId}`));
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}
export async function getCohortUsersCountAPI({ cohortId, getFullUrl }) {
  const res = await $fetch(getFullUrl(config.dataServiceURL, `/product-analytics/cohorts/${cohortId}/user-count`));
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function createCohortsAPI({ payload, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/cohorts`,
    }),
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function createStaticCohortsAPI({ payload, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/static-cohort`,
    }),
    {
      method: 'POST',
      body: payload,
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function updateStaticCohortsAPI({ payload, cohortId, projectKey, environmentKey }) {
  const res = await $fetch(
    getProjectEnvAwareURL({
      serviceURL: config.dataServiceURL,
      projectKey,
      environmentKey,
      path: `/product-analytics/static-cohort/${cohortId}`,
    }),
    {
      method: 'PUT',
      body: payload,
    },
  );
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}
export async function editCohortsAPI({ cohort_uuid, payload, projectKey, environmentKey }) {
  const url = getProjectEnvAwareURL({
    serviceURL: config.dataServiceURL,
    projectKey,
    environmentKey,
    path: `/product-analytics/cohorts/${cohort_uuid}`,
  });
  const res = await $fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });
  if (res.ok) {
    const results = await res.json();
    return results;
  }

  const errorResponse = await res.json();
  throw new Error(errorResponse.detail);
}

export async function getDownloadCohortAsCsvAPI({ cohortId, projectKey, environmentKey }) {
  const url = getProjectEnvAwareURL({
    serviceURL: config.dataServiceURL,
    projectKey,
    environmentKey,
    path: `/product-analytics/cohorts/${cohortId}/export-csv`,
  });
  const response = await $fetch(url);

  if (response.ok) {
    const csvData = await response.text();
    return csvData;
  } else {
    const errorResponse = await response.json();
    throw new Error(errorResponse.detail);
  }
}
