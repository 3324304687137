import { MemberAvatar, memberDisplayName, memberInitials } from '@gonfalon/members';
import { useMember } from '@gonfalon/rest-api';
import { Text } from '@launchpad-ui/components';
export const CreatorInfo = ({ userId }: { userId: string }) => {
  const { data: member, isLoading } = useMember({ memberId: userId || '' });

  if (isLoading || !member) {
    return <Text>-</Text>;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--lp-spacing-300)' }}>
      <MemberAvatar size="small" email={member.email} initials={memberInitials(member)} />
      <Text>{memberDisplayName(member)}</Text>
    </div>
  );
};
