import { snackbarQueue } from '@launchpad-ui/components';

export function requestErrorNotification(message: string) {
  return async (error?: Response) => {
    const errorMessage =
      error?.status === 500
        ? 'internal server error'
        : await error
            ?.clone()
            .json?.()
            .then((body) => body?.message);
    const description = `${message}: ${errorMessage ?? 'unknown error'}`;
    return snackbarQueue.add({ description, status: 'error' });
  };
}
