import type { JSX } from 'react';
import { CSSProperties } from 'react';

function MutedText({ text }: { text: string; style?: CSSProperties }): JSX.Element;

function MutedText({ children }: { children: string; style?: CSSProperties }): JSX.Element;

function MutedText({
  children,
  text,
  style,
}: {
  children?: string;
  text?: string;
  style?: CSSProperties;
}): JSX.Element {
  return <div style={{ color: 'var(--secondary-text)', ...style }}>{text || children}</div>;
}

export default MutedText;
