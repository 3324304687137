import { Statement } from '@gonfalon/audit-log';
import {
  account,
  aiConfig,
  aiModelConfig,
  application,
  contextKind,
  environment,
  experiment,
  flag,
  holdout,
  integration,
  layer,
  member,
  metric,
  metricGroup,
  payloadFilter,
  project,
  relayProxyConfig,
  releasePipeline,
  repository,
  ResourceSpecifier,
  resourceSpecifierFromString,
  resourceSpecifierToString,
  role,
  segment,
  serviceToken,
  team,
  template,
  webhook,
} from '@gonfalon/resource-specifiers';
import { trackError } from '@gonfalon/telemetry';
import invariant from 'tiny-invariant';

import { activityURLSchema } from './schema';

export const anyAction = ['*'];

export const anyResource = '*';

export type ActivityFiltersParams = {
  after?: Date;
  before?: Date;
  q?: string;
  critical?: boolean;
  spec?: Statement[];
  sort?: 'asc' | 'desc';
};

export const serializeActivityFilter = (query: ActivityFiltersParams, params: URLSearchParams) => {
  if (query.after) {
    params.set('activity-after', query.after.valueOf().toString());
  }
  if (query.before) {
    params.set('activity-before', query.before.valueOf().toString());
  }
  if (query.q) {
    params.set('activity-q', query.q);
  } else {
    params.delete('activity-q');
  }
  if (query.spec) {
    params.set('spec', query.spec.map((nextFilter) => encodeURIComponent(JSON.stringify(nextFilter))).toString());
  }
  if (query.sort) {
    params.set('activity-sort', query.sort);
  }
  params.set('activity', 'true');
  return params;
};

export const deserializeActivityFilter = (searchParams: URLSearchParams) => {
  const params: ActivityFiltersParams = {};
  const after = searchParams.get('activity-after');
  if (after) {
    params.after = new Date(Number(after));
  }
  const before = searchParams.get('activity-before');
  if (before) {
    params.before = new Date(Number(before));
  }
  const q = searchParams.get('activity-q');
  if (q) {
    params.q = q;
  }
  const spec = searchParams.get('spec');
  if (spec) {
    try {
      const nextSpec = spec.split(',').map((s) => JSON.parse(decodeURIComponent(s)));
      const parse = activityURLSchema.safeParse(nextSpec);
      if (parse.success) {
        params.spec = parse.data;
      } else {
        // if we can't parse the spec we fallback to a default spec
        params.spec = [defaultAccountPolicyList(anyAction)];
      }
    } catch (e) {
      if (e instanceof Error) {
        trackError(e, { severity: 'low' });
      }
      // if we can't parse the spec we fallback to a default spec
      params.spec = [defaultAccountPolicyList(anyAction)];
    }
  }
  const sort = searchParams.get('activity-sort');
  if (sort) {
    params.sort = sort as 'asc' | 'desc';
  }
  return params;
};

export const defaultFlagAuditLogPolicyList = (
  projectKey: string,
  environments: string[],
  flagKey: string,
  actions: string[],
): Statement => ({
  resources: environments.map((env) => resourceSpecifierToString(flag(environment(project(projectKey), env), flagKey))),
  effect: 'allow',
  actions,
});

export const defaultSegmentAuditLogPolicyList = (
  projectKey: string,
  environmentKeys: string[],
  segmentKey: string,
  actions: string[],
): Statement => ({
  resources: [resourceSpecifierToString(segment(environment(project(projectKey), environmentKeys[0]), segmentKey))],
  effect: 'allow',
  actions,
});

export const defaultMemberAuditLogPolicyList = (memberId: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(member(memberId))],
  effect: 'allow',
  actions,
});

export const defaultTeamsAuditLogPolicyList = (teamKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(team(teamKey))],
  effect: 'allow',
  actions,
});

export const defaultProjectAuditLogPolicyList = (projectKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(project(projectKey))],
  effect: 'allow',
  actions,
});

export const defaultRolePolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(role('*'))],
  effect: 'allow',
  actions,
});

export const defaultAccountPolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(account())],
  effect: 'allow',
  actions,
});

export const defaultRelayProxyConfigPolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(relayProxyConfig('*'))],
  effect: 'allow',
  actions,
});

export const defaultServiceTokenPolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(serviceToken('*'))],
  effect: 'allow',
  actions,
});

export const defaultWebhookPolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(webhook('*'))],
  effect: 'allow',
  actions,
});

export const defaultIntegrationPolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(integration('*'))],
  effect: 'allow',
  actions,
});

export const defaultCodeReferenceRepositoryPolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(repository('*'))],
  effect: 'allow',
  actions,
});

export const defaultApplicationPolicyList = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(application('*'))],
  effect: 'allow',
  actions,
});

export const defaultReleasePipelinePolicy = (projectKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(releasePipeline(project(projectKey), '*'))],
  effect: 'allow',
  actions,
});

export const defaultContextKindPolicyList = (projectKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(contextKind(project(projectKey), '*'))],
  effect: 'allow',
  actions,
});

export const defaultLayerPolicyList = (projectKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(layer(project(projectKey), '*'))],
  effect: 'allow',
  actions,
});

export const defaultMetricPolicy = (projectKey: string, metricKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(metric(project(projectKey), metricKey))],
  effect: 'allow',
  actions,
});

export const defaultEnviromentPolicy = (projectKey: string, environmentKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(environment(project(projectKey), environmentKey))],
  effect: 'allow',
  actions,
});

export const defaultHoldoutPolicyList = (
  projectKey: string,
  environments: string[],
  holdoutKey: string,
  actions: string[],
): Statement => ({
  resources: environments.map((env) =>
    resourceSpecifierToString(holdout(environment(project(projectKey), env), holdoutKey)),
  ),
  effect: 'allow',
  actions,
});

export const defaultExperimentPolicyList = (
  projectKey: string,
  environments: string[],
  actions: string[],
): Statement => ({
  resources: environments.map((env) =>
    resourceSpecifierToString(experiment(environment(project(projectKey), env), '*')),
  ),
  effect: 'allow',
  actions,
});

export const defaultPayloadFilterPolicy = (projectKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(payloadFilter(project(projectKey), '*'))],
  effect: 'allow',
  actions,
});

export const defaultMetricGroupPolicy = (projectKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(metricGroup(project(projectKey), '*'))],
  effect: 'allow',
  actions,
});

export const defaultTemplatePolicy = (actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(template('*'))],
  effect: 'allow',
  actions,
});

export const defaultAIConfigPolicyList = (projectKey: string, configKey: string, actions: string[]): Statement => ({
  resources: [resourceSpecifierToString(aiConfig(project(projectKey), configKey))],
  effect: 'allow',
  actions,
});

export const defaultAIModelConfigPolicyList = (
  projectKey: string,
  configKey: string,
  actions: string[],
): Statement => ({
  resources: [resourceSpecifierToString(aiModelConfig(project(projectKey), configKey))],
  effect: 'allow',
  actions,
});

export const addResource = (
  resource: ResourceSpecifier['type'],
  projectKey: string,
  environmentKeys: string[],
): Statement => {
  switch (resource) {
    case 'flag':
      return defaultFlagAuditLogPolicyList(projectKey, environmentKeys, anyResource, anyAction);
    case 'segment':
      return defaultSegmentAuditLogPolicyList(projectKey, environmentKeys, anyResource, anyAction);
    case 'experiment':
      return defaultExperimentPolicyList(projectKey, environmentKeys, anyAction);
    case 'holdout':
      return defaultHoldoutPolicyList(projectKey, environmentKeys, anyResource, anyAction);
    case 'member':
      return defaultMemberAuditLogPolicyList(anyResource, anyAction);
    case 'team':
      return defaultTeamsAuditLogPolicyList(anyResource, anyAction);
    case 'proj':
      return defaultProjectAuditLogPolicyList(anyResource, anyAction);
    case 'role':
      return defaultRolePolicyList(anyAction);
    case 'acct':
      return defaultAccountPolicyList(anyAction);
    case 'relay-proxy-config':
      return defaultRelayProxyConfigPolicyList(anyAction);
    case 'service-token':
      return defaultServiceTokenPolicyList(anyAction);
    case 'webhook':
      return defaultWebhookPolicyList(anyAction);
    case 'integration':
      return defaultIntegrationPolicyList(anyAction);
    case 'code-reference-repository':
      return defaultCodeReferenceRepositoryPolicyList(anyAction);
    case 'application':
      return defaultApplicationPolicyList(anyAction);
    case 'release-pipeline':
      return defaultReleasePipelinePolicy(projectKey, anyAction);
    case 'context-kind':
      return defaultContextKindPolicyList(projectKey, anyAction);
    case 'layer':
      return defaultLayerPolicyList(projectKey, anyAction);
    case 'metric':
      return defaultMetricPolicy(projectKey, anyResource, anyAction);
    case 'payload-filter':
      return defaultPayloadFilterPolicy(projectKey, anyAction);
    case 'metric-group':
      return defaultMetricGroupPolicy(projectKey, anyAction);
    case 'template':
      return defaultTemplatePolicy(anyAction);
    case 'env':
      return defaultEnviromentPolicy(projectKey, environmentKeys[0], anyAction);
    case 'aiconfig':
      return defaultAIConfigPolicyList(projectKey, anyResource, anyAction);
    case 'ai-model-config':
      return defaultAIModelConfigPolicyList(projectKey, anyResource, anyAction);
    default:
      return defaultAccountPolicyList(anyAction);
  }
};

export function getFirstResource(s: Statement) {
  const firstResourceSpecifier = s.resources && s.resources.length > 0 ? s.resources[0] : null;

  invariant(firstResourceSpecifier !== null, 'At least one resource specifier is required');

  const parsedFirstResource = resourceSpecifierFromString(firstResourceSpecifier);

  invariant(!parsedFirstResource.err, 'Failed to parse the first resource specifier');

  return parsedFirstResource;
}

export function getEnvironments(s: Statement) {
  invariant(s.resources && s.resources.length > 0, 'Resources must be defined');
  return s.resources.map((r) => {
    const resourceItem = resourceSpecifierFromString(r);
    if (resourceItem.err) {
      throw new Error(resourceItem.val.message);
    }
    if (resourceItem.val.type === 'flag') {
      return resourceItem.val.environment.name;
    }
    return '*';
  });
}
