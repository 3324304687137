import { useState } from 'react';
import { AutoComplete, DatePicker, InputNumber, Select, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';

import MutedText from '../../../components/MutedText';
import { BlockFilter, EventDimensionProperties } from '../../../types/types';

const { RangePicker } = DatePicker;

type BlockFilterProps = {
  dimension?: EventDimensionProperties;
  filter: BlockFilter & { type?: 'dimension' };
  getUpdateFilterValue: (newFilterValue: any) => void;
  disabled?: boolean;
};

function convertToNumberArray(value: string | string[]): number[] {
  if (typeof value === 'string') {
    value = value.split(',');
  }

  const numberArray = Array.from(new Set(value.map((v) => Number(v)).filter((v) => !isNaN(v) && v !== null)));
  return numberArray;
}

const DynamicFilterInputSelector = ({
  dimension,
  filter,
  getUpdateFilterValue,
  disabled = false,
}: BlockFilterProps) => {
  const isDimOperator = filter?.operator;
  const dimDataType = dimension?.data_type;
  const valueOptions = dimension?.values.map((value) => ({
    value: value.toString(),
  }));

  const [autoCompleteObj, setAutoCompleteObj] = useState<{
    options: Array<{ value: string; label?: any }>;
    value: string;
    open: boolean;
  }>({
    options: [],
    value: '',
    open: false,
  });

  const getSearchResultFromFilterValues = (query: string) => {
    const filteredValues = dimension?.values.filter((value) => value.toLowerCase().includes(query.toLowerCase()));
    return filteredValues?.map((value) => ({
      value,
    }));
  };
  const isHighCardinality = dimension?.is_high_cardinality;

  switch (dimDataType) {
    case 'date':
      if (['in', 'not_in'].includes(isDimOperator)) {
        return (
          <DatePicker
            multiple
            value={Array.isArray(filter?.value) || !!filter?.value ? filter.value : undefined}
            onChange={(selectedDates) => {
              getUpdateFilterValue(selectedDates);
            }}
            maxTagCount={2}
            disabled={disabled}
          />
        );
      } else if (['gte', 'lte'].includes(isDimOperator)) {
        return (
          <DatePicker
            size="small"
            variant="filled"
            disabled={disabled}
            style={{ width: '100%' }}
            value={
              Array.isArray(filter?.value) ? dayjs(filter.value[0]) : filter?.value ? dayjs(filter.value) : undefined
            }
            placeholder={disabled ? '' : 'Select a date'}
            onChange={(date) => {
              const selectedDate = dayjs(date).format('YYYY-MM-DD');
              getUpdateFilterValue(selectedDate);
            }}
          />
        );
      } else if (['between', 'not_between'].includes(isDimOperator)) {
        return (
          <RangePicker
            size="small"
            variant="filled"
            disabled={disabled}
            style={{ width: '100%' }}
            value={
              Array.isArray(filter?.value) && !!filter?.value
                ? [dayjs(filter.value[0]), dayjs(filter.value[1])]
                : undefined
            }
            placeholder={disabled ? undefined : ['Start date', 'End date']}
            onChange={(dates) => {
              const selectedDates = dates?.map((date) => dayjs(date).format('YYYY-MM-DD'));
              getUpdateFilterValue(selectedDates);
            }}
          />
        );
      }
      break;
    case 'string':
      if (['in', 'not_in'].includes(isDimOperator)) {
        if (isHighCardinality) {
          return (
            <Tooltip placement="left" title={disabled || !isDimOperator ? '' : `Select value...`}>
              <Select
                size="small"
                variant="filled"
                allowClear
                showSearch
                disabled={disabled}
                placeholder={disabled || !isDimOperator ? '' : `Select value...`}
                value={Array.isArray(filter?.value) ? filter.value : filter?.value ? [filter?.value] : []}
                mode="tags"
                maxTagCount={2}
                maxTagTextLength={7}
                style={{ width: '100%' }}
                onChange={(value: string | string[]) => {
                  const selectedValues = Array.from(new Set(Array.isArray(value) ? value : value.split(',')));
                  if (selectedValues?.length) {
                    getUpdateFilterValue(selectedValues);
                  }
                }}
                onClear={() => getUpdateFilterValue([])}
                tokenSeparators={[',']}
                notFoundContent={
                  <Space style={{ padding: '0 8px 4px' }}>Tip: Start typing and select the value to add</Space>
                }
              />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip placement="left" title={disabled || !isDimOperator ? '' : `Select value(s)...`}>
              <Select
                size="small"
                variant="filled"
                allowClear
                showSearch
                disabled={disabled}
                placeholder={disabled || !isDimOperator ? '' : `Select value(s)...`}
                style={{ width: '100%' }}
                maxTagCount={2}
                maxTagTextLength={7}
                value={filter?.value || undefined}
                onChange={(selected) => {
                  getUpdateFilterValue(selected?.length ? selected : null);
                }}
                mode="multiple"
                options={valueOptions}
              />
            </Tooltip>
          );
        }
      } else if (isDimOperator === 'icontains') {
        return (
          <Tooltip
            placement="left"
            title={disabled ? '' : 'Tip: Enter value and see the results that contain the value'}
          >
            <AutoComplete
              size="small"
              variant="filled"
              allowClear
              style={{ width: '100%' }}
              placeholder={disabled ? '' : 'Enter value...'}
              disabled={disabled}
              value={filter?.value}
              open={autoCompleteObj.open}
              onBlur={() =>
                setAutoCompleteObj((prev) => ({
                  ...prev,
                  open: false,
                }))
              }
              onFocus={() => {
                if (autoCompleteObj.value) {
                  setAutoCompleteObj((prev) => ({
                    ...prev,
                    open: true,
                  }));
                }
              }}
              options={[
                {
                  label: `Select value: ${autoCompleteObj.value}`,
                  value: autoCompleteObj.value,
                },
                {
                  label: `value found in ${autoCompleteObj.options.length} results`,
                  options: autoCompleteObj.options,
                },
              ]}
              onSelect={(value: string) => {
                getUpdateFilterValue(value);
                setAutoCompleteObj((prev) => ({
                  ...prev,
                  value: '',
                  open: false,
                }));
              }}
              onChange={(value: string) => {
                setAutoCompleteObj({
                  options: value ? getSearchResultFromFilterValues(value)! : [],
                  value,
                  open: !!value,
                });
                getUpdateFilterValue(value);
              }}
            />
          </Tooltip>
        );
      } else if (isDimOperator === 'is_not_set' || isDimOperator === 'is_set') {
        return null;
      }
      break;
    case 'number':
      if (['in', 'not_in', 'gte', 'gt', 'lt', 'lte'].includes(isDimOperator)) {
        return (
          <Tooltip
            placement="left"
            title={
              disabled || !isDimOperator
                ? ''
                : `Tip: Start typing ${isHighCardinality ? 'and' : 'or'} select from the dropdown`
            }
          >
            <Select
              size="small"
              variant="filled"
              allowClear
              showSearch
              disabled={disabled}
              placeholder={
                disabled || !isDimOperator ? '' : `${isHighCardinality ? 'Enter value...' : 'Select value...'}`
              }
              value={
                Array.isArray(filter?.value) && filter.value.length
                  ? filter.value.map((v) => Number(v)).filter((v) => !isNaN(v))
                  : Number(filter?.value)
                    ? [filter?.value]
                    : []
              }
              mode="tags"
              maxTagCount={2}
              style={{ width: '100%' }}
              onChange={(value: string | string[]) => {
                const selectedValues = convertToNumberArray(value);
                return ['in', 'not_in'].includes(isDimOperator)
                  ? getUpdateFilterValue(selectedValues)
                  : getUpdateFilterValue([selectedValues.at(-1)]);
              }}
              tokenSeparators={[',']}
              options={!isHighCardinality ? valueOptions : undefined}
              notFoundContent={
                <Space style={{ padding: '0 8px 4px' }}>Tip: Start typing and select the value to add</Space>
              }
            />
          </Tooltip>
        );
      } else if (['between', 'not_between'].includes(isDimOperator)) {
        return (
          <div style={{ width: '100%', display: 'flex' }}>
            <Tooltip title={disabled ? '' : 'Tip: Enter a numeric value '}>
              <InputNumber
                size="small"
                variant="filled"
                style={{ width: '100%' }}
                disabled={disabled}
                value={filter?.value?.[0] ?? null}
                placeholder={disabled ? '' : 'Value...'}
                onChange={(selected) => {
                  const newValues = filter?.value?.map((v: any) => Number(v));
                  newValues[0] = selected;
                  getUpdateFilterValue(newValues);
                }}
              />
            </Tooltip>
            <MutedText
              style={{
                padding: 5,
                alignItems: 'center',
                display: 'inline-flex',
              }}
            >
              →
            </MutedText>
            <Tooltip title={disabled ? '' : 'Tip: Enter a numeric value'}>
              <InputNumber
                size="small"
                variant="filled"
                style={{ width: '100%' }}
                disabled={disabled}
                value={filter?.value?.[1] ?? null}
                placeholder={disabled ? '' : 'Value...'}
                onChange={(selected) => {
                  const newValues = (filter?.value ?? []).map((v: any) => Number(v));
                  newValues[1] = selected;
                  getUpdateFilterValue(newValues);
                }}
              />
            </Tooltip>
          </div>
        );
      }
      break;
    case 'boolean':
      return (
        <Tooltip
          placement="left"
          title={disabled || !isDimOperator ? '' : `Tip: Please select either true or false from the dropdown option`}
        >
          <Select
            size="small"
            variant="filled"
            placeholder="Please select either true or false"
            onChange={(value) => {
              getUpdateFilterValue(value);
            }}
            style={{ width: '100%' }}
            value={filter?.value}
            options={valueOptions}
          />
        </Tooltip>
      );
    default:
      return null;
  }
};

export default DynamicFilterInputSelector;
