import { message } from 'antd';

import { getHumanDateLabelFromDateFilter } from '../../components/dateFilter/utils/dateFormatting';
import { humanReadableMappingForAllFilterOperators } from '../../features/block/constants';
import {
  CohortDateRange,
  CohortDetails,
  CohortFilterLogicalAnd,
  CohortFilterLogicalOthers,
} from '../../features/cohort/types';
import { BlockFilter, DateFilterViews, FilterOperatorKeys } from '../../types/types';

export default function getNewCohortDefFromOldCohortDef(cohortDef: CohortDetails['definition']) {
  if (cohortDef === null) {
    return null;
  }

  if (cohortDef?.values?.[0]?.values?.[0]?.values?.[0] !== undefined) {
    return cohortDef;
  }

  cohortDef.values = cohortDef?.values.map((andBlock) => {
    const newAndBlock: CohortFilterLogicalAnd = {
      type: 'logical',
      operator: 'and',
      values: [
        {
          ...(andBlock as unknown as CohortFilterLogicalOthers),
        },
      ],
    };
    return newAndBlock;
  });
  return cohortDef;
}

export function getCohortTypesFromCohortDefinition(
  cohortDef: Record<string, any> | null,
  results: Set<string> = new Set(),
) {
  // get type of cohort value from cohort definition eg 'from_funnels, from_retention' etc
  if (typeof cohortDef === 'object' && cohortDef !== null) {
    if (
      'type' in cohortDef &&
      ('aggregates' in cohortDef ||
        'cohort_period' in cohortDef ||
        'user_type' in cohortDef ||
        'action' in cohortDef ||
        'uuid' in cohortDef)
    ) {
      results.add(cohortDef.type);
    }
    // Recurse into the object's values
    Object.keys(cohortDef).forEach((key) => {
      if (typeof cohortDef[key] === 'object') {
        getCohortTypesFromCohortDefinition(cohortDef[key], results);
      }
    });
  }
  // Convert the Set to an array if necessary when returning (depends on how you want to use the results)
  return Array.from(results);
}

const SEPARATORS = {
  SECTION: ' | ',
  FILTER: ' , ',
  CONDITION: ' & ',
} as const;

interface CohortEvent {
  name: string;
  filter?: BlockFilter[];
}

interface CohortFilterGroup {
  users_in: string[];
  users_not_in: string[];
}

interface BreakdownFilter {
  dimensions?: Array<{ name: string; value: string }>;
  cohorts?: string[];
}

interface GenerateCohortDescriptionFromTrends {
  vizType: string;
  chartView: string;
  events?: CohortEvent[];
  dimensions?: BlockFilter[];
  breakdowns?: BreakdownFilter;
  dateRange: CohortDateRange;
  cohortFilterGroup?: CohortFilterGroup;
}

const formatEventFilter = (event: CohortEvent): string =>
  event.filter
    ?.map(
      ({ field, operator, value }) =>
        `${field} ${humanReadableMappingForAllFilterOperators[operator as FilterOperatorKeys]} ${value}`,
    )
    .join(SEPARATORS.FILTER) ?? '';

const formatCohortEvents = (events: CohortEvent[]): string =>
  events
    .map((event) => {
      const filter = formatEventFilter(event);
      return `"${event.name}"${filter ? ` filtered by ${filter}` : ''}`;
    })
    .join(SEPARATORS.CONDITION);

const createBasicDescription = (
  vizType: string,
  chartView: string,
  events: CohortEvent[],
  humanReadableDate: string,
  dateRangeType?: string,
): string =>
  [
    `Cohort created from ${vizType} — ${chartView} view`,
    `Users who did ${formatCohortEvents(events)}`,
    `${dateRangeType === 'between' ? 'from' : ''} ${humanReadableDate}`,
  ].join(SEPARATORS.SECTION);

const createFilterDescriptionSection = (cohortFilterGroup?: CohortFilterGroup, dimensions?: BlockFilter[]): string => {
  const cohortFilters: string[] = [];

  if (cohortFilterGroup?.users_in?.length) {
    cohortFilters.push(`users in cohort: ${cohortFilterGroup.users_in.join(SEPARATORS.CONDITION)}`);
  }

  if (cohortFilterGroup?.users_not_in?.length) {
    cohortFilters.push(`users not in cohort: ${cohortFilterGroup.users_not_in.join(SEPARATORS.CONDITION)}`);
  }

  const dimensionFilters = dimensions?.length
    ? dimensions
        .map(
          (d) => `${d.field} ${humanReadableMappingForAllFilterOperators[d.operator as FilterOperatorKeys]} ${d.value}`,
        )
        .join(SEPARATORS.CONDITION)
    : '';

  const filters = [cohortFilters.length && cohortFilters.join(SEPARATORS.FILTER), dimensionFilters]
    .filter(Boolean)
    .join(' , ');

  return filters ? `Chart Filtered by ${filters}` : '';
};

const createBreakdownDescriptionSection = (breakdowns?: BreakdownFilter): string => {
  if (!breakdowns) {
    return '';
  }

  const breakdownParts: string[] = [];

  if (breakdowns.dimensions?.length) {
    breakdownParts.push(breakdowns.dimensions.map((d) => `${d.name} = ${d.value}`).join(SEPARATORS.CONDITION));
  }

  if (breakdowns.cohorts?.length) {
    breakdownParts.push(breakdowns.cohorts.join(SEPARATORS.CONDITION));
  }

  return breakdownParts.length ? `in Breakdown — ${breakdownParts.join(SEPARATORS.FILTER)}` : '';
};

export const isCohortDuplicable = (cohortDetails: CohortDetails): boolean => {
  const cohortTypeFromDefinition = getCohortTypesFromCohortDefinition(cohortDetails.definition);
  const isCohortCreatedFromViz = cohortTypeFromDefinition.some(
    (element) => ['from_retention', 'from_funnel'].includes(element) && cohortDetails?.type !== 'reference',
  );

  return (cohortDetails?.type === 'definition' && !isCohortCreatedFromViz) || cohortDetails?.type === 'reference';
};

export const generateCohortDescriptionFromTrends = ({
  events = [],
  chartView,
  dateRange,
  dimensions,
  breakdowns,
  cohortFilterGroup,
  vizType,
}: GenerateCohortDescriptionFromTrends): string => {
  if (!chartView || !dateRange?.start_date || !dateRange?.end_date) {
    message.warning('Please select a date range on the date filter to create a cohort from this chart');
    return '';
  }

  const { label: humanReadableDate } = getHumanDateLabelFromDateFilter({
    view: dateRange.type as DateFilterViews,
    from: { date: dateRange.start_date },
    to: { date: dateRange.end_date },
  });

  const sections = [
    createBasicDescription(vizType, chartView, events, humanReadableDate, dateRange.type),
    createFilterDescriptionSection(cohortFilterGroup, dimensions),
    createBreakdownDescriptionSection(breakdowns),
  ].filter(Boolean);

  return sections.join(SEPARATORS.SECTION).trim();
};

export const sampleStaticCohortData = [
  {
    user_id: 'cus_369ee91d6c3a407ea4ffc466f0c05571',
  },
  {
    user_id: 'cus_ee19851f165343fd8f3a2b6e8b7cdea6',
  },
  {
    user_id: 'cus_46608e66fca64988892117791ec3328f',
  },
  {
    user_id: 'cus_04f11e1cfee24950899dfb137b85dc0b',
  },
  {
    user_id: 'cus_1a63b922469749548e89e917d4176d38',
  },
  {
    user_id: 'cus_f80ae9d4ffe844a9a4670cf363ac850c',
  },
  {
    user_id: 'cus_0b64a4482f2c487fb0a043ce6374920e',
  },
  {
    user_id: 'cus_44d8dad59eb247fdb390e5b177e5b2c2',
  },
  {
    user_id: 'cus_7b348303b3d147ff8a9323a1b60b2b54',
  },
  {
    user_id: 'cus_0c91b965991e4efcaba760ea47b57a46',
  },
  {
    user_id: 'cus_db42b4383b654eab912e5ce133b330c2',
  },
  {
    user_id: 'cus_520ecea7bf584ab1807de5e8285a8b2c',
  },
  {
    user_id: 'cus_2f1c7cc9a258462fb6b9a9a312fb691f',
  },
  {
    user_id: 'cus_53e1e6c2ef0e483c85ac311462d768fd',
  },
  {
    user_id: 'cus_b17e60ae0ae54aab85e79f9e58429bf9',
  },
  {
    user_id: 'cus_d87be278427d471d81286a7a9f477311',
  },
  {
    user_id: 'cus_70d8385720024a0c9825c1bea8059421',
  },
  {
    user_id: 'cus_7e25b7b02465407c9d0db8b9745bdf45',
  },
  {
    user_id: 'cus_2a697293417a4b249c5cdc40de6ea07c',
  },
  {
    user_id: 'cus_8548d5de8ffd4cd5adf3ec58565fa72d',
  },
  {
    user_id: 'cus_25c0fdcad121489fbfe2fd3007cdc558',
  },
  {
    user_id: 'cus_86c58e3012cb49aa9354da3775e38695',
  },
  {
    user_id: 'cus_9ff97eebc588492bb2e8c842aa0963be',
  },
  {
    user_id: 'cus_42ddcac0cd7d46f199d39308cebb7adb',
  },
  {
    user_id: 'cus_885c3af6a6124f66b3b0f1b73c970379',
  },
  {
    user_id: 'cus_f32b655ca45d44a8938856e830d1cf49',
  },
  {
    user_id: 'cus_131726d12e6a44c19e70f5ecefae99cf',
  },
  {
    user_id: 'cus_25063ecca2324ba28004eeefa6495085',
  },
  {
    user_id: 'cus_0c85d1496910460785ed0edefc214011',
  },
  {
    user_id: 'cus_86caaf03fc2e4df2a92ce05a9483245d',
  },
  {
    user_id: 'cus_7c9c866dea724e358907cf285ad3ef0c',
  },
  {
    user_id: '',
  },
];
