export default function fuzzySearch(listOfStringToSearchIn: string[], searchTerm: string): boolean {
  const formattedSearchTerm = (searchTerm || '').toLowerCase().replaceAll('"', '').replaceAll("'", '');
  for (const itemToSearchIn of listOfStringToSearchIn) {
    const wordToSearchIn = itemToSearchIn || '';
    const lowerCasedItemToSearchIn = wordToSearchIn?.toLowerCase();
    const normalisedSearchItem = wordToSearchIn.replaceAll('-', ' ').replaceAll('_', ' ');
    const wordsInNormalisedSearchItem = normalisedSearchItem.split(' ');
    const wordsInSearchItem = lowerCasedItemToSearchIn.split(' ');
    const wordsInSearchTerm = formattedSearchTerm.split(' ');

    const doesSearchMatch = wordsInSearchTerm.every((searchWord) =>
      wordsInSearchItem.some((searchableWord) => searchableWord.includes(searchWord)),
    );
    const doesNormalisedSearchMatch = wordsInSearchTerm.every((searchWord) =>
      wordsInNormalisedSearchItem.some((searchableWord) => searchableWord.includes(searchWord)),
    );
    if (doesSearchMatch || doesNormalisedSearchMatch) {
      return true;
    }
  }
  return false;
}
