import { useCallback, useMemo, useRef } from 'react';
import { DatePicker, Input, InputNumber, message, Segmented, Select, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { DatePeriodUnitsForDateFilter, HousewareDateFilter } from '../../../types/types';
import { parseDateToTimeZone } from '../../../utils/convertDateFormat';
import convertToTitleCase from '../../../utils/transformToTitleCase';
import { dateFilterType, humanDateStrings, VIZ_DATE_FILTER_FORMAT } from '../constants';
import { UseDatePickerUIProps } from '../types';
import {
  formatBetweenDateFilter,
  getFormattedDateWithTimeGranularity,
  getMaxValueAllowedForTimeGranularity,
} from '../utils/common';
import { getDateObjectFromHumanStr, getHumanDateStrFromDateObject } from '../utils/dateFormatting';

import DatePickerOffsetUI from './DatePickerOffSetUi';

export function useDatePickerUI({
  activeDateFilterType,
  setActiveDateFilterType,
  dateFilter,
  handleDateFilterUpdate,
  allowClearDateFilter,
  allowedDateRange,
  vizTypeAndTimeGranularity,
  showOffset,
  setShowOffset,
  disableOffset,
  possiblePeriodTypes,
  disabledViews,
  segmentedStyle,
}: UseDatePickerUIProps) {
  const rangePickerRef = useRef<Dayjs | null>(null);

  const handleOffsetChange = useCallback(
    (value: number | null) => {
      if (dateFilter?.view === 'last' && value !== null) {
        handleDateFilterUpdate({
          offset: {
            unit: dateFilter?.from?.window?.unit ?? 'day',
            value,
          },
        });
      }
    },
    [dateFilter?.view, dateFilter?.from?.window?.unit, handleDateFilterUpdate],
  );

  const handleRangePickerChange = useCallback(
    (val: [string, string] | null) => {
      if (!val) {
        return;
      }

      const [startDate, endDate] = val;
      const differenceInDays = dayjs(endDate).diff(startDate, 'day');

      if (allowedDateRange?.maxDaysLimit && differenceInDays > allowedDateRange.maxDaysLimit.limit) {
        void message.info(allowedDateRange.maxDaysLimit.message);
      }

      handleDateFilterUpdate(
        vizTypeAndTimeGranularity
          ? getFormattedDateWithTimeGranularity({
              vizTypeAndTimeGranularity,
              dateFilter: val,
            })
          : {
              view: 'between',
              from: { date: startDate },
              to: { date: endDate },
            },
      );
    },
    [allowedDateRange?.maxDaysLimit, vizTypeAndTimeGranularity, handleDateFilterUpdate],
  );

  const getMaxSelectorValueForNumberInput = useCallback(
    (unit: DatePeriodUnitsForDateFilter | undefined) => {
      if (!unit) {
        return undefined;
      }

      const isUserFlowWeek = unit === 'week' && vizTypeAndTimeGranularity?.vizType === 'user_flow';

      if (isUserFlowWeek) {
        return 1;
      }

      const isDayWithLimit = unit === 'day' && allowedDateRange?.maxDaysLimit;
      if (isDayWithLimit) {
        return allowedDateRange?.maxDaysLimit?.limit;
      }

      return getMaxValueAllowedForTimeGranularity(unit);
    },
    [vizTypeAndTimeGranularity?.vizType, allowedDateRange?.maxDaysLimit],
  );

  const disabledRangePickerDates = useCallback(
    (current: Dayjs) => {
      const { end, start, maxDaysLimit } = allowedDateRange || {};

      const getDayBoundary = (date: Dayjs, isEnd = false) => (isEnd ? date.endOf('day') : date.startOf('day'));

      const isOutsideRange = (date: Dayjs, startDate: Dayjs, endDate: Dayjs) =>
        date.isBefore(getDayBoundary(startDate)) || date.isAfter(getDayBoundary(endDate, true));

      const endDate = end ? dayjs(parseDateToTimeZone({ dateString: end })) : dayjs();
      const startDate = start ? dayjs(parseDateToTimeZone({ dateString: start })) : endDate.subtract(365, 'days');

      if (!maxDaysLimit?.limit || !rangePickerRef.current) {
        return isOutsideRange(current, startDate, endDate);
      }

      const selectedDate = rangePickerRef.current;
      const maxDays = maxDaysLimit.limit;

      const maxForward = getDayBoundary(selectedDate.add(maxDays - 1, 'days'), true);
      const maxBackward = getDayBoundary(selectedDate.subtract(maxDays - 1, 'days'));

      return (
        isOutsideRange(current, startDate, endDate) ||
        (current.isAfter(selectedDate, 'day') && current.isAfter(maxForward)) ||
        (current.isBefore(selectedDate, 'day') && current.isBefore(maxBackward))
      );
    },
    [allowedDateRange, rangePickerRef],
  );

  const RenderDatePickerContent = useCallback(() => {
    switch (activeDateFilterType) {
      case 'presets':
        return (
          <Select
            style={{ width: '100%' }}
            placeholder="Select a preset"
            value={getHumanDateStrFromDateObject(dateFilter?.from?.window)}
            onChange={(preset) => {
              const { from, to } = getDateObjectFromHumanStr(preset);
              handleDateFilterUpdate({
                view: 'presets',
                from: { window: from },
                to: { window: to },
              });
            }}
            options={humanDateStrings.map((str) => ({
              label: str,
              value: str,
            }))}
          />
        );

      case 'since':
        return (
          <DatePicker
            allowClear={allowClearDateFilter}
            style={{ width: '100%' }}
            value={dateFilter?.from?.date ? dayjs(dateFilter?.from?.date) : null}
            disabledDate={disabledRangePickerDates}
            onChange={(_, val) => {
              handleDateFilterUpdate({
                view: 'since',
                from: {
                  date: val ? dayjs(val as string).format(VIZ_DATE_FILTER_FORMAT) : null,
                },
                to: {
                  window: { unit: 'day', value: 0 },
                },
              });
            }}
          />
        );

      case 'last':
        return (
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            {allowedDateRange?.maxDaysLimit && (
              <div style={{ color: 'var(--secondary-text)', fontSize: '0.9rem' }}>
                {allowedDateRange.maxDaysLimit.message}
              </div>
            )}
            <>
              <Input.Group compact>
                <InputNumber
                  autoFocus
                  style={{ width: '8rem', textAlign: 'center' }}
                  min={0}
                  precision={0}
                  max={getMaxSelectorValueForNumberInput(dateFilter?.from?.window?.unit)}
                  placeholder="Last n"
                  value={dateFilter?.from?.window?.value}
                  onChange={(value) => {
                    if (value === null) {
                      return;
                    }
                    const intValue = Math.floor(Number(value));
                    handleDateFilterUpdate({
                      view: 'last',
                      from: {
                        window: {
                          unit: dateFilter?.from?.window?.unit ?? 'day',
                          value: intValue,
                        },
                      },
                      to: {
                        window: { unit: 'day', value: 0 },
                      },
                    });
                  }}
                />
                <Select
                  value={dateFilter?.from?.window?.unit}
                  onChange={(unit: DatePeriodUnitsForDateFilter) => {
                    const maxInputNumberValue = getMaxSelectorValueForNumberInput(unit);

                    const currentInputNumberValue = dateFilter?.from?.window?.value ?? 1;

                    handleDateFilterUpdate({
                      view: 'last',
                      from: {
                        window: {
                          unit,
                          value: Math.min(currentInputNumberValue, maxInputNumberValue ?? 1),
                        },
                      },
                      to: {
                        window: { unit: 'day', value: 0 },
                      },
                    });
                  }}
                  options={possiblePeriodTypes?.map((value) => ({
                    value,
                    label: `${value}s`,
                  }))}
                  style={{ width: '12rem' }}
                  placeholder="Period"
                  optionFilterProp="children"
                />
              </Input.Group>
              {dateFilter?.from?.window && (
                <div style={{ color: 'var(--secondary-text)', fontSize: '0.9rem' }}>
                  * Last {dateFilter.from.window.value} complete {dateFilter.from.window.unit}
                  {dateFilter.from.window.value > 1 ? 's' : ''} and current {dateFilter.from.window.unit}
                </div>
              )}
            </>
            <DatePickerOffsetUI
              dateFilter={dateFilter}
              showOffset={showOffset}
              setShowOffset={setShowOffset}
              handleOffsetChange={handleOffsetChange}
              handleDateFilterUpdate={handleDateFilterUpdate}
              disableOffset={Boolean(disableOffset)}
            />
          </Space>
        );

      case 'between':
        return (
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            {allowedDateRange?.maxDaysLimit && (
              <div style={{ color: 'var(--secondary-text)', fontSize: '0.9rem' }}>
                {allowedDateRange.maxDaysLimit.message}
              </div>
            )}
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              value={
                formatBetweenDateFilter({
                  dateFilter,
                  vizTypeAndTimeGranularity,
                }) as [Dayjs, Dayjs]
              }
              allowClear={!vizTypeAndTimeGranularity && allowClearDateFilter}
              onChange={(_, val) => handleRangePickerChange(val)}
              onCalendarChange={(dates) => {
                rangePickerRef.current = dates?.[0] || null;
              }}
              disabledDate={disabledRangePickerDates}
            />
          </Space>
        );

      case 'on':
        return (
          <DatePicker
            style={{ width: '100%' }}
            value={dateFilter?.from?.date ? dayjs(dateFilter?.from?.date) : null}
            allowClear={allowClearDateFilter}
            disabledDate={disabledRangePickerDates}
            onChange={(_, val) => {
              handleDateFilterUpdate({
                view: 'on',
                from: {
                  date: val ? dayjs(val as string).format(VIZ_DATE_FILTER_FORMAT) : null,
                },
                to: {
                  date: val ? dayjs(val as string).format(VIZ_DATE_FILTER_FORMAT) : null,
                },
              });
            }}
          />
        );

      default:
        return null;
    }
  }, [
    activeDateFilterType,
    dateFilter,
    allowClearDateFilter,
    allowedDateRange?.maxDaysLimit,
    getMaxSelectorValueForNumberInput,
    possiblePeriodTypes,
    showOffset,
    setShowOffset,
    handleOffsetChange,
    handleDateFilterUpdate,
    disableOffset,
    vizTypeAndTimeGranularity,
    disabledRangePickerDates,
    handleRangePickerChange,
  ]);

  const datePickerContent = useMemo(
    () => (
      <div style={{ position: 'relative', width: '100%' }}>
        <div
          style={{
            marginBottom: '16px',
            width: 'fit-content',
          }}
        >
          <Segmented
            value={activeDateFilterType}
            onChange={(value) => setActiveDateFilterType(value as HousewareDateFilter['view'])}
            options={dateFilterType.map((type) => ({
              label: convertToTitleCase(type),
              value: type,
              disabled: disabledViews?.includes(type),
            }))}
            style={segmentedStyle}
          />
        </div>

        <RenderDatePickerContent />
      </div>
    ),
    [activeDateFilterType, segmentedStyle, RenderDatePickerContent, setActiveDateFilterType, disabledViews],
  );

  return { datePickerContent };
}
