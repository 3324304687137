import { type JSX, Component } from 'react';
import { redirectToBeastModeEscalation, shouldRefreshBeastMode } from '@gonfalon/beastmode';

import { Confirmation, ConfirmationProps } from './Confirmation';

export type WithConfirmationProps = {
  render: ({ onAction, disabled }: { onAction(): void; disabled?: boolean }) => JSX.Element | null;
  canConfirm?: boolean;
  onConfirm:
    | ((data?: FormData) => void)
    | ((expiry?: number) => void)
    | ((config?: { expiry?: number | undefined } | null) => void);
  onCancel?(): void;
  beastModeRoute?: string;
  confirmation: ConfirmationProps;
  testId?: string;
  copyableValue?: string;
};

type StateProps = {
  showConfirmation?: boolean;
};

export class WithConfirmation extends Component<WithConfirmationProps, StateProps> {
  state = {
    showConfirmation: false,
  };

  render() {
    const { render, canConfirm, confirmation, onConfirm, copyableValue, ...props } = this.props;
    const { showConfirmation } = this.state;
    const wrappedProps = { ...props, onAction: this.handleAction };
    const children = render(wrappedProps);

    return (
      <>
        {children}
        {showConfirmation && (
          <Confirmation
            {...confirmation}
            copyableValue={copyableValue}
            canConfirm={canConfirm}
            onConfirm={this.handleConfirm}
            onCancel={this.handleCancel}
          />
        )}
      </>
    );
  }

  handleAction = () => {
    const { beastModeRoute } = this.props;
    if (beastModeRoute && shouldRefreshBeastMode(beastModeRoute)) {
      redirectToBeastModeEscalation();
      return;
    }
    this.setState({ showConfirmation: true });
  };

  handleConfirm = (data?: FormData & number) => {
    const { onConfirm } = this.props;
    this.setState({ showConfirmation: false }, () => onConfirm(data));
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    this.setState({ showConfirmation: false });
    onCancel && onCancel();
  };
}
