import { getTrialStatusInfo, hasPlan, trackTrialBannerCTAClicked } from '@gonfalon/billing';
import { useAccountContext } from '@gonfalon/context';
import { isPlanChangeEnabled } from '@gonfalon/dogfood-flags';
import { toBillingPlans } from '@gonfalon/navigator';
import { LinkButton } from '@launchpad-ui/components';
import clsx from 'clsx';

import styles from './TrialBanner.module.css';

interface TrialBannerProps {
  ref: (element: HTMLDivElement | null) => void;
  variant?: 'header' | 'footer';
}

export function TrialBanner({ ref, variant = 'header' }: TrialBannerProps) {
  const { subscription } = useAccountContext();
  const { isSubscribed, isTrialExpired, trialDaysRemaining } = getTrialStatusInfo(subscription);

  const planExists = hasPlan(subscription);

  if (isSubscribed || !isPlanChangeEnabled() || planExists) {
    return null;
  }

  let heading = 'Ship faster with confidence';
  let message = `${trialDaysRemaining} ${trialDaysRemaining === 1 ? 'day' : 'days'} remaining`;
  let buttonText = 'Explore plans';

  if (isTrialExpired) {
    heading = 'Trial expired';
    message = 'Limited access';
    buttonText = 'Unlock Access';
  }

  return (
    <div
      className={clsx(styles.trialBanner, isTrialExpired && styles.expired, variant === 'footer' && styles.footer)}
      ref={ref}
    >
      <div className={styles.bannerText}>
        <h2>{heading}</h2> &mdash;
        <p>{message}</p>
      </div>
      <LinkButton
        data-theme="default"
        variant="default"
        size="medium"
        href={toBillingPlans()}
        onPress={() => trackTrialBannerCTAClicked(trialDaysRemaining)}
      >
        {buttonText}
      </LinkButton>
    </div>
  );
}
