import { EVENT_NAME_AND_CUSTOM_LABEL_SEPARATOR } from '../../../visualization/constant';
import { EventDefinitoin } from '../../types';

export default function getEventLabels(e: EventDefinitoin, toSplitBy = null) {
  const splitBy = toSplitBy || EVENT_NAME_AND_CUSTOM_LABEL_SEPARATOR;
  const [eventLabel, ...rest] = typeof e?.label === 'string' ? e.label.split(splitBy) : [];
  const hasCustomLabel = rest?.length > 0;
  const [alphabetLabel, customLabel] = eventLabel?.split(':') || [];
  return { eventLabel, hasCustomLabel, alphabetLabel, customLabel };
}
