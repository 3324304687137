import type { JSX, ReactNode } from 'react';
import { useRef } from 'react';
import { type TooltipProps, Focusable, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

import './styles.css';

export type AbbrProps = {
  /**
   * Tooltip contents displayed on hover
   */
  title: string;
  content?: JSX.Element | string;
  placement?: TooltipProps['placement'];
  children: ReactNode;
  popoverContentClassName?: string;
  variant?: TooltipProps['variant'];
};

/* eslint-disable import/no-default-export */
export default function Abbr({ title, placement, children, content, popoverContentClassName, variant }: AbbrProps) {
  const ref = useRef(null);
  return (
    <TooltipTrigger>
      <Focusable>
        <span role="button" ref={ref}>
          <abbr aria-label={title}>{children}</abbr>
        </span>
      </Focusable>
      <Tooltip placement={placement} className={popoverContentClassName} variant={variant} triggerRef={ref}>
        {content || title}
      </Tooltip>
    </TooltipTrigger>
  );
}
