import { ErrorBoundary } from '@gonfalon/error-boundaries';
import { getFeatureFlagQuery } from '@gonfalon/rest-api';
import { Alert, ProgressBar } from '@launchpad-ui/components';
import { useQuery } from '@tanstack/react-query';
import { fromJS } from 'immutable';

import { PrerequisiteEntry } from 'components/InstructionList/PrerequisiteEntry';
import { createFlag } from 'utils/flagUtils';
import { FlagPrerequisitesInstructionKind } from 'utils/instructions/prerequisites/types';

export interface PrerequisiteEntryContainerProps {
  instructionKind: FlagPrerequisitesInstructionKind;
  flagKey: string;
  projKey: string;
  envKey: string;
  variationId?: string;
}

function PrerequisiteEntryRender({
  flagKey,
  projKey,
  envKey,
  instructionKind,
  variationId,
}: PrerequisiteEntryContainerProps) {
  const {
    data: prerequisiteFlag,
    isFetching,
    error,
  } = useQuery({
    ...getFeatureFlagQuery({
      projectKey: projKey,
      featureFlagKey: flagKey,
      query: {
        env: [envKey],
      },
    }),
    select(data) {
      return createFlag(fromJS(data));
    },
  });

  if (isFetching) {
    return <ProgressBar aria-label="Loading…" isIndeterminate />;
  }
  if (prerequisiteFlag) {
    return (
      <PrerequisiteEntry
        instructionKind={instructionKind}
        variationId={variationId}
        prerequisiteFlag={prerequisiteFlag}
      />
    );
  }

  // We don't anticipate data to be undefined with no error, but we'll handle it just in case
  if (error || !prerequisiteFlag) {
    throw error || new Error('No data');
  }
  // We don't ever anticipate hitting this, but we'll return null just in case
  return null;
}

export function PrerequisiteEntryContainer(props: PrerequisiteEntryContainerProps) {
  return (
    <ErrorBoundary
      severity="medium"
      fallbackRender={({ error }) => (
        <Alert variant="inline" status="error">
          {error.message}
        </Alert>
      )}
    >
      <PrerequisiteEntryRender {...props} />
    </ErrorBoundary>
  );
}
