import {
  type AddClausesToRuleInstruction,
  type AddExpiringTargetInstruction,
  type AddRuleInstruction,
  type AddValuesToClauseInstruction,
  type RemoveClausesFromRuleInstruction,
  type RemoveExpiringTargetInstruction,
  type RemoveRuleInstruction,
  type RemoveValuesFromClauseInstruction,
  type ReorderRulesInstruction,
  type SegmentSemanticInstruction,
  type UpdateClauseInstruction,
  type UpdateExpiringTargetInstruction,
  type UpdateRuleDescriptionInstruction,
  type UpdateRuleRolloutInstruction,
  AddBigSegmentIncludedTargetsInstruction,
  ProcessBigSegmentImportInstruction,
  RemoveBigSegmentIncludedTargetsInstruction,
} from '@gonfalon/openapi';

import { SegmentPendingChangesActionsType } from './actions';
import { isExpiringTargetInstruction, isSameTarget, makeUpdatedInstructions } from './makeUpdatedInstructions';
import { insertAddRuleInstruction, makeUpdatedInstructionsForNewRule } from './makeUpdatedInstructionsForNewRule';

export type PendingChangesState = {
  instructions: SegmentSemanticInstruction[];
};

export function pendingChangesReducer(state: PendingChangesState, action: SegmentPendingChangesActionsType) {
  switch (action.type) {
    case 'ADD_BIG_SEGMENT_INCLUDED_TARGETS':
      const addBigSegmentIncludedTargetsInstruction: AddBigSegmentIncludedTargetsInstruction = {
        kind: 'addBigSegmentIncludedTargets',
        values: action.values,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, addBigSegmentIncludedTargetsInstruction),
      };
    case 'ADD_CLAUSES_TO_RULE':
      const addClausesToRuleInstruction: AddClausesToRuleInstruction = {
        kind: 'addClauses',
        ruleId: action.ruleId,
        clauses: action.clauses,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, addClausesToRuleInstruction),
      };
    case 'ADD_EXPIRING_TARGET':
      const addExpiringTargetInstruction = {
        kind: 'addExpiringTarget',
        contextKind: action.contextKind,
        contextKey: action.contextKey,
        targetType: action.targetType,
        value: action.value,
      } as AddExpiringTargetInstruction;

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, addExpiringTargetInstruction),
      };
    case 'ADD_RULE':
      const addRuleInstruction: AddRuleInstruction = {
        kind: 'addRule',
        ...action.rule,
        beforeRuleId: action.beforeRuleId,
      };
      return {
        ...state,
        instructions: insertAddRuleInstruction(state.instructions, addRuleInstruction, action.beforeRuleKey),
      };
    case 'ADD_VALUES_TO_CLAUSE':
      const addValuesToClauseInstruction: AddValuesToClauseInstruction = {
        kind: 'addValuesToClause',
        ruleId: action.ruleId,
        clauseId: action.clauseId,
        values: action.values,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, addValuesToClauseInstruction),
      };
    case 'CHANGE_RULE_DESCRIPTION':
      if (action.isNewRule) {
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            description: action.description,
          }),
        };
      }
      const updateRuleDescriptionInstruction: UpdateRuleDescriptionInstruction = {
        kind: 'updateRuleDescription',
        description: action.description,
        ruleId: action.ruleId,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, updateRuleDescriptionInstruction),
      };
    case 'CHANGE_RULE_ROLLOUT':
      if (action.isNewRule) {
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            weight: action.weight,
          }),
        };
      }
      const updateRolloutWeightInstruction: UpdateRuleRolloutInstruction = {
        kind: 'updateRuleRolloutAndContextKind',
        ruleId: action.ruleId,
        weight: action.weight,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, updateRolloutWeightInstruction),
      };
    case 'CHANGE_RULE_ROLLOUT_CONTEXT_KIND':
      if (action.isNewRule) {
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            contextKind: action.contextKind,
          }),
        };
      }

      const updateRolloutContextKindInstruction: UpdateRuleRolloutInstruction = {
        kind: 'updateRuleRolloutAndContextKind',
        ruleId: action.ruleId,
        contextKind: action.contextKind,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, updateRolloutContextKindInstruction),
      };
    case 'EDIT_CLAUSE':
      if (action.isNewRule) {
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            clauseToUpdate: action.clause,
          }),
        };
      }
      return { ...state };
    case 'PROCESS_BIG_SEGMENT_IMPORT':
      const processBigSegmentImportInstruction: ProcessBigSegmentImportInstruction = {
        kind: 'processBigSegmentImport',
        importId: action.importId,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, processBigSegmentImportInstruction),
      };
    case 'REMOVE_BIG_SEGMENT_INCLUDED_TARGETS':
      const removeBigSegmentIncludedTargetsInstruction: RemoveBigSegmentIncludedTargetsInstruction = {
        kind: 'removeBigSegmentIncludedTargets',
        values: action.values,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, removeBigSegmentIncludedTargetsInstruction),
      };
    case 'REMOVE_CLAUSES_FROM_RULE':
      const removeClausesFromRuleInstruction: RemoveClausesFromRuleInstruction = {
        kind: 'removeClauses',
        ruleId: action.ruleId,
        clauseIds: action.clauseIds,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, removeClausesFromRuleInstruction),
      };
    case 'REMOVE_EXPIRING_TARGET':
      const removeExpiringTargetInstruction = {
        kind: 'removeExpiringTarget',
        contextKind: action.contextKind,
        contextKey: action.contextKey,
        targetType: action.targetType,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(
          state.instructions,
          removeExpiringTargetInstruction as RemoveExpiringTargetInstruction,
        ),
      };
    case 'REMOVE_RULE':
      const existingRuleToAddIdx = state.instructions.findIndex(
        (instruction) => instruction.kind === 'addRule' && instruction._key === action.ruleId,
      );
      if (existingRuleToAddIdx !== -1) {
        return { ...state, instructions: state.instructions.toSpliced(existingRuleToAddIdx, 1) };
      }
      const removeRuleInstruction = {
        kind: 'removeRule',
        ruleId: action.ruleId,
      } as RemoveRuleInstruction;
      return { ...state, instructions: state.instructions.concat(removeRuleInstruction) };
    case 'REMOVE_VALUES_FROM_CLAUSE':
      const removeValuesFromClauseInstruction: RemoveValuesFromClauseInstruction = {
        kind: 'removeValuesFromClause',
        ruleId: action.ruleId,
        clauseId: action.clauseId,
        values: action.values,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, removeValuesFromClauseInstruction),
      };
    case 'REORDER_RULES':
      const reorderRulesInstruction = {
        kind: 'reorderRules',
        ruleIds: action.ruleIds,
      } as ReorderRulesInstruction;
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, reorderRulesInstruction),
      };
    case 'RESET_ALL_INSTRUCTIONS':
      return { instructions: [] as SegmentSemanticInstruction[] };
    case 'RESET_RULE_INSTRUCTIONS':
      const instructionsToKeep = resetRuleInstructions(state, action);
      return { ...state, instructions: instructionsToKeep };
    case 'RESET_RULE_ROLLOUT':
      if (action.isNewRule) {
        return {
          ...state,
          instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleId, {
            resetRollout: action.resetRollout,
          }),
        };
      }
      const resetRolloutInstruction: UpdateRuleRolloutInstruction = {
        kind: 'updateRuleRolloutAndContextKind',
        ruleId: action.ruleId,
        resetRollout: action.resetRollout,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, resetRolloutInstruction),
      };
    case 'SAVE':
      return {
        ...state,
        originalSegment: action.currentSegment,
        instructions: [] as SegmentSemanticInstruction[],
      };
    case 'UPDATE_CLAUSE':
      const updateClauseInstruction: UpdateClauseInstruction = {
        kind: 'updateClause',
        ruleId: action.ruleId,
        clauseId: action.clauseId,
        clause: action.clause,
      };
      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, updateClauseInstruction),
      };
    case 'UNDO_EXPIRING_TARGET':
      const instructionToUndoIdx = state.instructions.findIndex(
        (instruction) => isExpiringTargetInstruction(instruction) && isSameTarget(instruction, action),
      );
      if (instructionToUndoIdx !== -1) {
        const instructionsAfterRemoval = state.instructions;
        instructionsAfterRemoval.splice(instructionToUndoIdx, 1);
        return { ...state, instructions: instructionsAfterRemoval };
      }
      return { ...state };
    case 'UPDATE_ADD_RULE':
      return {
        ...state,
        instructions: makeUpdatedInstructionsForNewRule(state.instructions, action.ruleKey, {
          beforeRuleId: action.beforeRuleId,
          beforeRuleKey: action.beforeRuleKey,
          resetBeforeRule: action.beforeRuleId === undefined,
        }),
      };
    case 'UPDATE_EXCLUDED_TARGETS':
      const excludedTargetsInstruction = {
        kind: action.kind,
        values: action.targets,
        contextKind: action.contextKind,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, excludedTargetsInstruction),
      };
    case 'UPDATE_EXCLUDED_USERS':
      const excludedUsersInstruction = {
        kind: action.kind,
        values: action.targets,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, excludedUsersInstruction),
      };
    case 'UPDATE_EXPIRING_TARGET':
      const updateExpiringTargetInstruction = {
        kind: 'updateExpiringTarget',
        contextKind: action.contextKind,
        contextKey: action.contextKey,
        targetType: action.targetType,
        value: action.value,
        version: action.version,
      } as UpdateExpiringTargetInstruction;

      // check if a corresponding remove expiring target instruction exists
      const existingRemoveExpiringTargetIdx = state.instructions.findIndex(
        (instruction) =>
          instruction.kind === 'removeExpiringTarget' && isSameTarget(instruction, updateExpiringTargetInstruction),
      );

      const updatedInstructionsForUpdateExpiringTarget = state.instructions;
      if (existingRemoveExpiringTargetIdx !== -1) {
        updatedInstructionsForUpdateExpiringTarget.splice(existingRemoveExpiringTargetIdx, 1);
      }

      return {
        ...state,
        instructions: makeUpdatedInstructions(
          updatedInstructionsForUpdateExpiringTarget,
          updateExpiringTargetInstruction,
        ),
      };
    case 'UPDATE_INCLUDED_TARGETS':
      const includedTargetsInstruction = {
        kind: action.kind,
        values: action.targets,
        contextKind: action.contextKind,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, includedTargetsInstruction),
      };
    case 'UPDATE_INCLUDED_USERS':
      const includedUsersInstruction = {
        kind: action.kind,
        values: action.targets,
      };

      return {
        ...state,
        instructions: makeUpdatedInstructions(state.instructions, includedUsersInstruction),
      };
    default:
      return state;
  }
}

const resetRuleInstructions = (
  state: PendingChangesState,
  action: Extract<SegmentPendingChangesActionsType, { type: 'RESET_RULE_INSTRUCTIONS' }>,
) => {
  const addRuleInstructionIndex = state.instructions.findIndex((i) => i.kind === 'addRule' && action.ruleId === i._key);

  if (addRuleInstructionIndex !== -1) {
    return state.instructions.toSpliced(addRuleInstructionIndex, 1);
  }

  return state.instructions.filter((instruction) => {
    if ('ruleId' in instruction) {
      return action.ruleId !== instruction.ruleId;
    } else {
      return true;
    }
  });
};
