import { Map } from 'immutable';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { TeamsAction } from 'actions/teams';
import { GlobalState } from 'reducers';
import { createRequestReducer } from 'reducers/createRequestReducer';
import paginate from 'reducers/paginate';
import registry from 'reducers/registry';
import { searchSelector } from 'reducers/router';
import { createTeamsBulkAddMembersResponse, TeamsBulkAddMembersResponse } from 'utils/teamsBulkAddMembersUtils';
import { createTeam, createTeamsFiltersFromQuery, Team } from 'utils/teamsUtils';

import {
  handleAddMaintainersToTeamDone,
  handleRemoveMaintainerFromTeamDone,
  handleRequestMoreTeamMaintainersDone,
} from './teams/teamMaintainerHandlers';

export const teamsEntities = (state: Map<string, Team> = Map(), action: TeamsAction): Map<string, Team> => {
  switch (action.type) {
    case 'teams/DELETE_TEAM_DONE':
      return state.delete(action.team.key);

    case 'teams/RECEIVE_TEAMS':
      if (action.response && action.response.hasIn(['entities', 'teams'])) {
        return action.response.getIn(['entities', 'teams']);
      }
      return state;
    default:
      return state;
  }
};

export const teamEntity = (state: Team = createTeam({}), action: TeamsAction) => {
  switch (action.type) {
    case 'teams/RECEIVE_TEAM':
    case 'teams/UPDATE_TEAM_DONE':
    case 'teams/ADD_TEAM_MEMBERS_DONE':
    case 'teams/REMOVE_MEMBER_FROM_TEAM_DONE':
      if (action.team) {
        return action.team;
      }
      return state;
    case 'teams/REQUEST_MORE_TEAM_MAINTAINERS':
      return state.setIn(['maintainers', '_isFetching'], true);

    case 'teams/REQUEST_MORE_TEAM_MAINTAINERS_FAILED':
      return state.setIn(['maintainers', '_isFetching'], false);

    case 'teams/ADD_MAINTAINERS_TO_TEAM_DONE':
      return handleAddMaintainersToTeamDone(state, action);

    case 'teams/REMOVE_MAINTAINER_FROM_TEAM_DONE':
      return handleRemoveMaintainerFromTeamDone(state, action);

    case 'teams/REQUEST_MORE_TEAM_MAINTAINERS_DONE':
      return handleRequestMoreTeamMaintainersDone(state, action);

    default:
      return state;
  }
};

export const teamForAccessEntity = (state: Team = createTeam({}), action: TeamsAction) => {
  if (action.type === 'teams/RECEIVE_TEAM_ACCESS_CHECK') {
    if (
      action.response &&
      action.response.hasIn(['entities', 'teams']) &&
      !action.response.getIn(['entities', 'teams']).isEmpty()
    ) {
      return action.response.getIn(['entities', 'teams']).first();
    }
  }
  return state;
};

export const teamsBulkAddMembersResponse = (
  state: TeamsBulkAddMembersResponse = createTeamsBulkAddMembersResponse({}),
  action: TeamsAction,
) => {
  switch (action.type) {
    case 'teams/BULK_ADD_TEAM_MEMBERS_DONE':
      if (action.response) {
        return action.response;
      }
      return state;
    case 'teams/BULK_ADD_TEAM_MEMBERS_FAILED':
    case 'teams/BULK_ADD_TEAM_MEMBERS_RESET':
      return createTeamsBulkAddMembersResponse({});
    default:
      return state;
  }
};

const request = createRequestReducer(['teams/REQUEST_TEAMS', 'teams/RECEIVE_TEAMS', 'teams/REQUEST_TEAMS_FAILED']);
const teamByKeyRequest = createRequestReducer([
  'teams/REQUEST_TEAM',
  'teams/RECEIVE_TEAM',
  'teams/REQUEST_TEAM_FAILED',
]);
const teamForAccessRequest = createRequestReducer([
  'teams/REQUEST_TEAM_ACCESS_CHECK',
  'teams/RECEIVE_TEAM_ACCESS_CHECK',
  'teams/REQUEST_TEAM_ACCESS_CHECK_FAILED',
]);
const teamsBulkAddMembersRequest = createRequestReducer([
  'teams/BULK_ADD_TEAM_MEMBERS',
  'teams/BULK_ADD_TEAM_MEMBERS_DONE',
  'teams/BULK_ADD_TEAM_MEMBERS_FAILED',
]);
const teamsPagination = paginate({
  types: ['teams/REQUEST_TEAMS', 'teams/REQUEST_TEAMS_FAILED', 'teams/RECEIVE_TEAMS'],
  invalidateTypes: ['teams/DELETE_TEAM_DONE', 'teams/CREATE_TEAM_DONE'],
  mapActionToKey: () => 'all',
});

export const teams = combineReducers({
  entities: teamsEntities,
  pagination: teamsPagination,
  request,
  teamByKeyRequest,
  teamEntity,
  teamForAccessEntity,
  teamForAccessRequest,
  teamsBulkAddMembersRequest,
  teamsBulkAddMembersResponse,
});

export const teamsSelector = (state: GlobalState) => state.teams;
export const teamsEntitiesSelector = (state: GlobalState) => teamsSelector(state).entities;
export const teamsRequestSelector = (state: GlobalState) => teamsSelector(state).request;
export const teamsListSelector = (state: GlobalState) => teamsEntitiesSelector(state).toList();

export const teamsPaginationSelector = (state: GlobalState) => teamsSelector(state).pagination.get('all');

export const teamEntitySelector = (state: GlobalState) => teamsSelector(state).teamEntity;
export const teamByKeyRequestSelector = (state: GlobalState) => teamsSelector(state).teamByKeyRequest;

export const teamForAccessCheckSelector = (state: GlobalState) => teamsSelector(state).teamForAccessEntity;
export const teamForAccessCheckRequestSelector = (state: GlobalState) => teamsSelector(state).teamForAccessRequest;

export const teamsBulkAddMembersRequestSelector = (state: GlobalState) =>
  teamsSelector(state).teamsBulkAddMembersRequest;
export const teamsBulkAddMembersResponseSelector = (state: GlobalState) =>
  teamsSelector(state).teamsBulkAddMembersResponse;

export const teamsFilterSelector = createSelector(searchSelector, (query) => createTeamsFiltersFromQuery(query));

registry.addReducers({ teams });
