import { useProjectContext } from '@gonfalon/context';
import dayjs from 'dayjs';

import { getEventsAPI } from '../../../app/apis/events';
import { eventsQueryKeys } from '../../../app/store/queryKeyFactory';
import { useGetAllSnowflakeIntegrations } from '../../../hooks/useGetAllSnowflakeIntegrations';
import { useProjectEnvAwareQuery } from '../../../hooks/useProjectEnvAwareQueryHooks';
import { EventsData } from '../../../types/types';

type AllEventsQueryResult = { data: EventsData; isLoading: boolean; isError: boolean; error?: unknown };

type AllEventsQueryOptions = { enable?: boolean };

export default function useAllEventsMetaData(options?: AllEventsQueryOptions): AllEventsQueryResult {
  const { project, selectedEnvironment } = useProjectContext();
  const enabled = options?.enable ?? true;

  // First check integration status
  const { data: integrations } = useGetAllSnowflakeIntegrations({ enabled });
  const hasIntegration = integrations?.some(
    (i) => i.project_key === project.key && i.environment_key === selectedEnvironment.key,
  );

  const {
    data: eventMetadata,
    isLoading: tempAreEventsLoading,
    fetchStatus: eventsFetchStatus,
    isError: isErrorLoadingEventsData,
    error,
  } = useProjectEnvAwareQuery({
    queryKey: eventsQueryKeys.all(),
    throwOnError: false,
    queryFn: async ({ getFullUrl }) => {
      const data = await getEventsAPI({ getFullUrl });
      return data;
    },
    meta: {
      silentOnError: true,
      sentryMessage: 'Network request failure: get Events metadata',
      errorMessage: 'Something went wrong while loading events, please try refreshing the page',
    },
    retry: (failureCount, apiError: any) => {
      // Don't retry on 404 (data being prepared)
      if (apiError?.status === 404) {
        return false;
      }
      // Retry other errors once
      return failureCount < 1;
    },
    enabled: enabled && hasIntegration,
  });

  const data: EventsData = eventMetadata;

  dayjs.tz.setDefault(data?.event_time_zone);

  return {
    data,
    isLoading: tempAreEventsLoading && eventsFetchStatus === 'fetching',
    isError: isErrorLoadingEventsData,
    error,
  };
}
