import { isMobileContextKind } from '@gonfalon/context-kinds';
import { disableBuiltInContextNameAttributes, isMobileAppTargetingEnabled } from '@gonfalon/dogfood-flags';
import { GroupedOption, OptionProps } from '@gonfalon/launchpad-experimental';
import { toContext, toHref } from '@gonfalon/navigator';
import { List, Map, OrderedSet, Set } from 'immutable';
import nullthrows from 'nullthrows';

import { getContextAttributeValue, getContextKey, getContextKind } from 'components/Contexts/utils/contextListUtils';
import {
  APP_VERSION_SUPPORT_STATUS_ATTRIBUTE,
  APPLICATION_CONTEXT_KIND,
  CONTEXT_KIND_ATTRIBUTE,
  DEVICE_CONTEXT_KIND,
  HIDDEN_MOBILE_ATTRIBUTES,
  USER_CONTEXT_KIND,
} from 'utils/constants';
import { Target } from 'utils/flagUtils';
import { SegmentTarget } from 'utils/segmentUtils';
import {
  builtinAttributes as userBuiltinAttributes,
  DisplayableUser,
  getDisplayName,
  mobileAttributesMapping,
  standardAttributesMapping,
  UserAttributesProps,
} from 'utils/userAttributeUtils';
import { createUser } from 'utils/userUtils';

import {
  ContextAttributesName,
  ContextAttributesNames,
  ContextAttributesValues,
  ContextItem,
  ContextItemsEntities,
  ContextKind,
  ContextResponse,
  ContextTargetKindKey,
  Links,
  ResolveContextsGroups,
  ResolveContextsResponse,
} from '../types';
import { LDContext } from '../types/LDContext';

import AttributeReference from './AttributeUtils';

const contextsBuilIntAttributes = ['key'];
const enableMobileTargeting = isMobileAppTargetingEnabled();

export const getContextDisplayName = (context: LDContext) => {
  const displayableUser: DisplayableUser = {
    key: getContextKey(context),
    email: context?.email,
    name: getContextAttributeValue(context, 'name'),
    firstName: context?.firstName,
    lastName: context?.lastName,
  };
  return getDisplayName(displayableUser);
};

export const contextHasName = (context: LDContext) =>
  !!(context.name || (context.firstName && context.lastName) || context.email);

export const makeSelectOptionsForContextAttributeValues = (
  contextAttributesValuesList: ContextAttributesValues[],
  contextKind: string,
) => {
  const options: OptionProps[] = [];
  const attributeValuesForContextKind = contextAttributesValuesList.find((c) => c.kind === contextKind);
  attributeValuesForContextKind?.values.forEach((v) => {
    const option = {
      label: v.name.toString(),
      value: v.name,
    };
    options.push(option);
  });

  return options;
};

const mergeBuiltInAttributes = (contextKind: string, attributes: ContextAttributesName[]) => {
  if (disableBuiltInContextNameAttributes()) {
    return attributes;
  }
  const mergedAttributes = [...attributes];
  const builtinAttributes = contextKind === USER_CONTEXT_KIND ? userBuiltinAttributes : contextsBuilIntAttributes;
  builtinAttributes
    .filter((attr) => attr !== 'avatar' && attr !== 'secondary')
    .forEach((builtinAttribute) => {
      if (!attributes.some((attribute) => attribute.name === builtinAttribute)) {
        mergedAttributes.push({ name: builtinAttribute, count: 1 });
      }
    });
  return mergedAttributes.sort((a, b) => a.name.localeCompare(b.name));
};

export const makeSelectOptionsForContextKinds = (contextKinds: ContextKind[]) => {
  const options: OptionProps[] = [];

  contextKinds.forEach((c) => {
    options.push({
      label: c.name !== '' ? c.name : c.key,
      value: c.key,
    });
  });

  return options;
};

export const makeSelectAttributeOptionsForContextKind = (
  contextKindKey: string | null | undefined,
  formValue: string | null | undefined,
  contextAttributesNamesList: ContextAttributesNames[],
) => {
  if (!contextKindKey) {
    return [];
  }

  const existingAttributes = contextAttributesNamesList.find((value) => value.kind === contextKindKey);
  let attributeNames: ContextAttributesName[] = [];

  if (existingAttributes) {
    attributeNames = existingAttributes.names;
  }

  const formatContextAttribute = (attribute: string) => new AttributeReference(attribute).toString();
  const attributes = mergeBuiltInAttributes(contextKindKey, attributeNames);
  const isMobileAtttribute = enableMobileTargeting && isMobileContextKind(contextKindKey);
  const attributeOptions = attributes.map((attribute) => {
    const option: OptionProps = {
      label: standardAttributesMapping[attribute.name] || formatContextAttribute(attribute.name),
      value: attribute.name,
      contextKind: contextKindKey,
    };

    if (attribute.redacted) {
      option.redacted = true;
    }
    return option;
  });

  // include the form value in the options if it's not already in the options
  if (formValue && !attributeOptions.some((option) => option.value === formValue)) {
    attributeOptions.push({
      label: formValue,
      value: formValue,
      contextKind: contextKindKey,
    });
  }

  // Always include 'key' in the options. Ensure it is not already in the options.
  if (!attributeOptions.some((option) => option.value === 'key')) {
    attributeOptions.push({
      label: 'Key',
      value: 'key',
      contextKind: contextKindKey,
    });
  }
  let options = attributeOptions;

  if (isMobileAtttribute) {
    options = options
      .filter((option) => !HIDDEN_MOBILE_ATTRIBUTES.includes(option.value))
      .map((option) => {
        // additional mapping for mobile attributes
        if (isMobileAtttribute) {
          return {
            ...option,
            label: mobileAttributesMapping[option.value] || formatContextAttribute(option.value),
          };
        }
        return option;
      });
  }

  const addVersionSupportStatusOption =
    // if the option doesn't already exist and the flag is enabled
    !attributeOptions.some((option) => option.value.toLowerCase() === APP_VERSION_SUPPORT_STATUS_ATTRIBUTE) &&
    contextKindKey === APPLICATION_CONTEXT_KIND;

  if (addVersionSupportStatusOption) {
    options.push({
      label: 'Version support status',
      value: APP_VERSION_SUPPORT_STATUS_ATTRIBUTE,
      contextKind: APPLICATION_CONTEXT_KIND,
    });
  }

  return options;
};

export const augmentResolveContextsResponse = (response: ContextResponse): ResolveContextsResponse => {
  const entities = response.items.reduce((accum, item) => {
    const kindKeyString = makeKindKeyStringFromProps(item.context.kind, getContextKey(item.context));
    return { ...accum, [kindKeyString]: item };
  }, {});
  return { ...response, entities };
};

export const getContextsWithMissingNames = (contextsResponse: ContextResponse) => {
  const c = contextsResponse.items;
  const missingContextNames = c
    .filter((item) => !item.context?.name)
    .map((item) => `${item.context.kind}:${item.context.key}`);
  return missingContextNames;
};

export const monkeyPatchContextItemsWithNames = (
  contextsResponse: ContextResponse,
  contextsResponseWithNames: ContextResponse,
) => {
  const updatedItems = contextsResponse.items.map((contextItem) => {
    const contextItemWithNames = contextsResponseWithNames.items.find(
      (item) => item.context.kind === contextItem.context.kind && item.context.key === contextItem.context.key,
    );
    if (!contextItemWithNames) {
      return contextItem;
    } else {
      return {
        ...contextItem,
        context: {
          ...contextItem.context,
          name: contextItemWithNames.context?.name,
        },
      } as ContextItem;
    }
  });
  const responseWithNames = {
    ...contextsResponse,
    items: updatedItems,
  };
  return responseWithNames;
};

export const getContextItemSiteLink = (contextItem: ContextItem, projKey: string, envKey: string) =>
  toContext({
    projectKey: projKey,
    environmentKey: envKey,
    kind: contextItem.context.kind,
    key: String(nullthrows(contextItem.context.key)),
  });

export const createUserFromContextItem = (contextItem: ContextItem, projKey: string, envKey: string) => {
  const siteLink = { href: toHref(getContextItemSiteLink(contextItem, projKey, envKey)), type: 'text/html' };
  const links: Links = { ...contextItem._links, site: contextItem._links.site || siteLink };

  const contextAttributes = contextItem.context;
  const attributes = userBuiltinAttributes.reduce((accum, builtIn) => {
    const builtInValue = contextAttributes[builtIn];
    if (builtInValue) {
      return { ...accum, [builtIn]: builtInValue };
    } else {
      return accum;
    }
  }, {} as UserAttributesProps);

  const customAttributes = Map(
    Object.keys(contextAttributes).reduce(
      (accum, attribute) => {
        if (!userBuiltinAttributes.includes(attribute)) {
          return { ...accum, [attribute]: contextAttributes[attribute] };
        } else {
          return accum;
        }
      },
      {} as Map<string, string | List<string>>,
    ),
  );

  attributes.custom = customAttributes;

  return createUser({ _links: links, attributes });
};

const getMatchedKindKeyFromEntities = (
  entities: ContextItemsEntities,
  requestedKindKeyOrEmail: ContextTargetKindKey,
): ContextTargetKindKey => {
  const userMatchByEmail =
    requestedKindKeyOrEmail.kind === USER_CONTEXT_KIND
      ? Object.values(entities).find(
          (entity) => entity.context.kind === USER_CONTEXT_KIND && entity.context.email === requestedKindKeyOrEmail.key,
        )
      : undefined;

  return userMatchByEmail
    ? { kind: getContextKind(userMatchByEmail.context), key: getContextKey(userMatchByEmail.context) }
    : requestedKindKeyOrEmail;
};

export const groupResolveContextsResponse = (
  entities: ContextItemsEntities,
  allRequestedTargets: OrderedSet<ContextTargetKindKey>,
): ResolveContextsGroups => {
  const allTargetKindKeys = allRequestedTargets.map((requestedKindKeyOrEmail) =>
    getMatchedKindKeyFromEntities(entities, requestedKindKeyOrEmail),
  );
  const found = allTargetKindKeys.filter((k) => entities.hasOwnProperty(makeKindKeyStringFromKindKey(k)));
  const notFound = allTargetKindKeys.filter((k) => !entities.hasOwnProperty(makeKindKeyStringFromKindKey(k)));
  return { all: allTargetKindKeys, found, notFound };
};

export const convertResolveContextsGroupsForUsers = (groups: ResolveContextsGroups) => ({
  all: groups.all.filter((t) => t.kind === USER_CONTEXT_KIND).map((t) => t.key),
  found: groups.found.filter((t) => t.kind === USER_CONTEXT_KIND).map((t) => t.key),
  notFound: groups.notFound.filter((t) => t.kind === USER_CONTEXT_KIND).map((t) => t.key),
});

export const makeKindKeyStringFromKindKey = (kindKey: ContextTargetKindKey) => `${kindKey.kind}:${kindKey.key}`;
export const makeKindKeyStringFromProps = (contextKind: string, key: string) => {
  const kindKey = {
    kind: contextKind,
    key,
  };
  return makeKindKeyStringFromKindKey(kindKey);
};

export const convertTargetsToKindKeyList = (
  targets: List<Target> | List<SegmentTarget>,
): List<ContextTargetKindKey> => {
  let kindKeys: List<ContextTargetKindKey> = List();
  targets.forEach((target) =>
    target.values.forEach((key) => (kindKeys = kindKeys.push({ kind: target.getContextKind(), key }))),
  );
  return kindKeys;
};

export const hasKindKeyInList = (
  kindKeysList: List<ContextTargetKindKey> | Set<ContextTargetKindKey>,
  targetKindKey: ContextTargetKindKey,
) => kindKeysList.some((t) => makeKindKeyStringFromKindKey(t) === makeKindKeyStringFromKindKey(targetKindKey));

export const subtractKindKeysList = (
  kindKeysList: OrderedSet<ContextTargetKindKey>,
  exclusionList: OrderedSet<ContextTargetKindKey>,
) =>
  kindKeysList.filter(
    (target) =>
      !exclusionList.some(
        (exclusionTarget) => makeKindKeyStringFromKindKey(exclusionTarget) === makeKindKeyStringFromKindKey(target),
      ),
  );

export const intersectKindKeysList = (
  kindKeysList: OrderedSet<ContextTargetKindKey>,
  intersectingList: OrderedSet<ContextTargetKindKey>,
) => {
  const processedKindKeys: { [key: string]: { timesProcessed: number; kindKey: ContextTargetKindKey } } = {};
  kindKeysList.forEach((target) => {
    const kindKeyString = makeKindKeyStringFromKindKey(target);
    processedKindKeys[kindKeyString] = { timesProcessed: 1, kindKey: target };
  });
  intersectingList.forEach((target) => {
    const kindKeyString = makeKindKeyStringFromKindKey(target);
    if (processedKindKeys.hasOwnProperty(kindKeyString)) {
      processedKindKeys[kindKeyString].timesProcessed = 2;
    } else {
      processedKindKeys[kindKeyString] = { timesProcessed: 1, kindKey: target };
    }
  });

  let intersectedList: OrderedSet<ContextTargetKindKey> = OrderedSet();
  Object.keys(processedKindKeys).forEach((keyStr) => {
    if (processedKindKeys[keyStr].timesProcessed > 1) {
      intersectedList = intersectedList.add(processedKindKeys[keyStr].kindKey);
    }
  });

  return intersectedList;
};

export const getContextKindDisplayName = (contextKinds: ContextKind[], contextKindKey: string) => {
  const contextKind = contextKinds.find((ck) => ck.key === contextKindKey);
  return contextKind?.name || contextKindKey;
};

export const createMockContextItem = (context: LDContext): ContextItem => {
  const date = new Date('2018-07-01');
  const link = {
    href: '/api/v2/projects/default/environments/production',
    type: 'application/json',
  };
  const links = {
    parent: link,
    self: link,
    site: link,
  };
  return { lastSeen: date, applicationId: 'NodeJSClient', _links: links, context };
};

export const makeSelectContextKindOptions = ({
  contextKinds,
  evaluatedContextKindKeys,
}: {
  contextKinds: ContextKind[];
  evaluatedContextKindKeys: List<string>;
}): { groupedOptions: true; options: GroupedOption[] } | { groupedOptions: false; options: OptionProps[] } => {
  const generalOptions: OptionProps[] = [];
  generalOptions.unshift({ label: 'Context kind', value: CONTEXT_KIND_ATTRIBUTE });

  const formatContextKindLabel = (contextKind: ContextKind) => {
    if (!enableMobileTargeting) {
      return contextKind.name;
    } else {
      switch (contextKind.key) {
        case DEVICE_CONTEXT_KIND:
          return 'Device';
        case APPLICATION_CONTEXT_KIND:
          return 'Application';
        default:
          return contextKind.name;
      }
    }
  };

  const allContextKindOptions: OptionProps[] = contextKinds.map((k: ContextKind) => ({
    label: formatContextKindLabel(k),
    value: k.key,
  }));

  const legacyOptions = [...generalOptions, ...allContextKindOptions];

  const evaluatedContextKindOptions: OptionProps[] = allContextKindOptions.filter((o) =>
    evaluatedContextKindKeys.contains(o.value),
  );

  const unevaluatedContextKindOptions: OptionProps[] = allContextKindOptions.filter(
    (o) => !evaluatedContextKindKeys.contains(o.value),
  );

  const useGroups = hasMultipleGroups(generalOptions, evaluatedContextKindOptions, unevaluatedContextKindOptions);
  if (useGroups) {
    const generalOptionsGroup: GroupedOption = { label: '', options: generalOptions };
    const evaluatedContextKindsGroup: GroupedOption = {
      label: 'Recently seen context kinds',
      options: evaluatedContextKindOptions,
    };
    const unevaluatedLabel = evaluatedContextKindsGroup.options.length
      ? 'Other available context kinds'
      : 'Context kinds';
    const unevaluatedContextKindsGroup: GroupedOption = {
      label: unevaluatedLabel,
      options: unevaluatedContextKindOptions,
    };
    return {
      groupedOptions: true,
      options: [generalOptionsGroup, evaluatedContextKindsGroup, unevaluatedContextKindsGroup].filter(
        (group) => group.options.length > 0,
      ),
    };
  }

  return { groupedOptions: false, options: legacyOptions };
};

const hasMultipleGroups = (...optionLists: OptionProps[]) => {
  let count = 0;
  for (const list of optionLists) {
    if (list.length > 0) {
      count++;
    }
    if (count > 1) {
      return true;
    }
  }
  return false;
};
