import { type UIFlagFilter } from '@gonfalon/flag-filters';

export const flagProjectFilters = [
  // We ignore 'query' because it's not captured in the filter dialog and we don't
  // want to count it as an active filter.
  // 'query',
  'applicationEvaluated',
  'codeReferences',
  'contextKindsEvaluated',
  'contextKindTargeted',
  'created',
  'excludeSettings',
  'maintainerId',
  'maintainerTeamKey',
  'releasePipeline',
  'sdkAvailability',
  'state',
  'tags',
  'type',
  'guardedRollout',
] as const;

export type UIFlagProjectFilter = Pick<UIFlagFilter, (typeof flagProjectFilters)[number]>;
