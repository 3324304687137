import type { DOMNode, Element, Text } from 'html-react-parser';

export function isAnchorDOMNode(node: DOMNode): node is Element {
  const element = node as Element;
  return element?.name === 'a';
}

export function isDOMText(node: DOMNode): node is Text {
  return (node as Text)?.type === 'text';
}

/**
 *
 * parLinkFromDOMNode returns undefined if the node is not anchor node with an href attribute.
 */
export function parseLinkFromDOMNode(node: DOMNode) {
  if (!isAnchorDOMNode(node)) {
    return;
  }
  if (!('href' in node.attribs)) {
    return;
  }
  const { href } = node.attribs;
  const textNode = node.firstChild;
  if (!textNode || !isDOMText(textNode)) {
    return;
  }
  try {
    const url = new URL(href);
    return { url, text: textNode.data !== href ? textNode.data : undefined };
  } catch {
    return;
  }
}

// Regex matching the format https://HOSTNAME.atlassian.net/wiki/spaces/SPACE_ID/pages/PAGE_ID/PAGE_TITLE
const CONFLUENCE_REGEX =
  /^https:\/\/[a-zA-Z0-9.-]*\.atlassian\.net\/wiki\/spaces\/[a-zA-Z0-9+~]*\/pages\/[a-zA-Z0-9+]*\/[-a-zA-Z0-9@:%_+.~#//=]*/gm;

export const isConfluenceLink = (href: string) => {
  const confluenceLink = href.match(CONFLUENCE_REGEX);
  if (confluenceLink) {
    return true;
  }
  return false;
};
