import { Dialog, IconButton, Modal, ModalOverlay } from '@launchpad-ui/components';

import { ContactUsForm, ContactUsFormProps } from './ContactUsForm';

import styles from './styles/ContactUsForm.module.css';

export type ContactUsFormModalProps = Omit<ContactUsFormProps, 'canManagePlans' | 'profile' | 'subscription'> & {
  isOpen?: boolean;
  onCancel?(): void;
  onFormSubmit?(): void;
};

export function ContactUsModal({
  isOpen,
  onCancel,
  productInterest,
  requestedFeature,
  headerText,
  onFormSubmit,
}: ContactUsFormModalProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay isDismissable onOpenChange={onCancel} isOpen>
      <Modal className={styles.modal}>
        <Dialog>
          {({ close }) => (
            <>
              <div className={styles.close} slot="header">
                <IconButton aria-label="close modal" icon="cancel" variant="minimal" onPress={close} />
              </div>
              <ContactUsForm
                requestedFeature={requestedFeature}
                productInterest={productInterest}
                headerText={headerText}
                onFormSubmit={onFormSubmit}
              />
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
