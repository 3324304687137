import { List } from 'immutable';
import { Action, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { GlobalState } from 'reducers';
import {
  ContextTargetingExpirationUpdates,
  expiringContextTargetsForFlagRequestSelector,
  expiringContextTargetsForSegmentListRequestSelector,
} from 'reducers/expiringContextTargets';
// eslint-disable-next-line import/no-namespace
import * as ExpiringContextTargetsAPI from 'sources/ExpiringContextTargetsAPI';
import { ExpiringTargetsUrlProps } from 'sources/types/expiringContextTargetsAPI';
import {
  ContextTargetingExpirationInstructionKind,
  ExpiringContextValuesInstructionProps,
  getContextTargetingExpirationInstructionKind,
  getContextTargetingExpirationInstructionsForFlag,
  getContextTargetingExpirationInstructionsForKey,
  getDeletedWorkflowsContextAndFlagKeys,
  getDeletedWorkflowsContextAndSegmentKey,
  getExpiringContextTargetsForSegment,
  makeAddContextTargetingExpirationInstruction,
  makeRemoveContextTargetingExpirationInstruction,
  makeUpdateContextTargetingExpirationInstruction,
} from 'utils/expiringContextTargetsUtils';

function postContextTargetingExpiration(props: ExpiringContextValuesInstructionProps) {
  const { contextKind, contextKey, flagKey, updatedExpirationDate, variationId, segmentKey } = props;

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return {
    type: 'expiringContextTargets/CHANGE_CONTEXT_TARGETING_EXPIRATION',
    contextKind,
    contextKey,
    flagKey,
    updatedExpirationDate,
    variationId,
    segmentKey,
    instruction: makeAddContextTargetingExpirationInstruction(
      contextKind,
      contextKey,
      flagKey!,
      variationId!,
      updatedExpirationDate!,
    ),
  }; /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

function resetExpiringContextTargets() {
  return {
    type: 'expiringContextTargets/RESET_CONTEXT_TARGETING_EXPIRATIONS',
  };
}

function deleteContextTargetingExpiration(props: ExpiringContextValuesInstructionProps) {
  const { contextKind, contextKey, flagKey, variationId, segmentKey } = props;
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return {
    type: 'expiringContextTargets/CHANGE_CONTEXT_TARGETING_EXPIRATION',
    contextKind,
    contextKey,
    flagKey,
    segmentKey,
    updatedExpirationDate: null,
    instruction: makeRemoveContextTargetingExpirationInstruction(contextKind, contextKey, flagKey!, variationId!),
  }; /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

function patchContextTargetingExpiration(props: ExpiringContextValuesInstructionProps) {
  const { contextKind, contextKey, flagKey, updatedExpirationDate, variationId, version, segmentKey } = props;
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return {
    type: 'expiringContextTargets/CHANGE_CONTEXT_TARGETING_EXPIRATION',
    contextKind,
    contextKey,
    flagKey,
    updatedExpirationDate,
    variationId,
    segmentKey,
    instruction: makeUpdateContextTargetingExpirationInstruction(
      contextKind,
      contextKey,
      flagKey!,
      variationId!,
      updatedExpirationDate!,
      version!,
    ),
  }; /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

function undoContextTargetingExpiration(props: ExpiringContextValuesInstructionProps) {
  const { contextKind, contextKey, flagKey, segmentKey } = props;
  return {
    type: 'expiringContextTargets/CHANGE_CONTEXT_TARGETING_EXPIRATION_UNDO',
    contextKind,
    contextKey,
    flagKey,
    segmentKey,
  };
}

export type ChangeContextTargetingExpirationProps = ExpiringContextValuesInstructionProps & {
  originalExpirationDate?: number | null;
  updatedExpirationDate?: number | null;
};

export const changeContextTargetingExpiration = (props: ChangeContextTargetingExpirationProps) => {
  const {
    contextKind,
    contextKey,
    flagKey,
    originalExpirationDate,
    updatedExpirationDate,
    variationId,
    version,
    segmentKey,
  } = props;
  const instructionKind = getContextTargetingExpirationInstructionKind(originalExpirationDate, updatedExpirationDate);
  switch (instructionKind) {
    case ContextTargetingExpirationInstructionKind.REMOVE:
      return deleteContextTargetingExpiration({ contextKind, contextKey, flagKey, variationId, segmentKey });
    case ContextTargetingExpirationInstructionKind.ADD:
      return postContextTargetingExpiration({
        contextKind,
        contextKey,
        segmentKey,
        flagKey,
        updatedExpirationDate,
        variationId,
      });
    case ContextTargetingExpirationInstructionKind.UPDATE:
      return patchContextTargetingExpiration({
        contextKind,
        contextKey,
        flagKey,
        updatedExpirationDate,
        segmentKey,
        variationId,
        version,
      });
    default:
      return undoContextTargetingExpiration({ contextKind, contextKey, flagKey, segmentKey });
  }
};

const renderExpiringValuesUrl = ({
  type,
  projKey,
  envKey,
  key,
}: {
  type: string;
  projKey: string;
  envKey: string;
  key: string;
}) => `/api/v2/${type}/${projKey}/${key}/expiring-targets/${envKey}`;

const updateExpiringValues =
  ({
    contextTargetingExpirationUpdates,
    flagKey,
    contextKey,
    envKey,
    projKey,
    segmentKey,
    options = {},
  }: {
    contextTargetingExpirationUpdates: ContextTargetingExpirationUpdates;
    flagKey?: string;
    contextKey?: string;
    envKey: string;
    projKey: string;
    segmentKey?: string;
    options: { isParallelWithChanges?: boolean; comment?: string };
  }) =>
  async (dispatch: Dispatch) => {
    const { isParallelWithChanges, comment } = options;
    dispatch({ type: 'expiringContextTargets/CHANGE_CONTEXT_TARGETING_EXPIRATIONS' });

    let patches;
    let deletedKeys: List<{ contextKey: string; flagKey: string }> | List<{ contextKey: string }>;

    if (segmentKey) {
      patches = getContextTargetingExpirationInstructionsForKey(contextTargetingExpirationUpdates, segmentKey);
      deletedKeys = getDeletedWorkflowsContextAndSegmentKey(patches, segmentKey);

      const expiringValues = getExpiringContextTargetsForSegment(patches, segmentKey);

      dispatch({
        type: 'expiringContextTargets/UPDATE_CONTEXT_TARGETING_EXPIRATION_DONE',
        isParallelWithChanges,
        expiringValues,
        envKey,
        deletedKeys,
        projKey,
        segmentKey,
      });

      return;
    } else {
      if (flagKey) {
        patches = getContextTargetingExpirationInstructionsForFlag(contextTargetingExpirationUpdates, flagKey);
      } else {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        patches = getContextTargetingExpirationInstructionsForKey(
          contextTargetingExpirationUpdates,
          contextKey!,
        ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
      deletedKeys = getDeletedWorkflowsContextAndFlagKeys(patches);
    }

    let url = '';
    if (segmentKey) {
      url = renderExpiringValuesUrl({ type: 'segments', envKey, projKey, key: segmentKey });
    } else if (contextKey) {
      url = renderExpiringValuesUrl({ type: 'contexts', envKey, projKey, key: contextKey });
    } else if (flagKey) {
      url = renderExpiringValuesUrl({ type: 'flags', envKey, projKey, key: flagKey });
    }

    return ExpiringContextTargetsAPI.updateExpiringContextTargets({
      patches,
      url,
      comment,
    })
      .then((expiringValues) => {
        dispatch({
          type: 'expiringContextTargets/UPDATE_CONTEXT_TARGETING_EXPIRATION_DONE',
          isParallelWithChanges,
          expiringValues,
          flagKey,
          envKey,
          deletedKeys,
          projKey,
          segmentKey,
        });
      })
      .catch((error) =>
        dispatch({
          type: 'expiringContextTargets/UPDATE_CONTEXT_TARGETING_EXPIRATION_FAILED',
          error,
        }),
      );
  };

const shouldFetchContextTargetsForFlag = (state: GlobalState) => {
  const req = expiringContextTargetsForFlagRequestSelector(state);
  return req ? (!req.lastFetched || req.isSummaryRep) && !req.isFetching : true;
};

const fetchExpiringContextTargetsForFlagIfNeeded =
  (params: ExpiringTargetsUrlProps) =>
  async (dispatch: ThunkDispatch<$TSFixMe, void, Action>, getState: () => GlobalState) => {
    if (shouldFetchContextTargetsForFlag(getState())) {
      return dispatch(fetchExpiringContextTargetsForFlag(params));
    } else {
      return Promise.resolve();
    }
  };

const fetchExpiringContextTargetsForFlag =
  ({ flagKey, projKey, envKey }: ExpiringTargetsUrlProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: 'expiringContextTargets/FETCH_EXPIRING_TARGETS_FOR_FLAG', flagKey, projKey, envKey });
    return ExpiringContextTargetsAPI.getExpiringContextTargetsForFlag({ flagKey, projKey, envKey })
      .then((expiringValues) => {
        dispatch({
          type: 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_FLAG',
          flagKey,
          envKey,
          projKey,
          expiringValues,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_FLAG_FAILED',
          flagKey,
          envKey,
          error,
        });
      });
  };

const shouldFetchExpiringContextTargetsForSegment = (state: GlobalState) => {
  const req = expiringContextTargetsForSegmentListRequestSelector(state);
  return req ? (!req.lastFetched || req.isSummaryRep) && !req.isFetching : true;
};

const fetchExpiringContextTargetsForSegmentIfNeeded =
  (params: { segmentKey: string; projKey: string; envKey: string }) =>
  async (dispatch: ThunkDispatch<$TSFixMe, void, Action>, getState: () => GlobalState) => {
    if (shouldFetchExpiringContextTargetsForSegment(getState())) {
      return dispatch(fetchExpiringContextTargetsForSegment(params));
    } else {
      return Promise.resolve();
    }
  };

const fetchExpiringContextTargetsForSegment =
  ({ segmentKey, projKey, envKey }: { segmentKey: string; projKey: string; envKey: string }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: 'expiringContextTargets/FETCH_EXPIRING_TARGETS_FOR_SEGMENT', segmentKey, projKey, envKey });
    return ExpiringContextTargetsAPI.getExpiringContextTargetsForSegment({ segmentKey, projKey, envKey })
      .then((expiringTargetsForContext) => {
        dispatch({
          type: 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_SEGMENT',
          segmentKey,
          envKey,
          projKey,
          expiringTargetsForContext,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'expiringContextTargets/RECEIVE_EXPIRING_TARGETS_FOR_FLAG_FAILED',
          segmentKey,
          envKey,
          error,
        });
      });
  };

function discardAllExpiringTargetChanges({
  expirationUpdates,
  flagKey,
  segmentKey,
}: {
  expirationUpdates: ContextTargetingExpirationUpdates;
  flagKey?: string;
  segmentKey?: string;
}) {
  return (dispatch: Dispatch) => {
    const key = flagKey || segmentKey;
    if (!key) {
      return;
    }

    const updates = expirationUpdates.get(key);
    if (!updates) {
      return;
    }

    const contextKinds = updates.keySeq().toArray();

    for (const contextKind of contextKinds) {
      const contextKeys = updates.get(contextKind)?.keySeq().toArray();
      if (!contextKeys) {
        continue;
      }

      for (const contextKey of contextKeys) {
        const update = updates.get(contextKind)?.get(contextKey);
        if (!update) {
          continue;
        }

        dispatch(undoContextTargetingExpiration({ contextKind, contextKey, flagKey, segmentKey }));
      }
    }
  };
}

export {
  fetchExpiringContextTargetsForFlagIfNeeded as fetchExpiringContextTargetsForFlag,
  patchContextTargetingExpiration,
  postContextTargetingExpiration,
  deleteContextTargetingExpiration,
  updateExpiringValues,
  undoContextTargetingExpiration,
  fetchExpiringContextTargetsForSegmentIfNeeded,
  fetchExpiringContextTargetsForSegment,
  resetExpiringContextTargets,
  discardAllExpiringTargetChanges,
};
