import {
  documentationUri,
  euCssStylesEnabled as isEUInstance,
  federalCssStylesEnabled as isFederalInstance,
} from '@gonfalon/constants';

// path example: 'home/code'
// leading slash is optional '/home/code'
export function getDocumentationUrl(path: string = ''): string {
  const baseUrl = documentationUri() || 'https://launchdarkly.com/docs';
  const newUrl = new URL(path, baseUrl);

  if (isFederalInstance()) {
    newUrl.searchParams.append('site', 'federal');
  } else if (isEUInstance()) {
    newUrl.searchParams.append('site', 'eu');
  } else {
    newUrl.searchParams.append('site', 'launchDarkly');
  }
  return newUrl.toString();
}
