import { fromJS, List, Set } from 'immutable';

import { FlagAction } from 'actions/flags';
import { FormAction } from 'actions/forms';
import actionTypes from 'actionTypes/flags';
import formActionTypes from 'actionTypes/forms';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { createMember } from 'utils/accountUtils';
import { createCustomPropertyForm, createFlag, CustomPropertyForm, Flag } from 'utils/flagUtils';
import { createFormState } from 'utils/formUtils';
import { createTeam } from 'utils/teamsUtils';

export const projectFlagSettingsFormKey = 'projectFlagSettingsForm';

const initialFormState = (flag = createFlag()) => createFormState(flag);

export function projectFlagSettingsForm(state = initialFormState(), action: FlagAction | FormAction) {
  switch (action.type) {
    case actionTypes.EDIT_PROJECT_FLAG_SETTINGS:
      let newState = state;
      if (action.field === 'tags') {
        newState = newState.hitField('tags');
      }
      return newState.trackField(action.field).revalidate(action.flag);
    case actionTypes.UPDATE_PROJECT_FLAG_SETTINGS:
      return state.submitting();
    case actionTypes.UPDATE_PROJECT_FLAG_SETTINGS_DONE:
      let flag = action.flag;
      // Because custom properties are stored as a map in the backend,
      // we need to make sure they're in the same order when returned from the API
      // The front-end and backend have the same validation, so comparing the modified form to the API result is fine
      const formCustomProps: CustomPropertyForm[] = state.modified.get('customProperties').toJS();
      const apiCustomProps: CustomPropertyForm[] = flag.get('customProperties').toJS();
      if (formCustomProps !== apiCustomProps) {
        const reorderedCustomProps: CustomPropertyForm[] = [];
        // O(n^2), but we limit the number of custom props entries
        formCustomProps.forEach((p) => {
          const customProp = apiCustomProps.find((e) => e.key === p.key);
          if (customProp) {
            reorderedCustomProps.push(createCustomPropertyForm(customProp));
          }
        });
        flag = action.flag.set('customProperties', List(reorderedCustomProps));
      }

      return initialFormState(flag);
    case actionTypes.UPDATE_PROJECT_FLAG_SETTINGS_FAILED:
      return state.submitFailed(action.flag, action.error);
    case formActionTypes.INITIALIZE:
      if (action.model !== projectFlagSettingsFormKey) {
        return state;
      }
      return createFormState(action.initialState as Flag);
    case formActionTypes.BLUR:
      if (action.model !== projectFlagSettingsFormKey) {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    case formActionTypes.DESTROY:
      if (action.model !== projectFlagSettingsFormKey) {
        return state;
      }
      return initialFormState();
    // This is a hack to keep this reducer in sync with the new shell. We do this to avoid HTTP 409
    // if the user saves a project setting change after they've updated the maintainer or tags,
    // while the UI that uses this reducer is mounted.
    case 'flags/SYNC_FLAG_WITH_REST_API':
      return initialFormState(
        state.get('modified').merge({
          _maintainer: createMember(fromJS(action.flag._maintainer)),
          maintainerId: action.flag.maintainerId,
          _maintainerTeam: createTeam(fromJS(action.flag._maintainerTeam)),
          maintainerTeamKey: action.flag._maintainerTeam?.key,
          tags: Set(action.flag.tags),
          _version: action.flag._version,
        }),
      );
    default:
      return state;
  }
}

export const projectFlagSettingsFormSelector = (state: GlobalState) => state.projectFlagSettingsForm;

registry.addReducers({ projectFlagSettingsForm });
