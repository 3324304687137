import { useReducer } from 'react';

import { AppAction, AppState } from './types';

const initialState: AppState = {
  orgUsers: [],
  metaData: {},
  blockConfigureState: null,
  isBlockBeingConfigured: false,
};

function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'update_block_configure_ui':
      return {
        ...state,
        blockConfigureState: action.payload,
      };

    case 'update_asset_preview_state':
      return {
        ...state,
        assetPreviewState: action.payload,
      };
    case 'toggle-is-block-being-configured':
      return {
        ...state,
        isBlockBeingConfigured: action.payload,
      };

    default:
      return state;
  }
}

export default function useAppReducer() {
  return useReducer(reducer, initialState);
}

export type AppDispatch = ReturnType<typeof useAppReducer>[1];
