import { type JSX, cloneElement } from 'react';
import classNames from 'clsx';

import 'stylesheets/components/Jumbo.css';

type JumboProps = {
  title: string;
  lead: string;
  actions?: JSX.Element;
  className?: string;
  isError?: boolean;
};

/* eslint-disable import/no-default-export */
export default function (props: JumboProps) {
  const { className, title, lead, actions, isError } = props;
  const classes = [className, 'Jumbo'];
  if (isError) {
    classes.push('Jumbo--error');
  }
  return (
    <div className={classNames(classes)}>
      <h1 className="Jumbo-title">{title}</h1>
      <p className="Jumbo-lead">{lead}</p>
      {!!actions &&
        cloneElement(actions, {
          className: classNames(actions.props.className, 'Jumbo-actions'),
        })}
    </div>
  );
}
