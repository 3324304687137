import { HTMLAttributes, memo, useMemo } from 'react';
import {
  DataDogLogoIcon,
  InvertableSentryIcon,
  InvertableSnowPlowIcon,
  LaunchDarklyLogoIcon,
  OpenTelemetryIcon,
  SnowflakeIcon,
} from '@gonfalon/icons';
import { capitalize } from '@gonfalon/strings';
import { Tag, TagGroup, TagList, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import clsx from 'clsx';

import styles from './source.module.css';
export interface Props extends HTMLAttributes<HTMLSpanElement> {
  source: string;
  variant?: 'normal' | 'small';
  popoverContent?: React.ReactNode;
}
export const Source = memo(({ source, popoverContent, variant = 'normal', className, ...rest }: Props) => {
  const sourceClass = useMemo(() => iconClass(source), [source]);
  const classes = useMemo(
    () => clsx(styles.source, sourceClass, className, styles[variant]),
    [className, sourceClass, variant],
  );
  return (
    <MaybePopover popoverContent={popoverContent}>
      <span className={classes} {...rest}>
        {renderIcon(source)} {renderSource(source)}
      </span>
    </MaybePopover>
  );
});

function MaybePopover({ popoverContent, children }: { popoverContent?: React.ReactNode; children: React.ReactNode }) {
  if (!popoverContent) {
    return children;
  }

  return (
    <TagGroup>
      <TagList>
        <TooltipTrigger>
          <Tag className={styles.sourceTagButton}>{children}</Tag>
          <Tooltip variant="popover">{popoverContent}</Tooltip>
        </TooltipTrigger>
      </TagList>
    </TagGroup>
  );
}

/**
 * Render Icon
 *
 * Renders an icon via source indicator
 *
 * Missing:
 * 1. gamma
 * 2. meijer
 * 3. wheniwork
 */
function renderIcon(source: string) {
  switch (source) {
    case 'browser-sdk':
    case 'backend-sdk':
    case 'mobile-sdk':
    case 'edge-sdk':
      return <LaunchDarklyLogoIcon />;
    case 'datadog':
      return <DataDogLogoIcon />;
    case 'sentry':
      return <InvertableSentryIcon />;
    case 'snowflake':
    case 'snowflake-experimentation':
      return <SnowflakeIcon />;
    case 'snowplow':
      return <InvertableSnowPlowIcon />;
    case 'otel-import':
      return <OpenTelemetryIcon />;
    default:
      return;
  }
}

/**
 * Icon Class
 *
 * Returns a class name for icons needing specific
 * color adjustments to accomodate light/dark mode
 *
 * Missing:
 * 1. gamma
 * 2. meijer
 * 3. wheniwork
 */
function iconClass(source: string) {
  switch (source) {
    case 'datadog':
    case 'snowflake':
    case 'snowflake-experimentation':
      return '';
    case 'snowplow':
      return styles.snowplowIcon;
    case 'sentry':
      return styles.sentryIcon;
    default:
      return styles.ldIcon;
  }
}

/**
 * Render Source
 *
 * Snowflake Experimentation sources need a haircut. Others
 * may some day too
 */
function renderSource(source: string) {
  switch (source) {
    case 'snowflake':
    case 'snowflake-experimentation':
      return 'Snowflake';
    case 'datadog':
    case 'snowplow':
    case 'sentry':
      return capitalize(source);
    default:
      return source;
  }
}
