import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch } from 'react-redux';
import { List } from 'immutable';
import nullthrows from 'nullthrows';

import { Clause as ImmutableClause } from 'utils/clauseUtils';
import { createRollout, createRule, Flag, idOrKey, Prerequisite, Rollout, Variation } from 'utils/flagUtils';
import {
  makeAddClausesInstruction,
  makeRemoveClausesInstruction,
  makeUpdateClauseInstruction,
} from 'utils/instructions/clauses/helpers';
import {
  makeOffVariationInstruction,
  makeToggleFlagInstruction,
  makeUpdateFallthroughRolloutInstruction,
  makeUpdateFallthroughVariationInstruction,
} from 'utils/instructions/onOff/helpers';
import {
  makeAddRuleInstruction,
  makeRemoveRuleInstruction,
  makeReorderRulesInstruction,
  makeUpdateRuleDescription,
  makeUpdateRuleRolloutInstruction,
  makeUpdateRuleVariationInstruction,
} from 'utils/instructions/rules/helpers';
import { AggregateRule, SemanticInstructionRolloutWeights } from 'utils/instructions/rules/types';
import { InstructionsType, SemanticInstruction } from 'utils/instructions/shared/types';
import { PendingChangesFormValueType } from 'utils/pendingChangesUtils';
import { GenerateActionType } from 'utils/reduxUtils';

import { makeUpdatedPrerequisiteInstructionsArray } from '../utils/instructions/prerequisites/helpers';

export const editPendingChangesForm = (field: string, value: PendingChangesFormValueType) =>
  ({
    type: 'pendingChanges/EDIT_PENDING_CHANGES_FORM',
    field,
    value,
  }) as const;

export function useEditPendingChangesFormAction() {
  const dispatch = useDispatch();

  return useCallback((field: string, value: PendingChangesFormValueType) => {
    dispatch(editPendingChangesForm(field, value));
  }, []);
}

const setFlagConfigurationInstructions = (instructions: InstructionsType) =>
  ({
    type: 'pendingChanges/SET_FLAG_CONFIGURATION_INSTRUCTIONS',
    instructions,
  }) as const;

const removeInstructions = (instructions: SemanticInstruction[]) =>
  ({
    type: 'pendingChanges/REMOVE_FLAG_CONFIGURATION_INSTRUCTION',
    newInstructions: instructions,
  }) as const;

function setToggleFlagInstruction(on: boolean) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeToggleFlagInstruction(on)],
  };
}

function setFallthroughVariationInstruction(variation: Variation) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeUpdateFallthroughVariationInstruction(variation._id)],
  };
}

function selectOffVariation(variation: Variation | null) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeOffVariationInstruction(variation ? variation._id : null)],
  };
}

export function editPrerequisiteInstruction(
  prerequisite: Prerequisite,
  originalPrerequisites: List<Prerequisite>,
  previousPrerequisiteKey: string | null,
  addPrereq: boolean = true,
) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: makeUpdatedPrerequisiteInstructionsArray(
      prerequisite.variationId,
      prerequisite.key,
      originalPrerequisites,
      previousPrerequisiteKey,
      addPrereq,
    ),
  };
}

function getFallthroughRollout(rollout: Rollout, variations: List<Variation>) {
  const weights: SemanticInstructionRolloutWeights = {};
  rollout.get('variations').forEach((variation) => {
    const index = variation.get('variation');
    const id = variations.get(index)?._id;
    if (id) {
      weights[id] = variation.get('weight');
    }
  });

  return {
    experimentAllocation: rollout.get('experimentAllocation'),
    bucketBy: rollout.get('bucketBy'),
    contextKind: rollout.get('contextKind'),
    weights,
  };
}

function setFallthroughRolloutInstruction({ flag, rollout }: { flag: Flag; rollout: Rollout }) {
  const newRollout = getFallthroughRollout(rollout, flag.variations);
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeUpdateFallthroughRolloutInstruction(newRollout)],
  };
}

//RULES

function addRuleInstruction(flag: Flag, envKey: string) {
  const updatedFlag = flag.addRule(envKey);
  const newRule = updatedFlag.getConfiguration(envKey).get('rules').last<undefined>();
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    // We just added this rule so it's safe to assert it exists.
    newInstructions: [makeAddRuleInstruction(newRule!)],
    addPatch: true,
  }; /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

function deleteRuleInstruction({
  originalRemovedRuleId,
  newRemovedRuleId,
}: {
  originalRemovedRuleId: string;
  newRemovedRuleId: string;
}) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeRemoveRuleInstruction(newRemovedRuleId)],
    addPatch: true,
    replaceId: originalRemovedRuleId,
  };
}

function setRuleVariationInstruction(rule: AggregateRule, variation: Variation) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeUpdateRuleVariationInstruction(rule.ruleId, variation._id)],
    addPatch: true,
  };
}

function setRuleRolloutInstruction(flag: Flag, ruleId: string, rollout: Rollout) {
  const newRollout = getFallthroughRollout(createRollout(rollout), flag.variations);
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeUpdateRuleRolloutInstruction(ruleId, newRollout)],
    addPatch: true,
  };
}

function deleteRuleClauseInstruction(ruleId: string, clause: ImmutableClause, existingId?: string) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeRemoveClausesInstruction(ruleId, [idOrKey(clause)])],
    addPatch: true,
    replaceId: existingId,
  };
}

function editRuleClauseInstruction(rule: AggregateRule, clause: ImmutableClause) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeUpdateClauseInstruction(rule.ruleId, idOrKey(clause), clause)],
    addPatch: true,
  };
}

function addRuleClauseInstruction(flag: Flag, envKey: string, aggregateRule: AggregateRule) {
  const updatedFlag = flag.addRuleClause(
    envKey,
    createRule({ ...aggregateRule, clauses: aggregateRule.clauses.filter((c): c is ImmutableClause => c !== null) }),
  );
  const rules = updatedFlag.getConfiguration(envKey).get('rules');
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const newClause = rules
    .find((r) => idOrKey(r) === aggregateRule.ruleId)!
    .get('clauses')
    .last<undefined>(); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeAddClausesInstruction(aggregateRule.ruleId, [nullthrows(newClause).toJS()])],
    addPatch: true,
  };
}

function updateRuleDescriptionInstruction(ruleId: string, description: string) {
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeUpdateRuleDescription(ruleId, description)],
    addPatch: true,
  };
}

export function setRuleIndex(flag: Flag, envKey: string, ruleId: string, index: number, ruleIds: string[]) {
  const ruleIdsCopy = ruleIds;
  const swapIndex = ruleIdsCopy.findIndex((v) => v === ruleId);
  ruleIdsCopy[swapIndex] = ruleIds[index];
  ruleIdsCopy[index] = ruleId;
  return {
    type: 'pendingChanges/CHANGE_FLAG_CONFIGURATION_INSTRUCTIONS',
    newInstructions: [makeReorderRulesInstruction(ruleIdsCopy)],
    environmentKey: envKey,
    addPatch: true,
  };
}

export {
  setFallthroughVariationInstruction,
  setFallthroughRolloutInstruction,
  setToggleFlagInstruction,
  selectOffVariation,
  addRuleInstruction,
  setRuleRolloutInstruction,
  deleteRuleInstruction,
  deleteRuleClauseInstruction,
  editRuleClauseInstruction,
  addRuleClauseInstruction,
  setRuleVariationInstruction,
  updateRuleDescriptionInstruction,
  removeInstructions,
  setFlagConfigurationInstructions,
  getFallthroughRollout,
};

const PendingChangesFormActionCreators = {
  editPendingChangesForm,
  setFlagConfigurationInstructions,
  removeInstructions,
};

export type PendingChangesAction = GenerateActionType<typeof PendingChangesFormActionCreators>;
