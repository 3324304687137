import { GetExperimentQueryParams } from '@gonfalon/openapi';
import { getExperimentQuery } from '@gonfalon/rest-api';
import { useQuery } from '@tanstack/react-query';

export const useExperiment = (
  {
    projectKey,
    environmentKey,
    experimentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    params?: GetExperimentQueryParams;
  },
  options?: { enabled?: boolean },
) =>
  useQuery({
    ...getExperimentQuery({ projectKey, environmentKey, experimentKey, query: params }),
    ...options,
  });
