import { createTrackerForCategory } from '@gonfalon/analytics';
import { teamsListPageSize } from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';
import { isValidKey } from '@gonfalon/strings';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record, Set } from 'immutable';
import qs from 'qs';

import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';

import { AccessChecks, allowDecision, createAccessDecision } from './accessUtils';
import { createMemberSummary, Member, MemberSummary } from './accountUtils';
import { isNotEmpty, isReservedKey, validateRecord } from './validationUtils';

export type TeamCustomRoleProps = {
  appliedOn: number;
  canUpdateAllProjects: boolean;
  key: string;
  name: string;
  projects: Set<string>;
};
export class TeamCustomRole extends Record<TeamCustomRoleProps>({
  appliedOn: 0,
  canUpdateAllProjects: false,
  key: '',
  name: '',
  projects: Set(),
}) {
  getProjectNames() {
    return this.projects;
  }
}

export class TeamProjectSummary extends Record<TeamProjectSummaryProps>({
  totalCount: 0,
  items: List(),
}) {}

type TeamCustomRoleSummaryItemProps = {
  appliedOn: number;
  key: string;
  name: string;
  projects: TeamProjectSummary;
};

type TeamProjectSummaryProps = {
  totalCount: number;
  items: List<TeamProjectReference>;
};

export const createTeamProjectSummary = (props: Partial<TeamProjectSummaryProps>): TeamProjectSummary =>
  props instanceof TeamProjectSummary ? props : new TeamProjectSummary(props);

export class TeamCustomRoleSummaryItem extends Record<TeamCustomRoleSummaryItemProps>({
  appliedOn: 0,
  key: '',
  name: '',
  projects: createTeamProjectSummary({}),
}) {
  getProjectNames() {
    const summary = this.projects;
    return summary.get('items').map((project) => project.get('name'));
  }
}

export const createTeamCustomRoleSummaryItem = (props?: CreateFunctionInput<TeamCustomRoleSummaryItem>) =>
  props instanceof TeamCustomRoleSummaryItem ? props : new TeamCustomRoleSummaryItem(fromJS(props));

type TeamCustomRoleSummaryProps = {
  totalCount: number;
  items: List<TeamCustomRoleSummaryItem>;
};

export class TeamCustomRoleSummary extends Record<TeamCustomRoleSummaryProps>({
  totalCount: 0,
  items: List(),
}) {}

export const createTeamCustomRoleSummary = (props?: CreateFunctionInput<TeamCustomRoleSummary>) => {
  const teamCustomRoleSummary =
    props instanceof TeamCustomRoleSummary ? props : new TeamCustomRoleSummary(fromJS(props));

  return teamCustomRoleSummary.withMutations((summary) => {
    summary.update('items', (items) => items.map((role) => new TeamCustomRoleSummaryItem(role)));
  });
};

type TeamMaintainerSummaryLinksProps = {
  // Optional path to the next page of team maintainers.
  // If missing, there are no more team maintainers to load.
  // Example: /api/v2/teams/{key}/maintainers?limit=10&offset=20
  next?: Link;
};

class TeamMaintainerSummaryLinks extends Record<TeamMaintainerSummaryLinksProps>({
  next: undefined,
}) {}

type TeamMaintainerSummaryProps = {
  totalCount: number;
  items: List<MemberSummary>;
  _links: TeamMaintainerSummaryLinks;
  _isFetching: boolean;
};

export class TeamMaintainerSummary extends Record<TeamMaintainerSummaryProps>({
  totalCount: 0,
  items: List(),
  _links: new TeamMaintainerSummaryLinks(),
  _isFetching: false,
}) {}

export const createTeamMaintainerSummary = (props?: CreateFunctionInput<TeamMaintainerSummary>) => {
  const teamMaintainerSummary =
    props instanceof TeamMaintainerSummary ? props : new TeamMaintainerSummary(fromJS(props));

  return teamMaintainerSummary.withMutations((summary) => {
    summary.update('items', (items) => items.map((maintainer) => createMemberSummary(maintainer)));
    summary.update('_links', (links) => new TeamMaintainerSummaryLinks(links));
  });
};

type TeamMemberSummaryProps = {
  totalCount: number;
};

class TeamMemberSummary extends Record<TeamMemberSummaryProps>({
  totalCount: 0,
}) {}

export const createTeamMemberSummary = (props?: CreateFunctionInput<TeamMemberSummary>) =>
  props instanceof TeamMemberSummary ? props : new TeamMemberSummary(fromJS(props));

type TeamPermissionGrantProps = { actionSet: string; actions: Set<string>; memberIDs: Set<string> };

export class TeamPermissionGrant extends Record<TeamPermissionGrantProps>({
  actionSet: '',
  actions: Set(),
  memberIDs: Set(),
}) {}

export type TeamProps = {
  _access?: AccessChecks;
  _links: ImmutableMap<{
    self: Link;
    parent: Link;
  }>;
  _idpSynced?: boolean;
  roles: TeamCustomRoleSummary;
  description?: string;
  key: string;
  members?: TeamMemberSummary;
  name: string;
  projects?: TeamProjectSummary;
  maintainers: TeamMaintainerSummary;
  roleAttributes?: ImmutableMap<{ [key: string]: Set<string> }>;
};

export type TeamProjectReferenceProps = {
  name: string;
  key: string;
};

export class TeamProjectReference extends Record<TeamProjectReferenceProps>({
  name: '',
  key: '',
}) {}

export class TeamSummary extends Record({
  _id: '',
  _links: Map(),
  key: '',
  name: '',
}) {
  getDisplayName() {
    return this.name;
  }
}

export const createTeamSummary = (props?: CreateFunctionInput<TeamSummary>) =>
  props instanceof TeamSummary ? props : new TeamSummary(fromJS(props));

export class Team extends Record<TeamProps>({
  _access: undefined,
  _idpSynced: undefined,
  _links: Map(),
  roles: createTeamCustomRoleSummary(),
  description: '',
  key: '',
  members: createTeamMemberSummary(),
  name: '',
  projects: undefined,
  maintainers: createTeamMaintainerSummary(),
  roleAttributes: undefined,
}) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this._access;

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isWriter()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Writer' });
    }

    if (profile.isAdminOrOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action?: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  getMaintainerIDs() {
    const maintainerIds = (this.maintainers?.items || []).map((m) => m._id);
    return Set<string>(maintainerIds);
  }

  // this method gets the custom role keys from the customRoles field - the customRoleKeys field will be removed after TeamsGA release
  getCustomRoleKeys() {
    const roleKeys = (this.roles?.items || []).map((r) => r.key);
    return Set(roleKeys);
  }

  getDisplayName() {
    return this.name;
  }
}

export function createTeam(props: Partial<TeamProps> = {}) {
  let team = props instanceof Team ? props : new Team(fromJS(props));
  team = team.withMutations((t) => {
    t.update('roles', (customRoleSummary) => createTeamCustomRoleSummary(customRoleSummary));
    t.update('members', (memberSummary) => createTeamMemberSummary(memberSummary));
    t.update('maintainers', (teamMaintainerSummary) => createTeamMaintainerSummary(teamMaintainerSummary));
  });
  return team;
}

export const UNKNOWN_TEAM_NAME = 'Unknown team';

export function createUnknownTeam(key: string) {
  return createTeam({ key, name: UNKNOWN_TEAM_NAME });
}

export type TeamFormProps = {
  customRoleKeys?: Set<string>;
  description?: string;
  key: string;
  maintainerIDs: Set<string>;
  memberIDs: Set<string>;
  name: string;
};

export class TeamForm extends Record<TeamFormProps>({
  customRoleKeys: Set(),
  description: '',
  key: '',
  maintainerIDs: Set(),
  memberIDs: Set(),
  name: '',
}) {
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: TeamForm) => {
      const permissionGrants = { actionSet: 'maintainTeam', actions: [], memberIDs: this.maintainerIDs };
      map.setIn(['permissionGrants'], [permissionGrants]);
      if (isEmpty(this.description)) {
        map.remove('description');
      }
    });
  }
  validate() {
    const predicates = [isNotEmpty('name'), isNotEmpty('key'), isValidKey('key'), isReservedKey('key')];
    return validateRecord(this, ...predicates);
  }
  teamHasCustomRoles() {
    return !this.customRoleKeys?.isEmpty();
  }
}

export function createTeamForm(props: Partial<TeamFormProps>) {
  return props instanceof TeamForm ? props : new TeamForm(fromJS(props));
}

/**
 * Teams Filter
 */
type TeamsFiltersProps = {
  query: string;
  limit: number;
  offset: number;
  noAccess: boolean;
};

export class TeamsFilters extends Record<TeamsFiltersProps>({
  query: '',
  limit: -1,
  offset: 0,
  noAccess: true,
}) {
  toFrontendTeamsQueryString(options?: { omitPaginationParams?: boolean }) {
    const { query, limit, offset } = this;

    if (options?.omitPaginationParams) {
      return { ...(query && { query }) };
    }

    const validLimit = limit && limit > 0 && (offset || limit !== teamsListPageSize());

    return {
      ...(query && { query }),
      ...(validLimit && { limit }),
      ...(offset && { offset }),
    };
  }
  toQueryString(options?: { omitPaginationParams?: boolean }) {
    return qs.stringify(this.toFrontendTeamsQueryString(options), { indices: false });
  }
  toBackendQueryString() {
    const { query, limit, offset, noAccess } = this;
    const q = {
      ...(query && { query }),
      ...(limit && limit > 0 && { limit }),
      ...(offset && { offset }),
      ...(!noAccess && { filter: `noaccess:${noAccess}` }),
    };
    return qs.stringify(q, { indices: false, addQueryPrefix: true });
  }
  isEmpty() {
    return isEmpty(this.query);
  }
}

export function createTeamsFilters(props: Partial<TeamsFiltersProps>) {
  return props instanceof TeamsFilters ? props : new TeamsFilters(fromJS(props));
}

export function createTeamsFiltersFromQuery(queryMap: Partial<TeamsFiltersProps>) {
  return createTeamsFilters({
    ...queryMap,
    limit: queryMap.limit || teamsListPageSize(),
  });
}

export function createTeamsFiltersFromBackendQueryString(queryString: string) {
  const backendParams = qs.parse(queryString, { ignoreQueryPrefix: true });
  const props = {
    query: backendParams.query,
    limit: parseInt(backendParams.limit, 10),
    offset: parseInt(backendParams.offset, 10),
  };
  return createTeamsFilters(props);
}
/**
 * End of Teams Filter
 */

// This filter will retain any teams where the given Member
//   A) is a Maintainer for the team
//   B) has a custom role (applied to the member or to a team they're on) with an ALLOW on `updateTeamMembers`
export function getTeamsWithNoUpdateMemberAccess(teams: List<Team>, profile: Member) {
  const teamsWithoutAccess = teams?.filter((team) => {
    const checkAccess = team.checkAccess({ profile });
    const updateTeamMembersAccess = checkAccess('updateTeamMembers');
    return !updateTeamMembersAccess.isAllowed;
  });
  return teamsWithoutAccess?.map((team) => team.key);
}

export function canManageMembersForAtLeastOneTeam(teams: List<Team>, profile: Member) {
  const teamsWithNoAccess = getTeamsWithNoUpdateMemberAccess(teams, profile);
  return teamsWithNoAccess?.size < teams?.size;
}

enum TeamInstructionKindForSingleValueResources {
  UPDATE_NAME = 'updateName',
  UPDATE_DESCRIPTION = 'updateDescription',
}

export enum TeamInstructionKindForMultipleValueResources {
  ADD_MEMBERS = 'addMembers',
  REMOVE_MEMBERS = 'removeMembers',
  ADD_CUSTOM_ROLES = 'addCustomRoles',
  REMOVE_CUSTOM_ROLES = 'removeCustomRoles',
}

enum TeamInstructionKindForPermissionGrants {
  ADD_PERMISSION_GRANTS = 'addPermissionGrants',
  REMOVE_PERMISSION_GRANTS = 'removePermissionGrants',
}

enum TeamInstructionActionSet {
  MAINTAIN_TEAM = 'maintainTeam',
}

export type TeamsSemanticPatchInstructions =
  | ({ kind: TeamInstructionKindForSingleValueResources } & { value: string })
  | ({ kind: TeamInstructionKindForMultipleValueResources } & { values: string[] })
  | ({ kind: TeamInstructionKindForPermissionGrants } & { actionSet: string; actions: string[]; memberIDs: string[] });

const getDiff = (arr1: string[], arr2: string[]) => arr1.filter((x: string) => !arr2.includes(x));
export const getAdded = (original: string[], modified: string[]) => getDiff(modified, original);
export const getRemoved = (original: string[], modified: string[]) => getDiff(original, modified);

/**
 * getTeamsSemanticPatchInstructions accepts the original and modified states of a team,
 * determines what attributes have been modified, and returns a set of instructions to be
 * passed along via a PATCH request to the API. It only sends along attributes that have
 * been updated. See TeamInstructionKindForSingleValueResources,
 * TeamInstructionKindForMultipleValueResources, and TeamInstructionKindForPermissionGrants
 * for the specific kinds of instructions that might be included in the set of instructions.
 */
export function getTeamsSemanticPatchInstructions(original: TeamForm, modified: TeamForm) {
  const instructions = [];

  const maintainersToAdd = getAdded(original.maintainerIDs.toJS(), modified.maintainerIDs.toJS());
  if (maintainersToAdd.length) {
    instructions.push({
      actionSet: TeamInstructionActionSet.MAINTAIN_TEAM,
      actions: [],
      kind: TeamInstructionKindForPermissionGrants.ADD_PERMISSION_GRANTS,
      memberIDs: maintainersToAdd,
    });
  }

  const maintainersToRemove = getRemoved(original.maintainerIDs.toJS(), modified.maintainerIDs.toJS());
  if (maintainersToRemove.length) {
    instructions.push({
      actionSet: TeamInstructionActionSet.MAINTAIN_TEAM,
      actions: [],
      kind: TeamInstructionKindForPermissionGrants.REMOVE_PERMISSION_GRANTS,
      memberIDs: maintainersToRemove,
    });
  }

  const membersToAdd = getAdded(original.memberIDs.toJS(), modified.memberIDs.toJS());
  if (membersToAdd.length) {
    instructions.push({
      kind: TeamInstructionKindForMultipleValueResources.ADD_MEMBERS,
      values: membersToAdd,
    });
  }

  const membersToRemove = getRemoved(original.memberIDs.toJS(), modified.memberIDs.toJS());
  if (membersToRemove.length) {
    instructions.push({
      kind: TeamInstructionKindForMultipleValueResources.REMOVE_MEMBERS,
      values: membersToRemove,
    });
  }

  const rolesToAdd = getAdded((original.customRoleKeys || Set()).toJS(), (modified.customRoleKeys || Set()).toJS());
  if (rolesToAdd.length) {
    instructions.push({
      kind: TeamInstructionKindForMultipleValueResources.ADD_CUSTOM_ROLES,
      values: rolesToAdd,
    });
  }

  const rolesToRemove = getRemoved(
    (original.customRoleKeys || Set()).toJS(),
    (modified.customRoleKeys || Set()).toJS(),
  );
  if (rolesToRemove.length) {
    instructions.push({
      kind: TeamInstructionKindForMultipleValueResources.REMOVE_CUSTOM_ROLES,
      values: rolesToRemove,
    });
  }

  if (modified.name !== original.name) {
    instructions.push({
      kind: TeamInstructionKindForSingleValueResources.UPDATE_NAME,
      value: modified.name,
    });
  }

  if ((modified.description || modified.description === '') && modified.description !== original.description) {
    instructions.push({
      kind: TeamInstructionKindForSingleValueResources.UPDATE_DESCRIPTION,
      value: modified.description,
    });
  }
  return instructions;
}

export const getAddMaintainersSemanticPatchInstructions = (memberIDs: string[]) => [
  {
    actionSet: TeamInstructionActionSet.MAINTAIN_TEAM,
    actions: [],
    kind: TeamInstructionKindForPermissionGrants.ADD_PERMISSION_GRANTS,
    memberIDs,
  },
];

export const getRemoveMaintainerSemanticPatchInstructions = (maintainerID: string) => [
  {
    actionSet: TeamInstructionActionSet.MAINTAIN_TEAM,
    actions: [],
    kind: TeamInstructionKindForPermissionGrants.REMOVE_PERMISSION_GRANTS,
    memberIDs: [maintainerID],
  },
];

export const sortTeams = (a?: Team, b?: Team) => (a && b && a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);

export function getTeam(teams: Map<string, Team>, key: string) {
  return teams.get(key) || createUnknownTeam(key);
}

const trackTeamsEvent = createTrackerForCategory('Teams');

// Team
export const trackCreateTeamsButtonClick = () => trackTeamsEvent('Create Team Button Clicked');
export const trackSaveTeamsButtonSubmitted = () => trackTeamsEvent('Save Team Button Clicked');
export const trackDeleteTeamsButtonClick = () => trackTeamsEvent('Delete Team Button Clicked');

// Team Members
export const trackTeamMemberModalLoaded = () => trackTeamsEvent('Add Team Member Button Clicked');
export const trackTeamMemberAdditionSubmitted = () => trackTeamsEvent('Add Team Member Submit Button Clicked');
export const trackTeamMemberRemovalSubmitted = () => trackTeamsEvent('Remove Team Member Button Clicked');

// Team Maintainers
export const trackTeamMaintainerModalLoaded = () => trackTeamsEvent('Add Team Maintainer Button Clicked');
export const trackTeamMaintainerAdditionSubmitted = () => trackTeamsEvent('Add Team Maintainer Submit Button Clicked');
export const trackTeamMaintainerRemovalSubmitted = () => trackTeamsEvent('Remove Team Maintainer Button Clicked');

// Team Custom Roles
export const trackCreateNewCustomRoleButtonClicked = () => trackTeamsEvent('Create Team Custom Role Button Clicked');
export const trackAddCustomRoleModalLoaded = () => trackTeamsEvent('Add Custom Roles Button Clicked');
export const trackTeamCustomRoleAdditionSubmitted = () => trackTeamsEvent('Add Custom Role Submit Button Clicked');
export const trackTeamCustomRoleRemovalSubmitted = () => trackTeamsEvent('Remove Custom Role Button Clicked');
// Team Permissions (new roles flow)
export const trackAssignAccessClicked = () => trackTeamsEvent('Assign Access Button Clicked');

// Team Settings
export const trackNameOrDescriptionUpdated = () => trackTeamsEvent('General Information Save Button Clicked');

// Team Bulk Add Members
export const trackTeamMemberCsvUploadModalLoaded = () => trackTeamsEvent('Upload CSV Button Clicked');
export const trackTeamMemberCsvUploadFileUploaded = () => trackTeamsEvent('Upload CSV Upload File Button Clicked');
export const trackTeamMemberCsvUploadNewFileUploaded = () =>
  trackTeamsEvent('Upload CSV Upload New File Button Clicked');
export const trackTeamMemberCsvUploadFileSelected = () => trackTeamsEvent('Upload CSV Select File Button Clicked');
export const trackTeamMemberCsvUploadCanceled = () => trackTeamsEvent('Upload CSV Cancel Button Clicked');
export const trackTeamMemberCsvUploadValidEntriesAdded = () =>
  trackTeamsEvent('Upload CSV Add Valid Entries Button Clicked');

// Team History Tab
export const trackTeamHistoryTabClicked = () => trackTeamsEvent('Team History Tab Clicked');
export const trackTeamHistoryTabSearchBarEdited = () => trackTeamsEvent('Team History Search Bar Field Edited');
export const trackTeamHistoryTabDateFilterApplyButtonClicked = () =>
  trackTeamsEvent('Team History Tab Date Filter Apply Button Clicked');

// TODO: remove this Teams foundation/administration function when the "Member Tabs" phase of TeamsGA goes live
// (you'll probably replace it with a new one)
export const trackMemberRemovalFromTeamClick = () => trackTeamsEvent('Team Member Removal Button Clicked');
