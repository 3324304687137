import { type JSX } from 'react';

import { CodeSpan } from '../CodeSpan';
import { MeasureType, Metric } from '../types';

import styles from './RandomizationUnitsSection.module.css';

interface RandomizationUnitsSectionProps {
  measureType: MeasureType;
  metricKind: Metric['kind'];
  unitAggregationType: Metric['unitAggregationType'];
  formElement?: JSX.Element;
  readonly?: boolean;
  readonlyValues?: string[];
}

export const RandomizationUnitsSection: React.FC<RandomizationUnitsSectionProps> = ({
  measureType,
  metricKind,
  unitAggregationType,
  readonlyValues,
  formElement,
  readonly = false,
}: RandomizationUnitsSectionProps) => {
  const randomizationUnits = readonly ? (
    <>
      {readonlyValues?.map((rov, index) => (
        <span key={rov} className={styles.commaSpan}>
          <CodeSpan textValue={rov} />
          {index === readonlyValues.length - 1 ? '' : ', '}
        </span>
      ))}
    </>
  ) : (
    formElement
  );

  return (
    <>
      {getFirstCopySection(measureType, metricKind)}
      {randomizationUnits}
      {getSecondCopySection(measureType, metricKind, unitAggregationType)}
    </>
  );
};

const getFirstCopySection = (selectedMeasureType: MeasureType, metricKind: Metric['kind']) => {
  if (selectedMeasureType === 'occurrence') {
    return <span>Percentage of </span>;
  }
  switch (metricKind) {
    case 'custom':
      if (selectedMeasureType === 'value') {
        return (
          <span>
            <CodeSpan textValue="value" /> of the per{' '}
          </span>
        );
      } else if (selectedMeasureType === 'count') {
        return (
          <span>
            of event <CodeSpan textValue="count" /> per{' '}
          </span>
        );
      }
      break;
    case 'click':
      return 'clicks per';
    case 'pageview':
      return 'page views per';
    default:
      return null;
  }
};

const getSecondCopySection = (
  selectedMeasureType: MeasureType,
  metricKind: Metric['kind'],
  unitAggregationType: Metric['unitAggregationType'],
) => {
  if (selectedMeasureType === 'count') {
    return ' where ';
  }
  switch (metricKind) {
    case 'custom':
      if (selectedMeasureType === 'value') {
        return (
          <span>
            {' '}
            event{' '}
            <span className={styles.commaSpan}>
              <CodeSpan textValue={unitAggregationType?.toString() ?? ''} />,
            </span>{' '}
            where{' '}
          </span>
        );
      } else if (selectedMeasureType === 'occurrence') {
        return ' units that sent the event, where ';
      }
      break;
    case 'click':
      return ' units that clicked, where ';
    case 'pageview':
      return ' units that saw the page, where ';
    default:
      break;
  }
};
