import { useParams } from 'react-router';
import { useProjectContext } from '@gonfalon/context';
import { toOnboarding, toProjectEnvironmentKeys } from '@gonfalon/navigator';

export function useShouldShowOnboarding() {
  const projectContext = useProjectContext({ optional: true });
  // TODO: Remove params logic when new IA is fully released and we no longer support the old routes
  const { projKey, projectKey, envKey, environmentKey } = useParams();
  let onboardingPath = null;
  let projectSDKKeysPath = null;

  if (projectContext && projectContext.project && projectContext.selectedEnvironment) {
    onboardingPath = toOnboarding({
      projectKey: projectContext.project.key,
    });
    projectSDKKeysPath = toProjectEnvironmentKeys({
      projectKey: projectContext.project.key,
      environmentKey: projectContext.selectedEnvironment.key,
    });
  } else if (projKey && envKey) {
    onboardingPath = toOnboarding({
      projectKey: projKey,
    });
  } else if (projectKey && environmentKey) {
    onboardingPath = toOnboarding({
      projectKey,
    });
  }

  return { shouldShowOnboarding: !!onboardingPath, onboardingPath, projectSDKKeysPath };
}
