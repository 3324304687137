import { useNavigate } from 'react-router';
import { track } from '@gonfalon/analytics';
import { enforceEmailVerificationLandingPage } from '@gonfalon/dogfood-flags';

import { fetchMembers } from 'actions/members';
import { navigateToGetStarted, navigateToVerifyEmail, redirectToLogin } from 'actions/navigation';
import { reloadProfile } from 'actions/profile';
import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { accountSelector, createRequestSeatFormSelector } from 'reducers/account';
import {
  createAccount as accountAPICreateAccount,
  createUnverifiedMember as accountAPICreateUnverifiedMember,
  deleteAccount as accountAPIDeleteAccount,
  deleteAccountToken as accountAPIDeleteAccountToken,
  getAccount as accountAPIGetAccount,
  getApiVersions as accountAPIGetAPIVersions,
  requestEnterpriseSeats as accountAPIRequestEnterpriseSeats,
  revokeAllSessions as accountAPIRevokeAllSessions,
  updateAccount as accountAPIUpdateAccount,
  updateAccountOwner as accountAPIUpdateAccountOwner,
} from 'sources/AccountAPI';
import { login } from 'sources/AuthAPI';
import {
  Account,
  APIVersions,
  Member,
  OwnerInfo,
  RequestSeatsFormRecord,
  RequestSeatsFormType,
  SignupForm,
  SignUpFormType,
} from 'utils/accountUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { trackLoginForm } from 'utils/loginUtils';
import Logger from 'utils/logUtils';
import { MemberFilters } from 'utils/memberUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const logger = Logger.get('AccountActions');

function shouldFetchAccount(state: GlobalState) {
  const account = accountSelector(state);
  return !account.get('lastFetched') && !account.get('isFetching');
}

const requestAccount = () =>
  ({
    type: 'account/REQUEST_ACCOUNT',
  }) as const;

const requestAccountDone = (account: Account) =>
  ({
    type: 'account/REQUEST_ACCOUNT_DONE',
    account,
  }) as const;

const requestAccountFailed = (error: ImmutableServerError) =>
  ({
    type: 'account/REQUEST_ACCOUNT_FAILED',
    error,
  }) as const;

function fetchAccount() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestAccount());
    return accountAPIGetAccount()
      .then((account: Account) => dispatch(requestAccountDone(account)))
      .catch((error: ImmutableServerError) => dispatch(requestAccountFailed(error)));
  };
}

function fetchAccountIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchAccount(getState())) {
      return dispatch(fetchAccount());
    }
  };
}

const requestUpdateAccount = (oldAccount: Account, newAccount: Account) =>
  ({
    type: 'account/UPDATE_ACCOUNT',
    oldAccount,
    newAccount,
  }) as const;

const requestUpdateAccountDone = (account: Account, options: UpdateAccountOptions) =>
  ({
    type: 'account/UPDATE_ACCOUNT_DONE',
    account,
    options,
  }) as const;

const requestUpdateAccountFailed = (account: Account, error: ImmutableServerError) =>
  ({
    type: 'account/UPDATE_ACCOUNT_FAILED',
    account,
    error,
  }) as const;

export type UpdateAccountOptions = {
  pathOnDone?: string;
  isEnablingGhosting?: boolean;
  isSupportGenAiEnabled?: boolean;
};
export type UseUpdateAccountThunk = (
  oldAccount: Account,
  newAccount: Account,
  options?: UpdateAccountOptions,
) => Promise<Account>;

export function useUpdateAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (oldAccount: Account, newAccount: Account, options: UpdateAccountOptions = {}): Promise<Account> => {
    dispatch(requestUpdateAccount(oldAccount, newAccount));
    return new Promise((resolve, reject) =>
      accountAPIUpdateAccount(oldAccount, newAccount).then(
        async (account: Account) => {
          if (options.pathOnDone) {
            await navigate({ pathname: options.pathOnDone });
          }
          dispatch(requestUpdateAccountDone(account, options));
          resolve(account);
        },
        (error: ImmutableServerError) => {
          dispatch(requestUpdateAccountFailed(newAccount, error));
          reject(error);
        },
      ),
    );
  };
}

const requestUpdateAccountOwner = (promotedMember: OwnerInfo) =>
  ({
    type: 'account/UPDATE_ACCOUNT_OWNER',
    promotedMember,
  }) as const;

const requestUpdateAccountOwnerDone = (promotedMember: OwnerInfo) =>
  ({
    type: 'account/UPDATE_ACCOUNT_OWNER_DONE',
    promotedMember,
  }) as const;

const requestUpdateAccountOwnerFailed = (promotedMember: OwnerInfo, error: ImmutableServerError) =>
  ({
    type: 'account/UPDATE_ACCOUNT_OWNER_FAILED',
    promotedMember,
    error,
  }) as const;

function updateAccountOwner(promotedMember: OwnerInfo, filter?: MemberFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestUpdateAccountOwner(promotedMember));
    return new Promise<OwnerInfo>((resolve, reject) =>
      accountAPIUpdateAccountOwner(promotedMember).then(
        () => {
          dispatch(fetchMembers(filter)).catch((e) => e);
          dispatch(reloadProfile()).catch((err) => err);
          dispatch(requestUpdateAccountOwnerDone(promotedMember));
          resolve(promotedMember);
        },
        (error: ImmutableServerError) => {
          dispatch(requestUpdateAccountOwnerFailed(promotedMember, error));
          reject(error);
        },
      ),
    );
  };
}

const selectAccountOwner = (field: 'promotedMember', value: Member) =>
  ({
    type: 'account/SELECT_ACCOUNT_OWNER',
    field,
    value,
  }) as const;

const requestRevokeAllSessions = () =>
  ({
    type: 'account/REVOKE_ALL_SESSIONS',
  }) as const;

const requestRevokeAllSessionsDone = () =>
  ({
    type: 'account/REVOKE_ALL_SESSIONS_DONE',
  }) as const;

const requestRevokeAllSessionsFailed = (error: ImmutableServerError) =>
  ({
    type: 'account/REVOKE_ALL_SESSIONS_FAILED',
    error,
  }) as const;

function revokeAllSessions() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestRevokeAllSessions());
    return new Promise((resolve) =>
      accountAPIRevokeAllSessions().then(
        (session) => {
          dispatch(requestRevokeAllSessionsDone());
          location.reload();
          resolve(session);
        },
        (error: ImmutableServerError) => {
          dispatch(requestRevokeAllSessionsFailed(error));
        },
      ),
    );
  };
}

const requestCreateAccount = (account: SignupForm) =>
  ({
    type: 'account/CREATE_ACCOUNT',
    account,
  }) as const;

const requestCreateAccountFailed = (account: SignupForm, error: ImmutableServerError) =>
  ({
    type: 'account/CREATE_ACCOUNT_FAILED',
    account,
    error,
  }) as const;

const requestCreateUnverifiedMember = (account: SignupForm) =>
  ({
    type: 'account/CREATE_UNVERIFIED_MEMBER',
    account,
  }) as const;

const requestCreateUnverifiedMemberFailed = (account: SignupForm, error: ImmutableServerError) =>
  ({
    type: 'account/CREATE_UNVERIFIED_MEMBER_FAILED',
    account,
    error,
  }) as const;

const requestCreateUnverifiedMemberDone = (account: SignupForm) =>
  ({
    type: 'account/CREATE_UNVERIFIED_MEMBER_DONE',
    account,
  }) as const;

function createAccount(account: SignupForm) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestCreateAccount(account));
    trackLoginForm('App Signup Form Submitted');
    return accountAPICreateAccount(account)
      .then(async () => {
        track('App Signup Successful', '', {
          email: account.username,
          firstName: account.firstName,
          lastName: account.lastName,
          organization: account.organization,
          referrer: window.location.href,
          oauth: account.oauth,
        });
        await login(account).then(() => {
          if (!enforceEmailVerificationLandingPage()) {
            navigateToGetStarted(true);
            return;
          } else {
            navigateToVerifyEmail();
          }
        });
      })
      .catch((error: ImmutableServerError) => {
        logger.error(`Error creating account: ${error}`);
        return dispatch(requestCreateAccountFailed(account, error));
      });
  };
}

function createUnverifiedMember(account: SignupForm) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestCreateUnverifiedMember(account));
    trackLoginForm('App Signup Form Submitted');

    return accountAPICreateUnverifiedMember(account)
      .then(async (response) => {
        if (response?.redirected && response?.url && response.url.includes('join-your-org')) {
          window.location.assign(response.url);
          return;
        }
        dispatch(requestCreateUnverifiedMemberDone(account));
        track('App Signup Successful', '', {
          email: account.username,
          firstName: account.firstName,
          lastName: account.lastName,
          organization: account.organization,
          referrer: window.location.href,
          oauth: account.oauth,
        });

        // we're in an environment that doesn't enforce email verification landing page
        // the full account was created in the backend
        // we log the user in
        if (!enforceEmailVerificationLandingPage()) {
          await login(account).then(() => {
            navigateToGetStarted(true);
            return;
          });
        }
      })
      .catch((error: ImmutableServerError) => {
        logger.error(`Error creating unverified member: ${error}`);
        return dispatch(requestCreateUnverifiedMemberFailed(account, error));
      });
  };
}

const requestDeleteAccountFailed = (error: ImmutableServerError) =>
  ({
    type: 'account/DELETE_ACCOUNT_FAILED',
    error,
  }) as const;

const requestDeleteAccount = () =>
  ({
    type: 'account/DELETE_ACCOUNT',
  }) as const;

function deleteAccount() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestDeleteAccount());
    return accountAPIDeleteAccount()
      .then(() => {
        redirectToLogin();
      })
      .catch((error: ImmutableServerError) => dispatch(requestDeleteAccountFailed(error)));
  };
}

const requestEditNewAccount = (field: keyof SignUpFormType, account: SignupForm) =>
  ({
    type: 'account/EDIT_NEW_ACCOUNT',
    field,
    account,
  }) as const;

function editNewAccount(field: keyof SignUpFormType, value: string) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().createAccountForm.get('form');
    return dispatch(requestEditNewAccount(field, formState.modified.set(field, value)));
  };
}

const requestDeleteAccountToken = (account: Account) =>
  ({
    type: 'account/DELETE_ACCOUNT_TOKEN',
    account,
  }) as const;

const requestDeleteAccountTokenDone = (account: Account) =>
  ({
    type: 'account/DELETE_ACCOUNT_TOKEN_DONE',
    account,
  }) as const;

const requestDeleteAccountTokenFailed = (account: Account, error: ImmutableServerError) =>
  ({
    type: 'account/DELETE_ACCOUNT_TOKEN_FAILED',
    account,
    error,
  }) as const;

function deleteAccountToken(account: Account) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestDeleteAccountToken(account));
    return accountAPIDeleteAccountToken(account).then(
      () => dispatch(requestDeleteAccountTokenDone(account)),
      (error: ImmutableServerError) => dispatch(requestDeleteAccountTokenFailed(account, error)),
    );
  };
}

const requestAPIVersions = () =>
  ({
    type: 'account/REQUEST_API_VERSIONS',
  }) as const;

const requestAPIVersionsDone = (versions: APIVersions) =>
  ({
    type: 'account/REQUEST_API_VERSIONS_DONE',
    versions,
  }) as const;

const requestAPIVersionsFailed = (error: ImmutableServerError) =>
  ({
    type: 'account/REQUEST_API_VERSIONS_FAILED',
    error,
  }) as const;

const requestSeatsRequest = (seatsRequest: RequestSeatsFormRecord) =>
  ({
    type: 'account/CREATE_SEATS_REQUEST',
    seatsRequest,
  }) as const;

const requestSeatsRequestDone = (seatsRequest: RequestSeatsFormRecord) =>
  ({
    type: 'account/CREATE_SEATS_REQUEST_DONE',
    seatsRequest,
  }) as const;

const requestSeatsRequestFailed = (seatsRequest: RequestSeatsFormRecord, error: ImmutableServerError) =>
  ({
    type: 'account/CREATE_SEATS_REQUEST_FAILED',
    seatsRequest,
    error,
  }) as const;

const editSeatRequestFieldAction = (field: keyof RequestSeatsFormType, seatsRequest: RequestSeatsFormRecord) =>
  ({
    type: 'account/EDIT_SEAT_REQUEST_FIELD',
    field,
    seatsRequest,
  }) as const;

function editSeatRequestField(field: keyof RequestSeatsFormType, value: string | number) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = createRequestSeatFormSelector(getState());
    const modified = form.modified.set(field, value);
    return dispatch(editSeatRequestFieldAction(field, modified));
  };
}

function requestEnterpriseSeats(seatsRequest: RequestSeatsFormRecord) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestSeatsRequest(seatsRequest));
    return accountAPIRequestEnterpriseSeats(seatsRequest).then(
      () => dispatch(requestSeatsRequestDone(seatsRequest)),
      (error: ImmutableServerError) => dispatch(requestSeatsRequestFailed(seatsRequest, error)),
    );
  };
}

function fetchApiVersions() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestAPIVersions());
    return accountAPIGetAPIVersions()
      .then((versions) => dispatch(requestAPIVersionsDone(versions)))
      .catch((error: ImmutableServerError) => dispatch(requestAPIVersionsFailed(error)));
  };
}

export {
  fetchAccountIfNeeded as fetchAccount,
  updateAccountOwner,
  selectAccountOwner,
  requestEnterpriseSeats,
  createAccount,
  createUnverifiedMember,
  deleteAccount,
  editNewAccount,
  revokeAllSessions,
  deleteAccountToken,
  fetchApiVersions,
  editSeatRequestField,
};

export const AccountActionCreators = {
  requestAccount,
  requestAccountDone,
  requestAccountFailed,
  requestUpdateAccount,
  requestUpdateAccountDone,
  requestUpdateAccountFailed,
  requestUpdateAccountOwner,
  requestUpdateAccountOwnerDone,
  requestRevokeAllSessions,
  requestRevokeAllSessionsDone,
  requestRevokeAllSessionsFailed,
  requestUpdateAccountOwnerFailed,
  requestCreateAccount,
  requestCreateAccountFailed,
  requestCreateUnverifiedMember,
  requestCreateUnverifiedMemberFailed,
  requestCreateUnverifiedMemberDone,
  requestDeleteAccount,
  requestDeleteAccountFailed,
  requestEditNewAccount,
  requestDeleteAccountToken,
  requestDeleteAccountTokenDone,
  requestDeleteAccountTokenFailed,
  requestAPIVersions,
  requestAPIVersionsDone,
  requestAPIVersionsFailed,
  selectAccountOwner,
  requestSeatsRequest,
  requestSeatsRequestDone,
  requestSeatsRequestFailed,
  editSeatRequestFieldAction,
};

export type AccountAction = GenerateActionType<typeof AccountActionCreators>;
