import { Tag, TagGroup, TagList } from '@launchpad-ui/components';
import clsx from 'clsx';

import styles from './CodeSpan.module.css';
export const CodeSpan: React.FC<{ textValue: string; className?: string }> = ({ textValue, className }) => (
  <TagGroup className={styles.inlineBlock} aria-label={textValue}>
    <TagList>
      <Tag className={clsx(className, styles.codeTag)} size="small">
        {textValue}
      </Tag>
    </TagList>
  </TagGroup>
);
