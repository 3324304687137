import { useProjectExperimentationSettings as useProjectExperimentationSettingsNew } from '@gonfalon/experiments';

import { ExperimentationSettings } from '../types';

type Props = {
  projKey: string;
  enabled?: boolean;
};

// @deprecated Use @gonfalon/experiments#useProjectExperimentationSettings instead
export function useProjectExperimentationSettings({ projKey, enabled = true }: Props) {
  const { data, ...rest } = useProjectExperimentationSettingsNew({ projectKey: projKey, enabled });

  return {
    ...rest,
    // hack to make the return type compatible with the old hook
    data: data as ExperimentationSettings,
  };
}
