import cx from 'clsx';

import styles from './DataListToolbar.module.css';

export type DataListToolbarProps = {
  className?: string;
  showBorder?: boolean;
  children?: React.ReactNode;
};

export const DataListToolbar = ({ className, children, showBorder = true }: DataListToolbarProps) => (
  <div
    className={cx(className, styles.dataListToolbar, {
      [styles.dataListToolbarBorder]: showBorder,
    })}
  >
    {children}
  </div>
);
