import { useStorageSlot } from '@gonfalon/browser-storage';

import type { OnboardingFlag, OnboardingPath, OnboardingValues } from './types';

export const defaultOnboardingValues: OnboardingValues = {
  path: 'flags',
  sdk: 'react-client-sdk',
  flags: { createAFlag: { flagKey: '', flagName: '', flagOn: false } },
  experiments: { createAFlag: { flagKey: '', flagName: '', flagOn: false } },
};

export const useOnboardingStorage = () => {
  const [currentString, slot] = useStorageSlot('onboarding', { storageType: 'sessionStorage' });
  const current = currentString ? (JSON.parse(currentString) as OnboardingValues) : defaultOnboardingValues;

  const setOnboardingStorage = (d: Partial<OnboardingValues>) => {
    slot.set(
      JSON.stringify({
        ...current, // include previous values
        ...d, // override with new values
      }),
    );
  };

  const setCreateAFlag = (path: OnboardingPath, flag: OnboardingFlag) => {
    const flagUpdate: { createAFlag: OnboardingFlag } = { createAFlag: flag };
    setOnboardingStorage({ [path]: flagUpdate });
  };

  const getCreateAFlag = (path: OnboardingPath) => current[path].createAFlag;

  return { data: current, setOnboardingStorage, setCreateAFlag, getCreateAFlag };
};
