import { Modal as _Modal, ModalOverlay } from '@launchpad-ui/components';

import { ChangeHistory } from '../ChangeHistory/ChangeHistory';
import { ChangeHistoryItem } from '../ChangeHistory/ChangeHistoryItem';
import { useChangeHistory } from '../useChangeHistory';

import { useChangeHistoryDetails } from './useChangeHistoryDetails';

import styles from './ChangeHistoryModal.module.css';

export function ChangeHistoryModal() {
  const { open, close, isChangeHistoryVisible, auditId } = useChangeHistory();
  const { hasExistingChangeHistoryEntryId, close: closeChangeHistoryDetails } = useChangeHistoryDetails();
  return (
    <ModalOverlay
      isOpen={isChangeHistoryVisible}
      onOpenChange={(nextIsOpen) => {
        if (hasExistingChangeHistoryEntryId) {
          return closeChangeHistoryDetails();
        }
        return nextIsOpen ? open() : close();
      }}
    >
      <_Modal className={styles.modal} isOpen>
        {auditId ? <ChangeHistoryItem id={auditId} /> : <ChangeHistory />}
      </_Modal>
    </ModalOverlay>
  );
}
