import { useCallback } from 'react';

import { AppState } from './types';
import { AppDispatch } from './useAppReducer';

export function updatePreviewAsset(state: AppState['assetPreviewState'], dispatch: AppDispatch) {
  dispatch({ type: 'update_asset_preview_state', payload: state });
}

export default function useAppServices(dispatch: AppDispatch) {
  const updateBlockConfigureState = useCallback(
    (state: AppState['blockConfigureState']) => {
      dispatch({ type: 'update_block_configure_ui', payload: state });
    },
    [dispatch],
  );

  const updatePreviewAssetState = useCallback(
    (state: AppState['assetPreviewState']) => updatePreviewAsset(state, dispatch),
    [dispatch],
  );

  return {
    updateBlockConfigureState,
    updatePreviewAssetState,
  };
}
