import { type ComponentProps, ReactNode, Suspense, useContext, useState, useTransition } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  CheckboxProps,
  Collection,
  ListBoxContext,
  OverlayTriggerStateContext,
  Selection,
  Text,
} from 'react-aria-components';
import {
  actionsRelatedToCollaboration,
  actionsRelatedToEnvironmentSettings,
  actionsRelatedToFlagSettings,
  actionsRelatedToTargetingChanges,
  allFlagActions,
} from '@gonfalon/audit-log';
import { useSelectedEnvironmentKey } from '@gonfalon/context';
import {
  enableAIBundle,
  enableHoldoutsUI,
  enableReleaseAutomation,
  selectAllPersistence,
} from '@gonfalon/dogfood-flags';
import { EnvironmentMarker } from '@gonfalon/environments';
import { isEqual } from '@gonfalon/es6-utils';
import {
  HelpDialog,
  ListBoxDialog,
  ListBoxDialogProps,
  ListBoxItem,
  TextTruncator,
} from '@gonfalon/launchpad-experimental';
import { MemberAvatar, memberDisplayName, memberInitials } from '@gonfalon/members';
import { ResourceSpecifier } from '@gonfalon/resource-specifiers';
import {
  ActionTypes,
  environmentsDetail,
  environmentsList,
  getAiConfigsQuery,
  getFeatureFlagsQuery,
  holdoutsList,
  membersQuery,
  metricsDetail,
  metricsList,
  projectsDetail,
  projectsList,
  segmentList,
  teamsList,
  useMember,
  useSuspenseActions,
  useTeam,
} from '@gonfalon/rest-api';
import { conditionalSuspenseQuery, isSuspenseQueryEnabled } from '@gonfalon/suspense';
import {
  Button,
  Checkbox as LPCheckBox,
  DialogTrigger,
  Header,
  ListBox,
  ListBoxSection,
  Popover,
  ProgressBar,
  Select,
  SelectValue,
} from '@launchpad-ui/components';
import { Box, Column } from '@launchpad-ui/core';
import { Icon } from '@launchpad-ui/icons';
import { useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query';
import nullthrows from 'nullthrows';

import { anyAction, anyResource } from '../../ChangeHistory/ChangeHistoryUtils';
import { useChangeHistoryDisplayPreferences } from '../useChangeHistoryDisplayPreferences/useChangeHistoryDisplayPreferences';

import styles from './ActivityResourceFilters.module.css';

export function FlagResourceFilter({
  onChange,
  project,
  flag,
  environments,
  actions,
}: {
  onChange: (nextFilter: { project: string; flag: string; environments: string[]; actions: string[] }) => void;
  project: string;
  flag: string;
  environments: string[];
  actions: string[];
}) {
  const { updateWithSelection } = useChangeHistoryDisplayPreferences();

  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection).length ? Array.from(selection) : [anyResource];

                  onChange({
                    environments,
                    flag,
                    actions,
                    project: String(selectedProject),
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {flag ? <SelectedItem resource="Flag" value={flag} /> : <DefaultItem resource="Flag" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <FlagDialog
                title="Choose flag"
                flagKey={flag}
                projectKey={project}
                onSelectionChange={(selection) => {
                  const [selectedFlag] = Array.from(selection).length ? Array.from(selection) : [anyResource];
                  onChange({ environments, actions, project, flag: String(selectedFlag) });
                }}
              />
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {environments ? (
                <SelectedItem
                  resource="Env"
                  value={environments.length === 1 ? environments[0] : `${environments.length} envs`}
                />
              ) : (
                <DefaultItem resource="Env" />
              )}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <EnvironmentDialog
                title="Choose environment"
                selectionMode="multiple"
                disallowEmptySelection
                defaultSelectedKeys={environments}
                environmentKeys={environments}
                projectKey={project}
                onSelectionChange={(selection) => {
                  // Todo: update to typeguard
                  const environment = Array.from(selection) as string[];
                  const latestSelectedEnvironment = environment[environment.length - 1];
                  onChange({
                    project,
                    flag,
                    actions,
                    environments:
                      latestSelectedEnvironment === anyResource
                        ? [String(anyResource)]
                        : environment.filter((env) => env !== anyResource),
                  });
                }}
              />
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>

      <ActionsResourceFilter
        actions={actions}
        resource="flag"
        onChange={(selection) => {
          const action = Array.from(selection);
          const latestSelectedAction = action[action.length - 1];

          if (selectAllPersistence()) {
            if (isEqual(action.sort(), allFlagActions.sort())) {
              updateWithSelection(new Set(['showAllActions']));
            } else {
              updateWithSelection(new Set([]));
            }
          }
          onChange({
            project,
            flag,
            environments,
            actions: latestSelectedAction === anyResource ? anyAction : action.filter((a) => a !== anyResource),
          });
        }}
      />
    </>
  );
}

export function EnvironmentResourceFilter({
  onChange,
  project,
  environments,
  actions,
}: {
  onChange: (nextFilter: { project: string; environments: string[]; actions: string[] }) => void;
  project: string;
  environments: string[];
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                disallowEmptySelection
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection);
                  onChange({
                    environments: [anyResource],
                    actions,
                    project: String(selectedProject),
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {environments ? (
                <SelectedItem
                  resource="Env"
                  value={environments.length === 1 ? environments[0] : `${environments.length} envs`}
                />
              ) : (
                <DefaultItem resource="Environment" />
              )}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <EnvironmentDialog
                disallowEmptySelection
                title="Choose environment"
                projectKey={project}
                selectionMode="single"
                defaultSelectedKeys={[environments[0]]}
                environmentKeys={[environments[0]]}
                onSelectionChange={(selection) => {
                  const [selectedEnvironment] = Array.from(selection);
                  onChange({
                    project,
                    actions,
                    environments: [String(selectedEnvironment)],
                  });
                }}
              />
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>

      <ActionsResourceFilter
        actions={actions}
        resource="environment"
        onChange={(selection) => {
          const action = Array.from(selection);
          const latestSelectedAction = action[action.length - 1];

          onChange({
            project,
            environments,
            actions: latestSelectedAction === anyResource ? anyAction : action.filter((a) => a !== anyResource),
          });
        }}
      />
    </>
  );
}

export function MemberResourceFilter({
  onChange,
  member,
  actions,
}: {
  onChange: (nextFilter: { member: string; actions: string[] }) => void;
  member: string;
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {member ? <MemberSelectedValue resource="Member" value={member} /> : <DefaultItem resource="Member" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <MemberDialog
                title="Choose member"
                maintainerId={member}
                onSelectionChange={(selection) => {
                  const [selectedMember] = Array.from(selection);
                  onChange({ actions, member: String(selectedMember) });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <ActionsResourceFilter
        actions={actions}
        resource="member"
        onChange={(selectedActions) => {
          onChange({ member, actions: selectedActions });
        }}
      />
    </>
  );
}

export function WildCardResourceFilter({ resource }: { resource: string }) {
  return (
    <Column size="content">
      <Box>
        <Button variant="default" className={styles.button} isDisabled>
          <SelectedItem resource={resource} value="*" />
          <Icon name="chevron-down" size="small" />
        </Button>
      </Box>
    </Column>
  );
}

export function ProjectResourceFilter({
  onChange,
  project,
  actions,
}: {
  onChange: (nextFilter: { project: string; actions: string[] }) => void;
  project: string;
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection);
                  onChange({
                    actions,
                    project: String(selectedProject),
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>
      <ActionsResourceFilter
        actions={actions}
        resource="member"
        onChange={(selectedActions) => {
          onChange({ project, actions: selectedActions });
        }}
      />
    </>
  );
}

export function MetricResourceFilter({
  onChange,
  metric,
  project,
  actions,
}: {
  onChange: (nextFilter: { project: string; metric: string; actions: string[] }) => void;
  metric: string;
  project: string;
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection).length ? Array.from(selection) : [anyResource];

                  onChange({
                    project: String(selectedProject),
                    metric,
                    actions,
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {metric ? <SelectedItem resource="Metric" value={metric} /> : <DefaultItem resource="Metric" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <MetricDialog
                title="Choose metric"
                metricKey={metric}
                project={project}
                className={styles.metricDialog}
                onSelectionChange={(selection) => {
                  const [selectedMetric] = Array.from(selection).length ? Array.from(selection) : [anyResource];

                  onChange({
                    project,
                    metric: String(selectedMetric),
                    actions,
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>
      <ActionsResourceFilter
        actions={actions}
        resource="metric"
        onChange={(selectedActions) => {
          onChange({ project, metric, actions: selectedActions });
        }}
      />
    </>
  );
}

export function ProjectScopedResourceFilter({
  onChange,
  project,
  resource,
}: {
  onChange: (nextFilter: { project: string }) => void;
  project: string;
  resource: string;
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection);
                  onChange({
                    project: String(selectedProject),
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <WildCardResourceFilter resource={resource} />
    </>
  );
}

export function ExperimentsResourceFilter({
  onChange,
  project,
  environments,
  actions,
}: {
  onChange: (nextFilter: { project: string; environments: string[]; actions: string[] }) => void;
  project: string;
  environments: string[];
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection);
                  onChange({
                    environments,
                    actions,
                    project: String(selectedProject),
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {environments ? (
                <SelectedItem
                  resource="Env"
                  value={environments.length === 1 ? environments[0] : `${environments.length} envs`}
                />
              ) : (
                <DefaultItem resource="Environment" />
              )}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <EnvironmentDialog
                disallowEmptySelection
                title="Choose environment"
                projectKey={project}
                selectionMode="single"
                defaultSelectedKeys={[environments[0]]}
                environmentKeys={[environments[0]]}
                onSelectionChange={(selection) => {
                  const [selectedEnvironment] = Array.from(selection);
                  onChange({
                    project,
                    actions,
                    environments: [String(selectedEnvironment)],
                  });
                }}
              />
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>

      <WildCardResourceFilter resource="experiment" />
    </>
  );
}

export function HoldoutsResourceFilter({
  onChange,
  project,
  environments,
  holdout,
  actions,
}: {
  onChange: (nextFilter: { project: string; environments: string[]; actions: string[]; holdoutKey: string }) => void;
  project: string;
  environments: string[];
  holdout: string;
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection);
                  onChange({
                    environments,
                    actions,
                    project: String(selectedProject),
                    holdoutKey: holdout,
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {environments ? (
                <SelectedItem
                  resource="Env"
                  value={environments.length === 1 ? environments[0] : `${environments.length} envs`}
                />
              ) : (
                <DefaultItem resource="Environment" />
              )}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <EnvironmentDialog
                disallowEmptySelection
                title="Choose environment"
                projectKey={project}
                selectionMode="single"
                defaultSelectedKeys={[environments[0]]}
                environmentKeys={[environments[0]]}
                onSelectionChange={(selection) => {
                  const [selectedEnvironment] = Array.from(selection);
                  onChange({
                    project,
                    actions,
                    environments: [String(selectedEnvironment)],
                    holdoutKey: holdout,
                  });
                }}
              />
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {holdout ? <SelectedItem resource="holdout" value={holdout} /> : <DefaultItem resource="holdout" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <EnvironmentScopedEntity environment={environments[0]}>
                <HoldoutDialog
                  title="Choose holdout"
                  holdoutKey={holdout}
                  projectKey={project}
                  environmentKey={environments[0]}
                  onSelectionChange={(selection) => {
                    const [selectedHoldout] = Array.from(selection).length ? Array.from(selection) : [anyResource];

                    onChange({
                      project,
                      environments,
                      actions,
                      holdoutKey: String(selectedHoldout),
                    });
                  }}
                />
              </EnvironmentScopedEntity>
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>

      <ActionsResourceFilter
        actions={actions}
        resource="holdout"
        onChange={(selection) => {
          const action = Array.from(selection);
          const latestSelectedAction = action[action.length - 1];

          onChange({
            project,
            holdoutKey: holdout,
            environments,
            actions: latestSelectedAction === anyResource ? anyAction : action.filter((a) => a !== anyResource),
          });
        }}
      />
    </>
  );
}

export function TeamResourceFilter({
  onChange,
  team,
  actions,
}: {
  onChange: (nextFilter: { team: string; actions: string[] }) => void;
  team: string;
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {team ? <TeamSelectedValue resource="Team" value={team} /> : <DefaultItem resource="Team" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <TeamDialog
                title="Choose team"
                maintainerTeamKey={team}
                onSelectionChange={(selection) => {
                  const [selectedTeam] = Array.from(selection);
                  onChange({ actions, team: String(selectedTeam) });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>
      <ActionsResourceFilter
        actions={actions}
        resource="team"
        onChange={(selectedActions) => {
          onChange({ team, actions: selectedActions });
        }}
      />
    </>
  );
}

export function SegmentResourceFilter({
  onChange,
  project,
  segment,
  environments,
  actions,
}: {
  onChange: (nextFilter: { project: string; segment: string; environments: string[]; actions: string[] }) => void;
  project: string;
  segment: string;
  environments: string[];
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection).length ? Array.from(selection) : [anyResource];

                  onChange({
                    environments,
                    actions,
                    segment,
                    project: String(selectedProject),
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {segment ? <SelectedItem resource="Segment" value={segment} /> : <DefaultItem resource="Segment" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <EnvironmentScopedEntity environment={environments[0]}>
                <SegmentDialog
                  title="Choose segment"
                  segmentKey={segment}
                  projectKey={project}
                  environmentKey={environments[0]}
                  onSelectionChange={(selection) => {
                    const [selectedSegment] = Array.from(selection).length ? Array.from(selection) : [anyResource];

                    onChange({
                      project,
                      environments,
                      actions,
                      segment: String(selectedSegment),
                    });
                  }}
                />
              </EnvironmentScopedEntity>
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {environments ? <SelectedItem resource="Env" value={environments[0]} /> : <DefaultItem resource="Env" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <ProjectScopedEntity project={project}>
              <EnvironmentDialog
                title="Choose environment"
                projectKey={project}
                selectionMode="single"
                defaultSelectedKeys={[environments[0]]}
                environmentKeys={[environments[0]]}
                onSelectionChange={(selection) => {
                  const [selectedEnvironment] = Array.from(selection).length ? Array.from(selection) : [anyResource];
                  onChange({
                    project,
                    segment,
                    actions,
                    environments: [String(selectedEnvironment)],
                  });
                }}
              />
            </ProjectScopedEntity>
          </Popover>
        </DialogTrigger>
      </Column>
      <ActionsResourceFilter
        actions={actions}
        resource="segment"
        onChange={(selection) => {
          const action = Array.from(selection);
          const latestSelectedAction = action[action.length - 1];

          onChange({
            project,
            segment,
            environments,
            actions: latestSelectedAction === anyResource ? anyAction : action.filter((a) => a !== anyResource),
          });
        }}
      />
    </>
  );
}

export function AIConfigResourceFilter({
  onChange,
  config,
  project,
  actions,
}: {
  onChange: (nextFilter: { project: string; config: string; actions: string[] }) => void;
  config: string;
  project: string;
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection);
                  onChange({
                    config,
                    project: String(selectedProject),
                    actions,
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {config ? <SelectedItem resource="AI Config" value={config} /> : <DefaultItem resource="AI Config" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>

          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <AIConfigDialog
                title="Choose AI Config"
                aiConfigKey={config}
                project={project}
                onSelectionChange={(selection) => {
                  const [selectedConfig] = Array.from(selection);
                  onChange({
                    project,
                    config: String(selectedConfig),
                    actions,
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>

      <ActionsResourceFilter
        actions={actions}
        resource="aiconfig"
        onChange={(selectedActions) => {
          onChange({ project, config, actions: selectedActions });
        }}
      />
    </>
  );
}
export function AIModelConfigResourceFilter({
  onChange,
  config,
  project,
  actions,
}: {
  onChange: (nextFilter: { project: string; config: string; actions: string[] }) => void;
  config: string;
  project: string;
  actions: string[];
}) {
  return (
    <>
      <Column size="content">
        <DialogTrigger>
          <Box marginRight="$300">
            <Button variant="default" className={styles.button}>
              {project ? <SelectedItem resource="Project" value={project} /> : <DefaultItem resource="Project" />}
              <Icon name="chevron-down" size="small" />
            </Button>
          </Box>
          <Popover>
            <Suspense
              fallback={
                <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                  <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                </Box>
              }
            >
              <ProjectDialog
                title="Choose project"
                projectId={project}
                onSelectionChange={(selection) => {
                  const [selectedProject] = Array.from(selection);
                  onChange({
                    config,
                    project: String(selectedProject),
                    actions,
                  });
                }}
              />
            </Suspense>
          </Popover>
        </DialogTrigger>
      </Column>
      <ActionsResourceFilter
        actions={actions}
        resource="ai-model-config"
        onChange={(selectedActions) => {
          onChange({ project, config, actions: selectedActions });
        }}
      />
    </>
  );
}

export function ActionsResourceFilter({
  onChange,
  actions,
  resource,
}: {
  actions: string[];
  resource: ActionTypes;
  onChange: (actions: string[]) => void;
}) {
  return (
    <Column size="content">
      <DialogTrigger>
        <Box marginRight="$200">
          <Button variant="default" className={styles.button}>
            {actions ? (
              <SelectedItem
                resource="Actions"
                value={actions.length === 1 ? actions[0] : `${actions.length} actions`}
              />
            ) : (
              <DefaultItem resource="Actions" />
            )}

            <Icon name="chevron-down" size="small" />
          </Button>
        </Box>
        <Popover>
          <Suspense
            fallback={
              <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
                <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
              </Box>
            }
          >
            <ActionsDialog
              title="Choose action"
              actions={actions}
              resource={resource}
              onSelectionChange={(selection) => {
                const selectedActions = Array.from(selection);
                onChange(selectedActions as string[]);
              }}
            />
          </Suspense>
        </Popover>
      </DialogTrigger>
    </Column>
  );
}

function ProjectScopedEntity({ project, children }: { project?: string; children: ReactNode }) {
  if (!project || project === '*') {
    return <HelpDialog title="Info" message="You must pick a project first." />;
  }
  return (
    <Suspense
      fallback={
        <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
          <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
}
function EnvironmentScopedEntity({ environment, children }: { environment?: string; children: ReactNode }) {
  if (!environment || environment === '*') {
    return <HelpDialog title="Info" message="You must pick an environment first." />;
  }
  return (
    <Suspense
      fallback={
        <Box width="$192" height="$208" display="grid" alignItems="center" justifyContent="center">
          <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
}
function SelectedItem({ resource, value }: { resource: string; value: string }) {
  const coercedAnyValue = value === '*' ? 'All' : value;
  return (
    <TextTruncator>
      <p className={styles.selectedItemPrefix}>{resource}:</p> {coercedAnyValue}
    </TextTruncator>
  );
}

function MemberSelectedValue({ resource, value }: { resource: string; value: string }) {
  const isAllMembers = value === '*';
  let memberName;
  const maintainerMember = useMember({ memberId: value }, { enabled: !isAllMembers });

  if (maintainerMember.status === 'success') {
    const member = maintainerMember.data;
    const name = memberDisplayName(member);
    memberName = name;
  }

  return <SelectedItem resource={resource} value={memberName ?? value} />;
}

function TeamSelectedValue({ resource, value }: { resource: string; value: string }) {
  const isAllTeams = value === '*';
  let teamName;
  const maintainerTeam = useTeam({ teamKey: value }, { enabled: !isAllTeams });

  if (maintainerTeam.status === 'success') {
    const team = maintainerTeam.data;
    teamName = team.name;
  }

  return <SelectedItem resource={resource} value={teamName ?? value} />;
}

function DefaultItem({ resource }: { resource: string }) {
  return (
    <TextTruncator>
      {resource} <Icon name="chevrons-out" size="small" />
    </TextTruncator>
  );
}

export const ActivityResourceFiltersOptions = [
  {
    name: 'Account',
    value: 'acct',
  },
  ...(enableAIBundle()
    ? [
        {
          name: 'AI Config',
          value: 'aiconfig',
        },
      ]
    : []),
  ...(enableAIBundle()
    ? [
        {
          name: 'AI Model Config',
          value: 'ai-model-config',
        },
      ]
    : []),
  {
    name: 'Application',
    value: 'application',
  },
  {
    name: 'Code reference',
    value: 'code-reference-repository',
  },
  {
    name: 'Context kinds',
    value: 'context-kind',
  },
  {
    name: 'Environment',
    value: 'env',
  },
  {
    name: 'Experiment',
    value: 'experiment',
  },
  {
    name: 'Flag',
    value: 'flag',
  },
  ...(enableHoldoutsUI()
    ? [
        {
          name: 'Holdout',
          value: 'holdout',
        },
      ]
    : []),
  {
    name: 'Integration',
    value: 'integration',
  },
  {
    name: 'Layer',
    value: 'layer',
  },
  {
    name: 'Member',
    value: 'member',
  },
  {
    name: 'Metric',
    value: 'metric',
  },
  {
    name: 'Metric group',
    value: 'metric-group',
  },
  {
    name: 'Payload filter',
    value: 'payload-filter',
  },
  {
    name: 'Project',
    value: 'proj',
  },
  {
    name: 'Relay Proxy',
    value: 'relay-proxy-config',
  },
  ...(enableReleaseAutomation()
    ? [
        {
          name: 'Release pipeline',
          value: 'release-pipeline',
        },
      ]
    : []),
  {
    name: 'Role',
    value: 'role',
  },
  {
    name: 'Segment',
    value: 'segment',
  },
  {
    name: 'Service token',
    value: 'service-token',
  },
  {
    name: 'Team',
    value: 'team',
  },
  {
    name: 'Template',
    value: 'template',
  },
  {
    name: 'Webhook',
    value: 'webhook',
  },
];

export function ResourceKindPicker({
  value,
  onSelectionChange,
}: {
  value: ResourceSpecifier['type'];
  onSelectionChange: ComponentProps<typeof Select>['onSelectionChange'];
}) {
  return (
    <Select
      aria-label="Resource: "
      selectedKey={value}
      defaultSelectedKey={value}
      onSelectionChange={onSelectionChange}
    >
      <Button className={styles.button}>
        <div className={styles.selectedItemPrefix}>Resource: </div>
        <SelectValue className={styles.selectValueTruncate} />
        <Icon name="chevron-down" size="small" />
      </Button>
      <Popover>
        <ListBox className={styles.resourceKindPicker}>
          {ActivityResourceFiltersOptions.map((item) => (
            <ListBoxItem key={item.value} id={item.value} textValue={item.value} className={styles.listBoxItem}>
              <Text slot="label" className={styles.selectValueTruncate}>
                <TextTruncator>{item.name}</TextTruncator>
              </Text>
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
}

type SegmentComboBoxItem = {
  id: string;
  name: string;
  description?: string;
};
type SegmentDialogProps = Omit<ListBoxDialogProps<SegmentComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  segmentKey?: string;
  projectKey?: string;
  environmentKey?: string;
};

function SegmentDialog({ segmentKey, projectKey, environmentKey, ...props }: SegmentDialogProps) {
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');
  const data = useSuspenseQueries({
    queries: [
      segmentList({
        projectKey: String(projectKey),
        environmentKey: String(environmentKey),
        params: {
          expand: 'flags',
          limit: 10,
          sort: '-lastModified',
          filter: { query: filterText },
        },
      }),
    ],
  });
  const [segments] = data;

  const segmentItems: SegmentComboBoxItem[] = segments.data.items.map((segment) => ({
    id: segment.key,
    name: segment.name,
  }));

  segmentItems.unshift({
    id: '*',
    name: 'All',
  });

  const handleFilterTextChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  return (
    <ListBoxDialog<SegmentComboBoxItem>
      {...props}
      aria-label="Segments"
      items={segmentItems.length ? segmentItems : []}
      selectionMode="single"
      defaultSelectedKeys={segmentKey !== undefined ? [segmentKey] : []}
      isBusy={isPending}
      onFilterChange={handleFilterTextChange}
    >
      {(item) => (
        <ListBoxItem id={item.id} textValue={item.id}>
          <Text slot="label">
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
          {item.description && (
            <Text slot="description">
              <TextTruncator>{item.description}</TextTruncator>
            </Text>
          )}
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

type AIConfigComboBoxItem = {
  id: string;
  name: string;
};

type AIConfigDialogProps = Omit<ListBoxDialogProps<AIConfigComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  project: string;
  aiConfigKey?: string;
};

function AIConfigDialog({ aiConfigKey, project, ...props }: AIConfigDialogProps) {
  const [filterText, setFilterText] = useState('');
  const { data: aiConfigs } = useSuspenseQuery({
    ...getAiConfigsQuery({ projectKey: project, header: { 'LD-API-Version': 'beta' } }),
  });

  // TODO: When the search api is implemented, we should use it here.
  const aiConfigItems: AIConfigComboBoxItem[] = aiConfigs.items
    .filter((config) => filterText === '' || config.name.toLowerCase().includes(filterText.toLowerCase()))
    .map((config) => ({
      id: config.key,
      name: config.name,
    }));

  aiConfigItems.unshift({
    id: '*',
    name: 'All',
  });

  const handleFilterTextChange = (value: string) => {
    setFilterText(value);
  };

  return (
    <ListBoxDialog<AIConfigComboBoxItem>
      {...props}
      aria-label="AI Configs"
      items={aiConfigItems.length ? aiConfigItems : []}
      selectionMode="single"
      defaultSelectedKeys={aiConfigKey !== undefined ? [aiConfigKey] : []}
      onFilterChange={handleFilterTextChange}
    >
      {(item) => (
        <ListBoxItem id={item.id} textValue={item.id}>
          <Text slot="label">
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

type HoldoutComboBoxItem = {
  id: string;
  name: string;
};

type HoldoutDialogProps = Omit<ListBoxDialogProps<HoldoutComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  holdoutKey?: string;
  projectKey?: string;
  environmentKey?: string;
};
function HoldoutDialog({ holdoutKey, projectKey, environmentKey, ...props }: HoldoutDialogProps) {
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');

  const handleFilterTextChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };
  const data = useSuspenseQueries({
    queries: [
      holdoutsList({
        projectKey: String(projectKey),
        environmentKey: String(environmentKey),
      }),
    ],
  });
  const [holdouts] = data;

  let holdoutItems: HoldoutComboBoxItem[] = (holdouts?.data?.items || []).map((h) => ({
    id: h.key || '',
    name: h.name || '',
  }));

  holdoutItems.unshift({
    id: '*',
    name: 'All',
  });

  holdoutItems = holdoutItems.filter((item) => item.name.toLowerCase().includes(filterText.toLowerCase()));

  return (
    <ListBoxDialog<HoldoutComboBoxItem>
      {...props}
      aria-label="Holdouts"
      items={holdoutItems.length ? holdoutItems : []}
      selectionMode="single"
      defaultSelectedKeys={holdoutKey !== undefined ? [holdoutKey] : []}
      isBusy={isPending}
      onFilterChange={handleFilterTextChange}
    >
      {(item) => (
        <ListBoxItem id={item.id} textValue={item.id}>
          <Text slot="label">
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

type EnvironmentComboBoxItem = {
  id: string;
  name: string;
  color: string;
  description?: string;
};
type EnvironmentDialogProps = Omit<
  ListBoxDialogProps<EnvironmentComboBoxItem>,
  'isBusy' | 'onFilterChange' | 'children'
> & {
  environmentKeys: string[];
  projectKey?: string;
};
export function EnvironmentDialog({ environmentKeys, projectKey, ...props }: EnvironmentDialogProps) {
  const state = useContext(OverlayTriggerStateContext);
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');
  const data = useSuspenseQueries({
    queries: [
      environmentsList({
        projectKey: String(projectKey),
        params: { limit: 10, sort: 'name', filter: { query: filterText } },
      }),
    ],
  });
  const [environments] = data;

  const specificEnvironmentKeys = environmentKeys.filter((key) => key !== anyResource);
  const selectedEnvironmentsData = useSuspenseQueries({
    queries: specificEnvironmentKeys.map((key) =>
      environmentsDetail({
        projectKey: String(projectKey),
        environmentKey: key,
      }),
    ),
  });

  const environmentItems: EnvironmentComboBoxItem[] = selectedEnvironmentsData.map((env) => ({
    id: env.data.key,
    name: env.data.name,
    color: env.data.color,
  }));

  environments.data.items.forEach((env) => {
    if (!environmentItems.some((item) => item.id === env.key)) {
      environmentItems.push({
        id: env.key,
        name: env.name,
        color: env.color,
      });
    }
  });

  environmentItems.unshift({
    id: '*',
    name: 'All',
    color: '',
  });

  const handleFilterTextChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  return (
    <ListBoxDialog<EnvironmentComboBoxItem>
      {...props}
      aria-label="Environments"
      items={environmentItems.length ? environmentItems : []}
      isBusy={isPending}
      onFilterChange={handleFilterTextChange}
      onSelectionChange={(selection) => {
        props?.onSelectionChange && props.onSelectionChange(selection);
        state?.close();
      }}
    >
      {(item) => (
        <ListBoxItem id={item.id} textValue={item.id}>
          <Text slot="label">
            {item.color && <EnvironmentMarker color={item.color} />}
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

type FlagComboBoxItem = {
  id: string;
  name: string;
  description?: string;
};
type FlagDialogProps = Omit<ListBoxDialogProps<FlagComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  flagKey?: string;
  projectKey?: string;
};
function FlagDialog({ flagKey, projectKey, ...props }: FlagDialogProps) {
  // must be optional since we are using this component in a non-context route (settings pages)
  const selectedEnvironmentKey = useSelectedEnvironmentKey({ optional: true });
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');
  const data = useSuspenseQueries({
    queries: [
      getFeatureFlagsQuery({
        projectKey: String(projectKey),
        query: {
          limit: 10,
          sort: '-creationDate',
          env: selectedEnvironmentKey ? [selectedEnvironmentKey] : undefined,
          filter: { query: filterText },
        },
      }),
    ],
  });
  const [flags] = data;

  const flagItems: FlagComboBoxItem[] = flags.data.items.map((flag) => ({
    id: flag.key,
    name: flag.name,
  }));

  const handleFilterTextChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  flagItems.unshift({
    id: '*',
    name: 'All',
  });

  return (
    <ListBoxDialog<FlagComboBoxItem>
      {...props}
      aria-label="Flags"
      items={flagItems.length ? flagItems : []}
      selectionMode="single"
      defaultSelectedKeys={flagKey !== undefined ? [flagKey] : []}
      isBusy={isPending}
      onFilterChange={handleFilterTextChange}
    >
      {(item) => (
        <ListBoxItem key={item.id} id={item.id} textValue={item.id}>
          <Text slot="label">
            <Icon name="flag" size="small" />
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
          {item.description && (
            <Text slot="description">
              <TextTruncator>{item.description}</TextTruncator>
            </Text>
          )}
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

type ProjectComboBoxItem = {
  id: string;
  name: string;
  description?: string;
};
type ProjectDialogProps = Omit<ListBoxDialogProps<ProjectComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  projectId?: string;
};
function ProjectDialog({ projectId, ...props }: ProjectDialogProps) {
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');
  const data = useSuspenseQueries({
    queries: [projectsList({ params: { limit: 10, sort: 'name', filter: { query: filterText } } })],
  });
  const [projects] = data;

  const projectItems: ProjectComboBoxItem[] = projects.data.items.map((project) => ({
    id: project.key,
    name: project.name,
  }));

  const [selectedProjectQuery] = useSuspenseQueries({
    queries: [
      conditionalSuspenseQuery({
        query: projectsDetail({ projectKey: projectId ?? '' }),
        enabled: projectId !== undefined && projectId !== anyResource,
      }),
    ],
  });

  if (isSuspenseQueryEnabled(selectedProjectQuery) && selectedProjectQuery.data) {
    projectItems.unshift({
      id: selectedProjectQuery.data.key,
      name: selectedProjectQuery.data.name,
    });
  }

  const handleFilterTextChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  projectItems.unshift({
    id: '*',
    name: 'All',
  });

  return (
    <ListBoxDialog<ProjectComboBoxItem>
      {...props}
      aria-label="Projects"
      items={projectItems.length ? projectItems : []}
      selectionMode="single"
      defaultSelectedKeys={projectId !== undefined ? [projectId] : []}
      isBusy={isPending}
      onFilterChange={handleFilterTextChange}
    >
      {(item) => (
        <ListBoxItem id={item.id} textValue={item.id}>
          <Text slot="label">
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
          {item.description && (
            <Text slot="description">
              <TextTruncator>{item.description}</TextTruncator>
            </Text>
          )}
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

type MetricComboBoxItem = {
  id: string;
  name: string;
  description?: string;
};
type MetricDialogProps = Omit<ListBoxDialogProps<MetricComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  project: string;
  metricKey?: string;
};

function MetricDialog({ metricKey, project, ...props }: MetricDialogProps) {
  const [isPending] = useTransition();
  const [metricQuery, setMetricQuery] = useState('');
  const data = useSuspenseQueries({
    queries: [metricsList({ projectKey: project, params: { sort: 'name' } })],
  });
  const [metrics] = data;

  const metricItems: MetricComboBoxItem[] =
    metrics?.data?.items
      ?.filter((metric) => metricQuery === '' || metric.name.toLowerCase().includes(metricQuery.toLowerCase()))
      .map((metric) => ({
        id: metric.key,
        name: metric.name,
      })) ?? [];

  const [selectedMetricQuery] = useSuspenseQueries({
    queries: [
      conditionalSuspenseQuery({
        query: metricsDetail({ projectKey: project, metricKey: metricKey ?? '' }),
        enabled: metricKey !== undefined && metricKey !== anyResource,
      }),
    ],
  });

  if (isSuspenseQueryEnabled(selectedMetricQuery) && selectedMetricQuery.data) {
    metricItems.unshift({
      id: selectedMetricQuery.data.key,
      name: selectedMetricQuery.data.name,
    });
  }

  metricItems.unshift({
    id: '*',
    name: 'All',
  });

  return (
    <ListBoxDialog<MetricComboBoxItem>
      {...props}
      aria-label="Metrics"
      items={metricItems.length ? metricItems : []}
      selectionMode="single"
      defaultSelectedKeys={metricKey !== undefined ? [metricKey] : []}
      isBusy={isPending}
      onFilterChange={setMetricQuery}
    >
      {(item) => (
        <ListBoxItem id={item.id} textValue={item.id}>
          <Text slot="label">
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
          {item.description && (
            <Text slot="description">
              <TextTruncator>{item.description}</TextTruncator>
            </Text>
          )}
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

function SelectAllListBox({
  defaultSelectedKeys,
  children,
}: {
  defaultSelectedKeys: string[] | 'all';
  children: ReactNode;
}) {
  const [selectedKeys, onSelectionChange] = useState<Selection>(
    Array.isArray(defaultSelectedKeys) ? new Set(defaultSelectedKeys) : 'all',
  );

  return <ListBoxContext.Provider value={{ selectedKeys, onSelectionChange }}>{children}</ListBoxContext.Provider>;
}

function SelectAllCheckbox({ children, ...props }: CheckboxProps) {
  return <Checkbox {...props}>{children}</Checkbox>;
}

type ActionComboBoxItem = {
  id: string;
  description?: string;
};

type ActionComboBoxGroupItem = {
  name: string;
  id: string;
  children: ActionComboBoxItem[];
};

type ActionDialogProps = Omit<ListBoxDialogProps<ActionComboBoxGroupItem>, 'isBusy' | 'onFilterChange'> & {
  actions?: string[];
  resource: ActionTypes;
};

function ActionsDialog({ actions, resource, ...props }: ActionDialogProps) {
  const [filterText, setFilterText] = useState('');
  const actionResult = useSuspenseActions({});
  const actionData = actionResult.data[resource] ?? [];

  const filterOutReleaseActions = (action: ActionComboBoxItem) => {
    if (enableReleaseAutomation()) {
      return true;
    }
    return ![
      'updateReleasePhaseStatus',
      'addReleasePipeline',
      'removeReleasePipeline',
      'replaceReleasePipeline',
      'updateReleasePhaseCompleted',
      'terminateReleaseAudience',
    ].includes(action.id);
  };

  const actionsItems: ActionComboBoxItem[] = actionData
    .map((action) => ({
      id: nullthrows(action.action),
      description: action.description,
    }))
    .toSorted((a, b) => a.id.localeCompare(b.id))
    .filter(filterOutReleaseActions);

  let actionsGroup: ActionComboBoxGroupItem[] = [
    {
      name: 'Actions',
      id: 'actions',
      children: actionsItems,
    },
  ];

  if (resource === 'flag') {
    actionsGroup = [
      {
        name: 'Flag targeting changes',
        id: 'targetingChanges',
        children: actionsItems.filter((action) => actionsRelatedToTargetingChanges.includes(action.id)),
      },
      {
        name: 'Flag settings changes',
        id: 'flagSettings',
        children: actionsItems.filter((action) => actionsRelatedToFlagSettings.includes(action.id)),
      },
      {
        name: 'Environment settings changes',
        id: 'environmentSettings',
        children: actionsItems.filter((action) => actionsRelatedToEnvironmentSettings.includes(action.id)),
      },
      {
        name: 'Collaboration changes',
        id: 'collaboration',
        children: actionsItems.filter((action) => actionsRelatedToCollaboration.includes(action.id)),
      },
      {
        name: 'Other actions',
        id: 'otherActions',
        children: actionsItems.filter(
          (action) =>
            !actionsRelatedToTargetingChanges.includes(action.id) &&
            !actionsRelatedToFlagSettings.includes(action.id) &&
            !actionsRelatedToEnvironmentSettings.includes(action.id) &&
            !actionsRelatedToCollaboration.includes(action.id),
        ),
      },
    ];
  }

  const handleFilterTextChange = (value: string) => {
    setFilterText(value);
  };

  // client side filter action items since the API does not support filtering
  if (filterText !== undefined && filterText !== '') {
    const filteredActions = actionsGroup.flatMap((group) => {
      const filteredChildren = group.children.filter(
        (item) =>
          item.id.toLowerCase().includes(filterText.toLowerCase()) ||
          item.description?.toLowerCase().includes(filterText.toLocaleLowerCase()),
      );

      return [{ ...group, children: filteredChildren }];
    });

    actionsGroup = filteredActions;
  }

  const checkSubset = (parentArray: string[], subsetArray: string[]) =>
    subsetArray.every((el) => parentArray.includes(el));

  const DefaultselectedGroup = actions
    ? actionsGroup
        .filter((group) =>
          actions.includes(anyResource)
            ? true
            : checkSubset(
                actions,
                group.children.map((child) => child.id).filter((id) => id !== anyResource),
              ),
        )
        .map((group) => group.id)
    : undefined;

  // if all actions are selected, select the 'All' checkbox
  if (DefaultselectedGroup?.length === actionsGroup.length) {
    DefaultselectedGroup.push(anyResource);
  }

  return (
    <CheckboxGroup
      defaultValue={DefaultselectedGroup}
      className={styles.actionsDialog}
      onChange={(value) => {
        if (props?.onSelectionChange) {
          const isDeselectingAll = checkSubset(
            value,
            actionsGroup.map((group) => group.id),
          );
          if (isDeselectingAll) {
            // set the selected actions to the first group of actions
            props?.onSelectionChange(new Set(actionsGroup[0].children.map((b) => b.id)));
          } else {
            const selectedActions = new Set(
              actionsGroup
                .filter((a) => (value.includes(anyResource) ? true : value.includes(a.id)))
                .flatMap((a) => a.children)
                .map((b) => b.id),
            );
            props?.onSelectionChange(selectedActions);
          }
        }
      }}
    >
      {({ state: { value } }) => {
        let selectedKeysFromSelection: string[] | 'all' = actionsGroup
          .filter((a) => value.includes(a.id))
          .flatMap((a) => a.children)
          .map((b) => b.id);

        if (actions && actions.length > 0) {
          actions.forEach((action) => {
            if (Array.isArray(selectedKeysFromSelection)) {
              selectedKeysFromSelection.push(action);
            }
          });
        }

        if (actions?.includes('*')) {
          selectedKeysFromSelection = 'all';
        } else {
          selectedKeysFromSelection = Array.from(new Set(selectedKeysFromSelection));
        }

        return (
          <SelectAllListBox key={value.toString()} defaultSelectedKeys={selectedKeysFromSelection}>
            <ListBoxDialog<ActionComboBoxGroupItem>
              {...props}
              aria-label="Actions"
              items={actionsGroup}
              selectionMode="multiple"
              isBusy={false}
              onFilterChange={handleFilterTextChange}
              afterSearch={
                actionsGroup.length > 1 ? (
                  <Box
                    display="flex"
                    color="$ui.tertiary"
                    font="$label.2.medium"
                    flexDirection="row-reverse"
                    alignItems="center"
                  >
                    <LPCheckBox value={anyResource}>Select all actions</LPCheckBox>
                  </Box>
                ) : null
              }
            >
              {(section) => (
                <ListBoxSection id={section.name}>
                  <Header>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      {section.name}
                      <SelectAllCheckbox className={styles.checkbox} value={section.id}>
                        {({ isSelected }) => <div>{isSelected ? 'Deselect' : 'Select'} all</div>}
                      </SelectAllCheckbox>
                    </Box>
                  </Header>
                  <Collection items={section.children}>
                    {(item) => (
                      <ListBoxItem>
                        <Text slot="label">{item.id}</Text>
                        <Text slot="description">{item.description}</Text>
                      </ListBoxItem>
                    )}
                  </Collection>
                </ListBoxSection>
              )}
            </ListBoxDialog>
          </SelectAllListBox>
        );
      }}
    </CheckboxGroup>
  );
}

type MemberComboBoxItem = {
  id: string;
  name: string;
  email: string;
  initials: string | undefined;
  description?: string;
};
type MaintainerDialogProps = Omit<ListBoxDialogProps<MemberComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  maintainerId?: string;
};
function MemberDialog({ maintainerId, ...props }: MaintainerDialogProps) {
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');
  const data = useSuspenseQueries({
    queries: [membersQuery({ query: { limit: 5, sort: 'displayName', filter: { query: filterText } } })],
  });
  const [members] = data;

  // The coercion here is a bit weird: the queries won't run unless the values exist, but TypeScript can't infer that
  // from the enabled option on the queries.
  const maintainerMember = useMember({ memberId: maintainerId ?? '' }, { enabled: maintainerId !== undefined });

  const memberItems: MemberComboBoxItem[] = [];
  for (const member of members.data.items) {
    if (member._id === maintainerId && (filterText === undefined || filterText === '')) {
      continue;
    }

    const name = memberDisplayName(member);
    memberItems.push({
      id: member._id,
      name,
      email: member.email,
      initials: memberInitials(member),
      description: member.email !== name ? member.email : undefined,
    });
  }

  if (filterText === undefined || filterText === '') {
    if (maintainerMember.status === 'success') {
      const member = maintainerMember.data;
      const name = memberDisplayName(member);
      memberItems.unshift({
        id: maintainerId ?? '',
        name,
        email: member.email,
        initials: memberInitials(maintainerMember.data),
        description: member.email !== name ? member.email : undefined,
      });
    }
  }

  // add All option
  memberItems.unshift({
    id: '*',
    name: 'All',
    email: 'any@member.com',
    initials: 'a.a',
  });

  const handleFilterTextChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  return (
    <ListBoxDialog<MemberComboBoxItem>
      {...props}
      aria-label="Members"
      items={memberItems.length ? memberItems : []}
      defaultSelectedKeys={maintainerId !== undefined ? [maintainerId] : []}
      selectionMode="single"
      isBusy={isPending}
      onFilterChange={handleFilterTextChange}
    >
      {(item) => (
        <ListBoxItem id={item.id} key={item.id} textValue={item.id}>
          <Text slot="label">
            <MemberAvatar size="small" email={item?.email} initials={item?.initials} />
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}

type TeamComboBoxItem = {
  id: string;
  name: string;
  description?: string;
};
type TeamDialogProps = Omit<ListBoxDialogProps<TeamComboBoxItem>, 'isBusy' | 'onFilterChange' | 'children'> & {
  maintainerTeamKey?: string;
};
function TeamDialog({ maintainerTeamKey, ...props }: TeamDialogProps) {
  const [isPending, startTransition] = useTransition();
  const [filterText, setFilterText] = useState('');
  const data = useSuspenseQueries({
    queries: [teamsList({ params: { limit: 5, filter: { query: filterText } } })],
  });
  const [teams] = data;

  // The coercion here is a bit weird: the queries won't run unless the values exist, but TypeScript can't infer that
  // from the enabled option on the queries.
  const maintainerTeam = useTeam({ teamKey: maintainerTeamKey ?? '' }, { enabled: maintainerTeamKey !== undefined });

  const teamItems: TeamComboBoxItem[] = [];
  for (const team of nullthrows(teams.data.items)) {
    if (team.key === maintainerTeamKey && (filterText === undefined || filterText === '')) {
      continue;
    }

    teamItems.push({
      id: nullthrows(team.key),
      name: nullthrows(team.name),
    });
  }

  if (filterText === undefined || filterText === '') {
    if (maintainerTeam.status === 'success') {
      const team = maintainerTeam.data;
      teamItems.unshift({
        id: nullthrows(team.key),
        name: nullthrows(team.name),
      });
    }
  }

  // add any option
  teamItems.unshift({
    id: '*',
    name: 'All',
  });

  const handleFilterTextChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  return (
    <ListBoxDialog<TeamComboBoxItem>
      {...props}
      aria-label="Teams"
      items={teamItems.length ? teamItems : []}
      selectionMode="single"
      defaultSelectedKeys={maintainerTeamKey !== undefined ? [maintainerTeamKey] : []}
      isBusy={isPending}
      onFilterChange={handleFilterTextChange}
    >
      {(item) => (
        <ListBoxItem key={item.id} id={item.id} textValue={item.id}>
          <Text slot="label">
            <Icon name="group" size="small" />
            <TextTruncator>{item.name}</TextTruncator>
          </Text>
          {item.description && (
            <Text slot="description">
              <TextTruncator>{item.description}</TextTruncator>
            </Text>
          )}
        </ListBoxItem>
      )}
    </ListBoxDialog>
  );
}
