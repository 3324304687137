import { useExperiments } from '@gonfalon/experiments';
import { useProjectKey } from '@gonfalon/router';

export function useHasRunningExperiment(flagKey: string, environmentKey: string): boolean {
  const projectKey = useProjectKey();

  const { status, data } = useExperiments({
    projectKey,
    environmentKey,
    params: {
      filter: {
        flagKey,
        kind: 'experiment',
      },
    },
  });

  const experiments = data?.items.filter((exp) => exp?.currentIteration?.status === 'running') ?? [];

  return status === 'success' && experiments.length > 0;
}
