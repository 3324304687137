import { ReactNode } from 'react';
import { PressEvent } from 'react-aria';
import { IconButton } from '@launchpad-ui/components';
import clsx from 'clsx';

import styles from './Pagination.module.css';

type PaginationRenderProps = {
  from: number;
  to: number;
  total: number | string;
};

type PaginationProps = {
  from: number;
  to: number;
  total: number | string;
  onFirstClick?: (e: PressEvent) => void;
  onPreviousClick?: (e: PressEvent) => void;
  onNextClick?: (e: PressEvent) => void;
  onLastClick?: (e: PressEvent) => void;
  className?: string;
  children?: ((values: PaginationRenderProps) => ReactNode) | undefined;
};

export function Pagination({
  from,
  to,
  total,
  className,
  children,
  onFirstClick,
  onPreviousClick,
  onNextClick,
  onLastClick,
}: PaginationProps) {
  return (
    <div className={clsx(styles.pagination, className)}>
      <IconButton
        size="small"
        icon="chevrons-left"
        aria-label="first page"
        isDisabled={onFirstClick === undefined}
        onPress={onFirstClick}
        variant="minimal"
      />
      <IconButton
        size="small"
        icon="chevron-left"
        aria-label="previous page"
        isDisabled={onPreviousClick === undefined}
        onPress={onPreviousClick}
        variant="minimal"
      />
      <div className={styles.hud}>
        {typeof children === 'function' ? (
          children({ from, to, total })
        ) : (
          <>
            <strong>
              {from}–{to}
            </strong>{' '}
            of <strong>{total}</strong>
          </>
        )}
      </div>
      <IconButton
        size="small"
        icon="chevron-right"
        aria-label="next page"
        isDisabled={onNextClick === undefined}
        onPress={onNextClick}
        variant="minimal"
      />
      <IconButton
        size="small"
        icon="chevrons-right"
        aria-label="last page"
        isDisabled={onLastClick === undefined}
        onPress={onLastClick}
        variant="minimal"
      />
    </div>
  );
}

export const PaginationFallback = () => (
  <div className={styles.pagination}>
    <div className={styles.hud}>Unable to load pagination</div>
  </div>
);
