import { ReactElement, useState } from 'react';
import { components, MenuPosition, MultiValueProps, SingleValueProps } from 'react-select';
import { isArray } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { OrderedMap, Set } from 'immutable';

import SelectMemberOption, { getMemberOptionStringLabel } from 'components/SelectMemberOption';
import { Member } from 'utils/accountUtils';
import { stringContains } from 'utils/stringUtils';

import 'stylesheets/components/SelectMember.css';

export type MemberOptionType = OptionProps & {
  email: string;
  isAdminOrOwner?: boolean;
  isCurrentMember?: boolean;
  member: Member;
  name?: string;
};

export type SelectMemberProps = {
  ariaLabel?: string;
  className?: string;
  closeMenuOnSelect?: boolean;
  customMultiValueRemoveTooltip?: string;
  customNoOptionsMessage?: ReactElement | string;
  disabled?: boolean;
  filterByMemberId?: Set<string>;
  filterMembers?: (m: Member) => boolean;
  filterOutCurrentMember?: boolean;
  filterPendingInvites?: boolean;
  isClearable?: boolean;
  isLoading: boolean;
  isMulti?: boolean;
  isTeamsMemberContainer?: boolean;
  menuPosition?: MenuPosition;
  members: OrderedMap<string, Member>;
  name?: string;
  onChange(member?: string | string[] | null): void;
  onInputChange?(input: string): void;
  placeholder?: string;
  profile?: Member;
  suggestedMemberIds?: string[];
  value?: Member | Member[] | null | string;
  valueContainerStyles?: object;
  menuPortalStyles?: object;
  menuStyles?: object;
};

export function SelectMember({
  ariaLabel = 'Maintainer',
  className,
  closeMenuOnSelect,
  customNoOptionsMessage,
  disabled,
  filterByMemberId,
  filterMembers = () => true,
  filterOutCurrentMember = false,
  filterPendingInvites,
  isLoading,
  isMulti = false,
  isTeamsMemberContainer,
  menuPosition = 'fixed',
  members = OrderedMap(),
  onChange,
  placeholder: originalPlaceholder,
  profile,
  suggestedMemberIds = [],
  value = null,
  valueContainerStyles = {},
  menuPortalStyles = {},
  menuStyles = {},
  ...other
}: SelectMemberProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isCurrentMember = (member: Member) => member._id === profile?._id;
  const isSelectedMember = (member: Member) => member._id === (value as Member)?._id;
  const isCurrentMemberSelected = profile?._id === (value as Member)?._id;
  const filteredMembersList = members
    .toList()
    .filter((m) => !isCurrentMember(m) && !isSelectedMember(m) && filterMembers(m));
  const suggestedMembers = filteredMembersList.filter((m) => suggestedMemberIds.includes(m._id));
  const notSuggestedMembers = filteredMembersList.filter((m) => !suggestedMemberIds.includes(m._id));
  let sortedMembers;
  if (isMulti || isCurrentMemberSelected || !value) {
    sortedMembers = [profile, ...suggestedMembers, ...notSuggestedMembers];
  } else {
    sortedMembers = [profile, value, ...suggestedMembers, ...notSuggestedMembers];
  }
  if (filterPendingInvites) {
    sortedMembers = sortedMembers.filter((m) => !(m as Member)?.hasPendingInvite());
  }
  const options = (sortedMembers as Member[])
    .filter((d) => (filterOutCurrentMember ? !isCurrentMember(d) : true))
    .map((m) => ({
      value: m?._id,
      label: m?.getDisplayName(),
      name: m?.getFullName(),
      email: m?.email,
      key: m?._id,
      member: m,
    }));

  const selectedValues = () => (isMulti && value ? (value as Member[]) : []);

  const multiValue = selectedValues()?.map((m) => ({
    value: m?._id,
    label: m?.getDisplayName(),
    name: m?.getFullName(),
    email: m?.email,
    isAdminOrOwner: m?.isAdminOrOwner(),
    isCurrentMember: m && isCurrentMember(m),
    key: m?._id,
    member: m,
  }));

  const handleMenuOpen = () => (!isMulti ? setIsMenuOpen(true) : undefined);

  let placeholder: string;
  if (isMenuOpen) {
    placeholder = 'Find a member by email or name';
  } else {
    placeholder = originalPlaceholder || (isMulti ? 'Select members' : 'Select member');
  }

  const SingleValue = (singleValueProps: SingleValueProps<OptionProps & { email: string; member: Member }>) => (
    <components.SingleValue {...singleValueProps}>
      <SelectMemberOption option={singleValueProps.data} isMulti={isMulti} />
    </components.SingleValue>
  );
  const MultiValue = (multiValueProps: MultiValueProps<MemberOptionType>) => (
    <components.MultiValue {...multiValueProps}>
      <SelectMemberOption option={multiValueProps.data} isMulti={isMulti} />
    </components.MultiValue>
  );

  const filterOption = ({ data }: MemberOptionType, searchValue: string) =>
    !filterByMemberId?.includes(data.key) &&
    (stringContains(data.label, searchValue) || stringContains(data.email, searchValue));

  const handleChange = (option: OptionProps | OptionProps[] | null) => {
    if (isArray(option)) {
      const memberIds = option.map((d) => d.value as string);
      onChange(memberIds);
    } else {
      const memberId = (option?.value as string) || null; // null is for react-select
      onChange(memberId);
    }
  };

  const customStyles = {
    control: { padding: '0.125rem 0' },
    valueContainer: {
      color: 'var(--lp-color-text-ui-primary-base)',
      maxHeight: '10.625rem',
      overflow: 'auto',
      padding: '0.125rem 0.1875rem',
      ...valueContainerStyles,
    },
    multiValueRemove: {
      display: disabled ? 'none' : 'flex',
    },
    menuPortal: {
      ...menuPortalStyles,
    },
    menu: {
      ...menuStyles,
    },
  };

  const noOptionsMessage = customNoOptionsMessage || 'No members found';

  return (
    <CustomSelect
      {...other}
      menuPosition={menuPosition}
      ariaLabel={ariaLabel}
      closeMenuOnSelect={closeMenuOnSelect}
      controlShouldRenderValue={!isMenuOpen}
      customComponents={{ SingleValue, MultiValue }}
      disabled={disabled}
      filterOption={filterOption}
      formatOptionLabel={(option) => (
        <SelectMemberOption option={option as unknown as MemberOptionType} isInMenu isMulti={isMulti} />
      )}
      getOptionLabel={(option: MemberOptionType) => getMemberOptionStringLabel(option)}
      isClearable={false}
      isMulti={isMulti}
      noOptionsMessage={() => (isLoading ? 'Loading' : noOptionsMessage)}
      onChange={handleChange}
      onMenuClose={() => (!isMulti ? setIsMenuOpen(false) : undefined)}
      onMenuOpen={() => handleMenuOpen()}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      value={isMulti ? multiValue : options.filter((option) => option.value === (value as Member)?._id)}
    />
  );
}

/* eslint-disable import/no-default-export */
export default SelectMember;
