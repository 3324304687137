import { cloneDeep } from '@gonfalon/es6-utils';
import dayjs from 'dayjs';
import millify from 'millify';

import { chartColors } from '../components/charts/theme';
import { periodFormatByTimegrainLabel } from '../features/block/constants';

import { parseDateToTimeZone } from './convertDateFormat';
import { getTextWidth } from './utils';

export function getFormattedDate(value, timeGranularity, lineBreak = true) {
  if (timeGranularity === 'week') {
    return `${parseDateToTimeZone({
      dateString: value,
      format: periodFormatByTimegrainLabel[timeGranularity],
    })} - ${parseDateToTimeZone({
      dateString: dayjs(value).add(1, 'week'),
      format: periodFormatByTimegrainLabel[timeGranularity],
    })}`;
  }
  if (!lineBreak) {
    return parseDateToTimeZone({
      dateString: value,
      format: periodFormatByTimegrainLabel[timeGranularity],
    });
  }
  return parseDateToTimeZone({
    dateString: value,
    format: periodFormatByTimegrainLabel[timeGranularity],
  }).replaceAll(' ', '\n');
}

export function getFormattedDateTooltip(value, timeGranularity) {
  if (timeGranularity === 'week') {
    return `${parseDateToTimeZone({
      dateString: value,
      format: `[Week] WW, Do MMM YYYY`,
    })} - ${parseDateToTimeZone({
      dateString: value,
      format: `Do MMM YYYY`,
    })}`;
  }
  if (timeGranularity === 'day') {
    return parseDateToTimeZone({
      dateString: value,
      format: 'Do MMM YYYY',
    });
  }
  return parseDateToTimeZone({
    dateString: value,
    format: periodFormatByTimegrainLabel[timeGranularity],
  });
}

export const echartsBaseOptions = {
  grid: { top: 80, right: 50, bottom: 60, left: 24, containLabel: true },
  color: chartColors,
};

export const curveChartDataTemplate = {
  ...echartsBaseOptions,
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      width: 200,
      align: 'center',
      overflow: 'break',
      hideOverlap: true,
      formatter: (value) => `${value} ${value === 1 ? 'day' : 'days'}`,
    },
  },
  yAxis: [
    {
      name: 'Stickiness %',
      type: 'value',
      position: 'left',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {},
      },
      axisLabel: {
        formatter(value) {
          return `${millify(Number.isInteger(value) ? Number(value) : Number(value).toFixed(2))}%`;
        },
        fontSize: 8,
        hideOverlap: true,
      },
    },
  ],
  tooltip: {
    trigger: 'axis',
    confine: true,
    order: 'valueDesc',
    formatter: (param) => {
      let html = `<b>Average Stickiness: day ${param[0].axisValue}</b></br>`;
      param
        .sort((a, b) => b.data.stickedUsersNum - a.data.stickedUsersNum)
        .forEach(({ marker, data, seriesName }) => {
          html += `
                ${marker} <b>${seriesName}</b> - ${data.stickinessPercentage.toFixed(
                  2,
                )}% (${Number(data.stickedUsersNum).toLocaleString()})</br>
              `;
        });
      return html;
    },
  },
  legend: {
    padding: [0, 24],
    type: 'scroll',
    data: [],
    scrollDataIndex: 0,
    selected: {},
    selector: [
      {
        type: 'all',
        title: 'Select/Deselect All',
      },
    ],
    selectorLabel: {
      fontSize: 10,
    },
  },
  toolbox: {
    right: 0,
    top: 20,
    orient: 'vertical',
    feature: {
      saveAsImage: {
        name: `houseware_${dayjs(new Date()).format('L LT')}`,
      },
    },
  },
  animation: false,
};

export const sankeyChartTemplate = {
  ...echartsBaseOptions,
  series: {
    type: 'sankey',
    nodeAlign: 'left',
    layoutIterations: 0,
    labelLayout: {
      hideOverlap: true,
    },
    label: {
      show: true,
      position: 'middle',
    },
    emphasis: {
      focus: 'trajectory',
    },
    data: [],
    links: [],
    nodeGap: 40,
  },
  tooltip: {
    trigger: 'item',
    axisPointer: {
      label: {
        formatter: undefined,
      },
    },
  },
  toolbox: {
    right: 10,
    top: 30,
    orient: 'vertical',
    feature: {
      saveAsImage: {
        name: 'houseware',
      },
    },
  },
  animation: false,
};

export const getLegendStyle = ({ vizOptionsProvided, enableSelectDeselect = true, height = 0 }) => {
  const vizOptions = cloneDeep(vizOptionsProvided);
  vizOptions.legend = {
    top: 0,
    ...vizOptions.legend,
    padding: [0, 24],
    itemStyle: {
      width: 0,
      height: 0,
      opacity: 0,
    },
    itemWidth: 0,
    itemHeight: height,
    ...(enableSelectDeselect
      ? {
          selectorLabel: {
            backgroundColor: '#fff',
            borderColor: '#d9d9d9',
            borderRadius: 6,
            padding: [5, 12],
            fontSize: 12,
            color: '#333333',
            shadowBlur: 0,
            shadowColor: '#00000005',
            shadowOffsetX: 0,
            shadowOffsetY: 2,
          },
        }
      : {}),
  };
  const mapLegendNameToColorIdx = {};
  let idx = 0;
  vizOptions?.legend?.data?.forEach((legend) => {
    const legendName = typeof legend === 'string' ? legend : legend?.name;
    if (!mapLegendNameToColorIdx[legendName]) {
      mapLegendNameToColorIdx[legendName] = idx;
      idx++;
    }
  });
  vizOptions.legend.data = vizOptions?.legend?.data?.map((legend) => {
    const legendName = typeof legend === 'string' ? legend : legend?.name;
    return {
      ...legend,
      name: legendName,
      textStyle: {
        color: enableSelectDeselect
          ? vizOptions.legend.selected[legendName]
            ? (legend?.itemStyle?.color ?? vizOptions.color[mapLegendNameToColorIdx[legendName]])
            : '#d9d9d9'
          : (legend?.itemStyle?.color ?? vizOptions.color[mapLegendNameToColorIdx[legendName]]),
        backgroundColor: '#fff',
        borderType: legend?.itemStyle?.borderType ?? 'inherit',
        borderWidth: 1,
        borderColor: '#d9d9d9',
        borderRadius: 6,
        padding: 5,
        // calculating the text width maunally, as echart is playing tricks
        // and text is getting cropped on first load.
        // explicit width fixes that.
        width: getTextWidth(null, legendName, '12px') * 1.055,
        fontSize: 12,
        fontWeight: 'bold',
        shadowBlur: 0,
        shadowColor: '#00000005',
        shadowOffsetX: 0,
        shadowOffsetY: 2,
      },
    };
  });
  return vizOptions.legend;
};

export function convertDataToHeatMapData(retentionData, retentionDataByBreakdown, selectedBreakdownValue = '') {
  let maxRet = 0;
  const rententionPeriodSet = new Set();
  const cohertPeriodSet = new Set();

  const retentionPeriods = [];
  const cohertPeriods = [];
  const periodsData = [];

  const retentionVizData =
    retentionDataByBreakdown && selectedBreakdownValue
      ? retentionDataByBreakdown[selectedBreakdownValue]
      : retentionData;

  retentionVizData.periods.forEach((data) => {
    const cohort = !!data?.cohort
      ? {
          label: data.cohort_value === false ? `Not in ${data.cohort}` : `${data.cohort}`,
          cohort_uuid: data?.cohort_uuid,
          cohort_value: data.cohort_value,
        }
      : {};
    if (data.retention_percentage > maxRet) {
      maxRet = data.retention_percentage;
    }
    periodsData.push({
      value: [data.retention_period + 1, data.cohort_period, data.retention_percentage.toFixed(2) || '-'],
      date: data.cohort_period,
      retentionValue: data.retension_value,
      retentionPercentage: data.retention_percentage,
      dropoffValue: data.dropoff_value,
      dropoffPercentage: data.dropoff_percentage,
      cohort,
      dimension: {
        name: data.dimension,
        value: data.dimension_value,
      },
    });
    if (!rententionPeriodSet.has(data.retention_period)) {
      retentionPeriods.push(data.retention_period);
      rententionPeriodSet.add(data.retention_period);
    }
    if (data.cohort_period && !cohertPeriodSet.has(data.cohort_period)) {
      cohertPeriods.push(data.cohort_period);
      cohertPeriodSet.add(data.cohort_period);
    }
  });

  const averageData = retentionVizData.averages.map((d, idx) => ({
    value: [idx + 1, 'Average Retention', d.retention_percentage.toFixed(2) || '-'],
    retentionValue: d.retension_value,
    retentionPercentage: d.retention_percentage,
    dropoffValue: d.dropoff_value,
    dropoffPercentage: d.dropoff_percentage,
  }));

  const dateSet = new Set();
  const totalCohertSizes = [
    {
      value: ['Total users', 'Average Retention', 100],
    },
  ];
  retentionVizData.periods.forEach((d) => {
    if (!dateSet.has(d.cohort_period)) {
      const cohertData = {
        value: ['Total users', d.cohort_period, d.cohort_size],
        cohertSize: d.cohort_size,
        date: d.cohort_period,
      };
      totalCohertSizes.push(cohertData);
    }
  });

  return {
    retentionPeriods,
    cohertPeriods,
    periodsData,
    averageData,
    totalCohertSizes,
    maxRet,
  };
}

export const compareStringsWithAlphabetPrecedenceIgnoringCase = (a, b) => {
  const aStartsWithAlpha = /^[A-Za-z]/.test(a);
  const bStartsWithAlpha = /^[A-Za-z]/.test(b);

  // sort by alphabets first, then other characters (ignoring case)
  if (aStartsWithAlpha && !bStartsWithAlpha) {
    return -1;
  }
  if (!aStartsWithAlpha && bStartsWithAlpha) {
    return 1;
  }

  return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
};
