import { useCallback, useMemo } from 'react';
import { createSearchParams } from 'react-router';
import { useStorageSlot } from '@gonfalon/browser-storage';
import { cacheFlagFiltersAndSortOrdering } from '@gonfalon/dogfood-flags';

import { clearEnvironmentFilters } from './clearEnvironmentFilters';
import { countActiveEnvironmentFilters } from './countActiveEnvironmentFilters';
import { hasActiveFilters } from './hasActiveFilters';
import { parseEnvironmentAwareUIFlagListViewFromBrowser } from './parseEnvironmentAwareUIFlagListViewFromBrowser';
import { serializeUIFlagFilterForBrowser } from './serializeUIFlagFilterForBrowser';
import { UIFlagFilter } from './types';

export const cacheKey = 'flag-list-filters';

export function useCachedFlagListFilters(selectedEnvironmentKey?: string) {
  const [rawParams, slot] = useStorageSlot(cacheKey, {
    storageType: 'sessionStorage',
    isEnabled: cacheFlagFiltersAndSortOrdering(),
  });

  const cached = useMemo(() => {
    if (!rawParams) {
      return {};
    }

    const params = createSearchParams(rawParams);
    const parsedParams = parseEnvironmentAwareUIFlagListViewFromBrowser(params);

    if (params.has('filterEnv') && parsedParams.filter && params.get('filterEnv') !== selectedEnvironmentKey) {
      const filtersWithoutEnvFilters = clearEnvironmentFilters(parsedParams.filter);
      return { ...parsedParams, filter: filtersWithoutEnvFilters };
    }
    return parsedParams;
  }, [rawParams]);

  const put = useCallback(
    (value?: UIFlagFilter | undefined, filterEnv?: string) => {
      if (!value) {
        slot.remove();
        return;
      }

      if (!hasActiveFilters(value)) {
        slot.remove();
        return;
      }

      const flagFilters = structuredClone(value);
      const hasEnvFilters = countActiveEnvironmentFilters(flagFilters) > 0;
      flagFilters.filterEnv = hasEnvFilters ? filterEnv : undefined;

      slot.set(serializeUIFlagFilterForBrowser(flagFilters).toString());
    },
    [rawParams],
  );

  const value = useMemo(
    () => ({
      value: cached,
      put,
    }),
    [cached, put],
  );

  return value;
}
